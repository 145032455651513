import { Form } from 'antd';
import ServicesSelection from 'features/users/components/ServicesSelection';

type Props = {
  onPrevStep: () => void;
  onNextStep?: () => void;
  selected: string[];
  setSelected: (val: string[]) => void;
  onSubmit?: () => void
  isManager?: boolean
};

const UserFormSecondStep = ({ onPrevStep, selected, setSelected, onNextStep, isManager }: Props) => {
  // const form = Form.useFormInstance();
  // const checkValid = async () => {
  //   try {
  //     await form.validateFields([]);
  //     return true;
  //   } catch (error) {
  //     return false;
  //   }
  // };

  // const handleNextStep = async () => {
  //   const isFormValid = await checkValid();
  //   if (!isFormValid) return;
  //   onNextStep();
  // };

  // const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
  //   e.preventDefault();
  //   onSubmit();
  // };

  return (
    <>
      <div className="form-row">
        <p className="form-label">Assigned Services</p>
      </div>
      <ServicesSelection selected={selected} setSelected={setSelected} disabledSelect={isManager}/>
      <Form.Item noStyle name={'services'} />
      <div className='form-submit-wrapper'>
        <button
          data-testing='Back'
          className='common-btn is-white'
          onClick={onPrevStep}
          type='button'>
          {'Back'}
        </button>
        <button
          data-testing='Continue'
          className='common-btn'
          type='button'
          onClick={onNextStep}
        >
          {'Continue'}
        </button>
      </div>
    </>
  );
};

export default UserFormSecondStep;
