import { RcFile } from 'antd/es/upload';
import { AxiosResponse } from 'axios';
import InputSearchText from 'components/common/Input/InputSearchDebounce';
import message from 'components/common/Message';
import ModalConfirm, {
  ModalConfirmRef,
} from 'components/common/Modal/ModalConfirm';
import ButtonFavorite from 'components/common/Table/ButtonFavorite';
import { IMerchantEmployee } from 'features/bookings/components/BookingForm/types';
import ImportModal, { ImportModalProps } from 'features/customers/components/ImportModal';
import { first, get } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import styled from 'styled-components';
import { tagColorClass } from 'utils/common';
import downloadFile from 'utils/downloadFile';
import { formatDurationTime, formatMoney, tagStatusColor } from 'utils/unit';
import ServicesForm, {
  IFuncHandleSubmit,
  IPayloadReturnType,
} from '../components/ServicesForm';
import ServicesTable from '../components/ServicesTable';
import serviceActions from '../services/actions';
import apisService from '../services/apis';
import formatNumber from 'utils/formatNumber';
import ServiceSelectView from '../components/ServiceSelectView';
interface IServiceVariant {
  name?: string;
  price?: number;
  duration?: number;
}

export type IServiceReponse = {
  category: {
    id: number;
    name: string;
    color: string;
    total_sale: number;
    total_services?: number;
  };
  enable_discount?: boolean;
  enable_voucher_sale?: boolean;
  enable_online_booking?: boolean;
  enable?: boolean;
  description?: string;
  id: number;
  merchant_location_id: number[];
  merchant_employees: IMerchantEmployee[];
  regular_price: number;
  sale_price: number;
  service_variants: IServiceVariant[];
  name?: string;
  service_name?: string;
  service_price?: number;
  duration_time?: number;
  is_favourite?: boolean;

  assigned_employee: {
    employee: {
      id: number;
      full_name: string;
      email: string;
      phone: string;
      merchant_location_id: number;
    };
    time_start: string;
    duration: string;
    time_end: string;
    status: string;
  };

  checked?: boolean;
  time_start?: string;
  employee_id?: number;
  quantity?: number;
  serviceVariantName?: string;
  serviceVariantPrice?: number;
  service_id?: string;
};

const ServicesPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serviceId, setServiceId] = useState<number>();
  const [currService, setCurrService] = useState<any>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const modalConfirmRef = useRef<ModalConfirmRef>(null);
  const { t: bookingLang } = useTranslation('booking');
  const setPageLoading = useSetLoadingPage();

  const [isOpenImportModal, setIsOpenImportModal] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(
    //   serviceActions.getListPaginateServices.fetch({
    //     page: Number(searchParams.get('page') ?? 1),
    //     per_page: Number(searchParams.get('per_page') ?? 10),
    //     keyword: searchParams.get('keyword') ?? ''
    //   })
    // );
    // const page: any = {
    //   page: searchParams.get('page') ?? 1,
    //   per_page: searchParams.get('per_page') ?? 10,
    //   keyword: searchParams.get('keyword') || undefined,
    // };

    // dispatch(serviceActions.setServiceListParams(page));

    dispatch(serviceActions.getShowCreateService.fetch());
  }, []);

  const onAddItem = () => {
    setIsEdit(false);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setCurrService(null);
  };

  const handleSubmitUpdate = async (payload: IPayloadReturnType) => {
    handleCancel();
    try {
      const rest: AxiosResponse<{ message: string }> =
        await apisService.updateServices(serviceId, payload);
      const msg = get(rest, 'data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(
          serviceActions.setServiceListParams({
            page: Number(searchParams.get('page') ?? 1),
            per_page: Number(searchParams.get('per_page') ?? 10),
            keyword: searchParams.get('keyword') ?? ''
          })
        );
        return true;
      } else {
        const msgError = get(rest, 'data.error.message', '');
        if (msgError) message.error(msgError);
        return false;
      }
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );
      const errors = Object.values(response);
      errors.map((value) => {
        message.error(get(value, [0], ''));
      });
      return false;
    }
  };

  const handleSubmitStore = async (payload: IPayloadReturnType) => {

    try {
      const rest: AxiosResponse<{ data: { message: string } }> =
        await apisService.storeServices(payload);
      const msg = get(rest, 'data.data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(
          serviceActions.setServiceListParams({
            page: 1,
            per_page: 10,
          })
        );
        handleCancel();
        return true;
      }
      const msgError = get(rest, 'data.error.message', '');
      if (msgError) message.error(msgError);

      return false;
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.values(response);
      errors.map((value) => {
        message.error(get(value, [0], ''));
      });
      return false;
    }
  };

  const handleSubmit: IFuncHandleSubmit = async (payload) => {
    setPageLoading(true);
    try {
      return isEdit
        ? await handleSubmitUpdate(payload)
        : await handleSubmitStore(payload);
    } catch (error) {
    } finally {
      setPageLoading(false);
      dispatch(serviceActions.getShowCreateService.fetch());
    }
  };

  function colorOpancity(color: string, opacity: number) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  const handleSwitchFavourite = async (serviceId: number) => {
    setPageLoading(true);
    try {
      const res = await apisService.switchFavourite(serviceId);
      const msg = get(res, 'data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(serviceActions.setServiceListParams({}));
      } else {
        const errorMsg = get(res, 'data.error.message');
        if (errorMsg) message.error(errorMsg);
        else throw 'fail';
      }
    } catch (error) {
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map(([, valueArr]) => {
          message.error(get(valueArr, [0], ''));
        });
        return;
      }
      message.error('An error occurred. Please try again');
    } finally {
      setPageLoading(false);
    }

  };

  const handleModalUpdate = (record: IServiceReponse) => {
    const fieldsValue = {
      service_name: record?.name,
      price: formatNumber(record?.sale_price),
      duration: record?.duration_time,
      category: record?.category?.id,
      isEnableDiscounts: record?.enable_discount,
      isEnableService: record?.enable,
      isEnableBookingLink: record?.enable_online_booking,
      isEnableVoucherSales: record?.enable_voucher_sale,
      serviceDescription: record?.description,
      availableLocation: record?.merchant_location_id,
      locationTemp: record?.merchant_location_id,
      serviceVariants: record?.service_variants,
      teamMember: record?.merchant_employees?.filter(o => record?.merchant_location_id?.includes(o.merchant_location_id)).map((o) => o.id),
    };
    setCurrService(fieldsValue);
    setServiceId(record?.id);
    setIsEdit(true);
    setIsModalOpen(true);
  };

  const onClickRemoveItem = (record: IServiceReponse) => {
    modalConfirmRef.current?.show({
      title: bookingLang('modalConfirm.deleteService.title'),
      msg: bookingLang('modalConfirm.deleteService.message'),
      submit: async () => {
        try {
          const res = await apisService.deleteService(record.id);
          const msg = get(res, 'data.message', '');
          if (msg) {
            message.success(msg);
            dispatch(serviceActions.setServiceListParams({}));
          } else {
            const msgError = get(res, 'data.error.message');
            if (msgError) message.error(msgError);
          }
        } catch (error) {
          message.error('An error occurred. Please try again');
        }
      },
      icon: 'delete',
    });
  };

  const columns = [
    {
      title: 'Service Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      render: (text: any, record: IServiceReponse) => {
        return (
          <NameOptionStyled onClick={() => handleModalUpdate(record)}>
            <div className='titleContainer'>
            <p className={`${text.length >= 20 ? 'service-name-ellipsis' : ''}`}>{`${text} `}</p>
              {
                record?.service_variants.length == 0
                  ? ''
                  : <div className='optionsNumber'>{`(${record?.service_variants.length}` + ' options)'}</div>
              }
            </div>
          </NameOptionStyled>
        );
      }
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (text: any, { category }: any) => {
        return (
          <div
            className={`common-tag ${tagColorClass(category.name)}`}
            style={{
              backgroundColor: `${colorOpancity(category.color, 0.1)}`,
              color: `${category.color}`,
            }}
          >
            {category.name}
          </div>
        );
      },
    },
    {
      title: 'Price',
      dataIndex: 'sale_price',
      key: 'sale_price',
      render: (text: string, record: IServiceReponse) => `${formatMoney((+text) || (first(record.service_variants)?.price ?? 0))}`,
    },
    {
      title: 'Duration',
      dataIndex: 'duration_time',
      key: 'duration_time',
      render: (text: number) => formatDurationTime(text),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => {
        return (
          <div className='booking-status'>
            <span style={{ backgroundColor: `${tagStatusColor(text)}` }}></span>
            {text}
          </div>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text: string, record: IServiceReponse) => (
        <ul className={'common-action'}>
          <li
            className='action-edit'
            onClick={() => handleModalUpdate(record)}
          ></li>
          <ButtonFavorite
            isFavourite={record?.is_favourite}
            handleSwitchFavourite={() => handleSwitchFavourite(record?.id)}
          />
          <li
            className='action-remove'
            onClick={() => onClickRemoveItem(record)}
          ></li>
        </ul>
      ),
    },
  ];

  const onSearch = (event: string) => {
    const params = {
      keyword: event || undefined,
      page: 1,
    };
    dispatch(serviceActions.setServiceListParams(params));
  };

  const handleSubmitImport: ImportModalProps['onSubmit'] = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file as RcFile);

      const rest: AxiosResponse<{ message: string }> =
        await apisService.importServiceInfo(formData);

      const msg = `${(rest as any).data.data.customerSuccess} record inserted`;
      if (msg) {
        message.success(msg);
        setIsOpenImportModal(false);
        dispatch(
          serviceActions.setServiceListParams({
            page: 1,
            per_page: 10,
          })
        );
        return true;
      } else {
        throw 'fail';
      }
    } catch (error: any) {
      const textError = get(error, 'response.data.error.message', '');
      if (textError) {
        message.error(textError);
      }

      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.values(response);
      errors.map((value) => {
        message.error(get(value, [0], ''));
      });
      return false;
    }
  };

  const onClickExport = async () => {
    setPageLoading(true);
    try {
      const res = await apisService.exportData();
      const file_export_url = get(res, ['data', 'data', 'file_export'], '');
      if (!file_export_url) return;
      downloadFile(file_export_url);
    } catch (error) {
      const errorText = get(error, 'response.data.error.message', '');
      if (errorText) {
        message.error(errorText);
        return;
      }
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map(([, valueArr]) => {
          message.error(get(valueArr, [0], ''));
        });
        return;
      }
      message.error('An error occurred. Please try again');
    } finally {
      setPageLoading(false);
    }
  };

  return (
    <ServicePageStyled>

      <div className='header'>
        <div className={'private-filter'}>
          <ServiceSelectView />
          <InputSearchText value={searchParams.get('keyword') ?? ''} onSearchText={onSearch} />
          <button
            className='common-btn-import'
            onClick={() => setIsOpenImportModal(true)}
          >
            <span>Import</span>
          </button>
          <button className='common-btn-export' onClick={onClickExport}>
            <span>Export</span>
          </button>
          <ImportModal
            onSubmit={handleSubmitImport}
            handleCancel={() => setIsOpenImportModal(false)}
            isOpenModal={isOpenImportModal}
          />
          <button onClick={onAddItem} className={'common-btn'}>
            Add New
          </button>
        </div>
      </div>

      <ServicesTable columns={columns} />

      {isModalOpen && (
        <ServicesForm
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          isModalOpen={isModalOpen}
          currService={currService}
        />
      )}

      <ModalConfirm ref={modalConfirmRef} />
    </ServicePageStyled>
  );
};

export default ServicesPage;


const NameOptionStyled = styled.div`
  cursor: pointer;
  .titleContainer {
    display: grid;
    .optionsNumber {
      margin-left: 0px !important;
    }
    /* @media only screen and (min-width: 768px) and (max-width: 1180.98px) {
      display: grid;
      .optionsNumber {
        margin-left: 0px !important;
      }
    } */
    .optionsNumber {
      color: #878C93;
      margin-left: 8px;
      font-size: 14px
    }
  }
  .service-name-ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 200px;
      @media only screen and (max-width: 767.98px) {
        width: 130px;
      }
      @media only screen and (min-width: 768px) and (max-width: 1180.98px) {
        width: 150px;
      }
    }
`;

const ServicePageStyled = styled.div`
  .header {
    display: flex;
    justify-content: right;
  }
`;