import { Button } from 'antd';
import message from 'components/common/Message';
import React, { useRef, useState } from 'react';

const ThermalPrinter = () => {
  const [printerIPAddress, setPrinterIPAddress] = useState('192.168.1.102');
  const [printerPort, setPrinterPort] = useState('9100');
  // const [printerPort5222] = useState('5222');
  const [connectionStatus, setConnectionStatus] = useState('');

  const ePosDevice = useRef();
  const printer = useRef();

  const STATUS_CONNECTED = 'Connected';

  const connect443 = () => {
    setConnectionStatus('Connecting ...');

    if (!printerIPAddress) {
      setConnectionStatus('Type the printer IP address');
      return;
    }
    if (!printerPort) {
      setConnectionStatus('Type the printer port');
      return;
    }

    setConnectionStatus('connecting ...');
    //@ts-ignore
    const ePosDev = new window.epson.ePOSDevice();
    ePosDevice.current = ePosDev;

    ePosDev.connect(printerIPAddress, printerPort, (data: any) => {
      if (data === 'OK') {
        ePosDev.createDevice(
          'local_printer',
          ePosDev.DEVICE_TYPE_PRINTER,
          { crypto: true, buffer: false },
          (devobj: any, retcode: any) => {
            message.info(retcode);
            if (retcode === 'OK') {
              printer.current = devobj;
              setConnectionStatus(STATUS_CONNECTED);
            } else {
              message.error(retcode);
            }
          }
        );
      } else {
        message.error(data);
      }
    });
  };

  const print443 = () => {
    const prn: any = printer.current;
    message.error('Not connected to printer');
    if (!prn) {
      console.log('Not connected to printer');
      return;
    }

    prn.addTextAlign(prn.ALIGN_CENTER);
    prn.addLogo(44, 44);
    prn.addPageArea(0, 0, 576, 532);
    prn.addTextFont(prn.FONT_SPECIAL_A);
    prn.addTextDouble(true, true);
    prn.addText('Store Name\n');
    prn.addTextDouble(false, false);
    prn.addTextFont(prn.FONT_B);
    prn.addText('Store Address \n');
    prn.addText('Tel: 032343252\n');
    prn.addFeedUnit(20);
    prn.addTextAlign(prn.ALIGN_LEFT);
    prn.addTextPosition(10);
    prn.addText('Invoice No: #INV8751429 \r');
    prn.addTextPosition(270);
    prn.addText('Issued Date: Mar 22, 2024 \n');
    prn.addTextPosition(10);
    prn.addText('Staff: Jonny \n');
    prn.addFeedUnit(10);
    prn.addTextStyle(false, false, true, prn.COLOR_1);
    prn.addTextPosition(10);
    prn.addText('Item \r');
    prn.addTextPosition(200);
    prn.addText('QTY \r');
    prn.addTextPosition(300);
    prn.addText('Price \r');
    prn.addTextPosition(450);
    prn.addText('Total \n');
    prn.addPageBegin();
    prn.addPageArea(0, 0, 576, 10);
    prn.addPageLine(10, 0, 500, 0, prn.LINE_MEDIUM);
    prn.addPageEnd();
    prn.addTextPosition(10);
    prn.addText('Hair Cut \r');
    prn.addTextPosition(200);
    prn.addText('2 \r');
    prn.addTextPosition(300);
    prn.addText('30 \r');
    prn.addTextPosition(450);
    prn.addText('60 \n');
    prn.addPageBegin();
    prn.addPageArea(0, 0, 576, 10);
    prn.addPageLine(10, 0, 500, 0, prn.LINE_MEDIUM);
    prn.addPageEnd();
    prn.send();
  };

  // const connect5222 = () => {
  //   setConnectionStatus('Connecting ...');

  //   if (!printerIPAddress) {
  //     setConnectionStatus('Type the printer IP address');
  //     return;
  //   }
  //   if (!printerPort5222) {
  //     setConnectionStatus('Type the printer port');
  //     return;
  //   }

  //   setConnectionStatus('Connecting ...');
  //   //@ts-ignore
  //   const ePosDev = new window.epson.ePOSDevice();
  //   ePosDevice.current = ePosDev;

  //   ePosDev.connect(printerIPAddress, printerPort5222, (data: any) => {
  //     if (data === 'OK') {
  //       ePosDev.createDevice(
  //         'local_printer',
  //         ePosDev.DEVICE_TYPE_PRINTER,
  //         { crypto: true, buffer: false },
  //         (devobj: any, retcode: any) => {
  //           if (retcode === 'OK') {
  //             printer.current = devobj;
  //             setConnectionStatus(STATUS_CONNECTED);
  //           } else {
  //             throw retcode;
  //           }
  //         }
  //       );
  //     } else {
  //       throw data;
  //     }
  //   });
  // };

  // const print5222 = () => {
  //   const prn: any = printer.current;
  //   if (!prn) {
  //     console.log('Not connected to printer');
  //     return;
  //   }

  //   prn.addTextAlign(prn.ALIGN_CENTER);
  //   prn.addLogo(44, 44);
  //   prn.addPageArea(0, 0, 576, 532);
  //   prn.addTextFont(prn.FONT_SPECIAL_A);
  //   prn.addTextDouble(true, true);
  //   prn.addText('Store Name\n');
  //   prn.addTextDouble(false, false);
  //   prn.addTextFont(prn.FONT_B);
  //   prn.addText('Store Address \n');
  //   prn.addText('Tel: 032343252\n');
  //   prn.addFeedUnit(20);
  //   prn.addTextAlign(prn.ALIGN_LEFT);
  //   prn.addTextPosition(10);
  //   prn.addText('Invoice No: #INV8751429 \r');
  //   prn.addTextPosition(270);
  //   prn.addText('Issued Date: Mar 22, 2024 \n');
  //   prn.addTextPosition(10);
  //   prn.addText('Staff: Jonny \n');
  //   prn.addFeedUnit(10);
  //   prn.addTextStyle(false, false, true, prn.COLOR_1);
  //   prn.addTextPosition(10);
  //   prn.addText('Item \r');
  //   prn.addTextPosition(200);
  //   prn.addText('QTY \r');
  //   prn.addTextPosition(300);
  //   prn.addText('Price \r');
  //   prn.addTextPosition(450);
  //   prn.addText('Total \n');
  //   prn.addPageBegin();
  //   prn.addPageArea(0, 0, 576, 10);
  //   prn.addPageLine(10, 0, 500, 0, prn.LINE_MEDIUM);
  //   prn.addPageEnd();
  //   prn.addTextPosition(10);
  //   prn.addText('Hair Cut \r');
  //   prn.addTextPosition(200);
  //   prn.addText('2 \r');
  //   prn.addTextPosition(300);
  //   prn.addText('30 \r');
  //   prn.addTextPosition(450);
  //   prn.addText('60 \n');
  //   prn.addPageBegin();
  //   prn.addPageArea(0, 0, 576, 10);
  //   prn.addPageLine(10, 0, 500, 0, prn.LINE_MEDIUM);
  //   prn.addPageEnd();
  //   prn.send();
  // };

  return (
    <div id='thermalPrinter'>
      <p>Printer Ip</p>
      <input
        id='printerIPAddress'
        placeholder='Printer IP Address'
        value={printerIPAddress}
        onChange={(e) => setPrinterIPAddress(e.currentTarget.value)}
      />
      <p>Printer Port</p>

      <input
        id='printerPort'
        placeholder='Printer Port'
        value={printerPort}
        onChange={(e) => setPrinterPort(e.currentTarget.value)}
      />
      <br/>
      <Button
        className='button'
        disabled={connectionStatus === STATUS_CONNECTED}
        onClick={() => connect443()}
      >
        Connect
      </Button>
      <span className='status-label'>{connectionStatus}</span>
      <p>When connect success, click Print</p>
      <Button
        // disabled={connectionStatus !== STATUS_CONNECTED}
        onClick={() => print443()}
      >
        Print
      </Button>
      {/* <br/>
      <Button
        className='button'
        disabled={connectionStatus === STATUS_CONNECTED}
        onClick={() => connect5222()}
      >
        Connect 5222
      </Button>
      <span className='status-label'>{connectionStatus}</span>
      <p>When connect success, click Print</p>
      <Button
        disabled={connectionStatus !== STATUS_CONNECTED}
        onClick={() => print5222()}
      >
        Print 5222
      </Button> */}
    </div>
  );
};

export default ThermalPrinter;