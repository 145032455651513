import { Col, Empty, Row } from 'antd';
import ModalConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import PaymentVoucherPurchasedItem from 'features/checkout/components/VoucherPayment/PurchasedItem';
import salesActions from 'features/sales/services/actions';
import salesSelectors from 'features/sales/services/selectors';
import { IVoucherDataItem } from 'features/sales/services/types/voucher';
import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import VoucherInput from 'features/checkout/components/VoucherInput';
import { BlockPaymentProps } from '..';
type Props = BlockPaymentProps & {
  onCancel?: () => void;
  onCloseModal: () => void;
  isEdit?: boolean;
  customerVouchers?: IVoucherDataItem[];
  open?: boolean;
};

const VoucherPayment = ({ 
  // customerVouchers = [],
  onCloseModal, 
  isEdit,
  open,
  action = salesActions,
  selector = salesSelectors
}: Props) => {
  const [, setCurrVoucherView] = React.useState<IVoucherDataItem | null>(null);
  const dispatch = useAppDispatch();
  const customer = selector.getCustomer();

  const [selected, setSelected] = React.useState<IVoucherDataItem | null>(null);
  const selectedService = selector.getSelectedServices();
  const modalConfirmRef = useRef<ModalConfirmRef>(null);
  const voucherSelected = selector.getSelectedVoucherUsed();

  // const curMixPayments = selector.getMixPayments();
  // const getTotalOriginal = selector.getOriginTotalPrice();
  // const voucherSelected = selector.getSelectedVoucherUsed();
  // const [, setErrorValid] = useMixErrors();  

  const purchasedVouchers = selector.getPurchasedVouchers()?.filter((o: any) => {  
    for (const service of selectedService) {      
      const haveServiceReward = o?.services?.find((item: any) => service.service_variant_id === undefined || service.service_variant_id === null ? item.id === service.id : item.id === service.id && item.service_variants?.find((i: any) => i.id === service.service_variant_id));
      if (haveServiceReward && o.is_enable && o.remaining >= 0.01)
        return true;
    }
  }) as IVoucherDataItem[];

  const [voucherList, setVoucherList] = useState(() => {
    if(customer?.is_walkin_in === 2)
      return [];
    return purchasedVouchers;
  });

  // const voucherValue = selector.getVoucherValue();

  // useEffect(() => {    
  //   const selected = curMixPayments.find(o => o.type === EPaymentMethod.VOUCHER);
  //   if(! voucherSelected) return setSelected(null);
  //   if (!selected) return;
  //   setSelected(selected.data);
  // }, [curMixPayments]);
  
  useEffect(() => {
    setSelected(voucherSelected);
  }, []);

  const handleChoose = (o?: IVoucherDataItem | null) => {
    if (!o) return;
    if(! voucherList.find(e => e.voucher_code === o.voucher_code)) {      
      setVoucherList([o ,...voucherList]);
    }

    setSelected(o);
    setCurrVoucherView(null);
  };  

  const _onRemove = (o?: IVoucherDataItem | null) => {
    if (!o) return;
    modalConfirmRef.current?.show({
      title: 'Are you sure',
      msg: 'Are you sure unused this voucher?',
      submit: () => setSelected(null),
    });
  };

  // const getValueVoucher = () => {

  //   const voucherValueRemaining = voucherValue;

  //   // if (!curMixPaymentsCash && !curMixPaymentsCard) return getTotalOriginal <= voucherValueRemaining ? getTotalOriginal : voucherValueRemaining;
  //   // const remainingPay = getTotalOriginal - (curMixPaymentsCash?.value ?? 0) - (curMixPaymentsCard?.value ?? 0);
  //   const remainingPay = getTotalOriginal;
  //   return remainingPay > voucherValueRemaining ? voucherValueRemaining : remainingPay;
  // };

  const onSubmit = () => {
    dispatch(action.setSelectedVoucherUsed(selected));
    onCloseModal();
  };

  // const addPayment = () => {
  //   setErrorValid('');
  //   const dataVoucher: IMixPaymentItem = {
  //     data: selected,
  //     type: EPaymentMethod.VOUCHER,
  //     value: roundNumber(getValueVoucher()),
  //   };
  //   const idx = findIndex(curMixPayments, o => o.type === EPaymentMethod.VOUCHER);
  //   if (idx !== -1) {
  //     const newMixPayments = curMixPayments.map(o => {
  //       if (o.type === EPaymentMethod.VOUCHER) {
  //         return ({
  //           ...o,
  //           ...dataVoucher,
  //         });
  //       }
  //       return o;
  //     });
  //     dispatch(action.setMixPayments(newMixPayments));
  //   } else {
  //     const newMixPayments = [...curMixPayments];
  //     newMixPayments.push(dataVoucher);
  //     dispatch(action.setMixPayments(newMixPayments));
  //   }    
  //   onCloseModal();
  // };  

  return (
    <>
      <h2 className='modal-title'>
        <p>{isEdit ? 'Edit' : 'Add'} voucher</p>
      </h2>
      <div className='modal-body'>
        <VoucherPaymentStyled>
          {/* <div className='form-heading'>
            <div className='form-label'>Voucher Code</div>
          </div> */}

          <VoucherInput
            voucherSelected={selected}
            customer_code={customer?.customer_code}
            onApply={handleChoose}
            services={selectedService}
            openModal={open}
          />

          {voucherList?.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No voucher'} /> :
            <VoucherPaymentListStyled>
              <Row gutter={[8, 8]} className='row-vouchers'>
                {voucherList.map(o => (
                  <Col key={o.voucher_code} span={12} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} >
                    <PaymentVoucherPurchasedItem
                      data={o}
                      isWrap
                      onChoose={() => handleChoose(o)}
                      active={o.voucher_code?.toString() === selected?.voucher_code?.toString()}
                      onRemove={() => _onRemove(o)}
                    />
                  </Col>
                ))}
              </Row>
            </VoucherPaymentListStyled>
          }
        </VoucherPaymentStyled>
      </div>
      <div className='form-submit-wrapper'>
        <button
          data-testing='Close'
          onClick={onCloseModal}
          type='button'
          className='common-btn is-white'
        >
          Close
        </button>
        <button
          data-testing='Apply'
          onClick={onSubmit}
          type='button'
          className='common-btn'
        >
          Apply
        </button>
      </div>

      {/* <ModalViewInfoVoucher visible={!!currVoucherView} data={currVoucherView} onSubmit={handleChoose} onCancel={() => setCurrVoucherView(null)} /> */}
      <ModalConfirm ref={modalConfirmRef} />
    </>
  );
};

export default VoucherPayment;

const VoucherPaymentStyled = styled.div`
  .form-heading {
    margin-bottom: 16px;
  }
`;

const VoucherPaymentListStyled = styled.div`
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  margin-bottom: 8px;
  gap:12px;
  .row-vouchers {
    width:100%;
  }
`;