import { useEffect, useState } from 'react';
import InvoiceServiceItem from './InvoiceServiceItem';
import { getFormatShortDateAndTimeAMAndPM } from 'utils/moment/getSingleDateShortFormat';
import './printInvoiceBooking.css';

interface IItem {
  name?: string;
  serviceVariantName?: string;
  quantity?: number;
  category?: { name?: string };
  service_name?: string;
  category_service_name?: string;
  serivce_variant_name?: string;
}

export default function PrintInvoiceBooking() {
  const storedData = JSON.parse(localStorage.getItem('printerInvoice') || '{}');  
  const {
    name = '',
    bookingDate = null,
    currServices = [],
    noteOfCustomer = '',
  } = storedData || {};
  const [isUnmounted, setIsUnmounted] = useState<boolean>(false);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (!isUnmounted) {
        localStorage.removeItem('printerInvoice');
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      setIsUnmounted(true);
    };
  }, [isUnmounted]);
  return (
    <div className="print__invoice__booking">
      <div className="booking__wrapper">
        <div className="booking__container">
          <div className="booking__info">
            <div className="booking__info_customer-detail">
              <p className="booking__info__customer-name">{name}</p>
              <div className="booking__info__customer-contact">
                <p>{getFormatShortDateAndTimeAMAndPM(bookingDate)}</p>
              </div>
            </div>
            <div className="booking__divider"></div>
            <div className="booking__info-details">
              <div className="booking__service">
                <>
                  {currServices && (
                    <>
                      {currServices.map((item: IItem) => (
                        <InvoiceServiceItem
                          key={item?.name}
                          name={item?.name ?? item?.service_name}
                          subName={item?.serviceVariantName ?? item?.serivce_variant_name}
                          qty={item?.quantity}
                          categoryName={item?.category?.name ?? item?.category_service_name}
                        />
                      ))}
                    </>
                  )}
                </>
              </div>
              <div className="booking__divider"></div>
              <p className="booking__note">{noteOfCustomer}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
