import { Form, Spin } from 'antd';
import { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import CustomersFormFirstStep from './FirstStep';
import { Dayjs } from 'dayjs';

const DEFAULT_STEP = 1;
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};


export type IFuncCancel = () => void;

export interface IFormAddCustomerData {
  name: string;
  phoneNumberCode: string;
  phoneNumber: string;
  email: string;
  birthDate: Dayjs;
  numberVisits: string;
  cancellations: string;
  noShows: string;
  loyaltyPoints: string;
  amountSpent: number;
  contact: string;
  importantClientInfo: string;
  gender?: number | null;
  loyalty_point?: number | null;
}

export type IFuncSubmit = (data: IFormAddCustomerData) => Promise<boolean>;

type Props = {
  onCancel?: () => void;
  onSubmit?: IFuncSubmit;
  errors?: Record<string, string> | null;
};
export type AddNewCustomerFormRef = {
  prevStep: () => void;
  nextStep: () => void;
  // setErrors: (errors: Record<string, string>) => void;
};
/* eslint-disable-next-line */
const AddNewCustomerForm = forwardRef<AddNewCustomerFormRef, Props>((({
  onCancel = () => undefined,
  onSubmit = () => undefined,
  errors,
}, ref) => {
  // const [errors, setErrors] = useState<Record<string, string>>({});
  const [step, setStep] = useState(DEFAULT_STEP);
  const [form] = Form.useForm();

  const handleCancel = () => {
    onCancel();
    setStep(DEFAULT_STEP);
    form.resetFields();
  };

  const nextStep = () => {
    setStep((currPage) => currPage + 1);
  };

  const prevStep = () => {
    setStep((currPage) => currPage - 1);
  };

  useImperativeHandle(ref, () => ({
    nextStep,
    prevStep,
    // setErrors,
  }));

  const _onSubmit = () => form.submit();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: IFormAddCustomerData) => {
    setLoading(true);
    try {

      const success = await onSubmit(values);
      if (success) {
        form.resetFields();
      }
    } catch (error) {

    } finally {
      setLoading(false);
    }
  };

  const FormSteps = () => {
    return (
      <FormStyled>
        <div style={step !== 1 ? { display: 'none' } : undefined}>
          <CustomersFormFirstStep errors={errors} onCancel={handleCancel} onNextStep={_onSubmit} />
        </div>
        {/* <div style={step !== 2 ? { display: 'none' } : undefined}>
          <CustomersFormSecondStep errors={errors} onPrevStep={prevStep} onSubmit={_onSubmit} />
        </div> */}
      </FormStyled>
    );
  };
  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        layout="vertical"
        data-testing='form-add-customer'
        initialValues={{
          phoneNumberCode: '+92',
          phoneNumber: null,
          email: null,
          birthDate: null,
          numberVisits: 0,
          cancellations: 0,
          noShows: 0,
          loyaltyPoints: 0,
          amountSpent: 0,
          contact: null,
          importantClientInfo: null,
          gender: 0,
        }}
        onKeyPress={(e) => {
          if(e.key === 'Enter') {
            _onSubmit();
          }
        }}
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        scrollToFirstError
        validateTrigger={['onChange', 'onBlur']}
      >
        <FormSteps />
      </Form>
    </Spin>
  );
}));

export default AddNewCustomerForm;

const FormStyled = styled.div`
  .ant-form-item-extra {
    font-style: italic;
    color:#7980BC;
  }
`;
