import { Form, Spin, Switch } from 'antd';
import message from 'components/common/Message';
import FormTabSetting from './FormTabSetting';
import settingSelectors from '../services/selectors';
import PairButtonOptimize from './Tyro/PairButtonOptimize';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import settingApis from '../services/apis';
import settingActions from '../services/actions';
import { get } from 'lodash';

const TyroTab = () => {

  const [form] = Form.useForm();
  const getSetting = settingSelectors.getSetting();
  const loading = settingSelectors.loadSetting();
  const dispatch = useDispatch();

  const [enableTabToPay, setEnableTabToPay] = useState<boolean>();

  useEffect(() => {
    setEnableTabToPay(get(getSetting, 'general.enable_tap_to_pay', false));
  }, [getSetting]);

  const getPayload = () => {
    return {
      enable_tap_to_pay: enableTabToPay,
    };
  };
  
  const handleSubmit = async () => {
    const payload = getPayload();
    try {
      const rest: AxiosResponse<{ message: string }> = await settingApis.updateGeneral(payload);

      const msg = get(rest, 'data.message', '');
      if (msg) {
        dispatch(settingActions.getSetting.fetch());
        message.success(msg);
        form.resetFields();
        return true;
      }
      return false;
    } catch (error: any) {
      dispatch(settingActions.getSetting.fetch());
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      message.error(...Object.values(response)[0]);
      return false;
    }
  };

  return (
    <>
      <FormTabSetting
        form={form}
        handleSubmit={handleSubmit}
        initialValues={{
          enable_tap_to_pay: getSetting?.general?.enable_tap_to_pay,
        }}
      >
        <Spin spinning={loading}>
          <div className='cards-wrapper'>
            <div className='cards-col'>
              <div className='common-card'>
                <div className='form-row'>
                  <p className='form-label'>Set up quick sale items</p>
                  <Switch defaultChecked />
                </div>
                {/* <div className='form-row'>
                  <PairButton 
                    tyroMID={getSetting?.general.merchant_id}
                    tyroTID={getSetting?.general.teminal_id}
                  />
                </div> */}
                <div className='form-row'>
                  <Form.Item valuePropName='checked' initialValue={form?.getFieldValue('enable_tap_to_pay') ?? getSetting?.general?.enable_tap_to_pay} name={'enable_tap_to_pay'} noStyle>
                    <div className='form-row'>
                      <p className='form-label'>{'Enable Tab to Pay'}</p>
                      <Switch
                        checked={enableTabToPay}
                        onChange={() => {
                          setEnableTabToPay(!enableTabToPay);
                        }}
                        checkedChildren=''
                        unCheckedChildren=''
                      />
                    </div>
                  </Form.Item>
                </div>
                <div className='form-row'>
                  <PairButtonOptimize
                    tyroMID={getSetting?.general.merchant_id}
                    tyroTID={getSetting?.general.teminal_id}
                  />
                </div>
                <div className='form-row'>
                  <button className='common-btn is-big no-flex'>Request iClient Logs</button>
                </div>
                {/* <Divider />
          
                  <div className='form-row'>
                    <p className='form-label'>Test for developer</p>
                  </div> */}

                  
                  {/* <div className='form-row'>
                    <TestTyroPurchase />
                  </div> */}
                  {/* <div className="form-row">
                    <p className='form-label'>For test Tyro Transactions</p>
                  </div>
                  <div className='form-row'>
                    <Form
                      autoComplete="off"
                      layout="vertical"
                      className="modal-form" form={form} initialValues={{ amount: 1 }}>
                      <Form.Item label='Amount' name={'amount'}>
                        <Input />
                      </Form.Item>
                    </Form>
                  </div>
                  <div className='form-row is-wrap' style={{ justifyContent: 'center' }}>
                    <button className='common-btn is-big' onClick={onCheckout} >checkout</button>
                    <button className='common-btn is-big' onClick={onRefund} >Refund</button>
                  </div> */}
              </div>
            </div>
          </div>
        </Spin>
      </FormTabSetting>
    </>
    
  );
};

export default TyroTab;
