import { Popover } from 'antd';
import { EBookingStatus } from 'constants/index';
import bookingActions from 'features/bookings/services/actions';
import bookingSelectors from 'features/bookings/services/selectors';
import settingSelectors from 'features/settings/services/selectors';
import { MouseEventHandler, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { IconDash, IconDuplicate, IconNote, IconTrash } from './Icons';
import ModalCancel, { IModalCancelSubmitFunc } from './ModalCancel';
import { useBookingMultiContext } from './UIBookingMulti';
import { BookingItem } from './types';
import useOpenPopover from './useOpenPopover';

type Props = {
  bookId: number | string;
  bookStatus: string;
  data: BookingItem;
  handleOpenPopup?: (status: boolean) => void;
};

enum ENUM_ACTIONS {
  EDIT = 'edit',
  DUPLICATE = 'duplicate',
  CANCEL = 'cancel',
  NO_SHOW = 'no-show',
}

const PopoverEdit = ({ bookId, bookStatus, data, handleOpenPopup }: Props) => {
  const [open, setOpen] = useOpenPopover();
  const bookingMultiContext = useBookingMultiContext();
  const merchant_location_id = bookingSelectors.getCalendarParamValue('merchant_location_id');
  const dispatch = useAppDispatch();
  const note = data.noted_client || data.appointment_note;
  const close = () => {
    setOpen(false);
  };

  const onClickDuplicate = () => {
    dispatch(bookingActions.duplicateBooking(String(bookId)));
    close();
  };

  const onClickNoShow = () => {
    dispatch(bookingActions.setBookingNoShow(String(bookId)));
    close();
  };

  const onClickDeleteBooking = () => {
    dispatch(bookingActions.deleteBooking(String(bookId)));
    close();
  };

  const setPopoverClose = () => {
    setOpen(false);
    bookingMultiContext?.setPopover(false);
  };

  const disableActions: ENUM_ACTIONS[] = useMemo(() => {
    switch (bookStatus) {
      case EBookingStatus.IN_PROGRESS:
        return [ENUM_ACTIONS.NO_SHOW];
      case EBookingStatus.NO_SHOW:
      case EBookingStatus.COMPLETED:
        return [ENUM_ACTIONS.NO_SHOW, ENUM_ACTIONS.CANCEL, ENUM_ACTIONS.EDIT];
      case EBookingStatus.OPEN: {
        // const _timeStart = moment(data.time_start);
        // const beforeTimeStart10mins = _timeStart.clone().subtract(10, 'minute');
        // const afterTimeStart10mins = _timeStart.clone().add(10, 'minute');
        // const currentTime = moment();
        // if (currentTime < beforeTimeStart10mins) {
        //   return [ENUM_ACTIONS.NO_SHOW];
        // }
        // if (currentTime >= beforeTimeStart10mins) {
        //   return [ENUM_ACTIONS.NO_SHOW, ENUM_ACTIONS.CANCEL];
        // }

        // if (currentTime >= afterTimeStart10mins) {
        //   return [ENUM_ACTIONS.CANCEL];
        // }
        return [];
      }
      default:
        return [];
    }
  }, [bookStatus]);

  if (bookStatus === EBookingStatus.DRAFT) {
    return (
      <button type='button' onClick={onClickDeleteBooking}>
        <IconTrash />
      </button>
    );
  }

  if (bookStatus === EBookingStatus.CANCELLED) {
    return (
      <button type='button' onClick={onClickDuplicate}>
        <IconDuplicate />
      </button>
    );
  }

  const onClickButton: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setOpen(true);
    handleOpenPopup?.(true);
  };

  return (
    <>
      <Popover
        arrow={false}
        trigger='click'
        placement='rightTop'
        open={open}
        onOpenChange={setOpen}
        content={
          <ActionsStyled>
            <ul className='more-settings'>
              {!disableActions.includes(ENUM_ACTIONS.EDIT) && (
                <Link to={`/private/bookings/edit-booking?bookId=${bookId}&merchant_location_id=${merchant_location_id}`}>
                  <li className='is-edit' onClick={() => close()}>Edit</li>
                </Link>
              )}
              {!disableActions.includes(ENUM_ACTIONS.DUPLICATE) && (
                <li className='is-double' onClick={onClickDuplicate}>Duplicate</li>
              )}
              {!disableActions.includes(ENUM_ACTIONS.CANCEL) && (
                <ButtonCancel id={bookId} setPopoverClose={setPopoverClose} />
              )}
              {!disableActions.includes(ENUM_ACTIONS.NO_SHOW) && (
                <li className='is-disabled' onClick={onClickNoShow}>No show</li>
              )}
            </ul>
          </ActionsStyled >
        }
      >
        {!!note && <IconNote />}
        <button type='button' onClick={onClickButton}>
          <IconDash />
        </button>
      </Popover >

    </>
  );
};

export default PopoverEdit;

type IButtonCancelProps = {
  id: string | number;
  setPopoverClose: (val: boolean) => void;
};

const ButtonCancel = ({ id, setPopoverClose }: IButtonCancelProps) => {
  const dispatch = useAppDispatch();
  const isPinRequired = settingSelectors.getIsRequirePin();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClickCancel: MouseEventHandler<HTMLLIElement> = () => {
    setIsModalOpen(true);
    setPopoverClose(false);
  };
  const handleCancel = () => setIsModalOpen(false);

  const handleSubmit: IModalCancelSubmitFunc = async (values) => {
    dispatch(bookingActions.cancelBooking({ id: String(id), body: { note: values.note, reason_id: values.reason, pin_number: values.PIN?.toString() } }));
  };

  return (
    <>
      <li className='is-cancel' onClick={onClickCancel}>Cancel</li>
      {!!isModalOpen && <ModalCancel visible onCancel={handleCancel} onSubmit={handleSubmit} isPin={isPinRequired} />}
    </>
  );
};

const ActionsStyled = styled.div`
  .more-settings {
    display: block;
    position: static;
    transform: none;
    padding: 0;
    box-shadow: none;
  }
  button {
    border: none;
    background: transparent;
    display: block;
  }
`;
