import { Button, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import TableFailedIcon from 'assets/images/setting/table-status-failed.png';
import { useRef, useState } from 'react';
import MessageHistoryModal from './MessageHistoryModal';
import { useDispatch } from 'react-redux';
import settingActions from 'features/settings/services/actions';
import settingSelectors from 'features/settings/services/selectors';
import { formatMoney } from 'utils/unit';
import AutomaticTopup from './components/AutomaticTopup';
import PopupConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import topUpSetting from 'assets/images/setting/top_up_setting.png';
interface topUpHistoryColumnType {
  transactionID: string;
  amount: string;
  date: string;
  status: string;
}

interface TopUpStotyProps {
  handleOpenConfirmTopup?: () => void
}

const TopUpStoty = ({handleOpenConfirmTopup} : TopUpStotyProps) => {
  const [isMessageModalOpen, setIsMessageModalOpen] = useState<boolean>(false);
  const { t: topUpLang } = useTranslation('top_up');
  const dispatch = useDispatch();
  const listTopUpHistory = settingSelectors.getTopUpHistory();
  const pagination = settingSelectors.getTopUpHistoryListPagination();
  const loadingTopUpSMSHistory = settingSelectors.loadTopUpHistory();
  const modalConfirmRef = useRef<ModalConfirmRef>(null);

  const columns: ColumnsType<topUpHistoryColumnType> = [
    {
      title: topUpLang('topUpHistory.table.column.transactionID'),
      dataIndex: 'id',
      key: 'id',
      render: (value) => <div>#{value}</div>
    },
    {
      title: topUpLang('topUpHistory.table.column.amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (value) => <div>{formatMoney(value)}</div>
    },
    {
      title: topUpLang('topUpHistory.table.column.date'),
      dataIndex: 'created_at_format',
      key: 'created_at_format',
    },
    {
      title: topUpLang('topUpHistory.table.column.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'left',
      render: (text: string) => {
        return (
          <div className='table-status-column d-flex no-wrap'>
            <div className={text.toLowerCase() + '_dot status_dot'} />
            <div className={text.toLowerCase() + '_text status_text'}>
              {text}
            </div>
            <div className={text.toLowerCase() + '_img status_img'}>
              <img src={TableFailedIcon} alt='failed-icon' />
            </div>
          </div>
        );
      },
    },
  ];

  const handleOpenHistoryModal = () => {
    dispatch(settingActions.getTopUpSMSHistory.fetch({page: 1, per_page: 10}));
    setIsMessageModalOpen(!isMessageModalOpen);
  };

  const handleDisplaySetupTopUpModal = () => {
    modalConfirmRef.current?.show({
      msg: 'Sorry you haven\'t set up the top-up yet. Are you sure you want to set up now?',
      title: 'Enable automatic top-up',
      icon: <img src={topUpSetting} alt='topup-setting' />,
       submit: () => handleOpenConfirmTopup?.()
    });
  };

  return (
    <SetupTopUpCardStyled>
      <div className='cards-wrapper'>
        <div className='cards-col'>
          <div className='common-card'>
            <AutomaticTopup handleDisplaySetupTopUpModal={handleDisplaySetupTopUpModal}/>
            <div className='topup-history-wrap'>
              <div className='d-flex no-wrap history-title-wrap'>
                <p className='topup-histoty-title'>Top-up history</p>
                <Button
                  className='message-histoty-btn'
                  onClick={handleOpenHistoryModal}
                >
                  Message history
                </Button>
              </div>
              <div className='history-table-wrap'>
                <Spin spinning={loadingTopUpSMSHistory}>
                  <Table
                    className={'top-up-histoty-table'}
                    dataSource={listTopUpHistory}
                    columns={columns}
                    rowKey={(item) => item.transactionID}
                    scroll={{
                      x: 900,
                    }}
                    pagination={{
                      total: pagination?.total_record,
                      defaultCurrent: 1,
                      defaultPageSize: 10,
                      onChange: (pageChange, sizeChange) => {
                        const params: any = {
                          page: pageChange == 0 ? 1 : pageChange,
                          per_page: sizeChange,
                        };
                        dispatch(
                          settingActions.getTopUpHistory.fetch({
                            page: params.page,
                            per_page: params.per_page,
                          })
                        );
                      },
                      showSizeChanger: true,
                      showTotal(total) {
                        return `Total ${total} items`;
                      },
                    }}
                  />
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isMessageModalOpen ? (
        <MessageHistoryModal
          isModalOpen={isMessageModalOpen}
          handleCancel={() => setIsMessageModalOpen(!isMessageModalOpen)}
        />
      ) : null}
       <PopupConfirm ref={modalConfirmRef} />

    </SetupTopUpCardStyled>
  );
};

const SetupTopUpCardStyled = styled.div`
  .message-remaining-wrap {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #c3c2e0;

    .remaining-title {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
    }

    .money-remaining {
      margin-top: 12px;

      .money-remaining-tag {
        color: #1677ff;
        margin-left: 12px;
        font-size: 14px;
      }
      align-items: center;
    }

    .remaining-desc {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      margin-top: 12px;
      color: var(--grayish-navy-60, #7980bc);
    }
  }

  .topup-history-wrap {
    margin-top: 24px;

    .topup-histoty-title {
      font-size: 18px;
      font-weight: 600;
      line-height: 140%;
    }

    .history-title-wrap {
      justify-content: space-between;
      align-items: center;
    }

    .history-table-wrap {
      margin-top: 33px;

      .top-up-histoty-table {
        .ant-table-cell {
          font-weight: 500;
          font-size: 14px;
        }

        .table-status-column {
          text-transform: capitalize;
          align-items: center;
          gap: 8px;
          display: flex;
          justify-content: left;

          .status_dot {
            width: 10px;
            height: 10px;
            border-radius: 5px;
            margin-left: 8px;
          }

          .succeeded_dot {
            background: #95de64;
          }

          .pending_dot {
            background: #fadb14;
          }

          .failed_dot {
            background: #bfbfbf;
          }

          .success_img,
          .pending_img {
            display: none;
          }
        }
      }
    }
  }

  .auto-to-charge {
    color: #7980bc;
    font-size: 14px;
    line-height: 140%;
    margin-top: 16px;
  }

  .auto-switch-wrap {
    margin-top: 12px;
    .auto-switch {
      margin-left: 12px;
    }
  }

  .message-histoty-btn {
    border-radius: 6px;
    background: #f8f8ff;
    border: none;
  }

  .number-sms {
    color: #363565;
    font-size: 32px;
    font-weight: 600;
    line-height: 140%;
  }
`;

export default TopUpStoty;
