import { Button, Form, Input, Modal, Select, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import IconLeaf from 'assets/svg/IconLeaf';
import { AxiosResponse } from 'axios';
import SwitchInput from 'components/common/Input/SwitchInput';
import message from 'components/common/Message';
import dayjs from 'utils/dayjs';
import { ITeamMemberItemResData } from 'features/users/services/types/teamMember';
import { get, set } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import IconHelpCircle from 'assets/svg/IconHelpCircle';
import styled from 'styled-components';
import { formOptionalText } from 'utils/unit';
import NumberValidations from 'utils/validations/number';
import LocationsFormThirdStep from '../components/LocationsFormThirdStep';
import locationActions from '../services/actions';
import apisLocation from '../services/apis';
import locationSelectors from '../services/selectors';
import { DayLocation, ILocationForm, WorkingTime } from '../services/types/location';
// import { DownloadOutlined } from '@ant-design/icons';
import QRCode from 'qrcode.react';
import IconRightGreen from 'assets/svg/IconRightGreen';
import * as htmlToImage from 'html-to-image';


const LocationsEditPage = () => {

  const { id } = useParams();
  const [locationId, setLocationId] = useState<number>(0);
  // const [locationDetail] = useState(locationSelectors.getLocationDetail());
  const [errors, setErrors] = useState<any>(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loadingLocationDetail = locationSelectors.loadingLocationsDetail();
  const locationDetail = locationSelectors.getLocationDetail();

  useEffect(() => {
    setLocationId(Number(id));
    dispatch(locationActions.getLocationDetail.fetch(id ?? ''));
  }, []);
  const setPageLoading = useSetLoadingPage();

  const getPayload = (values: ILocationForm) => {

    const name = values.locationName;
    const address = values.address;
    const contact_code = values.phoneNumberCode;
    const contact_number = values.locationPhoneNumber;
    const manager_id = typeof values?.manager === 'string' ? locationDetail?.location_manager?.manager_id : values?.manager;
    // const manager_name = values.managerName;
    // const manager_phone = values.managerPhoneNumber;
    // const manager_email = values.managerEmail;


    const weekWorkingTime: any = [];
    Object.values(values.workingTime as WorkingTime).forEach((workTime) => {
      workTime.forEach((element: any) => {
        weekWorkingTime.push(element);
      });
    });

    const weekday_working_times = weekWorkingTime.map((item: DayLocation) => ({
      weekday: item?.weekday,
      time_start: item?.time_start?.format('HH:mm:ss'),
      time_end: item?.time_end?.format('HH:mm:ss'),
    }));

    return {
      manager_id,
      name,
      address,
      contact_code,
      contact_number,
      description: values.description,
      // manager_phone,
      // manager_name,
      // manager_email,
      status: values.status ? 1 : 0,
      weekday_working_times,
      abn: values.business_abn,
    };
  };

  const handleSubmit = async (values: ILocationForm) => {
    setErrors(null);
    setPageLoading(true);
    try {
      const payload = getPayload(values);
      const rest: AxiosResponse<{ data: { message: string } }> =
        await apisLocation.updateLocation(locationId, payload);

      const msg = rest?.data?.data?.message;
      if (msg) {
        message.success(msg);
        navigate(-1);
      }
      return false;
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const _errors = {};
      Object.entries(response).map(([key, value]) => {
        set(_errors, key, get(value, 0, ''));
      });
      setErrors(_errors);

      return false;
    } finally {
      setTimeout(() => {
        setPageLoading(false);
      }, 100);
    }
  };

  if (loadingLocationDetail) return <Spin />;

  return (
    <LocationsEditPageStyled>
      <FormLocationData
        handleSubmit={handleSubmit}
        handleCancel={() => navigate('/private/locations')}
        locationDetail={locationDetail}
        errors={errors}
      />
    </LocationsEditPageStyled>
  );
};

export default LocationsEditPage;


const FormLocationData = ({ locationDetail, handleSubmit, handleCancel, errors }: {
  handleSubmit: (values: ILocationForm) => Promise<boolean>,
  handleCancel: () => void,
  locationDetail: any;
  errors: any;
}) => {
  const location = useLocation();

  const [form] = Form.useForm<ILocationForm>();
  const locationQRRef = useRef<any>(null);
  const [currentManagerId, setCurrentManagerId] = useState<string | number>(locationDetail?.location_manager?.manager_id);


  const onChangeStaff = (value: number) => {
    const staffSelected = locationDetail?.merchant_employee?.find((item: ITeamMemberItemResData) => item?.manager_id == value);
    form?.setFieldsValue({
      email: staffSelected?.email,
      managerPhoneNumber: staffSelected?.phone
    });
    setCurrentManagerId(value);
  };
  
  const QRpath = useMemo(() => {
    const href = window.location.href;
    return href.replace(location?.pathname, '') + '/' + locationDetail?.qr_code ?? '';
  }, [locationDetail]);
  const initialValues = useMemo(() => {
    const workingTime = {};
    locationDetail?.weekday_working_times?.forEach((item: any) => {
      const time_start = dayjs(item.time_start, 'h:m:s');
      const time_end = dayjs(item.time_end, 'h:m:s');
      const exists = get(workingTime, [item.weekday]);
      if (exists) {
        exists.push({
          weekday: item.weekday,
          time_start: time_start.isValid() ? time_start : null,
          time_end: time_end.isValid() ? time_end : null,
        });
      } else {
        set(workingTime, [item.weekday], [{
          weekday: item.weekday,
          time_start: time_start.isValid() ? time_start : null,
          time_end: time_end.isValid() ? time_end : null,
        }]);
      }
    });
    return ({
      locationName: locationDetail?.name,
      locationPhoneNumber: locationDetail?.contact_number,
      address: locationDetail?.address,
      manager: locationDetail?.location_manager?.manager_id,
      managerName: locationDetail?.location_manager?.full_name,
      managerEmail: locationDetail?.location_manager?.email,
      managerPhoneNumber: locationDetail?.location_manager?.phone,
      description: locationDetail?.description,
      workingTime,
      email: locationDetail?.location_manager?.email,
      status: !!locationDetail?.status,
      business_abn: locationDetail?.abn,
    });
  }, [locationDetail]);

  const errorsText = useMemo(() => ({
    name: get(errors, 'name', ''),
    contact_number: get(errors, 'contact_number', ''),
    address: get(errors, 'address', ''),
    description: get(errors, 'description', ''),
  }), [errors]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const downloadQRCode = async () => {
    
    const dataUrl = await htmlToImage.toPng(locationQRRef.current);
 
    // download image
    const link = document.createElement('a');
    link.download = 'qr-location.png';
    link.href = dataUrl;
    link.click();
  };

  return (
    <LocationFormStyled>
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValues}
        layout='vertical'
      >
        <div className='cards-wrapper'>
          <div className='cards-col'>
            <div className='common-card'>
              <h2 className='card-title'>General</h2>
              <div className='card-body'>
                <div className='form-row'>
                  <Form.Item
                    label='Location Name'
                    name='locationName'
                    rules={[{ required: true }]}
                    validateStatus={errorsText?.name ? 'error' : undefined}
                    help={errorsText?.name ? errorsText?.name : undefined}
                  >
                    <Input autoFocus placeholder='Enter location name' />
                  </Form.Item>
                </div>
                <div className='form-row items-end'>
                  {/* <Form.Item
                      label='Phone number'
                      name='phoneNumberCode'
                      className='is-small'
                    >
                      <Select
                        className='is-small'
                        options={[
                          {
                            value: '+92',
                            label: '+92',
                          },
                          {
                            value: '+94',
                            label: '+94',
                          },
                        ]}
                      />
                    </Form.Item> */}
                  <Form.Item
                    label='Location phone'
                    name='locationPhoneNumber'
                    rules={[
                      { required: true },
                      NumberValidations.PHONE
                    ]}
                    validateStatus={errorsText?.contact_number ? 'error' : undefined}
                    help={errorsText?.contact_number ? errorsText?.contact_number : undefined}
                  >
                    <Input type='tel' placeholder='Enter your phone number' />
                  </Form.Item>
                </div>
                <Form.Item
                  label='Address'
                  name='address'
                  rules={[{ required: true }]}
                  validateStatus={errorsText?.address ? 'error' : undefined}
                  help={errorsText?.address ? errorsText?.address : undefined}
                >
                  <Input placeholder='Enter address' />
                </Form.Item>
                <p className='form-note is-bottom'>
                  This address can be used as your billing detail address
                </p>
                <Form.Item
                  label='ABN'
                  name='business_abn'>
                  <Input placeholder='Enter your location abn' />
                </Form.Item>
                <Form.Item
                  name='description'
                  label={
                    <p>
                      Description <span>{formOptionalText}</span>
                    </p>
                  }
                  validateStatus={errorsText?.description ? 'error' : undefined}
                  help={errorsText?.description ? errorsText?.description : undefined}
                >
                  <TextArea placeholder='This is store description' rows={4} />
                </Form.Item>

                {/* <div className='d-center w-full' id='myqrcode'>
                  <QRCode value={QRpath} size={250} color='#000000' bordered={false} />
                  <Button onClick={() => downloadQRCode()} icon={<DownloadOutlined />}>
                    Download
                  </Button>
                </div> */}

                {/* <p className='form-note'>
                  Customers can scan this QR and check into the location.
                </p> */}
                <SwitchInput name="status" label='Enable location' />
                <BtnModalStyled>
                  <div className='btn-model-qr' onClick={showModal}>
                    <IconHelpCircle />
                    <p>Download and print the QR code for Check-in</p>
                    <IconRightGreen />
                  </div>
                </BtnModalStyled>
                  <ModalStyled
                    visible={isModalVisible}
                    onCancel={handleModalCancel}
                    closable={false} // Hide close button
                    footer={null} // Hide footer (buttons)
                  >
                  <div className='qr-code-location' ref={locationQRRef}>

                    <p>{locationDetail?.name}</p>
                    <div className="qr-code">
                    <QRCode value={QRpath} />
                    </div>
                    <Button onClick={downloadQRCode}>Scan this code to Check-in</Button>
                  </div>

                  </ModalStyled>
                </div>
              </div>
            </div>
          <div className='cards-col'>
            <div className='common-card'>
              <h2 className='card-title'>Location Manager</h2>
              <div className='card-body'>
                <div className='form-row'>
                  <Form.Item
                    label='Manager Name'
                    name='manager'
                    help={Number(currentManagerId) !== Number(locationDetail?.location_manager?.manager_id) ? 'All services will be assigned to the new manager' : ''}>
                    <Select
                      placeholder='Select team member'
                      onChange={onChangeStaff}
                      options={locationDetail?.merchant_employee?.map((item: ITeamMemberItemResData) => ({
                        label: item.full_name,
                        value: item.manager_id,
                      })) ?? []}
                    />
                  </Form.Item>
                </div>
                <div className='form-row items-end'>
                  {/* <Form.Item
                      label='Phone number'
                      name='phoneNumberCode'
                      className='is-small'
                    >
                      <Select
                        className='is-small'
                        options={[
                          {
                            value: '+92',
                            label: '+92',
                          },
                          {
                            value: '+94',
                            label: '+94',
                          },
                        ]}
                      />
                    </Form.Item> */}
                  <Form.Item label='Manager phone' name='managerPhoneNumber'>
                    <Input type='tel' placeholder='Enter your phone number' disabled />
                  </Form.Item>
                </div>

                {/* <Form.Item label='Email' name='managerEmail'>
                <Input disabled />
              </Form.Item> */}
              </div>
            </div>
          </div>
          {/* <div className='cards-col is-biggest'>
              <div className='common-card'>
                <h2 className='card-title'>Team Members</h2>
                <div className='card-body is-flex'>
                  <div className='block'>
                    <div className='common-input-search'>
                      <input
                        type='text'
                        placeholder='Search member name'
                      // onFocus={handleFocus}
                      // onBlur={handleBlur}
                      // onChange={(e) => handleSortName(e.target.value)}
                      ></input>
                      <ul>
                        <SelectItem
                          data={{
                            name: 'Select all available',
                            label: '20 team member',
                          }}
                          isAll={true}
                        />
                        {teamMemberData.map((member) => {
                          const { id } = member;
                          return <SelectItem key={id} data={member} />;
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className='block'>
                    <div className='form-row'>
                      <p className='form-label'>Team member added</p>
                    </div>
                    <ul className='list-member-added'>
                      {teamMemberData.map((member) => {
                        const { id, name, checked } = member;
                        return checked ? (
                          <li key={id}>
                            <span className='member-avatar'>
                              {name && Array.from(name)[0]}
                            </span>
                            <span className='member-text'>{name}</span>
                            <button
                              className='btn-delete'
                              onClick={() => handleRemoveMember(id)}
                            >
                              <IconClose />
                            </button>
                          </li>
                        ) : (
                          true
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}
          <div className='cards-col is-biggest'>
            <div className='common-card'>
              <h2 className='card-title'>Active Hours</h2>
              <div className='card-body'>
                <div className='body-hours'>
                  <div className='hours-wrapper'>
                    <LocationsFormThirdStep form={form} />
                  </div>
                </div>
                <div className='hours-note'>
                  <p>
                    Opening hours for these locations are default working
                    hours for your team and will be visible to your clients.
                  </p>
                  <span className='note-icon'>
                    <IconLeaf />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='cards-col actions'>
          <div className='form-row'>
            <div className='common-btn is-white' onClick={handleCancel}>Cancel</div>
            <button type='submit' className='common-btn'>
              Save
            </button>
          </div>
        </div>
      </Form>
    </LocationFormStyled>
  );
};

const BtnModalStyled = styled.div`
.btn-model-qr {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  p {
    color: #4A9D77;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 140%;
  }
}
`;

const ModalStyled = styled(Modal)`
.ant-modal-content{
  background: #E2E5FF;
  padding: 60px 58px;
  display: flex;
  justify-content: center;
  .ant-modal-body {
    display: grid;
    gap: 24px;
    text-align: center;
    .qr-code-location {
      display: flex;
      gap: 24px;
      flex-direction: column;
      background-color: #E2E5FF;
      padding: 24px 46px;
      border-radius: 12px;
      p {
        color: #000;
        font-size: 24px;
        font-weight: 600;
        line-height: 140%;
      }
      .qr-code {
        padding: 24px;
        background: white;
        border-radius: 20px;
        canvas {
          width: 256px !important;
          height: 256px !important;
        }
      }
      .ant-btn-default {
        width: 100%;
        background: transparent;
      }
    }
  }
}
  
`;

const LocationFormStyled = styled.div`
  .actions {
    margin-top:16px;
  }
  @media only screen and (max-width: 767.98px) {
    .common-btn {
      min-width: 0;
    }
  }
`;

const LocationsEditPageStyled = styled.div`
  .list-heading {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 32px;
    li {
      font-size: 24px;
      line-height: 28px;
      color: var(--color-primary);
      position: relative;
      margin-right: 17px;
      padding-right: 27px;
      text-transform: capitalize;
      &:last-child::after {
        display: none;
      }
      &::after {
        content: '';
        display: block;
        width: 10px;
        height: 16px;
        background: var(--icon-gt) center/contain no-repeat;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &:last-child {
        font-weight: 600;
      }
    }
  }

  .card-body.is-flex,
  .body-hours {
    @media only screen and (min-width: 1025px) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 50px;
      .block {
        width: calc(50% - 25px);
        max-width: unset;
        &:first-child:after {
          content: '';
          display: block;
          width: 1px;
          height: 100%;
          position: absolute;
          right: -25px;
          top: 0;
          border-left: 1px solid #f0f2f7;
        }
      }
    }
    .block {
      position: relative;
      max-width: 526px;
      margin: 0 auto 24px;
    }
  }

  .body-hours {
    width: 100%;
    max-width: calc(100vw - 48px);
  }

  .hours-wrapper {
    margin: 0 auto 24px;
    overflow: auto;

    @media only screen and (min-width: 1025px) {
      min-width: 420px;
      max-width: 616px;
      width: auto;
      flex: 1;
      margin: 0;
    }
  }

  .hours-note {
    max-width: 374px;
    background: rgba(74, 157, 119, 0.2);
    border-radius: 6px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #363565;
    padding: 32px 75px 32px 32px;
    position: relative;
    margin: 0 auto;
    @media only screen and (min-width: 1025px) {
      margin-right: 50px;
      margin-top: 84px;
    }
    .note-icon {
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translateX(50%);
    }
  }

  .common-input-search {
    flex: 1;
    input {
      height: 48px;
      width: 100%;
      background-color: var(--color-white-01);
    }
  }

  .list-member-added {
    display: flex;
    flex-direction: column;
    gap: 8px;
    li {
      display: flex;
      gap: 8px;
      align-items: center;
      padding: 14px 8px;
      border: 1px solid #c3c2e0;
      border-radius: 5px;
    }
    .member-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      background: #e2e5ff;
      border-radius: 90px;
      font-size: 18px;
      font-weight: 600;
    }
    .member-text {
      font-weight: 500;
      font-size: 16px;
      color: #363565;
    }
    .btn-delete {
      margin-left: auto;
    }
  }
`;
