import { Empty, Form, Input } from 'antd';
import { InputSearchContain } from 'components/common/Input/InputSearch';
import bookingActions from 'features/bookings/services/actions';
import bookingSelectors from 'features/bookings/services/selectors';
import { IServiceItem } from 'features/bookings/services/types/service';
import { debounce, findIndex, remove } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ServiceItem from './ServiceItem';
import { useParams } from 'react-router-dom';
import PopupConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import { TIME_START_FORMAT_RESPONSE } from 'features/bookings/services/constants';
import { Moment } from 'moment';
import settingSelectors from 'features/settings/services/selectors';
import NewServicesModal from './NewServicesModal';
import userSelectors from 'features/users/services/selectors';
import MultipleSelect, { IOptionMulti } from 'components/common/MultipleSelect';
import ModalConfirm from 'components/common/Modal/ModalConfirm';

// import { useTranslation } from 'react-i18next';

type ServiceFormProps = {
  hideInput?: boolean;
  errorService?: string;
  isCheckIn?: boolean;
  isTimeRoster?: boolean;
};
const ServicesForm = (props: ServiceFormProps) => {
  const { hideInput, isCheckIn = false, isTimeRoster = false } = props;

  const dispatch = useAppDispatch();
  const [search, setSearch] = useState('');
  const form = Form.useFormInstance();
  const modalConfirmRef = useRef<ModalConfirmRef>(null);

  const selected = bookingSelectors.bookingOnline.getSelectedServices();
  const param = useParams();
  const merchant_location_id = !isCheckIn ? bookingSelectors.bookingOnline.getActiveLocationId() : param.location_id;
  const servicesStore = bookingSelectors.bookingOnline.getServices();
  const location = bookingSelectors.bookingOnline.getActiveLocation();
  const locationList = bookingSelectors.bookingOnline.getLocations();
  const { location_id = '' } = useParams();
  const [draffService, setDraffService] = useState<IServiceItem | null>(null);
  const setting = settingSelectors.getSettingBookingForm();
  // const getSetting = settingSelectors.getonlin();
  // const checkCategory = getSetting?.booking?.featured_category;
  const userOnline = userSelectors.getStaffPublic();
  const [catSelected, setCatSelected] = useState<(string | number)[]>();
  const teamMemberSelected = bookingSelectors.bookingOnline.getTeamMemberSelected();
  const modalConfirmSelectServiceRef = useRef<ModalConfirmRef>(null);
  

  const [isNameService, setIsService] = useState();

  const getServiceCheckout = useMemo(() => {

    return servicesStore.filter(o => {
      if (!o.merchant_location_id || o.merchant_location_id?.length === 0) return true;
      return !!o.merchant_location_id?.some(d => d?.toString() === merchant_location_id?.toString());
    }).map(o => ({
      ...o,
      merchant_employees: o.merchant_employees.filter(f => {
        if (!f.id) return true;
        return f.merchant_location_id?.toString() === merchant_location_id?.toString();
      })
    }));

  }, [servicesStore, merchant_location_id]);

  const getServiceCheckIn = () => {
    const paths = window.location.pathname.split('/');
    const location_id = paths[paths.length - 1];
    return servicesStore.filter(o => {

      if (setting.is_team_member_booking) {
        if (!o.merchant_location_id || o.merchant_location_id?.length === 0) return false;

        return !!o.merchant_location_id?.find(d => d?.toString() === location_id?.toString()) && o.merchant_employees.some(emp => {
          if (emp.merchant_location_id?.toString() !== merchant_location_id?.toString()) return false;
          return emp.is_receive_book === 1;
        });

      } else {
        if (!o.merchant_location_id || o.merchant_location_id?.length === 0)
          return true;
        return !!o.merchant_location_id?.find(d => d?.toString() === location_id?.toString());
      }

    }).map(o => ({
        ...o,
        merchant_employees: o.merchant_employees.filter(f => {
          if (!f.id) return true;
          return f.merchant_location_id?.toString() === merchant_location_id?.toString();
        })
      }));
  };


  const services = useMemo(() => {
    if (!getServiceCheckout) return [];

    return !isCheckIn ? getServiceCheckout : getServiceCheckIn();
  }, [merchant_location_id, getServiceCheckout]);

  const categories = useMemo(() => {

    if (!services.length) return [];
    const result: IOptionMulti[] = [];
    // result.push({
    //   id: 'general',
    //   color: '#4A9D77',
    //   name: 'General'
    // });
    services?.forEach(o => {
      if (o?.category && !result.find(c => c.value === o?.category?.id))
        result.push({
          value: o.category.id,
          title: o.category.name
        });
    });
    return result;
  }, [services]);

  const categoriesAllValue = useMemo(() => {
    return categories.map(cat => cat.value);
  }, [categories]);

  const _services = useMemo(() => {
    const _search = search.toLowerCase();
    return services?.filter(o => {
      const isPassCate = catSelected ? catSelected.some(cat => cat.toString() === o.category.id.toString()) : true;

      if (isPassCate) {
        if (o?.name?.toLowerCase().includes(_search)) {
          return true;
        }
        return !!o?.service_variants?.some(s => s.name?.toLowerCase().includes(_search));
      }
      return false;
    })
      .map(o => ({
        name: o.name,
        id: o.id,
        price: o.sale_price || o.regular_price,
        duration: o.duration_time,
        quantity: 1,
        merchant_employees: o.merchant_employees,
        sale_price: o.sale_price,
        regular_price: o.regular_price,
        // @ts-ignore
        description: o?.description,
        subServices: o?.service_variants?.filter(s => _search ? s.name?.toLowerCase().includes(_search) : true)?.map(s => ({
          id: o.id,
          service_variant_id: s.id,
          name: s.name,
          price: s.price ?? 0,
          duration: s.duration ?? 0,
          quantity: 1,
          payload: {
            ...s,
            sale_price: s.price,
            merchant_employees: o.merchant_employees,
            id: o.id,
            service_variant_id: s.id,
          },
        }) as IServiceItem),
        payload: o,
        category: o.category,
      }) as IServiceItem);
  }, [services, catSelected, search]);

  const setFormValue = (serviceItem: IServiceItem) => {
    const value = serviceItem.id + '-' + serviceItem?.service_variant_id ?? '';
    const temp: string[] = selected.map(
      (o) => o.id + '-' + o?.service_variant_id ?? ''
    );
    const idx = temp.indexOf(value);
    if (idx !== -1) {
      remove(temp, (n) => n === value);
      form.setFieldsValue({
      });
    } else {
      temp.push(value);
      const serviceFirst = selected.length > 0 ? selected[0] : undefined;

      form.setFieldsValue({
        service: temp.join('') ? temp.join('') : (serviceFirst?.id + '-' + serviceFirst?.service_variant_id),
      });
    }

  };


  // const getTimeStart = () => {
  //   let timePicker;
  //     if (service.length === 0) {
  //       timePicker = dayjs(bookingDate);
  //     } else {
  //       const lastItem = last(currServices);
  //       const breakTimeMinutes = BREAK_TIME_MINUTES;
  //       const prevServiceMinutes = (lastItem?.duration_time ?? 0) * (lastItem?.quantity ?? 0);
  //       const nextTimeStart = moment(lastItem?.time_start, TIME_START_FORMAT).add(breakTimeMinutes + prevServiceMinutes, 'minute');
  //       timePicker = dayjs(nextTimeStart.format());
  //     }
  //   }
  //   return timePicker;
  // };


  const handleAddService = (formValue: { members: number, quantity: number, time_start: Moment, duration: number }) => {
    if (!draffService) return null;

    if (!setting?.is_team_member_booking && !formValue.members) {
      const employee_id = location?.manager?.id || locationList?.find(o => Number(o?.id) === Number(location_id))?.manager?.id || '';
      draffService.employee_id = employee_id;
    } else {
      draffService.employee_id = formValue.members;
    }

    draffService.quantity = formValue.quantity;
    draffService.price = (draffService?.sale_price > 0 ? draffService?.sale_price : draffService?.regular_price) ?? 0;
    draffService.time_start = formValue.time_start.format(TIME_START_FORMAT_RESPONSE);
    draffService.duration = draffService.duration || formValue.duration;

    setFormValue(draffService);

    dispatch(bookingActions.bookingOnlineServiceSelected.toggle(draffService));
    setDraffService(null);
  };



  const onChangeServices = (serviceItem: IServiceItem, dataService: any) => {
    const settingTeamMember = setting?.is_team_member_booking;
    const settingTimeRoster = setting?.enable_timeroster_management;
    // if(isCheckIn) {
    setIsService(dataService);

    const idx = findIndex(selected, (o) => o.id === serviceItem.id && o?.service_variant_id === serviceItem.service_variant_id);

    if (idx !== -1) {
      dispatch(bookingActions.bookingOnlineServiceSelected.toggle(serviceItem));
    } else {

      if (!isCheckIn && settingTimeRoster) {
        
        if(teamMemberSelected.length > 0 && !serviceItem.payload.merchant_employees.some((emp: any) => emp.id === teamMemberSelected?.[0]?.id)) {
          modalConfirmSelectServiceRef.current?.show({
            submit: () => {
              dispatch(bookingActions.setBookingOnlineTeamMemberSelected([]));
              dispatch(bookingActions.bookingOnlineServiceSelected.toggle(serviceItem));
            },
            msg: 'The current employee cannot perform this service. Do you want to continue to select a different service and unselect the current employee?',
            title: 'Team member can not assign this service'
          });
          return;
        }

        return dispatch(bookingActions.bookingOnlineServiceSelected.toggle(serviceItem));
      }

      if (settingTeamMember) {
        setDraffService(serviceItem?.payload);
      } else {
        const employee_id = location?.manager?.id || locationList?.find(o => Number(o?.id) === Number(location_id))?.manager?.id || '';
        setDraffService({ ...serviceItem?.payload, employee_id: employee_id });
      }
    }
    // return;
    // }
    // setFormValue(serviceItem);
    // dispatch(bookingActions.bookingOnlineServiceSelected.toggle(serviceItem));
  };

  const updateQuantity = (
    id: IServiceItem['id'],
    service_variant_id: IServiceItem['service_variant_id'],
    quantity: number
  ) => {
    dispatch(
      bookingActions.bookingOnlineSetQuantityServiceItem({
        id,
        service_variant_id,
        value: quantity,
      })
    );
  };
  return (
    <ServicesStyled>
      <div className='service-filter'>
        {!hideInput && <InputSearch onSearch={setSearch} />}
        {setting.featured_category ?
          <MultipleSelect
            onChange={setCatSelected}
            value={catSelected ?? categoriesAllValue}
            options={categories}
            isWhiteInput
            maxTagPlaceholder=''
            placeholder='Select category'
          />
        : <></>} 
      </div>

      <MessageStyled>
        <Form.Item shouldUpdate noStyle>
          {() => {
            return (
              <Form.Item rules={[
                {
                  validator: () => {
                    if (!isCheckIn && selected.length === 0) {
                      return Promise.reject(
                        'You must select at least one service.'
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]} name={'service'}>
                <Input />
              </Form.Item>
            );
          }}
        </Form.Item>
      </MessageStyled>
      <ServicesSelectionStyled>
        <table>
          <tbody>
            {
              _services?.length > 0 ?

                _services?.map((item) => (
                  <ServiceItem
                    isTimeRoster={isTimeRoster}
                    selectedList={selected}
                    key={item.id}
                    dataService={_services}
                    data={item}
                    onServiceChange={onChangeServices}
                    updateQuantity={updateQuantity}
                    employeeList={userOnline}
                  />
                ))
                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No services'} />
            }
          </tbody>
        </table>
      </ServicesSelectionStyled>
      {draffService ?
        <NewServicesModal isNameService={isNameService} disableMember={!setting?.is_team_member_booking} isOpenModal={!!draffService} handleCancel={() => setDraffService(null)} dataService={draffService} handleAddServices={handleAddService} modalConfirmRef={modalConfirmRef} isCheckIn={isCheckIn} /> : <></>
      }
      <PopupConfirm ref={modalConfirmRef} />
      <ModalConfirm ref={modalConfirmSelectServiceRef} />


    </ServicesStyled>
  );
};

export default ServicesForm;

const InputSearch = ({ onSearch }: { onSearch: (val: string) => void }) => {
  const [text, setText] = useState('');
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setText(e.target.value ?? '');
  };
  // const { t: formLang } = useTranslation('form');

  const _debounce = useCallback(
    debounce((text) => {
      onSearch(text);
    }, 200),
    []
  );

  useEffect(() => {
    _debounce(text);
  }, [text]);

  return (
    <InputSearchContain
      className='input_search'
      placeholder={'Search service'}
      value={text}
      onChange={onChange}
    />
  );
};

const MessageStyled = styled.div`
  .ant-form-item-control-input {
    display: none !important;
  }
`;
const ServicesStyled = styled.div`
  .service-filter {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    margin-bottom: 16px;

    .inputSelect {
      @media only screen and (max-width: 507.99px) {
        width: 100%;
      }
    }

    .ant-select-selector {
      height: 48px;
      background-color: var(--color-white-01) !important;
      border-radius: 6px;
      border: none;
      color: var(--color-primary);
      font-weight: 500;
      box-shadow: none !important;
      width: 100%;
    }
  }
`;

// const FeaturedServicesStyled = styled.div`
//   display: flex;
//   width: 100%;
//   margin-bottom: 8px;
//   overflow: auto;
//   &.disableScroll {
//     display: flex;
//   }
//   .service_item {
//     margin-right: 12px;
//     margin-bottom: 12px;
//     min-width: max-content;
//   }
// `;

const ServicesSelectionStyled = styled.div`
  display: flex;
  max-height: 360px;
  overflow-x: hidden;
  table {
    width: 100%;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d9d9d9;
  }

  @media (max-width: 767.98px) {
    min-height: 300px;
  }
`;
