import bookingActions from 'features/bookings/services/actions';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import BookingForms from '../../components/BookingForm';
import StickyPromotion from '../../components/BookingForm/StickyPromotion';
import { BOOKING_FORM } from '../../components/BookingForm/types';
import { AxiosResponse } from 'axios';
import apisLocation from 'features/locations/services/apis';
import { get } from 'lodash';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import locationActions from 'features/locations/services/actions';
import locationSelectors from 'features/locations/services/selectors';
import dayjs from 'dayjs';
import moment from 'moment';
import { TIME_DATE_MERCHANT } from 'features/bookings/services/constants';
import apisBooking from 'features/bookings/services/apis';
import { message } from 'antd';
import bookingSelectors from 'features/bookings/services/selectors';
import { IMerchantPublicInfoResData } from 'features/bookings/services/types/bookingOnline';
import { PATH } from 'constants/path.enum';
import settingSelectors from 'features/settings/services/selectors';
import { ECurrencyUnit } from 'constants/index';

const BookingForm: React.FC<{ isCheckIn?: boolean, isTestTyro?: boolean, v2?: boolean, wallet?: boolean, live?: boolean}> = ({ isCheckIn, isTestTyro, v2, wallet, live }) => {
  const { location_id = '', merchant_code = '' } = useParams();
  const [formActive, setFormActive] = React.useState<BOOKING_FORM>(
    BOOKING_FORM.INFORMATION
  );

  const setLoadingPage = useSetLoadingPage();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const activeLocation = locationSelectors.getLocationDetailPublic();
  const navigate = useNavigate();
  const locationsStore = bookingSelectors.bookingOnline.getLocations();
  const statusLocation = bookingSelectors.bookingOnline.getBookingOnlinePage();
  const [bookingCode, setBookingCode] = useState<string>();
  const [merchantPublicInfo, setMerchantPublicInfo ] = useState<IMerchantPublicInfoResData>();
  const listSettingsBookingForm = settingSelectors.getSettingBookingForm();

  useEffect(() => {
    (async () => {
      try {
        const rest = await apisBooking.getCheckMerchantCode(merchant_code);
        const msg = get(rest, 'data.message', '');
        if (msg) {
          return;
        }
      } catch (error: any) {
        const response = get<Record<string, string[]>>(error, 'errors.merchant_code', {});
        const errors = Object.values(response);
        errors.map((value) => {
          message.error(get(value, [0], ''));
        });
        return navigate('*');
      }
    })();

    (async () => {
      try {
        const res = await apisBooking.getPublicMerchantInfo(merchant_code);
        setMerchantPublicInfo(res.data.data);
      } catch (error) {
        console.log('Error get public merchant info: ', error);
      }
    })();
  }, []);

  useEffect(() => {
    if (!locationsStore) return;

    if (!isCheckIn) {
      if (statusLocation === 'CLOSE' ) {
        setFormActive(BOOKING_FORM.INVALID_LOCATION);
        setLoadingPage(false);
      } else if (statusLocation === 'ACTIVE') {
        setFormActive(BOOKING_FORM.INFORMATION);
      }
    }
  }, [locationsStore]);

  const dispatch = useAppDispatch();
  const checkLocationValid = async () => {

    try {
      const res: AxiosResponse<{ data: any[] }> = await apisLocation.getLocationDetailPublic(merchant_code, location_id);
      if (res?.data?.data) {
        const status = get(res.data, 'data.status', 1);
        if (!status) {
          setFormActive(BOOKING_FORM.INVALID_LOCATION);
          setLoadingPage(false);
          return false;
        }
        return true;
      }
    } catch (error) {
      return true;
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('checkin')) {
      document.title = 'Check in';
    } else if (location.pathname.includes('booking-form')) {
      document.title = 'Booking online';
    }
  }, [location]);

  useEffect(() => {
    isCheckIn && dispatch(locationActions.getLocationDetailPublic.fetch({ merchant_code, location_id }));
    checkLocationValid();

    dispatch(bookingActions.getBookingOnlineData.fetch(merchant_code));
  }, []);

  const date_closed: any = useMemo(() => {

    if (!activeLocation) return null;

    return activeLocation?.dateClosed?.map(o => ({
      start: dayjs(o.start_date, 'YY-MM-DD'),
      end: dayjs(o.end_date, 'YY-MM-DD'),
    }));
  }, [activeLocation?.dateClosed]);


  const getDisableDateByClosedPeriod = () => {
    // return true;
    return date_closed?.find((o: any) => moment()?.format('YY-MM-DD') == o.start?.format('YY-MM-DD') || moment().isBetween(o.start, o.end.add(1, 'day')));
  };

  const getDatePeriod = () => {
    return getDisableDateByClosedPeriod().start?.format(TIME_DATE_MERCHANT) === getDisableDateByClosedPeriod().end?.format(TIME_DATE_MERCHANT) ?
      getDisableDateByClosedPeriod().start?.format(TIME_DATE_MERCHANT) :
      `${getDisableDateByClosedPeriod().start?.format(TIME_DATE_MERCHANT)} to ${getDisableDateByClosedPeriod().end?.format(TIME_DATE_MERCHANT)}`;

  };

  if (isCheckIn && !!getDisableDateByClosedPeriod()) {
    return <BookingForms.InValidLocation
      title='Sorry! Our store has temporary closed.'
      subtitle={`Our store is temporarily closed on ${getDatePeriod()}. We'll be back soon, giving a new offer of tranquility and bliss.`}
      setFormActive={setFormActive}
      isCheckIn={isCheckIn} />;
  }

  return (
    <BookingFormStyled>
      {formActive !== BOOKING_FORM.INVALID_LOCATION ?
        <StickyPromotion isCheckIn={isCheckIn} label='Some promotion - book something get another thing free!' /> : <></>}

      <div className='form_container'>
        <div
          className={` ${formActive === BOOKING_FORM.INFORMATION ? '' : ' hide'} `} >
          <BookingForms.Information
            errors={errors}
            setFormActive={setFormActive}
            isCheckIn={isCheckIn}
            v2={v2}
          />
        </div>
        <div
          className={` ${formActive === BOOKING_FORM.CONFIRM ? '' : ' hide'} `}
        >
          <BookingForms.Confirm
            setErrors={setErrors}
            setFormActive={setFormActive}
            isCheckIn={isCheckIn}
            isTestTyro={isTestTyro}
          />
        </div>
        <div
          className={` ${formActive === BOOKING_FORM.SUCCESS ? '' : ' hide'} `}
        >
          <BookingForms.BookingDone formActive={formActive} setFormActive={setFormActive} isCheckIn={isCheckIn} isTestTyro={isTestTyro} bookingCode={bookingCode}/>        
        </div>
        <div
          className={` ${formActive === BOOKING_FORM.INVALID_LOCATION ? '' : ' hide'} `}
        >
          <BookingForms.InValidLocation setFormActive={setFormActive} isCheckIn={isCheckIn} />
        </div>
        {formActive === BOOKING_FORM.PREPAID && isTestTyro ?
          <div className={` ${formActive === BOOKING_FORM.PREPAID ? '' : ' hide'} `}>
            <BookingForms.PrepaidPage setFormActive={setFormActive} isCheckIn={isCheckIn} setBookingCode={setBookingCode} wallet={wallet} live={live}/>
          </div> : <></>
        }
      </div>
      {merchantPublicInfo && (
        <div className="footer">
          <div className="footer-top">
          <h3>Contact us</h3>
          <p>{merchantPublicInfo.full_name}</p>
          {
            locationsStore.length > 0 && <p>{ locationsStore[0].address }</p>
          }
          <span>
            <p>{ merchantPublicInfo.email }</p>
            <p>{ merchantPublicInfo.phone }</p>
          </span>
          {
            locationsStore.length > 0 && <p>ABN: { locationsStore[0].abn }</p>
          }
          <p>We aim to respond to all enquiries within 1 business day.</p>
          </div>
          <div className="footer-bottom">
            <p>All pricing in {listSettingsBookingForm.currency_unit || ECurrencyUnit.AUD} and includes GST unless stated otherwise</p>
            <span>
              <a href={PATH.privacy_policy+ '/' + merchant_code} target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
              <a href={PATH.refund_policy + '/' + merchant_code} target="_blank" rel="noreferrer">
                Refund Policy
              </a>
            </span>
          </div>
        </div>
      )}
    </BookingFormStyled>
  );
};

export default memo(BookingForm);

const BookingFormStyled = styled.div`
  background: var(--color-white-01);
  min-height: 100vh;
  font-family: var(--font-base);
  @media only screen and (min-width: 1025px) {
    padding-bottom: 80px;
  }
  @media only screen and (max-width: 767.98px) {
    padding-bottom: 20px;
  }
  .form_container {
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 24px;

    @media only screen and (min-width: 768px) and (max-width: 1024.98px) {
      padding: 40px 58px;
    }

    @media only screen and (max-width: 767.98px) {
      padding: 40px 12px 0;
    }
  }
  .hide {
    display: none;
  }
    
  .footer {
    width: 100%;
    margin-top: 25px;
    padding-top: 25px;
    padding-inline: 20%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    
    .footer-top, .footer-bottom {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      gap: 4px;

      span, p {
        color: var(--color-black);
      }

      span {
        display: flex;
        gap: 4px;

        p, a {
          border-left: 1px solid var(--color-black);
          padding-left: 4px;

          &:first-child {
            border-left: none;
          }
        }

        a {
          color: var(--color-purple-02);
          text-decoration: underline;
        }
      }
    }

    .footer-top {
      padding-bottom: 6px;
      border-bottom: 1px solid var(--color-black);

      h3 {
        text-transform: uppercase;
      }
    }

    .footer-bottom {
      padding-top: 6px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024.98px) {
      padding: 25px 58px;
    }

    @media only screen and (max-width: 767.98px) {
      padding: 25px 12px 0;
    }
  }
`;
