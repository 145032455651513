import { Form, Modal } from 'antd';
import styled from 'styled-components';
import EditShiftForm from '../components/EditShiftForm';
import { EditShiftInitValue, ITimeShiftFormValue } from '../types/timeSchedule';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { sortTimeSchedule } from '../components/TimeScheduleTable';

interface EditShiftModalProps {
  isModalOpen?: boolean;
  handleClose?: () => void;
  handleSubmit?: (values: any) => Promise<void>;
  initData?: EditShiftInitValue;
  handleRemoveTimeOff: (off_id: string | number, isRepeat?: boolean) => Promise<boolean>;
}

const EditShiftModal = ({
  isModalOpen,
  handleClose,
  handleSubmit,
  initData,
  handleRemoveTimeOff
}: EditShiftModalProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue('dayOff', dayjs(initData?.date));
    form.setFieldValue(
      'times',
      initData?.time?.map((item) => {
        const timeArray = item?.text?.split(' - ');
        return {
          start: timeArray?.[0] ? dayjs(timeArray?.[0], 'HH:mm') : '',
          end: timeArray?.[1] ? dayjs(timeArray?.[1], 'HH:mm') : '',
          is_work: item?.is_work,
          off_id: item?.off_id,
          is_repeat: item?.is_repeat,
        };
      })
    );
  }, [initData]);

  const checkValid = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };

  const getPayload = (values: ITimeShiftFormValue) => {
    const result = {
      date: (values?.dayOff as any).format('YYYY-MM-DD'),
      work_times: values?.times?.filter(item => item?.is_work)?.map((item) => {
        return {
          time_start: (item.start as any).format('HH:mm:ss'),
          time_end: (item.end as any).format('HH:mm:ss'),
        };
      }),
      is_shift_default: values?.updateTimeScheduleCheck
    };
    return result;
  };

  const handleForm = async () => {
    const valid = await checkValid();
    if (!valid) return;
    form?.submit();
  };

  const submitForm = async (values: ITimeShiftFormValue) => {
    const payload = getPayload(values);
    handleSubmit?.(payload);
  };

  return (
    <Modal
      maskClosable={false}
      open={isModalOpen}
      onCancel={handleClose}
      okButtonProps={{ style: { display: 'none' } }}
      footer={null}
      forceRender
      centered
      zIndex={1040}
    >
      <EditShiftModalStyled>
        <div className='form-content'>
          <h2 className='modal-title'>Edit Shift</h2>

          <Form
            form={form}
            layout='vertical'
            onFinish={submitForm}
            initialValues={{ oneDayLeave: false, repeatType: false, updateTimeScheduleCheck: false }}
          >
            <EditShiftForm timeShift={sortTimeSchedule(initData?.timing_shifts)} handleRemoveTimeOff={handleRemoveTimeOff}/>
          </Form>
        </div>

        <div className='form-submit-wrapper'>
          <div data-testing='Cancel' onClick={handleClose} className='common-btn is-white'>
            Cancel
          </div>
          <button data-testing='Save' type='submit' className='common-btn' onClick={handleForm}>
            Save
          </button>
        </div>
      </EditShiftModalStyled>
    </Modal>
  );
};

const EditShiftModalStyled = styled.div``;

export default EditShiftModal;
