import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useMemo, useState } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { PATH } from '../../../constants/path.enum';
import invoiceActions from '../services/actions';
import invoiceSelectors from '../services/selectors';
// import { getFormatShortDateAndTime } from 'utils/moment/getSingleDateShortFormat';
import ModalSendInvoiceEmail, { ICustomerSendEmail } from 'components/common/Modal/ModalSendInvoiceEmail';
import { formatMoney } from 'utils/unit';
import moment from 'moment';

type InvoiceItem = {
  key: string | number,
  invoiceNo: string,
  customerName: string,
  customerCode: string,
  customerEmail: string,
  date: string,
  amount: string,
  isWalkinIn: boolean | 0 | 1 | 2,
};

const InvoicesTable = () => {
  const dispatch = useAppDispatch();
  const loading = invoiceSelectors.getInvoicesLoading();
  const _data = invoiceSelectors.getInvoices();
  const pagination = invoiceSelectors.getPagination();
  const [searchParams] = useSearchParams();
  const [isOpenHandInvoice, setIsOpenHandInvoice] = useState<boolean>(false);
  const [invoiceDetail, setInvoiceDetail] = useState<any>(null);

  const handleCancelSendInvoice = () => {
    setIsOpenHandInvoice(false);
  };

  // const _sendCustomerInvoice = useSendCustomerInvoice();

  // const sendMail = (record: InvoiceItem) => {
  //   const customerCode = record?.customerCode ?? '';
  //   const email = undefined;
  //   _sendCustomerInvoice({ invoiceId: record.invoiceNo ?? '', email, customerCode });
  // };
  

  const columns: ColumnsType<InvoiceItem> = [
    {
      title: 'Invoice No',
      dataIndex: 'invoiceNo',
      key: 'invoiceNo',
      fixed: 'left',
      className: 'nowrap',
      render: (text: string, record: InvoiceItem) => {
        return (
          <div data-testing={`invoice_${record.key}`}>
            <NavLink
              to={PATH.invoicesDetail + record.invoiceNo.replace('#', '')}
            >
              {text}
            </NavLink>
          </div>
        );
      },
    },
    {
      title: 'Customer Phone',
      dataIndex: 'customerPhone',
      key: 'customerPhone',
    },
    {
      title: 'Customer Name',
      dataIndex: 'customerName',
      key: 'customerName',
    },
    {
      title: 'Issued date',
      dataIndex: 'date',
      key: 'date',
      className: 'nowrap',
      render(value) {
        if (!value) {
          return '';
        }
        return <span>{moment(value).format('MMM DD,YYYY')}</span>;
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text: string, record: InvoiceItem) => {
        return formatMoney((+record?.amount) ?? 0);
      }
    },
    // {
    //   title: 'Created At',
    //   dataIndex: 'created_at',
    //   key: 'created_at',
    // },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text: string, record: InvoiceItem) => {
        return (
          <>
          <ul className={'common-action'}>
            <NavLink
              to={PATH.invoicesDetail + record.invoiceNo.replace('#', '')}
            >
              <li
                className="action-edit"
              ></li>
            </NavLink>
            <li className='action-send' onClick={() => {
              setIsOpenHandInvoice(true);
              setInvoiceDetail(record);
              }}></li>
          </ul>
          </>
        );
      },
    },
  ];

  const data = useMemo(() => {
    return _data.map(o => ({
      key: o?.id?.toString(),
      invoiceNo: o.invoice_no,
      amount: o?.total_price?.toString(),
      customerName: o.customer?.name,
      customerPhone: o.customer?.phone_number,
      customerCode: o.customer?.customer_code,
      customerEmail: o.customer?.email,
      date: o.created_at,
      isWalkinIn: o?.customer?.is_walkin_in
    }) as InvoiceItem);
  }, [_data]);

  return (
    <>
      <Table
        className={'custom-table'}
        scroll={{ x: 900 }}
        columns={columns}
        rowKey={(record) => record?.key}
        dataSource={data}
        pagination={{
          total: pagination?.total_record,
          pageSize: pagination?.record_per_page,
          defaultCurrent: Number(searchParams.get('page') ?? 1),
          defaultPageSize: Number(searchParams.get('per_page') ?? 10),
          onChange: (pageChange, sizeChange) => {
            const params: any = {
              page: pageChange == 0 ? 1 : pageChange,
              per_page: sizeChange,
            };
            dispatch(invoiceActions.setInvoiceParams({
              page: params.page,
              per_page: params.per_page,
            }));
          },
          showSizeChanger: true,
          showTotal(total) {
            return `Total ${total} items`;
          },
        }}
        loading={loading}
      />
      {isOpenHandInvoice && <ModalSendInvoiceEmail
        handleCancel={handleCancelSendInvoice}
        isOpen={isOpenHandInvoice}
        customer={{
          email: invoiceDetail?.customerEmail,
          customer_code: invoiceDetail?.customerCode,
          is_walkin_in: invoiceDetail
        } as ICustomerSendEmail}
        invoice_id={invoiceDetail?.invoiceNo ?? ''} />}
    </>
  );
};

export default InvoicesTable;
