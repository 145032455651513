import { get, sum, sumBy } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { IBookingDetailResData } from './types/bookingDetail';
import { EPaymentMethod } from './types/checkout';
import { CHOOSE_CATEGORY_REWARD } from 'features/loyaltyProgram/services/constants';
import { IServiceSelectedItem } from './types/service';
import { IVoucherItemResData } from 'features/vouchers/services/types/voucher';
import { IVoucherDataItem } from './types/voucher';
import uiSelector from 'services/UI/selectors';
import { PATH_LOADING } from './constants';
import { checkServiceRestrict, getActivePricePromotionVoucher, roundNumber } from 'utils/unit';
import settingSelectors from 'features/settings/services/selectors';
import promotionSelectors from 'features/promotions/services/selectors';


type MyState = RootState['checkout'];

const getCurrentState = (state: RootState): MyState => state.checkout;

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getIdBookingDetail = () => selector('id') as (string | number | null);

export const getIdPure = (rootState: RootState) => {
  const state = getCurrentState(rootState);
  return state.id;
};

export const getDetailPure = (rootState: RootState) => {
  const state = getCurrentState(rootState);
  return state.bookingDetail;
};

const getBookingDetail = () => selector('bookingDetail') as IBookingDetailResData | null;

const getServices = () => selector('services') as MyState['services'];
const getCategories = () => selector('categories') as MyState['categories'];
const getRewards = () => selector('rewards') as MyState['rewards'];
const getVouchers = () => selector('vouchers') as MyState['vouchers'];
const getCustomerInfo = () => selector('customerInfo') as MyState['customerInfo'];
const getCustomer = () => selector('customerInfo') as MyState['customerInfo'];

const getInitialSelectedServices = () => selector('initSelectedServices') as MyState['initSelectedServices'];
const getSelectedServices = () => selector('selectedServices') as MyState['selectedServices'];

const getInitSelectedVouchersForSales = () => selector('initSelectedVouchersForSales') as MyState['initSelectedVouchersForSales'];
const getBookingDate = () => selector('bookingDate') as MyState['bookingDate'];

const getOriginalCardValue = () => selector('purchaseInfo.currentCard') as (number | null);

const getPurchaseCurrentCard = () => {
  const getOriginalCardValue = selector('purchaseInfo.currentCard') as (number | null);
  const cardSurcharge = getCardSurcharge();
  
  return (getOriginalCardValue ?? 0) + cardSurcharge;
  
};

const getHolidayValue = () => {
  const selected = getSelectedServices();  
  const totalPrice = +sumBy(selected, (o) => o.price * o.quantity);
  const totalReward = getRewardValue();

  
  const promotionAutoValue = getPromotionValue()?.promotionAutoValue ?? 0;
  const promotionManualValue = getPromotionValue()?.promotionManualValue ?? 0;

  let promotionValue =  roundNumber(promotionAutoValue + promotionManualValue);

  if(!settingSelectors?.getSetting()?.booking.both_promotion_types_allow) {
    promotionValue = roundNumber(promotionAutoValue) > roundNumber(promotionManualValue)  ? 
      promotionAutoValue:
      promotionManualValue;
  }

  const extraFee = roundNumber(getExtraFeeValue());
  const discountFee = roundNumber(getDiscountFeeValue());
  const holiday = settingSelectors.getHolidayInfoToday();
  const isEachService = holiday?.is_each_service ?? false;
  const percentHoliday = roundNumber((holiday?.value ?? 0) / 100);

  const total = sum([
    +(totalPrice ?? 0),
    // +(totalPriceVoucher ?? 0),
    -(totalReward ?? 0),
    -(promotionValue ?? 0),
    +(extraFee ?? 0),
    -(discountFee ?? 0),
  ]);

  
  const holidayValue = isEachService === 0 ? total * percentHoliday ?? 0 : totalPrice * percentHoliday ?? 0;

  return holidayValue;
};

const getCardSurcharge = () => {
  const cardValue = getOriginalCardValue() ?? 0;
  const bookingSetting = settingSelectors.getSetting()?.booking;
  const cardSurchargeValue = bookingSetting?.value_card_surchange;
  const isCardSurcharge = bookingSetting?.is_card_surchange;

  return isCardSurcharge ? cardValue * (cardSurchargeValue/100) : 0;
};

const getOriginTotalPriceExceptPromotion = () => {
  const selected = getSelectedServices();
  const totalPriceVoucher = getTotalPriceVoucherForSales();
  const totalPrice = +sumBy(selected, (o) => o.price * o.quantity);
  const totalReward = getRewardValue();

  const extraFee = roundNumber(getExtraFeeValue());
  const discountFee = roundNumber(getDiscountFeeValue());
  
  const holidayValue = roundNumber(getHolidayValue());

  const total = sum([
    +(totalPrice ?? 0),
    +(totalPriceVoucher ?? 0),
    -(totalReward ?? 0),
    +(extraFee ?? 0),
    -(discountFee ?? 0),
    +(holidayValue ?? 0),
  ]);
  return total > 0 ? total : 0;
};

const getOriginTotalPriceAfterCardSurcharge = () => {
  const selected = getSelectedServices();
  const totalPriceVoucher = getTotalPriceVoucherForSales();
  const totalPrice = +sumBy(selected, (o) => o.price * o.quantity);
  const totalReward = getRewardValue();

  const promotionAutoValue = getPromotionValue()?.promotionAutoValue ?? 0;
  const promotionManualValue = getPromotionValue()?.promotionManualValue ?? 0;

  let promotionValue =  roundNumber(promotionAutoValue + promotionManualValue);

  if(!settingSelectors?.getSetting()?.booking.both_promotion_types_allow) {
    promotionValue = roundNumber(promotionAutoValue) > roundNumber(promotionManualValue)  ? 
      promotionAutoValue:
      promotionManualValue;
  }
  const extraFee = roundNumber(getExtraFeeValue());
  const discountFee = roundNumber(getDiscountFeeValue());
  const holidayValue = roundNumber(getHolidayValue());
  const totalPrePaid = checkoutSelectors.getBookingDetail()?.total_pre_paid;

  const total = sum([
    +(totalPrice ?? 0),
    +(totalPriceVoucher ?? 0),
    -(totalReward ?? 0),
    -(promotionValue ?? 0),
    +(extraFee ?? 0),
    -(discountFee ?? 0),
    +(holidayValue ?? 0),
    -(totalPrePaid ?? 0),
  ]);
  return total > 0 ? total : 0;
};

const getOriginTotalPriceAfterCardSurchargeNotPrePaid = () => {
  const selected = getSelectedServices();
  const totalPriceVoucher = getTotalPriceVoucherForSales();
  const totalPrice = +sumBy(selected, (o) => o.price * o.quantity);
  const totalReward = getRewardValue();

  const promotionAutoValue = getPromotionValue()?.promotionAutoValue ?? 0;
  const promotionManualValue = getPromotionValue()?.promotionManualValue ?? 0;

  let promotionValue = roundNumber(promotionAutoValue + promotionManualValue);

  if (!settingSelectors?.getSetting()?.booking.both_promotion_types_allow) {
    promotionValue =
      roundNumber(promotionAutoValue) > roundNumber(promotionManualValue)
        ? promotionAutoValue
        : promotionManualValue;
  }
  const extraFee = roundNumber(getExtraFeeValue());
  const discountFee = roundNumber(getDiscountFeeValue());
  const holidayValue = roundNumber(getHolidayValue());
  const total = sum([
    +(totalPrice ?? 0),
    +(totalPriceVoucher ?? 0),
    -(totalReward ?? 0),
    -(promotionValue ?? 0),
    +(extraFee ?? 0),
    -(discountFee ?? 0),
    +(holidayValue ?? 0),
  ]);
  return total > 0 ? total : 0;
};

const getOriginTotalPrice = () => {
  const totalOriginal = getOriginTotalPriceAfterCardSurcharge();
  const cardSurchargeValue = roundNumber(getCardSurcharge());

  const total = sum([
    +(totalOriginal ?? 0),
    +(cardSurchargeValue ?? 0)
  ]);
  return total > 0 ? total : 0;
};

const getOriginTotalPriceNotPrePaid = () => {
  const totalOriginal = getOriginTotalPriceAfterCardSurchargeNotPrePaid();
  const cardSurchargeValue = roundNumber(getCardSurcharge());

  const total = sum([
    +(totalOriginal ?? 0),
    +(cardSurchargeValue ?? 0)
  ]);
  return total > 0 ? total : 0;
};

const getTotalSelectedServices = () => {
  const mainAmount = getOriginTotalPrice();

  const method = getPaymentMethod();

  const totalPaymentVoucher = getVoucherValue() ?? 0;

  const mixPayments = checkoutSelectors.getMixPayments();
  const totalMixPayments = +sumBy(mixPayments, o => o.value ?? 0);

  switch (method) {
    case EPaymentMethod.VOUCHER: return mainAmount - totalPaymentVoucher;
    case EPaymentMethod.MIX: return mainAmount - totalMixPayments;
    case EPaymentMethod.CASH:
    case EPaymentMethod.CARD:
    default: return mainAmount;
  }
};

const getRewardValue = () => {
  const selectedReward = getSelectedReward();
  const selected = getSelectedServices();

  const extraFeeValue = getExtraFeeValue();
  const extraDiscountValue = getDiscountFeeValue();
  const totalPrice = getSubTotal() + extraFeeValue - extraDiscountValue;
  if (!selectedReward) return 0;
  const { category, free_service, value_reward, amount_reward } = selectedReward;
  if (category === CHOOSE_CATEGORY_REWARD.FREE_SERVICE) {
    if (free_service.length === 0) {
      return totalPrice;
    }
    const _selected: IServiceSelectedItem[] = [];
    free_service.forEach(o => {
      if (o.extend_services.length === 0) {
        const it = selected.find(a => a.id?.toString() === o?.id?.toString());
        if (it) _selected.push(it);
        return;
      }
      const it = selected.find(a => a.id?.toString() === o?.id?.toString()
        && !!o.extend_services.find(s => a.service_variant_id?.toString() === s.toString()));
      if (it) _selected.push(it);
    });
    return +sumBy(_selected, (o) => o.price);
  }

  if (amount_reward) return amount_reward;

  if (value_reward) return totalPrice * (value_reward / 100);

  return 0;
};

const getPaymentMethod = () => selector('paymentMethod') as EPaymentMethod;

const getPurchaseCurrentCash = () => selector('purchaseInfo.currentCash') as (number | null);

const getPurchaseMethodErrors = (method: EPaymentMethod) => selector(`purchaseErrors.${method}`) as Record<string, string>;

const getLoyaltyPoint = () => selector('loyaltyPoint');

const getRewardOfCustomer = () => selector('rewardOfCustomer');

const loadingRewardOfCustomer = () => uiSelector.getLoading(PATH_LOADING.getRewardOfCustomer);

const getSubTotal = () => {
  const selected = getSelectedServices();
  const totalPrice = +sumBy(selected, (o) => o.price * o.quantity);
  const totalPriceVoucher = getTotalPriceVoucherForSales();

  return totalPrice + totalPriceVoucher;
};

const getSelectedReward = () => selector('selectedReward') as MyState['selectedReward'];

const getSelectedVouchersForSales = () => selector('selectedVouchersForSales') as MyState['selectedVouchersForSales'];

const getSelectedVouchers = () => selector('selectedVouchersForSales') as MyState['selectedVouchersForSales'];



const getTotalPriceVoucherForSales = () => {
  const selected = getSelectedVouchersForSales();
  const totalPrice = +sumBy(selected, (o) => o.retail_price * o.quantity);
  return totalPrice ?? 0;
};

const getPurchasedVouchers = (): IVoucherDataItem[] => {
  const voucher = (selector('voucherOfCustomer') ?? []) as IVoucherItemResData[];
  
  return voucher.map(v => {
    const result: string[] = [];
    v.services.forEach(o => {
      if (o.service_variants.length > 0) {
        o.service_variants.forEach(s => {
          const id = o?.id + '_' + s.id;
          result.push(id);
        });
      } else {
        result.push(o?.id?.toString());
      }
    });
    return ({
      ...v,
      services_flatten_ids: result,
      value_remaining: v.value - v.redeemed_value
    });
  });
};

const getSelectedVoucher = () => selector('selectedVoucher') as MyState['selectedVoucher'];
const getVoucherOfCustomer = () => selector('voucherOfCustomer') as MyState['voucherOfCustomer'];
const getResultTotal = () => selector('resultTotal') as MyState['resultTotal'];

const getSelectedVoucherUsed = () => selector('selectedVoucher') as MyState['selectedVoucher'];

const getVoucherValue = () => {
  const selected = getSelectedVoucher();
  const serviceSelected = getSelectedServices();
  const extraFeeValue = getExtraFeeValue();
  const discountFeeValue = getDiscountFeeValue();

  if (!selected) return 0;

  const services = selected.services;
  const validServices = serviceSelected.filter(o => {
    
    const serviceAvailable = services?.find((item: any) => {
      return o.service_variant_id === null ? item.id === o.id : item.id === o.id && item.service_variants?.find((i: any) => i.id === o.service_variant_id);
    });
    if(serviceAvailable) 
      return true;
  });
  const sumServicePrice = +sumBy(validServices, o => o.price ?? 0);
  const total = sumServicePrice + extraFeeValue - discountFeeValue;
  return total > selected?.value_remaining ? selected?.value_remaining : total;
};

const getMixPayments = () => selector('mixPayments') as MyState['mixPayments'];

const getTotalPointWillEarn = () => selector('total_point_program_will_earn') as MyState['total_point_program_will_earn'];

const getLocationData = () => {
  const locations = (selector('locations') ?? []) as MyState['locations'];
  const detail = getBookingDetail();
  return locations.find(o => o.id === detail?.merchant_location_id);
};

const getMerchantLocationId = () => getLocationData()?.id;


const getPromotionInfo = () => selector('promotionInfo') as MyState['promotionInfo'];

const getPromotionValue = () => {
  const promotionAuto = promotionSelectors.getPromotionAuto();
  const selected = getSelectedServices();
  const promotionManual = getPromotionInfo();
  const voucherValue = getTotalPriceVoucherForSales();


  const servicesAuto = promotionAuto?.services_restricts ?? [];
  const serviceManual = promotionManual?.services_restricts ?? [];

  let totalPriceAuto = 0;
  let totalPriceManual = 0;

  for(const service of selected) {
    const isApplyAuto = checkServiceRestrict(servicesAuto, service);
    const isApplyManual = checkServiceRestrict(serviceManual, service);
    const serviceTotalPrice = (service?.price * service?.quantity);

    if (isApplyAuto){
      totalPriceAuto += serviceTotalPrice;
    }

    if (isApplyManual){
      totalPriceManual += serviceTotalPrice;
    }
  }

  if(promotionManual?.enable_voucher_sales && voucherValue > 0) {
    totalPriceManual += voucherValue;
  }

  if (promotionAuto?.enable_voucher_sales && voucherValue > 0) {
    totalPriceAuto += voucherValue;
  }

  const promotionAutoValue = getActivePricePromotionVoucher(totalPriceAuto, promotionAuto);
  const promotionManualValue =  getActivePricePromotionVoucher(totalPriceManual, promotionManual);
  
  // const promotionAuto = promotionSelectors.getPromotionAuto();
  // const selected = getSelectedServices();
  // const promotionManual = getPromotionInfo();
  // const voucherValue = getTotalPriceVoucherForSales();


  // const servicesAuto = promotionAuto?.services_restricts ?? [];
  // const serviceManual = promotionManual?.services_restricts ?? [];

  // let promotionAutoValue = 0;
  // let promotionManualValue = 0;

  // for(const service of selected) {

  //   // apply auto or manual for each service
  //   const isApplyAuto = checkServiceRestrict(servicesAuto, service);
  //   const isApplyManual = checkServiceRestrict(serviceManual, service);
  //   const autoValue = getActivePricePromotion(service, promotionAuto);
  //   const manualValue = getActivePricePromotion(service, promotionManual);


  //   if(isApplyAuto) {
  //     promotionAutoValue += getValueInLimit(autoValue, service.price);
  //   }

  //   if(isApplyManual) {
  //     promotionManualValue += getValueInLimit(manualValue, service.price);
  //   }

  //   // switch(true) {
  //   //   case isApplyAuto && !isApplyManual:
  //   //     promotionAutoValue += getValueInLimit(autoValue, service.price);
  //   //     break;
      
  //   //   case !isApplyAuto && isApplyManual:
  //   //     promotionManualValue += getValueInLimit(manualValue, service.price);
  //   //     break;

  //   //   case isApplyAuto && isApplyAuto:  
  //   //     autoValue > manualValue ? promotionAutoValue += getValueInLimit(autoValue, service.price):
  //   //     promotionManualValue += getValueInLimit(manualValue, service.price);
  //   //     break;
  //   //   case !isApplyAuto && !isApplyManual:
  //   //   default:
  //   //     break;  
  //   // }    
  // }
  
  // if(promotionManual?.enable_voucher_sales && voucherValue > 0) {
  //   promotionManualValue += getActivePricePromotionVoucher(voucherValue, promotionManual);
  // }

  // if(promotionAuto?.enable_voucher_sales && voucherValue > 0) {
  //   promotionAutoValue += getActivePricePromotionVoucher(voucherValue, promotionAuto);
  // }
  

  return {
    promotionAutoValue, 
    promotionManualValue
  };
};

const getPromotionErrorBoundary = () => selector('promotionErrorBoundary') as MyState['promotionErrorBoundary'];
const getSoftPromotionCode = () => selector('softPromotionCode') as MyState['softPromotionCode'];

const getTotalPointOfCustomer = () => selector('total_point') as MyState['total_point'];

const getExtraFee = () => selector('extraFee') as MyState['extraFee'];

const getExtraFeeValue = () => {
  const subTotal = getSubTotal();
  const extraFee = getExtraFee();
  if (!extraFee) return 0;

  switch (extraFee.type) {
    case 'percent':
      return roundNumber(subTotal * (extraFee.value / 100));
    case 'price':
      return roundNumber(extraFee.value) ;
    default:
      return 0;
  }
};

const getDiscountFee = () => selector('discountFee') as MyState['discountFee'];

const getDiscountFeeValue = () => {
  const subTotal = getSubTotal();
  const discountFee = getDiscountFee();
  const extraFee = getExtraFeeValue();
  const totalPrice = (getExtraFee()?.value ?? 0) + subTotal;

  if (!discountFee) return 0;

  switch (discountFee.type) {
    case 'percent':
      return ((subTotal + extraFee) * (discountFee.value / 100));
    case 'price':
      return discountFee.value > totalPrice ? totalPrice : discountFee.value;
    default:
      return 0;
  }
};

const checkoutSelectors = {
  getLocationData,
  getIdBookingDetail,
  getBookingDetail,
  getCategories,
  getServices,
  getRewards,
  getVouchers,
  getCustomerInfo,
  getInitialSelectedServices,
  getInitSelectedVouchersForSales,
  getSelectedServices,
  getTotalSelectedServices,
  getBookingDate,
  getPaymentMethod,
  getPurchaseCurrentCash,
  getPurchaseMethodErrors,
  getLoyaltyPoint,
  getSelectedReward,
  getRewardValue,
  getSelectedVouchersForSales,
  getTotalPriceVoucherForSales,
  getPurchasedVouchers,
  getSelectedVoucher,
  getVoucherValue,
  getMixPayments,
  getOriginTotalPrice,
  getRewardOfCustomer,
  loadingRewardOfCustomer,
  getVoucherOfCustomer,
  getResultTotal,
  getTotalPointWillEarn,
  getPromotionValue,
  getPromotionInfo,
  getSubTotal,
  getPromotionErrorBoundary,
  getSoftPromotionCode,
  getTotalPointOfCustomer,
  getExtraFee,
  getExtraFeeValue,
  getDiscountFee,
  getDiscountFeeValue,
  getPurchaseCurrentCard,
  getCustomer,
  
  getSelectedVoucherUsed,
  getCardSurcharge,
  getHolidayValue,
  getOriginTotalPriceAfterCardSurcharge,
  getOriginalCardValue,
  getSelectedVouchers,
  getMerchantLocationId,
  getOriginTotalPriceExceptPromotion,
  getOriginTotalPriceAfterCardSurchargeNotPrePaid,
  getOriginTotalPriceNotPrePaid
};
export default checkoutSelectors;
