import React, { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import LocationsTable from '../components/LocationsTable';
import locationActions from '../services/actions';
import { useAppDispatch } from 'store/hooks';
import axios from 'axios';

const LocationsPage = () => {

  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(
      locationActions.getListPaginateLocations.fetch({
        page: Number(searchParams.get('page') ?? 1),
        per_page: Number(searchParams.get('per_page') ?? 10),
      })
    );
  }, [searchParams]);

  useEffect(() => {
    axios.post('https://auth.connect.tyro.com/oauth/token', {
      client_id:'hSjNHyAbTxDQoUNf50rLDSmExi351JeK',
    client_secret: 'GJUAdWSzf9w-WoysfwRKpcN5qcm51iLPgM4Q3V1Itp5urz-vMY_QoLJNWnogH5qe',
    grant_type:'client_credentials',
    audience:'https://merchant-staging.heyapos.com/'
    }, {
      headers: {
        'Content-Type' : 'application/x-www-form-urlencoded'
      }
    });
  }, []);

  useEffect(() => {
    axios.post('https://auth.connect.tyro.com/oauth/token', {
      client_id:'hSjNHyAbTxDQoUNf50rLDSmExi351JeK',
    client_secret: 'GJUAdWSzf9w-WoysfwRKpcN5qcm51iLPgM4Q3V1Itp5urz-vMY_QoLJNWnogH5qe',
    grant_type:'client_credentials',
    audience:'http://localhost:3000/'
    }, {
      headers: {
        'Content-Type' : 'application/x-www-form-urlencoded'
      }
    });
  }, []);

  useEffect(() => {
    axios.post('https://auth.connect.tyro.com/oauth/token', {
      client_id:'hSjNHyAbTxDQoUNf50rLDSmExi351JeK',
    client_secret: 'GJUAdWSzf9w-WoysfwRKpcN5qcm51iLPgM4Q3V1Itp5urz-vMY_QoLJNWnogH5qe',
    grant_type:'client_credentials',
    audience:'https://pos.connect.tyro'
    }, {
      headers: {
        'Content-Type' : 'application/x-www-form-urlencoded'
      }
    });
  }, []);

  return (
    <>
      <div className={'private-filter'}>
        <Link to="add-new" className={'common-btn'}>
          Add New
        </Link>
      </div>
      <LocationsTable />
    </>
  );
};

export default LocationsPage;
