import { Form, FormInstance, Input, Modal } from 'antd';
import { useEffect, useRef } from 'react';
import { ICancellationReasonItem } from '.';

interface IFormModal {
  isModalOpen?: boolean;
  handleCancel?: () => void;
  form?: FormInstance;
  handleSubmit?: (values: ICancellationReasonItem) => Promise<boolean>;
  isDisableSubmit?: boolean;
  formData?: any;
}

const FormModal = ({
  isModalOpen,
  handleCancel,
  handleSubmit,
  formData,
  isDisableSubmit
}: IFormModal) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form?.setFieldsValue(formData);
  }, [formData]);

  const myFocus = useRef<any>();

  useEffect(() => {
    myFocus?.current?.input?.focus();
  }, []);

  return (
    <Modal
      maskClosable={false}
      open={isModalOpen}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      centered
    >
      <h2 className='modal-title'>Add Cancellation Reason</h2>

      <Form
        className='modal-form'
        onFinish={handleSubmit}
        autoComplete='off'
        form={form}
        layout='vertical'
        initialValues={{
          locations: null,
        }}
      >
        <div className='form-row'>
          <Form.Item
            label='Name'
            name='name'
            rules={[{ required: true }]}
          >
            <Input ref={myFocus}  placeholder='Enter name' />
          </Form.Item>
        </div>
        <div className='form-submit-wrapper'>
          <div data-testing='Cancel' onClick={handleCancel} className='common-btn is-white'>
            Cancel
          </div>
          <button data-testing='Done' disabled={isDisableSubmit} type='submit' className='common-btn'>
            Done
          </button>
        </div>
      </Form>
    </Modal>
  );
};

export default FormModal;