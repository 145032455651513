import { Checkbox, Form, Input, Modal } from 'antd';
import { get } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import message from 'components/common/Message';
import apisInvoice from 'features/invoices/services/apis';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import styled from 'styled-components';
import { useAppDispatch } from 'store/hooks';
import invoiceActions from 'features/invoices/services/actions';
import invoiceSelectors from 'features/invoices/services/selectors';
import customerActions from 'features/customers/services/actions';
import { CUSTOMER_WALKIN_INDEX } from 'utils/unit';

export interface ICustomerSendEmail {
  email: string,
  customer_code: string;
  is_walkin_in: number | boolean;
}

interface Props {
  isOpen: boolean;
  handleCancel: () => void;
  invoice_id: string;
  customer: ICustomerSendEmail;
  onDone?: () => void;
  type?: 'invoice_detail' | 'invoice_table' | 'card_booking';
}

const ModalSendInvoiceEmail = ({ isOpen, handleCancel, invoice_id, customer, onDone = () => undefined, type }: Props) => {

  const [form] = Form.useForm();
  const setPageLoading = useSetLoadingPage();
  const [error, setError] = useState<string>('');
  const dispatch = useAppDispatch();
  const paramInvoice = invoiceSelectors.getInvoiceAllParam();
  const invoiceCode = invoice_id?.replace('#', '') ?? '';
  
  useEffect(() => {
    form.setFieldValue('customerEmail', customer?.is_walkin_in !== CUSTOMER_WALKIN_INDEX ? customer?.email : '');
  }, [customer]);

  const handleSendInvoice = async (value: any) => {
    setPageLoading(true);
    
    const isUpdateEmail = value?.isUpdateEmail;

    try {
      const body = {
        email_customer: value?.customerEmail,
        is_update_email: isUpdateEmail ?? !customer?.email ?? false
      };
      const res = await apisInvoice.sendInvoiceEmail(invoiceCode, customer?.customer_code.replace('#', '') ?? '', body);

      const msg = get(res, 'data.message', '');
      if (msg) {
        message.success(msg);
        onDone();
        handleCancel();
        if(paramInvoice && isUpdateEmail) {
          switch(type) {
            case 'invoice_detail':
              return dispatch(invoiceActions.setInvoiceDetailId(invoiceCode));
            case 'card_booking': {
              const payload = {
                is_walkin_in: customer.is_walkin_in,
                customer_code: customer?.customer_code.replace('#', '') ?? '',
              };
              dispatch(invoiceActions.setInvoiceDetailId(invoiceCode));
              return dispatch(customerActions.setCustomerCodeDetail(payload));

            }
            case 'invoice_table':
            default:
              return dispatch(invoiceActions.getInvoices.fetch(paramInvoice));
          }
        }
      } else {
        const err = get(res, 'data.error.message', '');

        if(err) {
          message.error(err);
        } else {
          throw 'fail';
        }
      }

    } catch (error) {

      const errors = Object.entries(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map(([, valueArr]) => {
          setError(get(valueArr, [0][0], ''));
        });
        return;
      }
      message.error('An error occurred. Please try again');
    } finally {
      setPageLoading(false);
    }
  };

  const myFocus = useRef<any>();

  useEffect(() => {
    myFocus?.current?.input?.focus();
  }, []);

  return (
    <Modal
      maskClosable={false}
      open={isOpen}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      centered
      forceRender
    >
      <h2 className='modal-title'>
        <p>Send Invoice</p>
      </h2>
      <Form
        name='basic'
        className='modal-form'
        onFinish={handleSendInvoice}
        autoComplete='off'
        form={form}
        layout='vertical'
        initialValues={{
          customerEmail: customer?.email ?? ''
        }}
      >
        <Form.Item
          label="Customer email"
          name="customerEmail"
          rules={[
            { required: true, message: 'Please input email!' },
            { type: 'email' }
          ]}
        >
          <Input type='email'
            allowClear={{ clearIcon: <IconClose /> }}
            ref={myFocus} placeholder='Enter email' onChange={() => setError('')}/>
        </Form.Item>
        {error && <p className='text-error'>{error}</p>}
        {customer?.email && <Form.Item valuePropName={'checked'} name='isUpdateEmail'>
          <Checkbox>Update email in Customer&apos;s Info</Checkbox>
        </Form.Item>}

        <BtnModalStyled>
          <div className='form-row form-submit'>
            <button type='button' className='common-btn is-white' onClick={handleCancel}>
              Close
            </button>
            <button type='submit' className='common-btn'>Send</button>
          </div>
        </BtnModalStyled>
      </Form>

    </Modal>
  );
};

export default ModalSendInvoiceEmail;

const BtnModalStyled = styled.div`
.form-row {
  justify-content: center;
}
`;

const IconClose = () => {
  return (
    <svg width="32" height="32" viewBox="0 -2 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.3334 16.0013C24.3334 11.3989 20.6024 7.66797 16 7.66797C11.3976 7.66797 7.66669 11.3989 7.66669 16.0013C7.66669 20.6037 11.3976 24.3346 16 24.3346C20.6024 24.3346 24.3334 20.6037 24.3334 16.0013Z" fill="#363565"/>
      <path d="M18.9166 18.9154L13.0833 13.082" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M18.9166 13.082L13.0833 18.9154" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>

  );
};