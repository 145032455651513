import { Form, SwitchProps } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import FormSwitch from 'components/common/Form/FormSwitch';

interface Props extends SwitchProps {
    form?: FormInstance;
    label?: string;
    name?: string;
    dataTesting?: string
    helperText?: string;
}



const SwitchInput = ({
    label = '',
    name = '',
    form = Form.useFormInstance(),
    dataTesting,
    helperText = '',
    ...props
}: Props) => {
    return (
        
        <Form.Item valuePropName='checked' initialValue={ form?.getFieldValue(name) ?? true } name={name} noStyle>
            <FormSwitch dataTesting={dataTesting} {...props} label={label} defaultChecked={ form?.getFieldValue(name) } helperText={helperText} />
        </Form.Item>
    );
};

export default SwitchInput;