import styled from 'styled-components';
export const CheckoutWrapPageStyled = styled.div`

.form-row-textarea .ant-form-item-row {
  display: flex;
  flex-direction: column;

  .ant-form-item .ant-form-item-label > label {
    text-align: start;
  }
}

@media (max-width: 1180px) {
   padding-bottom: 43vh;
}
`;
const CheckoutPageStyled = styled.div`
/* padding: 0px 0px 24px; */
gap: 24px;
max-width: 1300px;
/* padding: 0 20px; */
background: var(--color-white);
border-radius: 24px;
color: var(--color-primary);



.block-heading {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 15px 80px;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  text-transform: capitalize;
  border-bottom: 1px solid #C3C2E0;
  justify-content: space-between;
  span {
    display: flex;
    cursor: pointer;
    svg {
      max-width: 100%;
    }
  }
  ul {
    display: flex;
    gap: 80px;
    overflow: auto;
    @media only screen and (max-width: 767.98px) {
      padding-bottom: 10px;
    }
  }
  li {
    display: flex;
    gap: 12px;
    position: relative;
    align-items: center;
    @media only screen and (max-width: 767.98px) {
      white-space: nowrap;
    }
    &:after {
      content: "";
      display: block;
      width: 48px;
      height: 1px;
      position: absolute;
      border-bottom: 1px dashed #C3C2E0;
      top: 50%;
      left: calc(100% + 16px);
    }
    &:last-child:after {
      display: none;
    }

    span {
      display: flex;
      width: 20px;
      height: 20px;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      background: #F0F2F7;
      border-radius: 30px;
      padding-bottom: 2px;
      &.is-finish {
        overflow: hidden;
        text-indent: -9999px;
        position: relative;
        background: #363565;
        &:before {
          box-sizing: border-box;
          position: absolute;
          top: 50%;
          inset-inline-start: 30%;
          display: table;
          width: 5.7142857142857135px;
          height: 9.142857142857142px;
          border: 2px solid #fff;
          border-top: 0;
          border-inline-start: 2px;
          content: "";
          opacity: 1;
          transform: rotate(45deg) scale(1) translate(-50%,-50%);
          transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
        }
      }
    }
  }
}
.divider {
  display: none;
}
.is-complete {
  .list-detail-items {
    max-height: unset !important;
  }
}
.body {
  display: flex;
  padding: 24px 56px;
  justify-content: center;
}
.block {
  display: flex;
  flex-direction: column;
  max-width: 50%;
  padding: 0 24px;
  flex: 1;
  border-right: 1px solid #949BDA;
  &:first-child {
    .form-row:last-child {
      margin-top: 25px;
      margin-bottom: -15px;
    }
  }
  &:last-child {
    border-right: none;
    & > div:last-child {
      margin-top: auto;
    }
  }
}
.block-address {
  display: flex;
  gap: 4px;
  /* font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #363565; */
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  color: rgb(54, 53, 101);
  margin-bottom: 12px;
}
.block-tabs {
  margin: 24px 0 16px;
}
.block-title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 24px;
  margin: 24px 0 0 0;
}
.form-row {
  margin-top: 48px;
  margin-bottom: 0;
}
.note {
  font-size: 16px;
  text-align: center;
  margin: 16px 0 30px;
  color: var(--color-primary);
}
.common-input-search {
  flex: 1;
  input {
    height: 48px;
    width: 100%;
    background-color: var(--color-white-01);
  }
}
.detail {
  display: flex;
  padding: 24px;
  height: 365px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 16px;
  text-align: center;
  color: var(--color-primary);
  font-weight: 500;
}

.total,
.total-pre-paid,
.voucher {
  display: flex;
  align-items: center;
  font-size: 14px;
  text-transform: uppercase;
  span {
    flex: 1;
    font-weight: 600;
    font-size: 20px;
    text-align: right;
    text-transform: uppercase;
    color: var(--color-green);
  }
}

.total {
  color: var(--color-green);
  font-size: 20px;
  font-weight: 600;
}

.total-pre-paid {
  font-size: 16px;
  font-weight: 600;
}

.voucher {
  text-transform: capitalize;
  margin-bottom: 10px;
  span {
    font-weight: 600;
    font-size: 16px;
    color: var(--color-primary);
  }
}
.extra-price-row {
  width:100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #363565;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-align: right;
    text-transform: uppercase;
    color: #363565;
  }
}

.input_search {
  flex: 1;
}
.form-note {
  margin-bottom: 48px;
}
.search-wrapper {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }
  .icon-back {
    width: 64px;
    height: 64px;
    background: #F0F2F7;
    border-radius: 6px;
  }
  .btn-return {
    background: #F0F2F7;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    color: #363565;
  }
}

.form-notifications-note {
  margin-bottom: 20px;
}
.group-customer-content {
  margin-bottom: 24px;
  .customer-note {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 12px;
    width: 100%;
    background: #F0F5FF;
    border-radius: 6px;
  }
}
.current-customer {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 12px;
  transition: all 0.3s ease;
  border-radius: 6px;

  .customer-avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
  }

  .customer-detail {
    flex: 1;
    overflow: hidden;
    p {
      font-size: 12px;
      span {
        font-weight: 600;
      }
    }
    .detail-name {
      font-weight: 600;
      font-size: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .customer-link-disable {
        pointer-events: none;
    }
    .reward {
      display: flex;
      align-items: center;
      gap: 4px;
      
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #363565;
      }

      .icon {
        display: flex;
      }

      .box-reward {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        background: #FFFBE6;
        border-radius: 6px;
        padding: 6px 8px;
        gap:12px;
      }
      .highlight-reward {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #FAAD14;
      }
    }
  }

  .btn-reset {
    background: #F0F2F7;
    border-radius: 6px;
  }

  .customer-link {
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    text-decoration-line: underline;
    color: var(--color-green);
    text-transform: capitalize;
    /* cursor: pointer; */
    &.is-text {
      text-decoration-line: none;
    }
  }
}

.form-voucher {
  display: flex;
  gap: 4px;
  margin-bottom: 20px;
  button {
    display: flex;
    width: 112px;
    height: 64px;
    align-items: center;
    justify-content: center;
    border: 1px solid #4A9D77;
    border-radius: 6px;
    background: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-transform: capitalize;
    color: #4A9D77;
  }
}

@media only screen and (max-width: 1250px) {
  .body {
    padding: 12px 0px !important;
  }
  padding: 24px;
  .block {
    padding: 0 10px;
  }
  .block-heading {
    font-size: 18px;
    padding: 8px 60px
  }
  .block-title {
    margin-bottom: 12px;
    font-size:16px;
  }
  .input-search-wrap {
    margin-bottom: 12px;
  }
  
  .current-customer .customer-avatar {
    width: 80px;
    height: 80px;
    font-size: 55px;
  }
  .title {
    font-size: 16px !important;
  }
  .current-customer {
    padding-top:0px;
    margin-bottom:20px;
    .customer-detail .detail-name {
      font-size: 14px;
    } 
    .customer-link {
      font-size: 14px;
    }
  }
  .booking_date {
    font-size:14px;
    .booking_date_value {
      font-size:14px;
    }
  }
  .time.booking_date {
    font-size:14px;
  }
  .group-customer-content {
    margin-bottom: 12px;
  }
  .note {
    margin: 16px 0px 10px;
  }
  .list-detail-items {
    margin: 12px 0px;
  }
  .ant-tabs-tab {
    padding: 6px 12px;
    line-height: 1.5;
  }
  .ant-tabs-tab-btn {
    font-size: 14px;
  }
  .common-input-search input  {
    height: 40px;
  }
  .disableScroll {
    margin-bottom: -8px;
    .service_item {
      margin-right: 8px;
      margin-bottom: 8px;
      span {
        font-size: 10px;
      }
    }
  }
  .service_content {
    .group_name {
      .service_name {
        font-size: 14px;
      }
    }
  }
  .search-wrapper .icon-back, .form-booking-submit, .checkout-form-btn {
    height: 42px;
  }
  .form-note {
    margin-bottom: 12px;
    padding:0;
  }
}

@media (max-width: 1400.98px) {
  .body {
    display: flex;
    padding: 24px 0;
  }
}

@media (max-width: 1180px) and (min-width: 1025px) {
  .block-fixed {
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 8px;
    bottom: 0;
    right: 0;
    width: 100%;
    background: var(--color-white);
    z-index: 101;
    padding: 12px 12px 12px 170px;
  }
  .body {
    padding: 12px 12px 200px;
  }
}
@media (max-width: 1024.98px) {
  .block-fixed {
    position: sticky;
    bottom: 0;
    right: 0;
    width: 100%;
    background: var(--color-white);
    /* z-index: 101; */
    padding: 12px;
  }
  .body {
    padding: 24px 12px 200px;
  }
}

@media (max-width: 992.98px) {
  .block-heading {
    padding: 20px 12px;
  }
  .body {
    padding: 24px 12px 200px;
    flex-direction: column;
    /* gap: 50px; */
  }
  .divider {
    display: block;
  }
  .block {
    padding: 0;
    max-width: unset;
    border-right: none;
    &:first-child {
      .form-row:last-child {
        margin-top: 24px;
      }
    }
  }
  .block-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--color-white);
    z-index: 101;
    padding: 12px;
  }
  .form-note {
    margin-bottom: 12px;
  }
}

.ant-input {
  min-width: unset;
}
.complete-order {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;
  .complete-icon {

  }

  .complete-content {
    flex: 1;
  }

  .complete-title {
    font-weight: 600;
    font-size: 28px;
  }

  .complete-text {
    overflow: hidden;
    font-size: 16px;
    span {
      font-weight: 600;
    }
    .customer-name {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 73%;
      text-transform: capitalize;
    }
  }
}
.form-booking-submit {
  background: #363565;
  &.disabled {
    background: #949bda;
    cursor: no-drop;
  }
}
.extra-price-row {
  width:100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #363565;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-align: right;
    text-transform: uppercase;
    color: #363565;
  }
}
`;

export default CheckoutPageStyled;
