import { Popover } from 'antd';
import IconHelpCircle from 'assets/svg/IconHelpCircle';
import checkoutSelectors from 'features/checkout/services/selectors';
import settingSelectors from 'features/settings/services/selectors';
import styled from 'styled-components';
import { formatMoney } from 'utils/unit';

const HolidaySurchargeResultRow = () => {

  const getHolidaySurchargeValue = checkoutSelectors.getHolidayValue();
  const getCardSurchargeValue = checkoutSelectors.getCardSurcharge();
  const publicHoliday = settingSelectors.getHolidayInfoToday();

  const ContentNotification = () => (
    <HolidayChargeStyled>
      <span className='tooltip-text'>This is applicable only to the sale of services, excluding vouchers.</span>
    </HolidayChargeStyled>
  );

  return (

    <div>
      {getHolidaySurchargeValue > 0 && <div className='extra-price-row'>
        <div className='label'>
          Public Holiday charge ({publicHoliday.is_each_service === 1 ? 'Each service' : 'Total booking'})
          <Popover placement="top" title={ContentNotification}>
            <button><IconHelpCircle /></button>
          </Popover>
        </div>
        <span> +{formatMoney(getHolidaySurchargeValue)}</span>
      </div>}
      {getCardSurchargeValue > 0 && <div className='extra-price-row'>
        <div className='label'>
          Card surcharge
          <Popover placement="top" title={ContentNotification}>
            <button><IconHelpCircle /></button>
          </Popover>
        </div>
        <span> +{formatMoney(getCardSurchargeValue)}</span>
      </div>}
    </div>

  );
};

export default HolidaySurchargeResultRow;

const HolidayChargeStyled = styled.div`
  padding: 12px 16px 8px 16px;
`;
