import { DatePicker, Form, Input } from 'antd';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { disabledDateBefore } from 'utils/unit';
import { IPromotionFormValue } from '../types/promotion';

type Props = {
  errorsText?: Record<string, string>;
  isUsed?: boolean;
  formData?: IPromotionFormValue | null;
};
const PromotionsFormFirstStep = ({ errorsText, isUsed = false }: Props) => {
  const { t: formLang } = useTranslation('form');

  const myFocus = useRef<any>();

  useEffect(() => {
    myFocus?.current?.input?.focus();
  }, []);

  return (
    <>
      <div className='form-row'>
        <Form.Item
          label={formLang('promotion.promotionMame')}
          name='name'
          validateStatus={errorsText?.name ? 'error' : undefined}
          help={errorsText?.name ? errorsText?.name : undefined}
          rules={[{ required: true }]}
        >
          <Input
            ref={myFocus}
            placeholder={formLang('EnterPromotionNamePlaceholder') || ''}
          />
        </Form.Item>
      </div>

      <Form.Item
        dependencies={['promotionType']} // if using dependencies
        noStyle
      >
        {({ getFieldValue }: any) => {
          // getFieldValue defined here
          return getFieldValue('promotionType') === 'code' ? (
            <div className='form-row'>
              <Form.Item
                label={formLang('promotion.promotionCode')}
                name='code'
                
                validateStatus={errorsText?.code ? 'error' : undefined}
                help={errorsText?.code ? errorsText?.code : undefined}
                rules={[
                  { required: true },
                  {
                    validator: (_, value) => {
                      if (value && value.length !== 8) {
                        return Promise.reject(
                          'Please enter a string with a length of 8 characters.'
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  disabled={isUsed}
                  placeholder={formLang('EnterPromotionCodePlaceholder') || ''}
                />
              </Form.Item>
            </div>
          ) : null;
        }}
      </Form.Item> 

      <Form.Item
        dependencies={['promotionType']}
        noStyle
      >
        {({ getFieldValue }: any) => {
          // getFieldValue defined here
          return getFieldValue('promotionType') === 'auto_offer' ? (
            <div className='form-row'>
              <Form.Item
                label={formLang('promotion.startDate')}
                name='startDate'
                rules={[
                  { required: true, message: 'Please select start date' },
                  {
                    validator: (_, value) => {
                      const currentDate = moment();

                      if (value && value.isBefore(currentDate, 'day')) {
                        return Promise.reject(
                          'Start date must be greater than or equal to the current date'
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(current: Dayjs) =>
                    disabledDateBefore(current, dayjs())
                  }
                  format={'DD.MM.YYYY'}
                  picker='date'
                  className='promotion-start-date'
                />
              </Form.Item>
              <Form.Item
                label={
                  <p>
                    {formLang('promotion.endDate')}
                    <span>(Optional)</span>
                  </p>
                }
                name='endDate'
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const startDate = getFieldValue('startDate');

                      if (value && startDate && value.isBefore(startDate)) {
                        return Promise.reject(
                          'End date must be greater than or equal to the start date'
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <DatePicker
                  disabledDate={(current: Dayjs) =>
                    disabledDateBefore(current, dayjs())
                  }
                  format={'DD.MM.YYYY'}
                  picker='date'
                  className='promotion-end-date'
                />
              </Form.Item>
            </div>
          ) : null;
        }}
      </Form.Item>
    </>
  );
};

export default PromotionsFormFirstStep;
