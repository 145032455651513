interface IDetailServiceItem {
  name?: string | undefined;
  subName?: string | undefined;
  qty?: number | undefined;
  categoryName?: string | undefined;
}

const InvoiceServiceItem = ({ name, subName, qty, categoryName }: IDetailServiceItem) => {
  return (
    <div className="booking__service__wrapper">
      <div className="booking__service__content">
        <div className="booking__service__item__category">{categoryName}</div>
        <div className="booking__service__item">
          <p className="booking__service__item__name">{name}</p>
          <p className="booking__service__item__name__sub">{subName}</p>
        </div>
        <div className="booking_service__item__quantity">{qty}</div>
      </div>
      <div className="booking__divider__hidden"></div>
    </div>
  );
};

export default InvoiceServiceItem;
