// import { Popover } from 'antd';
import { CalendarViewType } from 'constants/index';
import bookingSelectors from 'features/bookings/services/selectors';
// import { get } from 'lodash';
// import moment from 'moment';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import UIBookingItem from './UIBookingItem';
import UITeamMemberMultiItem from './UITeamMemberMultiItem';
import { IColCalendar } from './types';
// import { useDispatch } from 'react-redux';
// import bookingActions from 'features/bookings/services/actions';
import { PopupType } from 'features/bookings/services/types/reducer';
type Props = {
  col_id?: string;
  grData: IColCalendar;
  rowSpan: number;
  forceRender?: boolean;
  multiViewPopup?: boolean;
  height?: number;
};
type IBookingMultiContext = {
  setPopover: (visible: boolean) => void;
};
const BookingMultiContext = createContext<IBookingMultiContext | null>(null);

export const useBookingMultiContext = () => useContext(BookingMultiContext);
const UIBookingMulti = (props: Props) => {
  const viewType = bookingSelectors.getCalendarViewType();
  const isMemberCalendarView = useMemo(() => viewType === CalendarViewType.MemberView, [viewType]);
  if (!isMemberCalendarView || !!props.forceRender) return <BookingItemLayout {...props} />;
  return <UITeamMemberMultiItem {...props} />;
};
const BookingItemLayout: React.FC<Props> = ({ col_id, grData, forceRender, multiViewPopup, height }) => {
  const [open, setOpen] = useState(false);
  const zoomCalendar = bookingSelectors.getZoomView();
  const pixelPerMinute = bookingSelectors.getPixelPerMinute();
  const setPopover = useCallback((val: boolean) => setOpen(val), []);
  const bookingPopup = bookingSelectors.getCalendarPopupStatus();
  // const dispatch = useDispatch();

  useEffect(() => {
    if (
      bookingPopup?.status &&
      bookingPopup?.type === PopupType.BOOKING_ITEM &&
      open &&
      !bookingPopup?.is_mutiple
    ) {
      setOpen(false);
    }
  }, [bookingPopup]);
  
  const content = useMemo(() => {
    return (
      <BookingMultiContext.Provider value={{ setPopover }}>
        <PopoverContentStyled>
          {grData.data.map(it => (
            <div className='box' key={it.id}>
              <UIBookingItem forceRender={forceRender} data={it} isChildMultiple col_id={col_id} rowTime={grData.rowTime} multiViewPopup={multiViewPopup} isMultiple={true}/>
            </div>
          ))}
        </PopoverContentStyled>
      </BookingMultiContext.Provider>
    );
  }, [grData, setPopover]);
  
  return (
    // <Popover
    //   arrow={false}
    //   open={open}
    //   onOpenChange={(val: boolean) => {
    //     setOpen(val);
    //     dispatch(bookingActions.setCalendarPopupOpenData({
    //       status: val,
    //       type: PopupType.MULTIPLE_ITEM,
    //       col_id
    //     }));
    //   }}
    //   overlayInnerStyle={{ borderRadius: 0, boxShadow: 'none', padding: 0, background: 'transparent', width: '215px' }}
    //   placement='bottom'
    //   content={content}
    //   rootClassName='popover-multiple-booking'
    //   trigger='click'
    //   align={{ offset: [10, -((height ?? (zoomCalendar / 2)) * pixelPerMinute - 40)] }}
    // >
    //   <UIBookingItemStyled height={(height ?? (zoomCalendar / 2)) * pixelPerMinute}>
    //     <div className='item-inner'>
    //       <p className='item-title'>Multiple bookings ({grData.data.length})</p>
    //       <p className='item-time'>
    //         Check-in {moment(get(grData.data, [0, 'time_start'])).format('HH:mm')}
    //       </p>
    //     </div>
    //     <div className="dash">
    //       {!open ? <IconDown /> : <IconUp />}
    //     </div>
    //   </UIBookingItemStyled>
    // </Popover>

    <>
      <UIBookingItemStyled height={(height ?? zoomCalendar / 2) * pixelPerMinute} >
        <div className="booking-multi-wrapper">
          <div className="item-inner">
            <p className="item-title">Multiple bookings ({grData.data.length})</p>
          </div>
          <div className='booking-multi-list common-scrollbar'>{content}</div>
        </div>
      </UIBookingItemStyled>
    </>
  );
};

export default UIBookingMulti;

// const IconDown = () => {
//   return (
//     <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
//       <path d="M4.26562 6.94238L8.26563 10.9424L12.2656 6.94238" stroke="#363565" strokeLinecap="round" strokeLinejoin="round" />
//     </svg>

//   );
// };

// const IconUp = () => {
//   return (
//     <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
//       <path d="M12.2656 10.9424L8.26563 6.94238L4.26562 10.9424" stroke="#363565" strokeLinecap="round" strokeLinejoin="round" />
//     </svg>
//   );
// };

const UIBookingItemStyled = styled.div`
  width: 100%;
  // height:${({ height }: { height: number }) => height}px;
  height: 100%;
  background: var(--color-white);
  border: none;
  outline: none;
  border-radius: 4px;
  position: relative;
  padding-right: 4px;
  text-align: left;
  font-size: 14px;
  overflow: hidden;

  // &:before {
  //   content: '';
  //   display: block;
  //   width: 0;
  //   height: 100%;
  //   border-left: 4px solid var(--color-green-01);
  //   border-right: 4px solid var(--color-white);
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   border-radius: 8px;
  // }
  .booking-multi-wrapper {
    position: relative;
    padding-left: 8px;
    height: 100%;

    &:before {
      content: '';
      display: block;
      width: 4px;
      height: 100%;
      background: var(--color-green-01);
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 8px;
    }
  }
  .dash {
    position: absolute;
    top: 10px;
    right: 10px;
    // pointer-events: none;
  }
  :hover {
    cursor: pointer;
  }
  .dash {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .item-inner {
    display: block;
    text-align: left;
    color: var(--color-primary);
    padding: 0;
  }
  .item-title {
    padding: 4px 0 8px 0;
    font-weight: 600;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .item-time {
    font-size: 12px;
  }
  .booking-multi-list {
    width: 100%;
    height: 100%;
    position: relative;
    overflow-y: auto;
    padding: 0 4px 35px 0;
  }
`;

const PopoverContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap:8px;
  table {
    width: 100%;
  }
  .box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border: 1px solid var(--color-purple-05);
    border-radius: 12px;
    font-weight: 500;
    font-size: 16px;
    color: var(--color-primary);
    .icon {
      padding-bottom: 16px;
    }
  }
  .subtext {
    color: #949bda;
  }
  .d-flex {
    display: flex;
    align-items: center;
    .icon {
      margin-right: 10px;
    }
  }
  .button {
    width: 100%;
    margin-top: 24px;
    text-align: center;
  }
`;
