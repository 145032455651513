import { Checkbox, Form, InputNumber, Radio, Select, Spin } from 'antd';
// import TextArea from 'antd/lib/input/TextArea';
import { AxiosResponse } from 'axios';
import SwitchInput from 'components/common/Input/SwitchInput';
import message from 'components/common/Message';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import settingActions from '../services/actions';
import settingApis from '../services/apis';
import settingSelectors from '../services/selectors';
import FormTabSetting from './FormTabSetting';
import styled from 'styled-components';
import { CHOOSE_METHOD_NOTIFY } from 'features/loyaltyProgram/services/constants';
import loyalSelectors from 'features/loyaltyProgram/services/selectors';
import loyalActions from 'features/loyaltyProgram/services/actions';
import IconRightGreen from 'assets/svg/IconRightGreen';
import TooltipComponent, { tooltipConfig } from './TooltipComponent';
import Unclock from 'assets/images/setting/unclock.png';
import OneMoreStep from 'assets/images/setting/one-more.png';
import { Popover } from 'antd';

interface IOptional {
  value: number;
  label: string;
}

interface IFormNotification {
  appointments: boolean;
  rattings_and_reviews: boolean;
  remider?: number;
  discount_review?: number;
  loyalty_notify_after_visit: boolean;
  loyalty_notify_method: string;
  loyalty_content_message: string;
  first_visit_loyalty_notice: string;
  remider_for_next_reward: string;
  notify_after_visit: boolean;
  is_remider: boolean;
  review_request?: boolean;
  enable_sms_inprogress_book: boolean,
  enable_notification_checkout: boolean,
  sms_booked: boolean, // disable sms global after booking successfully - Lukas's request 30.04
}

const data_first = [
  {
    value: 1,
    label: '1 week'
  },
  {
    value: 2,
    label: '2 weeks'
  },
  {
    value: 3,
    label: '3 weeks'
  },
  {
    value: 4,
    label: '4 weeks'
  },
];


const NotificationTab = () => {

  const getBookOption = settingSelectors.getOptionBooking();
  const getSetting = settingSelectors.getSetting();
  const loyalSetting = loyalSelectors.getLoyalSetting();
  const [loading, setLoading] = useState<boolean>(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();


  const getSelectValue = (value: number, options: IOptional[]) => {
    const valueCustom = options.some((item: IOptional) => item?.value === value) ? value : options[0]?.value;

    return Number(valueCustom);
  };

  const getBoolean = (value: boolean | string | undefined) => {
    return (typeof value === 'string') ? value?.toLowerCase() === 'true'.toLowerCase() : value;
  };

  const getFieldsValue = () => {
    const notification = getSetting?.notification_setting;
    
    return {
      appointments: getBoolean(notification?.appointments),
      rattings_and_reviews: getBoolean(notification?.rattings_and_reviews),
      is_remider: notification?.is_remider,
      review_request: notification?.review_request,
      remider: getSelectValue(notification?.remider, getBookOption?.m_option_remiders) === 0 ? '' : getSelectValue(notification?.remider, getBookOption?.m_option_remiders),
      discount_review: notification?.discount_review,
      loyalty_notify_method: notification?.loyalty_notify_method,
      notify_after_visit: notification?.notify_after_visit,
      // loyalty_notify_after_visit: notification?.loyalty_notify_after_visit,
      loyalty_content_message: notification?.loyalty_content_message,
      remider_for_next_reward: notification?.remider_for_next_reward,
      first_visit_loyalty_notice: notification?.first_visit_loyalty_notice,
      enable_sms_inprogress_book: notification?.enable_sms_inprogress_book,
      enable_notification_checkout: notification?.enable_notification_checkout,
      sms_booked: notification?.sms_booked
    };
  };
  useEffect(() => {
    if (!loyalSetting) {
      dispatch(loyalActions.getLoyalSetting.fetch());
    }


    form.setFieldsValue(getFieldsValue());
  }, [getSetting, loyalSetting]);



  const getPayload = (values: IFormNotification) => {
    const rattings_and_reviews = getBoolean(values?.rattings_and_reviews);
    const appointments = getBoolean(values?.appointments);
    const remider = values?.remider;
    const is_remider = values?.is_remider;
    const review_request = values?.review_request;
    const discount_review = values?.discount_review === undefined ? 5 : values?.discount_review;
    const notify_after_visit = values?.notify_after_visit;
    const loyalty_notify_method = values?.loyalty_notify_method;
    const loyalty_content_message = values?.loyalty_content_message;
    const first_visit_loyalty_notice = values?.first_visit_loyalty_notice;
    const remider_for_next_reward = values?.remider_for_next_reward;
    const enable_sms_inprogress_book = values?.enable_sms_inprogress_book;
    const enable_notification_checkout = values?.enable_notification_checkout;
    const sms_booked = values?.sms_booked;

    return {
      rattings_and_reviews,
      appointments,
      remider,
      discount_review,
      loyalty_notify_method,
      notify_after_visit,
      loyalty_content_message,
      first_visit_loyalty_notice,
      remider_for_next_reward,
      is_remider, review_request,
      enable_sms_inprogress_book,
      enable_notification_checkout,
      sms_booked
    };
  };

  const handleSubmit = async (values: IFormNotification) => {
    setLoading(true);
    const payload = getPayload(values);

    try {
      const rest: AxiosResponse<{ message: string }> = await settingApis.updateNotification(payload);
      const msg = get(rest, 'data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(settingActions.getSetting.fetch());
        dispatch(loyalActions.getLoyalSetting.fetch());
        form.setFieldsValue(getFieldsValue());
        return true;
      }
      return false;
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.values(response);
      errors.map((value) => {
        message.error(get(value, [0], ''));
      });

      return false;
    } finally {
      setLoading(false);
    }
  };


  const checkValid = async () => {
    try {
      await form.validateFields([
        'appointments',
        'noShowStatus',
        'rattings_and_reviews',
        'remider',
        'discount_review',
        'loyalty_content_message'
      ]);
      return true;
    } catch (error) {
      return false;
    }
  };


  return (
    <Spin spinning={loading}>
      <FormTabSetting
        form={form}
        handleSubmit={handleSubmit}
        checkValid={checkValid}
        initialValues={getFieldsValue()}
      >
        <div className='cards-wrapper'>
          <div className='cards-col'>
            <div className='common-card'>
              <h2 className='card-title form-helper-text'>
                Customer Notifications
                <TooltipComponent title='Sends notifications to your customers via email.' />
              </h2>

              <div className='card-title'>
                <div className='form-row form-megin'>
                  <SwitchInput
                    label='Reminder to customers'
                    name='is_remider'
                    form={form}
                    helperText='Sends notifications to your customers prior to their bookings.'
                  />
                </div>
                <div>
                  <Form.Item label='Reminder timeframe' name='remider'>
                    <Select
                      defaultValue={form.getFieldValue('remider')}
                      options={getBookOption?.m_option_remiders}
                    />
                  </Form.Item>
                </div>

                <div className='form-row form-megin'>
                  <SwitchInput
                    label='Change status Open to InProgress'
                    name='enable_sms_inprogress_book'
                    form={form}
                    helperText='Notification to customers when booking is in progress.'
                  />
                </div>

                <div className='form-row form-megin'>
                  <SwitchInput
                    label='Disable notification when booking created '
                    name='sms_booked'
                    form={form}
                    helperText='Disable send notification to customers when booking created.'
                  />
                </div>
              </div>
              <div className='common-card card-title'>
                <div className='form-row form-megin'>
                  <SwitchInput
                    label='Review Request'
                    name='review_request'
                    form={form}
                    helperText='Sends notifications to your customers after their visit.'
                  />
                </div>
                <div>
                  <Form.Item
                    label='Discount for customers'
                    name='discount_review'
                    tooltip={tooltipConfig('This promotional offer will be sent to your customers when they leave a review of their experience.')}
                  >
                    <InputNumber min={0.01} max={100} addonBefore='%' placeholder='Amount' />
                  </Form.Item>
                </div>
              </div>
              <div className='common-card'>
                <div className='form-row form-megin'>
                  <SwitchInput
                    label='Loyalty Program'
                    name='notify_after_visit'
                    form={form}
                    helperText='Sends notifications to your customers after their visit.'
                  />
                </div>
                <Form.Item
                  label='Choose method to notify your customers'
                  name='loyalty_notify_method'
                >
                  <Radio.Group>
                    <Radio value={CHOOSE_METHOD_NOTIFY.EMAIL}>Email</Radio>
                    <Radio value={CHOOSE_METHOD_NOTIFY.SMS}>SMS</Radio>
                  </Radio.Group>
                </Form.Item>
                <div>
                  <Form.Item
                    label='First visit loyalty notice'
                    name='first_visit_loyalty_notice'
                    tooltip={tooltipConfig('Choose how far in advance your messages are sent to customers after their first visit.')}
                  >
                    <Select
                      defaultValue={form.getFieldValue('first_visit_loyalty_notice')}
                      options={data_first}
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label='Reminder for next reward'
                    name='remider_for_next_reward'
                    tooltip={tooltipConfig('Choose how far in advance your loyalty reminder are sent to customers  before next reward for 1 more visit/point.')}
                  >
                    <Select
                      defaultValue={form.getFieldValue('remider_for_next_reward')}
                      options={data_first}
                    />
                  </Form.Item>
                </div>
                <div className='form-row form-megin'>
                  <SwitchInput
                    label='Send Loyalty Email After Checkout'
                    name='enable_notification_checkout'
                    form={form}
                  />
                </div>
              </div>
            </div>
            <div className='common-card'>
              <h2 className='card-title no-border-bottom'>Loyalty messages</h2>
              <LoyaltyMessagesStyled>
                <div>
                  <Form.Item name='' valuePropName=''>
                    <div className='container-item-loyaty'>
                      <div className='container-loyaty'>
                        <span className='form-label no-padding no-white-space form-helper-text'>
                          Unlock your next loyalty reward
                          <TooltipComponent title='Reach out to customers when their booking is checked out, with a link to leave a rating' />
                        </span>
                      </div>
                      <div className='icon-loyaty'>
                        <Popover
                          content={<img src={Unclock} alt='Error' className='notity-tab-img-card-right' />}
                          placement='topRight'
                          trigger='click'
                        >
                          <ButtonLoyaltyMessageStyled>
                            <IconRightGreen />
                          </ButtonLoyaltyMessageStyled>
                        </Popover>
                      </div>
                    </div>
                  </Form.Item>
                  <Form.Item name='' valuePropName=''>
                    <div className='container-item-loyaty'>
                      <div className='container-loyaty'>
                        <span className='form-label no-padding no-white-space form-helper-text'>
                          <span>
                            One more step: Review your experience for &#123;number shown when
                            merchant set the value field&#125;% off
                          </span>
                          <TooltipComponent title='Reach out to customers and send them Google review link when they rate their appointment with 4 or 5 stars' />
                        </span>
                      </div>
                      <div className='icon-loyaty'>
                        <Popover
                          content={<img src={OneMoreStep} alt='Error' className='notity-tab-img-card-right' />}
                          placement='topRight'
                          trigger='click'
                        >
                          <ButtonLoyaltyMessageStyled>
                            <IconRightGreen />
                          </ButtonLoyaltyMessageStyled>
                        </Popover>
                      </div>
                    </div>
                  </Form.Item>
                  <div className='helper-texr'>
                    <IconHelper />
                    <p>You have the option to reach out to Heya to modify the email template.</p>
                  </div>
                </div>
              </LoyaltyMessagesStyled>
            </div>
            <div className='common-card'>
              <h2 className='card-title form-helper-text'>
                Store Notifications
                <TooltipComponent title='Sends notifications to your account via email.' />
              </h2>
              <div className='card-body'>
                <CheckBoxStyled>
                  <div className='form-row'>
                    <Form.Item name='appointments' valuePropName='checked'>
                      <Checkbox defaultChecked={getSetting?.notification_setting?.appointments}>
                        <span className='form-label no-padding form-helper-text'>
                          Appointments
                          <TooltipComponent title='All appointment reminders' />
                        </span>
                      </Checkbox>
                    </Form.Item>
                  </div>
                  <div className='form-row'>
                    <Form.Item name='rattings_and_reviews' valuePropName='checked'>
                      <Checkbox
                        defaultChecked={getSetting?.notification_setting?.is_team_member_booking}
                      >
                        <span className='form-label no-padding form-helper-text'>
                          Ratings & reviews
                          <TooltipComponent title='New review notifications' />
                        </span>
                      </Checkbox>
                    </Form.Item>
                  </div>
                </CheckBoxStyled>
              </div>
            </div>
          </div>
        </div>
      </FormTabSetting>
    </Spin>
  );
};

export default NotificationTab;

const LoyaltyMessagesStyled = styled.div`
.helper-texr {
    display: flex;
    gap: 12px;
    padding: 8px;
    background: #F8F8FF;
    border-radius: 8px;
    align-items: center;
}
.container-item-loyaty {
    border: 1px solid #C3C2E0;
    padding: 12px;
    border-radius: 16px;
    display: flex;
    gap: 16px;
    cursor: pointer;
    .container-loyaty {
        display: grid;
        gap: 8px;
        flex: 2;
    }
    .icon-loyaty {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
`;

const CheckBoxStyled = styled.div`
display: grid;
gap: 8px;
.form-row {
    padding: 14px 0 0 12px;
    background: #F0F2F7;
    border-radius: 6px;
}
.ant-form-item-control {
    margin-bottom: 14px !important;
}

@media only screen and (max-width: 767.98px) {
    .form-label {
        overflow: inherit;
    }
}
`;

const ButtonLoyaltyMessageStyled = styled.div`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
`;

const IconHelper = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#4A9D77" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.08984 9.00057C9.32495 8.33224 9.789 7.76868 10.3998 7.4097C11.0106 7.05073 11.7287 6.91951 12.427 7.03928C13.1253 7.15906 13.7587 7.52209 14.2149 8.0641C14.6712 8.6061 14.9209 9.29209 14.9198 10.0006C14.9198 12.0006 11.9198 13.0006 11.9198 13.0006" stroke="#4A9D77" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 17H12.01" stroke="#4A9D77" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
