import { HOUR_MINUTE_FORMAT, TIME_DATE_MERCHANT, TIME_START_FORMAT, HOUR_MINUTE_FORMAT_AM_PM, TIME_DAY_DATE_MERCHANT } from 'features/bookings/services/constants';
import moment, { Moment } from 'moment';

// MMM DD, YYYY
const getSingleDateShortFormat = (_date: Moment) => {
  const today = moment();
    
  if (_date.isSame(today, 'day')) {
    return 'Today';
  }

  if (_date.isSame(today.clone().subtract(1, 'days'), 'day')) {
    return 'Yesterday';
  }

  if (_date.isSame(today.clone().add(1, 'days'), 'day')) {
    return 'Tomorrow';
  }

  return _date.format(TIME_DATE_MERCHANT);
};

const getSingleDateAndTimeShortFormat = (_date: Moment) => {

  return _date.format(TIME_DATE_MERCHANT + ' • ' + HOUR_MINUTE_FORMAT);
};

const getSingleDateFormat = (_date: Moment) => {

  return _date.format(TIME_DATE_MERCHANT);
};

// MMM DD, YYYY • HH:mm
export const getFormatShortDateAndTime = (val: string | null | undefined, template?: string) => {
  if (!val) return null;
  const booking_time = moment(val, template);
  if (!booking_time.isValid()) return null;
  return `${getSingleDateAndTimeShortFormat(booking_time)}`;
};

// MMM DD, YYYY
export const getFormatSingeDate = (val: string | null | undefined, template?: string) => {
  if (!val) return null;
  const booking_time = moment(val, template);
  if (!booking_time.isValid()) return null;
  return `${getSingleDateFormat(booking_time)}`;
};

// DD.MM.YYYY HH:mm:ss
const getSingleDateShortFormatCustomer = (_date: Moment) => {
  return _date.format(TIME_START_FORMAT);
};

export const getFormatShortDateCustomer = (val: string | null | undefined, template?: string) => {
  if (!val) return null;
  const booking_time = moment(val, template);
  if (!booking_time.isValid()) return null;
  return `${getSingleDateShortFormatCustomer(booking_time)}`;
};


export const getFormatShortDate = (val: string | null | undefined, template?: string) => {
  if (!val) return null;
  const booking_time = moment(val, template);
  if (!booking_time.isValid()) return null;
  return `${getSingleDateShortFormat(booking_time)} • ${booking_time.format(HOUR_MINUTE_FORMAT)}`;
};

export const getWorkingDateShortFormat = (_date: Moment) => {
  // const _date = moment((params?.start_date ?? 0) * 1000);
  const today = moment();
  if (_date.isSame(today, 'day')) {
    return 'Today';
  }

  if (_date.isSame(today.clone().subtract(1, 'days'), 'day')) {
    return 'Yesterday';
  }

  if (_date.isSame(today.clone().add(1, 'days'), 'day')) {
    return 'Tomorrow';
  }

  return _date.format('YYYY-MM-DD');
};

export default getSingleDateShortFormat;

// dddd DD MMM • HH:mma
const getSingleDateAndTimeShortFormatAMAndPM = (_date: Moment) => {
  return _date.format(TIME_DAY_DATE_MERCHANT + ' • ' + HOUR_MINUTE_FORMAT_AM_PM);
};

export const getFormatShortDateAndTimeAMAndPM = (val: string | null | undefined, template?: string) => {
  if (!val) return null;
  const booking_time = moment(val, template);
  if (!booking_time.isValid()) return null;
  return `${getSingleDateAndTimeShortFormatAMAndPM(booking_time)}`;
};
