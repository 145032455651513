import { Divider, Spin } from 'antd';
import PromotionCode from 'features/checkout/components/PromotionCode';
import checkoutSelectors from 'features/checkout/services/selectors';
import CustomerInfoStepSuccess from 'features/checkout/widgets/Customer/InfoStepSuccess';
import DetailAmountRow from 'features/checkout/widgets/ResultAmountRow/DetailAmount';
import settingActions from 'features/settings/services/actions';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import BlockInformation from '../../components/BlockInformation';
import RewardList from '../../components/RewardList';
import checkoutActions from '../../services/actions';
import ButtonHandler from '../../widgets/ButtonHandler';
// import CustomerDetail from '../../widgets/Customer/Detail';
// import CustomerDetail from '../widgets/CustomerDetail';
import ResultAmountRow from '../../widgets/ResultAmountRow';
import CheckoutPageStyled, { CheckoutWrapPageStyled } from './styles';
import CustomerDetail from 'features/checkout/components/CustomerDetail';
import ServiceDetails from 'features/checkout/components/ServiceDetails';
import BlockPaymentCheckout from '../../components/BlockPayment';
import settingSelectors from 'features/settings/services/selectors';
import { mappingPromotionInfo } from 'features/checkout/components/PromotionCode/PromotionInput';
import { CUSTOMER_WALKIN_INDEX } from 'utils/unit';
import moment from 'moment';
import promotionActions from 'features/promotions/services/actions';
import { sumBy } from 'lodash';
// import LocationResult from 'features/checkout/components/LocationResult';

const CheckoutPage = () => {
  const [step, setStep] = useState<number>(1);
  const dispatch = useAppDispatch();
  const { book_assignment_id = '' } = useParams();
  const [searchParam, setSearchParam] = useSearchParams();
  const setting = settingSelectors.getSetting();
  const selectedService = checkoutSelectors.getSelectedServices();
  const detail = checkoutSelectors.getBookingDetail();
  const merchantLocationId = checkoutSelectors.getLocationData()?.id;
  const selectedVoucherForSale = checkoutSelectors.getSelectedVouchersForSales();

  useEffect(() => {
    dispatch(checkoutActions.resetFormPayment());
    dispatch(checkoutActions.setCheckoutBookingId(book_assignment_id));
    dispatch(checkoutActions.setSelectedReward(null));
    dispatch(checkoutActions.setSelectedVouchersForSales([]));
    dispatch(settingActions.getSetting.fetch());
    if(searchParam.get('success')) setStep(3);
  }, []);

  useEffect(() => {
    dispatch(promotionActions.getPromotionAutoOffer.fetch({
      customer_code: detail?.customer.customer_code ?? '',
      today: moment().unix(),
      merchant_location_id: Number(merchantLocationId),
      total_booking: +sumBy(selectedService, (o) => o.price * o.quantity),
      total_voucher: +sumBy(selectedVoucherForSale ?? [], o => o.retail_price)
    }));
  }, []);

  useEffect(() => {
    dispatch(settingActions.getHolidayInfoToday.fetch({today: moment().unix(), merchant_location_id: Number(merchantLocationId)}));
  }, [merchantLocationId]);

  useEffect(() => {
    if(! detail?.promotion) return;
    const dataMapping = mappingPromotionInfo(detail.promotion);
    dispatch(checkoutActions.setPromotionInfo(dataMapping));

  }, [detail]);

  const customer = checkoutSelectors.getCustomerInfo();
  const totalPrice = checkoutSelectors.getOriginTotalPrice();

  useEffect(() => {
    dispatch(checkoutActions.getVoucherOfCustomer.fetch(customer?.customer_code ?? ''));
    dispatch(checkoutActions.reviewPointCustomerWillReceive.fetch(totalPrice));
    !customer?.is_walkin_in && dispatch(checkoutActions.getRewardOfCustomer.fetch({ id: customer?.customer_code?.replace('#', '') }));
  }, [customer]);

  useEffect(() => {
    if(step === 3) {
      setSearchParam({
        success: '1'
      });
    }
  },[step]);

  return (
    <CheckoutWrapPageStyled>
      <Spin spinning={false}>
        <CheckoutPageStyled>
          {/* <div className='block-heading'>
            <ul>
              <li>
                <span className={`${step !== 1 && 'is-finish'}`}>1</span>
                Information
              </li>
              <li>
                <span className={`${step === 3 && 'is-finish'}`}>2</span>
                Payment Details
              </li>
              <li>
                <span className={`${step === 3 && 'is-finish'}`}>3</span>
                Complete Order
              </li>
            </ul>
          </div> */}
          <div className='body'>
            {step === 1 && <BlockInformation step={step} />}
            {step === 2 && <BlockPaymentCheckout />}
            <div className='block'>
              {step === 3 && 
                <>
                  <CustomerInfoStepSuccess />
                  <CustomerDetail step={step} />
                </>}
              <div className={`${step === 3 && 'is-complete'}`}>
                {step !== 2 && <ServiceDetails step={step} viewOnly={step !== 1} />}
              </div>
              {step === 2 && (
                <>
                  <Divider className='divider'/>
                  {customer?.is_walkin_in !== CUSTOMER_WALKIN_INDEX ? <RewardList /> : <></>}
                  {setting.booking.is_promotion_redeem && selectedService.length > 0 && <PromotionCode />}
                </>
              )}
              <div className='block-fixed'>
                <DetailAmountRow step={step} />
                <Divider style={{margin: '8px 0px'}}/>
                <ResultAmountRow step={step} />
                <ButtonHandler setStep={setStep} step={step} />
              </div>
            </div>
          </div>
        </CheckoutPageStyled>
      </Spin>
    </CheckoutWrapPageStyled>

  );
};

export default CheckoutPage;