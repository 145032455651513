import { Button } from 'antd';
import { render, Printer, Text } from 'react-thermal-printer';

const ThermalPrinterSerialPort = () => {

  const printDefault = async () => {
    const data = await render(
      <Printer type="epson">
        <Text>Hello World</Text>
      </Printer>
    );
    
    //@ts-ignore
    const port = await window.navigator.serial.requestPort();
    await port.open({ baudRate: 9600 });
    
    const writer = port.writable?.getWriter();
    if (writer != null) {
      await writer.write(data);
      writer.releaseLock();
    }
  };

  const print443 = async () => {
    const data = await render(
      <Printer type="epson">
        <Text>Hello World</Text>
      </Printer>
    );
    
    //@ts-ignore
    const port = await window.navigator.serial.requestPort();
    await port.open({ baudRate: 443 });
    
    const writer = port.writable?.getWriter();
    if (writer != null) {
      await writer.write(data);
      writer.releaseLock();
    }
  };

  const print5222 = async () => {
    const data = await render(
      <Printer type="epson">
        <Text>Hello World</Text>
      </Printer>
    );
    
    //@ts-ignore
    const port = await window.navigator.serial.requestPort();
    await port.open({ baudRate: 443 });
    
    const writer = port.writable?.getWriter();
    if (writer != null) {
      await writer.write(data);
      writer.releaseLock();
    }
  };

  return (
    <div>
      <Button onClick={printDefault}>Print Default</Button>
      <Button onClick={print443}>Print 443</Button>
      <Button onClick={print5222}>Print 5222</Button>
    </div>
  );
};

export default ThermalPrinterSerialPort;
