import InputSearchText from 'components/common/Input/InputSearchDebounce';
import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import userActions from '../services/actions';
import userSelectors from '../services/selectors';
import AddNewUser from '../widgets/AddNewUser';
import LocationsFilter from './LocationsFilter';
// import LocationsFilter from './LocationsFilter';

function StaffFilter() {
  const dispatch = useAppDispatch();
  const params = userSelectors.getParams();
  const [, setSearchParams] = useSearchParams();
  const searchTime = useRef<boolean>(false);

  useEffect(() => {
    // @ts-ignore
    setSearchParams(params);
  }, [params]);

  const onChangeSearch = (val: string) => {
    if (searchTime.current) {
      dispatch(userActions.setParams({ 
        ...params,
        keyword: val || undefined, 
      }));
    }

    if (!searchTime.current && !val?.length) {
      searchTime.current = true;
    }
  };

  return (
    <div className={'private-filter'}>
      <LocationsFilter />
      <InputSearchText onSearchText={onChangeSearch} />
      {/* <button className={'export-btn'}></button> */}
      <AddNewUser />
    </div>
  );
}

export default StaffFilter;
