import PrimaryButton from 'components/common/Buttons/PrimaryButton';
import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import Section from '../Section';
import { BookingFormProps } from '../types';
import { DividerStyled } from './BasicContent';
import BookingDetails from './BookingDetails';
import CustomerInfo from './CustomerInfo';
import ImportantNote from './ImportantNote';
import Location from './Location';
import ServiceDetails from './ServiceDetails';

interface Props extends BookingFormProps {
  isCheckIn?: boolean;
  isTestTyro?: boolean;
  bookingCode?: string;
  formActive?: number;
}

const Confirm: React.FC<Props> = ({ isCheckIn, isTestTyro, bookingCode, formActive }) => {
  useEffect(() => {
    if (isCheckIn && formActive === 3) {
      const timeoutId = setTimeout(() => {
        window.location.reload();
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [formActive]);
  
  return (
    <ConfirmStyled>
      <Section label={'You\'re all set! Your booking has been confirmed'}>
        <Fragment>
          <CustomerInfo />
          <Location isCheckin={isCheckIn} />
          <DividerStyled />  
          <BookingDetails bookingCode={bookingCode}/>
          <DividerStyled />
          <ServiceDetails isCheckIn={isCheckIn} isTestTyro={isTestTyro}/>
          <ImportantNote onlyView />
        </Fragment>
      </Section>
      <PrimaryButton type='button' label='Add New Booking' className='primary_button' onClick={() => window?.location?.reload()} />
    </ConfirmStyled>
  );
};

export default Confirm;

const ConfirmStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  width: 100%;
  .primary_button {
    display: flex;
    align-self: center;
    &.outline{
      color: var(--color-primary);
      background-color: transparent;
      
    }
  }
`;