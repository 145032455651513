import styled from 'styled-components';
import bookingSelectors from '../services/selectors';
import { IBookingItemResData } from '../services/types/booking';
import BookingCard from './BookingCard';
import DateViewPicker from './BookingFilter/widgets/DateViewPicker';
import { Col, Empty, Row, Spin } from 'antd';

const BookingsGrid = () => {
  const listBookings = bookingSelectors.getBookings();
  const loading = bookingSelectors.loadingBookings();
  // const statusOptions = bookingSelectors.getLstBookingStatus(); 
  // const teamMembers = bookingSelectors.getTeamMembers();

  // const dispatch = useAppDispatch();   

  // useEffect(() => {
  //   dispatch(bookingActions.setCalendarViewType(CalendarViewType.MemberView));
  //   dispatch(bookingActions.setCalendarBookingParams({
  //     employee_id: [...teamMembers.map(o => Number(o.id)), 0],
  //   }));
  // }, [statusOptions]);

  return (
    <BookingsGridStyled className={'booking-grid-block'}>
      <div className='block-heading'>
        <div className='heading-date'>
          <DateViewPicker isNoSelect={true} />
        </div>
      </div>
      <Spin spinning={loading}>
        <Row className='block-body common-scrollbar'>
          <Col xs={24} sm={24} md={24} lg={24} xl={20} xxl={20}>
            {listBookings.length > 0 ? <Row className='list-cards'>
              {listBookings.map((booking: IBookingItemResData) => {
                return <Col key={booking.id} span={12} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="gutter-row"><BookingCard data={booking} /></Col>;
              })}
            </Row> : <div className='center'><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No data'} /></div>}
          </Col>
        </Row>
      </Spin>
    </BookingsGridStyled>
  );
};

export default BookingsGrid;
const BookingsGridStyled = styled.div`
.block-body {
  justify-content:center;
  padding:24px;
  @media only screen and (max-width: 767.98px) {
    padding: 6px;
  }
}
.center {
  display:flex;
  align-items:center;
  justify-content: center;
}
.common-booking-card {
  display:flex;
  flex-direction:column;
  .item-services {
    display:flex;
    flex-direction:column;
    flex:1;
    .view-span {
      flex:1;
    }
  }
}
.list-cards {
  margin: -12px;
}
.gutter-row {
  padding: 12px 12px;
}
`;
