import React from 'react';
import styled from 'styled-components';
type Props = {
  label?: string;
  children?: React.ReactElement;
  Footer?: React.ReactElement;
  text?: string;
};
const Section: React.FC<Props> = ({
  label = '',
  children = null,
  Footer = null,
  text = null,
  ...props
}) => {
  return (
    <SectionStyled>
      <div {...props} className='section_container'>
        <h2 className='section_title'>{label}</h2>
        {text && <p className='section_text'>{text}</p>}
        <div className='section_body'>{children}</div>
        {Footer}
      </div>
    </SectionStyled>
  );
};

export default Section;

const SectionStyled = styled.div`
  .section_container {
    width: 100%;
    background: var(--color-white);
    border-radius: 6px;
    padding: 40px;
    margin-bottom: 46px;
    @media only screen and (max-width: 767.98px) {
      margin-bottom: 24px;
      padding: 12px;
    }
  }

  .section_title {
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 46px;
    @media only screen and (max-width: 767.98px) {
      font-size: 24px;
    }
  }

  .section_text {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #363565;
    margin-top: -40px;
    margin-bottom: 40px;
  }

  .section_body {
    max-width: 526px;
    margin: 0 auto;
  }
`;
