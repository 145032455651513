import { Tabs, message } from 'antd';
import LocationPicker from '../LocationPicker';
import ServicesTab from './ServicesTab';
import VouchersTab from './VouchersTab';
import CustomerDetail from 'features/bookings/components/BookingForm/QuickBooking/CustomerDetail';
import salesHook from 'features/sales/hooks/salesHook';
import { AxiosResponse } from 'axios';
import apisCustomer from 'features/customers/services/apis';
import { IApiUpdateCustomerBody, ICustomerItemResData } from 'features/customers/services/types/cutomer';
import { get } from 'lodash';

const BlockInformation = ({step}: {step: number}) => {
  const [currCustomer, setCurrCustomer] = salesHook.useSetCustomerBooking();

  const items = [
    // TODO: NEXT PHRASE
    // {
    //   key: '1',
    //   label: 'Favorite',
    //   children: <ServicesTab isFavorite={true}/>,
    // },
    {
      key: '2',
      label: 'Services',
      children: <ServicesTab/>,
    },
    {
      key: '3',
      label: 'Vouchers',
      children: <VouchersTab />,
    },
  ];

  const handleUpdateLoyalty = async (values: any) => {
    try {
      const customerEdit = {
        ...currCustomer,
        loyalty_point: values.loyaltyPoint
      };
      const res: AxiosResponse<{ message: string }> = await apisCustomer.updateCustomerInfo(currCustomer?.id.toString() ?? '' ,customerEdit as IApiUpdateCustomerBody);  

      
      if(res?.data) {
        message.success(res?.data?.message);
        // setCurrCustomer({
        //   ...currCustomer,
        //   current_point: values.loyaltyPoint
        // });
        setCurrCustomer({
          ...currCustomer,
          current_point: values.loyaltyPoint
        } as ICustomerItemResData);
  
      }
    } catch (error) {
      const textError = get(error, 'response.data.error.message', '');
      if (textError) {
        message.error(textError);
      }
    }
  };


  return (
    <div className='block'>
      <LocationPicker />
      <CustomerDetail
        handleUpdateLoyalty={handleUpdateLoyalty}
        viewOnly={true}
        isInProgress={step !== 1}
        isTotalWillPointEarn={step === 3}
        currCustomer={currCustomer}
        setCurrCustomer={setCurrCustomer}
        isEdit
      />
      <div className='block-tabs'>
        <Tabs defaultActiveKey='1' items={items} />
      </div>
    </div >
  );
};

export default BlockInformation;
