import { Form, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import message from 'components/common/Message';
import PopupConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import { EBookingStatus } from 'constants/index';
import ModalConfirm from 'features/checkout/components/ModalConfirm';
import actions from 'features/customers/services/actions';
import settingSelectors from 'features/settings/services/selectors';
import _, { findIndex, get, last } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
// import formatNumber from 'utils/formatNumber';
import { formOptionalText, formatMoney, isBookingTeamMemberRequired } from 'utils/unit';
import CustomerDetail from '../components/BookingForm/QuickBooking/CustomerDetail';
import LocationPicker from '../components/BookingForm/QuickBooking/LocationPicker';
import NewServicesModal from '../components/BookingForm/QuickBooking/NewServicesModal';
import ServiceDetails from '../components/BookingForm/QuickBooking/ServiceDetails';
import ServicesSelection from '../components/BookingForm/QuickBooking/ServicesSelection';
import useSubscribeGetRealtime from '../hooks/subscribeGetRealtime';
import quickBookingHooks from '../hooks/useQuickBooking';
import bookingActions from '../services/actions';
import apis from '../services/apis';
import bookingSelectors from '../services/selectors';
import { IBookAssignmentDetail, IServiceQuickBookingItem } from '../services/types/quickBooking';
import Activity from '../components/BookingForm/Activity';
import styled from 'styled-components';
import notificationActions from 'components/layout/Header/service/actions';
// import { convertNotSetTimezone } from 'utils/moment/date';
import { BREAK_TIME_MINUTES, TIME_START_FORMAT_RESPONSE } from '../services/constants';
import settingActions from 'features/settings/services/actions';
import dayjs from 'dayjs';
import { isDisableDateByClosedPeriod } from '../hooks/disableTime';
import { PATH } from 'constants/path.enum';
import IconBack from 'assets/svg/IconBack';
import { AxiosResponse } from 'axios';
import apisCustomer from 'features/customers/services/apis';
import { IApiUpdateCustomerBody, ICustomerItemResData } from 'features/customers/services/types/cutomer';
// import { ButtonSuccess } from '../components/BookingForm/Confirm/BasicContent';
// import EditTime from '../components/BookingForm/QuickBooking/EditTime';
import _debounce from 'lodash/debounce';

const timerReload = () => {
  const interval = setInterval(() => {
    window.location.reload();
  }, 600000);
  return () => clearInterval(interval);
};

type Props = {
  bookId?: string;
  bookingData?: IBookAssignmentDetail;
};

const QuickBookingPage = (props: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const booking_date = searchParams.get('booking_date') || '';
  const merchant_location_id = searchParams.get('merchant_location_id') || '';
  const isCheckIn = searchParams.get('isCheckIn')?.toLocaleLowerCase() === 'true' ?? false;
  const modalConfirmRef = useRef<ModalConfirmRef>(null);
  const modalConfirmClosedRef = useRef<ModalConfirmRef>(null);
  const locationActive = bookingSelectors.quickBooking.getMerchantLocationActive();
  const { bookId, bookingData } = props;
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const [isOpenServicesModal, setIsOpenServicesModal] = useState<boolean>(false);
  const dataConfig = bookingSelectors.quickBooking.getDataConfigs();
  const locations = useMemo(() => dataConfig?.locations ?? [], [dataConfig?.locations]);
  const setIsLoading = useSetLoadingPage();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [currServices, setCurrServices] = quickBookingHooks.useSelectedServices();
  // const initialServices = bookingSelectors.quickBooking.getInitialServices();
  const [draffService, setDraffService] = useState<IServiceQuickBookingItem>();
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState<boolean>();
  const isLoading = bookingSelectors.quickBooking.getLoadingDataConfigs();
  const isRequirePin = settingSelectors.getIsRequirePin();
  const [currCustomer, setCurrCustomer] = quickBookingHooks.useSetCustomerBooking();
  const currBookingDate = bookingSelectors.quickBooking.getBookingDate();
  const merchantLocationId = bookingSelectors.quickBooking.getMerchantLocationId();
  const setting = settingSelectors.getSetting();
  const [isFirst, setIsFirst] = useState(bookingData?.book_status !==  EBookingStatus.IN_PROGRESS ? (isCheckIn ?? false) : true);
  const [note, setNote] = useState('');
  const totalPrePaid = bookingData?.total_pre_paid ?? 0;

  const date_closed: any = useMemo(() => {
    return locationActive?.date_closed?.map(o => ({
      start: moment(o.start_date, 'YY-MM-DD'),
      end: moment(o.end_date, 'YY-MM-DD'),
    }));
  }, [locationActive?.date_closed]);


  const debouncedHandleInputChange = _debounce((e) => {
    setNote(e.target.value);
  }, 300);

  const goBackBooking = () => {
    dispatch(bookingActions.setCalendarBookingParams({ date: moment(currBookingDate).valueOf(), merchant_location_id: merchantLocationId ?? '' }));
    navigate('/private/bookings');
  };

  useEffect(() => {
    if( !isFirst || !currServices || currServices.length === 0) return;

    if( !isBookingTeamMemberRequired(setting, isCheckIn, bookingData?.book_status )) return;

    const serviceAnyTeamMember = currServices.find(service => !service.assigned_employee);
    setDraffService(serviceAnyTeamMember);
    setIsOpenServicesModal(true);
    setIsFirst(false);

  }, [currServices, isFirst]);

  useEffect(() => {
    dispatch(settingActions.getSetting.fetch());
  }, []);

  useEffect(() => {
    if (bookId) {
      // @ts-ignore
      setSearchParams({ bookId, merchant_location_id: merchantLocationId ?? (locations.length > 0 ? locations[0]?.id : 0), booking_date: moment(currBookingDate).valueOf(), isCheckIn});
      return;
    }
    // @ts-ignore
    setSearchParams({ merchant_location_id: merchantLocationId ?? (locations.length > 0 ? locations[0]?.id : 0), booking_date: booking_date ?? moment(currBookingDate).valueOf(), isCheckIn});
  }, [currBookingDate, merchantLocationId]);

  const { t: formLang } = useTranslation('form');
  
  const subscribeGetRealtime = useSubscribeGetRealtime();
  const navigate = useNavigate();
  // const book_assignment_code = bookingSelectors.quickBooking.getBookAssignmentCode();
  const [bookingDate,] = quickBookingHooks.useSetDateBooking();

  useEffect(() => {
    dispatch(actions.getListCustomers.fetch());
    dispatch(bookingActions.quickBooking.getDataConfigs.fetch());
    const unsubscribeGetRealtime = subscribeGetRealtime();
    dispatch(bookingActions.getActivities.fetch(bookId ?? ''));


    let unSubTimer: (() => void) | null = null;
    if (!bookingData) {
      unSubTimer = timerReload();
    }
    window.addEventListener('popstate', goBackBooking);

    return () => {
      unsubscribeGetRealtime();
      if (typeof unSubTimer === 'function') unSubTimer();
      window.removeEventListener('popstate', goBackBooking);
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(bookingData)) {
      form.setFieldValue('note', bookingData?.noted_merchant);
      dispatch(bookingActions.quickBooking.setEditData({
        bookingDate: bookingData?.time_start ?? '',
        services: bookingData?.book_assignment_services ?? [],
        bookingStatus: bookingData?.book_status ?? '',
        customer: bookingData?.customer ?? null,
        merchantLocationId: bookingData?.merchant_location_id || merchant_location_id,
      }));

      setIsEdit(true);
    } else {
      dispatch(bookingActions.quickBooking.reset({
        bookingTime: +booking_date,
        merchant_location_id: +merchant_location_id,
      }));
    }
  }, [bookingData]);

  const totalPrice = useMemo(() => {
    const price = currServices.reduce((acc, service: any) => {
      const { sale_price, regular_price, service_price, quantity } = service;
      return (acc += (sale_price || regular_price || service_price) * quantity);
    }, 0);

    return +price?.toFixed(2);
  }, [currServices]);

  const handleEditService = (id: number, service_variant_id?: number, index?: number) => {
    const currEditService = currServices.find(
      o => id === o?.id && o.service_variant_id === service_variant_id && index === o.index
    );

    setDraffService(currEditService);
    setIsOpenServicesModal(true);
  };

  const getTimeStart = () => {

    if (currServices.length === 0) {
      return dayjs(bookingDate).tz();
    } else {
      const lastItem = last(currServices);

      const breakTimeMinutes = BREAK_TIME_MINUTES;
      const prevServiceMinutes = (lastItem?.duration_time ?? 0) * (lastItem?.quantity ?? 0);
      const nextTimeStart = moment(lastItem?.time_start).add(breakTimeMinutes + prevServiceMinutes, 'minute');

      return dayjs(nextTimeStart.format()).tz();
    }
  };

  const handleChooseService = (item: IServiceQuickBookingItem, isChecked: boolean) => {
    if (isChecked) {
      // handleAddServices({
      //   quantity: 1,
      //   members: get(first(item.merchant_employees), 'id', undefined),
      // }, item);
      if (setting?.booking?.is_team_member_booking) {
        setIsOpenServicesModal(true);
        setDraffService(item);
      } else {
        handleAddServices({
          quantity: 1,
          members: item.merchant_employees.find(o => o?.id === locationActive?.manager?.id) ? locationActive?.manager?.id : '',
          time_start: getTimeStart(),
        }, item);
      }
    } else {
      setCurrServices(currServices.filter(
        (o) => !(o?.id === item?.id && o.service_variant_id === item.service_variant_id)
      ));
    }
  };

  const handleRemoveService = (id: number, service_variant_id?: number, index?: number) => {
    setCurrServices(currServices.filter(
      o => !(id === o?.id && o.service_variant_id === service_variant_id && index === o.index)
    ));
    currServices.map(o => ({
      ...o,
      index: o?.index ? o.index - 1 : undefined,
    }));
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleEditQuantityService = (id: number, service_variant_id: number | undefined, quantity: number, index?: number) => {
    const currEditService = currServices.map(o => {
      if (id === o?.id && o.service_variant_id === service_variant_id && index === o.index) {
        return { ...o, quantity };
      }
      return o;
    });

    setCurrServices(currEditService);
  };

  const handleAddServices = (value: any, dataService: IServiceQuickBookingItem) => {
    const employee_id = value.members;
    const quantity = value.quantity;
    const serviceData = {
      ...dataService,
      employee_id,
      quantity: quantity || 1,
    };

    const currentIdx = findIndex(currServices, (o: IServiceQuickBookingItem) => o?.id === dataService?.id && o.service_variant_id === dataService.service_variant_id && o?.index === dataService?.index);
    const isEdit = currentIdx !== -1;

    if (isEdit) {
      const newServices = currServices.map((service) => {
        if (service?.id === serviceData?.id && service.service_variant_id === serviceData.service_variant_id && service?.index === dataService?.index) {
          return { ...service, ...serviceData };
        }
        return service;
      });
      setCurrServices(newServices);
    } else {
      const temp = [...currServices];
      // let employee_id = undefined;
      temp.push({
        ...dataService,
        shift_one_time: currServices.length,
        employee_id,
        quantity: quantity || 1,
      });
      setCurrServices(temp);
    }

    setIsOpenServicesModal(false);
  };

  const handleCancelServicesModal = (e: any) => {
    e.preventDefault();
    setIsOpenServicesModal(false);
  };

  const createDataBooking = (value?: any) => {
    const bodyServices = currServices.map((o) => {
      const { time_start, id } = o;
      const targetDate = moment(time_start).format(TIME_START_FORMAT_RESPONSE);
      return {
        ...o,
        time_start:
          targetDate || moment().format(TIME_START_FORMAT_RESPONSE),
        employee_id: o?.employee_id,
        service_id: o?.id,
        service_variant_id: o?.service_variant_id ?? null,
        book_assignment_service_id: id,
        sale_price: o?.sale_price !== 0 ? o?.sale_price : o?.regular_price
      };
    });

    const bodyCustomer = {
      id: currCustomer?.id,
      phone_number: currCustomer?.phone_number,
      name: currCustomer?.name,
      email: currCustomer?.email,
      date_of_birth: currCustomer?.date_of_birth,
      is_walkin_in: !!currCustomer?.is_walkin_in,
      customer_code: currCustomer?.customer_code,
    };

    const body = {
      customer: bodyCustomer,
      book_assignment_services: bodyServices,
      note: form.getFieldValue('note'),
      total_price: totalPrice,
      merchant_location_id: merchantLocationId,
      // book_assignment_code,
      allow_over: value?.isByPass
    };

    return { bodyServices, bodyCustomer, body };
  };

  const checkIsUpdateBooking = (value?: any) => {
    let isUpdate = false;

    const { body } = createDataBooking(value);

    if (bookingData?.customer?.id !== body?.customer?.id) {
      isUpdate = true;
    }
    if (bookingData?.book_assignment_services.length !== body?.book_assignment_services.length) {
      isUpdate = true;
    } else {
      const result = bookingData?.book_assignment_services.every((o1) =>
        body?.book_assignment_services.some(
          (o2) =>
            o1.service_id === o2.service_id &&
            o1.quantity === o2.quantity &&
            o1?.service_variant_id === o2?.service_variant_id  &&
            o1?.assigned_employee?.time_start === o2?.time_start &&
            o1?.assigned_employee?.employee?.id === (o2?.employee_id ?? o2?.assigned_employee?.employee?.id)
        )
      );
      
      if (!result) {
        isUpdate = true;
      }
    }
    
    if(bookingData?.noted_merchant !== body?.note) {
      isUpdate = true;
    }

    return isUpdate;
  };

  const onCheckOut = () => {
    if (isInProgress && isRequirePin && checkIsUpdateBooking()) {
      setIsModalConfirmOpen(true);
      localStorage.setItem('isCheckedOut', 'true');
      return;
    }
    
    localStorage.setItem('isCheckedOut', 'true');
    handleSubmitBooking();
    // dispatch(bookingActions.checkInBooking(String(bookId)));   
  };
  
  /**
   * handleSubmitBooking
   * @param value all value of form
   * @returns 
   */
  const handleSubmitBooking = async (value?: any) => {
    if (!currCustomer) {
      message.error('Please select customer');
      window.scrollTo(0, 0);
      return;
    }

    if (currServices.length === 0) {
      message.error('Please select at least one service');
      return;
    }
    setIsLoading(true);

    const { bodyCustomer, body } = createDataBooking(value);

    try {
      if (!isEdit) {
        const response = await apis.storeBookAssignments(body);
        if (response?.data.error) {
          message.error(response?.data.error.message);
          const employee_busy = get(response, 'data.error.employee_busy', []);
          if (employee_busy.length > 0) {
            dispatch(bookingActions.quickBooking.setBusyEmployees(employee_busy));

            modalConfirmRef.current?.show({
              title: 'Team member currently busy',
              // msg: 'Are you sure you want to make a new booking with an employee who is currently busy? Click \'confirm\' to proceed or \'Cancel\' to adjust the booking',
              msg: 'Do you still want to assign this customer at this time?',
              submit: () => {
                handleSubmitBooking({
                  ...value,
                  isByPass: true
                });
              },
            });
            return;
          }
        } else {
          message.success('You have successfully made quick booking!');
          form.resetFields();
          goBackBooking();
        }
      }

      if (isEdit) {
        const payload = {
          book_start: body?.book_assignment_services[0]?.time_start,
          customer_id: bodyCustomer?.id,
          // customer_code: bodyCustomer?.customer_code,
          is_walkin_in: bodyCustomer?.is_walkin_in,
          pin_number: (isRequirePin && checkIsUpdateBooking()) ? value?.PIN : undefined,
          note: form.getFieldValue('note'),
          action_update: 'before_work',
          book_assignment_services: body.book_assignment_services.map((item) => {
            return {
              service_id: item?.service_id ?? item?.id,
              service_variant_id: item?.service_variant_id,
              time_start: item?.time_start,
              duration_time: item?.duration_time,
              quantity: item?.quantity,
              employee_id: item?.employee_id ?? item?.assigned_employee?.employee?.id,
              sale_price: item?.service_price
            };
          }),
          merchant_location_id: merchantLocationId,
          allow_over: value?.isByPass
        };
        if (checkIsUpdateBooking()) {
          const response = await apis.updateBooking(bookId || '', payload);
          if (response?.data.error) {
            message.error(response?.data.error.message);
            const employee_busy = get(response, 'data.error.employee_busy', []);
            if (!value?.isByPass && employee_busy.length > 0) {
              dispatch(bookingActions.quickBooking.setBusyEmployees(employee_busy));
              modalConfirmRef.current?.show({
                title: 'Team member currently busy',
                msg: 'Do you still want to assign this customer at this time?',
                submit: () => {
                  handleSubmitBooking({
                    ...value,
                    isByPass: true
                  });
                },
              });

            }
          } else {
            if(isCheckIn) {
              dispatch(bookingActions.checkInBooking(String(bookId)));
            }
            message.success('You have successfully edit this booking!');
            const isCheckedOut = localStorage.getItem('isCheckedOut') === 'true';
            isCheckedOut ? navigate(`/private/bookings/checkout/${bookId}`) : goBackBooking();
          }
        } else {
          message.success('You have successfully edit this booking!');
          const isCheckedOut = localStorage.getItem('isCheckedOut') === 'true';
          isCheckedOut ? navigate(`/private/bookings/checkout/${bookId}`) : goBackBooking();
        }
      }
      setIsLoading(false);
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.entries(response);
      const errorList = errors.map(([, value]) => {
        return value[0];
      });

      const uniqueErrors = errorList.filter((error, index) => {
        return errorList.indexOf(error) === index;
      });

      uniqueErrors.map(item => message.error(item));
    
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * isInProgress check booking is in-progress
   */
  const isInProgress = useMemo(() => {
    return bookingData?.book_status === EBookingStatus.IN_PROGRESS;
  }, [bookingData]);

  const handleCancel = () => setIsModalConfirmOpen(false);

  const handleUpdateLoyalty = async (values: any) => {
    try {
      const customerEdit = {
        ...currCustomer,
        loyalty_point: values.loyaltyPoint
      };
      const res: AxiosResponse<{ message: string }> = await apisCustomer.updateCustomerInfo(currCustomer?.id.toString() ?? '' ,customerEdit as IApiUpdateCustomerBody);  

      
      if(res?.data) {
        message.success(res?.data?.message);
        // setCurrCustomer({
        //   ...currCustomer,
        //   current_point: values.loyaltyPoint
        // });
        setCurrCustomer({
          ...currCustomer,
          current_point: values.loyaltyPoint
        } as ICustomerItemResData);
  
      }
    } catch (error) {
      const textError = get(error, 'response.data.error.message', '');
      if (textError) {
        message.error(textError);
      }
    }
  };

  /**
   * _onSubmit handle when click save button
   * @param bypass 
   * @returns 
   * 
   */
  const _onSubmit = async () => {
    // if (!bypass && busyEmployees?.length > 0) {
    //   modalConfirmRef.current?.show({
    //     title: 'Staff currently busy',
    //     // msg: 'Are you sure you want to make a new booking with an employee who is currently busy? Click \'confirm\' to proceed or \'Cancel\' to adjust the booking',
    //     msg: 'Do you still want to assign this customer at this time?',
    //     submit: () => _onSubmit(true),
    //   });
    //   return;
    // }
    localStorage.setItem('isCheckedOut', 'false');
    const timeBook = moment(currBookingDate);

    const isServiceAnyTeamMember = currServices.some(service => {
      return service?.employee_id === undefined ? !service.assigned_employee : service?.employee_id === '';
    } );

    if( isBookingTeamMemberRequired(setting, isCheckIn ,bookingData?.book_status) && isServiceAnyTeamMember) return;

    if(isDisableDateByClosedPeriod(timeBook, date_closed)) {
      modalConfirmClosedRef.current?.show({
        title: 'Closed period',
        // msg: 'Are you sure you want to make a new booking with an employee who is currently busy? Click \'confirm\' to proceed or \'Cancel\' to adjust the booking',
        msg: 'Are you sure you want to make a new booking during the closed period? Please go to Settings to remove the closed period if you want to create a new booking.',
        submit: () => {
          navigate(PATH.settings, {
            state: {
              key: 6
            }
          });
        },
      });
      return;
    }

    if (isInProgress && isRequirePin && checkIsUpdateBooking()) {
      setIsModalConfirmOpen(true);
      return;
    }
    handleSubmitBooking();
    dispatch(notificationActions.getListNotification.fetch());
  };

 const printerInvoice = {
   totalPrice,
   currServices: currServices,
   noteOfCustomer: note,
   name: currCustomer?.name,
   phone: currCustomer?.phone_number,
 };

  if (isLoading) return <Spin />;

  return (
    <>
      <QuickBookingStyled>
        {/* <div className='block-heading'>
          {isEdit && (
            <span onClick={() => navigate(-1)}>
              <IconBack />
            </span>
          )} */}
        {/* {!isEdit
            ? <BookingID />
            : `booking ID ${bookingData?.book_assignment_code || ''}`} */}
        {/* </div> */}
        <Form layout='vertical' form={form}>
          <div className='body'>
            <div className='block'>
              <LocationPicker
                isEdit={isEdit}
              />
              <div>
                <CustomerDetail
                  handleUpdateLoyalty={handleUpdateLoyalty}
                  isInProgress={isInProgress}
                  currCustomer={currCustomer}
                  setCurrCustomer={setCurrCustomer}
                  isEdit={isEdit}
                  isDraft={bookingData?.book_status === EBookingStatus.DRAFT}
                />
              </div>
              <div className='block-title'>Services</div>
              <ServicesSelection
                handleChooseService={handleChooseService}
                currChooseServices={currServices}
              />
              {isEdit ? <Activity /> : <></>}
            </div>
            <div className='block'>
              {/* <div>
                <CustomerDetail
                  isInProgress={isInProgress}
                  currCustomer={currCustomer}
                  setCurrCustomer={setCurrCustomer}
                />
              </div> */}
              <div>
                <ServiceDetails
                  isEdit={isEdit}
                  // @ts-ignore
                  listServices={currServices}
                  handleRemoveService={handleRemoveService}
                  handleEditService={handleEditService}
                  handleEditQuantityService={handleEditQuantityService}
                  bookingData={bookingData}
                  // isInProgress={isInProgress}
                  isInProgress={isInProgress}
                  printerInvoice={printerInvoice}
                />
              </div>
              <div className='form-row'>
                <Form.Item
                  name={'note'}
                  label={
                    <p>
                      Important customer’s note<span>{formOptionalText}</span>
                    </p>
                  }
                >
                  <TextArea
                    placeholder={formLang('AddNotesPlaceholder') || ''}
                    rows={2}
                    className='is-white'
                    onChange={debouncedHandleInputChange}
                  ></TextArea>
                </Form.Item>
              </div>

              <div className='block-fixed'>
                <p className='total'>
                  TOTAL
                  <span>{formatMoney(totalPrice)}</span>
                </p>
                {totalPrePaid !== null && totalPrePaid > 0 && (
                  <p className='total-pre-paid'>
                    Pre-paid
                    <span>{formatMoney(totalPrePaid)}</span>
                  </p>
                )}
                <div className='form-note'>*All prices include GST.</div>

                {/* <EditTime 
                  bookingData={bookingData} 
                /> */}

                <div className='search-wrapper'>
                  <button
                    className='icon-back'
                    onClick={goBack}
                  >
                    <IconBack />
                  </button>
                  <button
                    type='button'
                    className={`${isEdit && bookingData?.book_status === EBookingStatus.IN_PROGRESS ? 'form-booking-checkout' : 'form-booking-submit'}`}
                    // disabled={currServices.length === 0 || !currCustomer}
                    onClick={() => _onSubmit()}
                  >
                    {isCheckIn ? 'Check-in' : 'Save'}
                  </button>

                  {isEdit && bookingData?.book_status === EBookingStatus.IN_PROGRESS ? <button className='form-booking-submit' onClick={() => onCheckOut()} type='button'>Checkout</button> : <></>}
                </div>
              </div>
            </div>
          </div>
        </Form>

        {draffService && (
          <NewServicesModal
            dataService={draffService}
            isOpenModal={isOpenServicesModal}
            handleCancel={handleCancelServicesModal}
            handleAddServices={handleAddServices}
            currServices={currServices}
            isInProgress={isInProgress}
            disableSelectTeamMember={!setting?.booking?.is_team_member_booking}
          />
        )}
      </QuickBookingStyled>
      {!!isModalConfirmOpen && <ModalConfirm visible onCancel={handleCancel} onSubmit={(value) => handleSubmitBooking({
        ...value,
        isByPass: false
      })} resetOnSubmit />}
      <PopupConfirm titleClose='Cancel' ref={modalConfirmRef} />
      <PopupConfirm titleConfirm='Go to setting' ref={modalConfirmClosedRef} />
    </>
  );
};

export default QuickBookingPage;

// const BookingID = () => {
//   const { t: bookingLang } = useTranslation('booking');
//   const book_assignment_code = bookingSelectors.quickBooking.getBookAssignmentCode();
//   return <>{`${bookingLang('BookingID')} ${book_assignment_code}`}</>;
// };

const QuickBookingStyled = styled.div`
  padding: 0px 0px 24px;
  gap: 24px;
  max-width: 1300px;
  padding: 0 0px;
  /* min-height: 958px; */
  background: var(--color-white);
  border-radius: 24px;
  color: var(--color-primary);
  .block-heading {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 15px 80px;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    text-transform: capitalize;
    border-bottom: 1px solid #C3C2E0;
    span {
      display: flex;
      cursor: pointer;
      svg {
        max-width: 100%;
      }
    }
  }
  .form-booking-checkout {
    background: #F0F2F7;
    color: #363565;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    text-transform: capitalize;
    border: none;
    transition: all 0.3s ease;
  }
  .body {
    display: flex;
    padding: 24px 56px;
  }
  .block {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    padding: 0 24px;
    flex: 1;
    border-right: 1px solid #949BDA;
    &:first-child {
      .form-row:last-child {
        margin-top: 25px;
        margin-bottom: -15px;
      }
    }
    &:last-child {
      border-right: none;
      & > div:last-child {
        margin-top: auto;
      }
    }
  }
  .block-title {
    font-weight: 600;
    font-size: 18px;
    margin-top: 24px;
  }
  .form-row {
    margin-top: 26px;
    margin-bottom: 0;
  }
  .note {
    font-size: 16px;
    text-align: center;
    margin: 16px 0 30px;
    color: var(--color-primary);
  }
  .common-input-search {
    flex: 1;
    input {
      height: 48px;
      width: 100%;
      background-color: var(--color-white-01);
    }
  }
  .detail {
    display: flex;
    padding: 24px;
    height: 365px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 16px;
    text-align: center;
    color: var(--color-primary);
    font-weight: 500;
  }
  .total, .total-pre-paid {
    display: flex;
    align-items: center;
    /* font-size: 14px; */
    text-transform: uppercase;
    /* color: var(--color-primary); */
    color: var(--color-green);
    font-size: 20px;
    font-weight: 600;
    span {
      flex: 1;
      font-weight: 600;
      font-size: 20px;
      text-align: right;
      text-transform: uppercase;
      color: var(--color-green);
    }
  }
  .input_search {
    flex: 1;
  }
  .form-note {
    margin-bottom: 48px;
  }
  .search-wrapper {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .total-pre-paid {
    color: black;
    font-size: 16px;
    font-weight: 600;
  }
  .form-notifications-note {
    margin-bottom: 20px;
  }
  .group-customer-content {
    margin-bottom: 20px;
    .customer-note {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px;
      gap: 12px;
      width: 100%;
      background: #F0F5FF;
      border-radius: 6px;
    }
  }
  .current-customer {
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 12px;
    transition: all 0.3s ease;
    border-radius: 6px;

    .customer-avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      font-weight: 600;
      text-transform: uppercase;
      color: #fff;
    }

    .customer-detail {
      flex: 1;
      overflow: hidden;
      p {
        font-size: 12px;
        span {
          font-weight: 600;
        }
      }
      .detail-name {
        font-weight: 600;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; 
      }
      .customer-link-disable {
        pointer-events: none;
      }
    }

    .btn-reset {
      background: #F0F2F7;
      border-radius: 6px;
    }

    .customer-link {
      display: inline-block;
      font-weight: 500;
      font-size: 16px;
      text-decoration-line: underline;
      color: var(--color-green);
      cursor: pointer;
    }
  }

  @media (max-width: 1400.98px) {
    .body {
      display: flex;
      padding: 24px 0;
    }
  }

  @media (max-width: 1180px) and (min-width: 1025px) {
  .block-fixed {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    background: var(--color-white);
    z-index: 101;
    padding: 12px 12px 12px 170px;
  }
  .body {
    padding: 24px 12px 200px;
  }
}
@media (max-width: 1024.98px) {
  .block-fixed {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    background: var(--color-white);
    z-index: 0;
    padding: 12px;
  }
  .body {
    padding: 24px 12px 200px;
  }
}

  @media (max-width: 992.98px) {
    .block-heading {
      padding: 20px 12px;
    }
    .body {
      padding: 24px 12px 200px;
      flex-direction: column;
      gap: 50px;
    }
    .block {
      padding: 0;
      max-width: unset;
      border-right: none;
      &:first-child {
        .form-row:last-child {
          margin-top: 24px;
        }
      }
    }
    .block-fixed {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: var(--color-white);
      z-index: 0;
      padding: 12px;
    }
    .form-note {
      margin-bottom: 12px;
    }
  }

  .ant-input {
    min-width: unset;
  }
`;
