import PrimaryButton from 'components/common/Buttons/PrimaryButton';
import userActions from 'features/users/services/actions';
import userSelectors from 'features/users/services/selectors';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { getFormatShortDateAndTime } from 'utils/moment/getSingleDateShortFormat';

const CancelSuccessPage = () => {

    const dispatch = useDispatch();
    const {state} = useLocation();

    useEffect(() => {
        dispatch(userActions.getProfileStaffDetail.fetch());
      },[]);
    
    const user = userSelectors.getProfileStaffDetail();

    return (
        <CancelSuccessPageStyled>
            <IconCancelSuccess/>
            <p className='header'>
            Booking Cancelled Successfully
            
            </p>
            <p className='description'>
                Congratulations, your booking has been successfully cancelled! Your booking for {state.bookCode} on {getFormatShortDateAndTime(state.timeStart)} has been cancelled. If you have any questions, please contact us.
            </p>
            {!!user?.phone &&  <PrimaryButton type='button' label={`Call us at ${user?.phone}`} className='primary_button' />}

        </CancelSuccessPageStyled>
    );
};

const CancelSuccessPageStyled = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    .header {
        margin-top: 46px;
        font-weight: 600;
        font-size: 32px;
        line-height: 140%;
        text-align: center;
        width: 562px;;
    }

    .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        width: 562px;
        margin-top: 64px;
    }

    .primary_button {
        position: absolute;
        bottom: 64px;
    }
`;


export default CancelSuccessPage;

const IconCancelSuccess = () => {
    return (
        <svg width="210" height="210" viewBox="0 0 210 210" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M105 17.5C56.7 17.5 17.5 56.7 17.5 105C17.5 153.3 56.7 192.5 105 192.5C153.3 192.5 192.5 153.3 192.5 105C192.5 56.7 153.3 17.5 105 17.5ZM105 175C66.4125 175 35 143.588 35 105C35 66.4125 66.4125 35 105 35C143.588 35 175 66.4125 175 105C175 143.588 143.588 175 105 175ZM138.95 72.5375L87.5 123.988L71.05 107.537C69.4139 105.901 67.195 104.982 64.8812 104.982C62.5675 104.982 60.3486 105.901 58.7125 107.537C57.0764 109.174 56.1573 111.393 56.1573 113.706C56.1573 116.02 57.0764 118.239 58.7125 119.875L81.375 142.538C84.7875 145.95 90.3 145.95 93.7125 142.538L151.375 84.875C152.186 84.0655 152.83 83.104 153.269 82.0455C153.708 80.9869 153.934 79.8522 153.934 78.7062C153.934 77.5603 153.708 76.4256 153.269 75.367C152.83 74.3085 152.186 73.347 151.375 72.5375C147.962 69.125 142.363 69.125 138.95 72.5375Z" fill="#363565"/>
        </svg>        
    );
};
