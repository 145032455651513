import { Form, FormInstance, Input, Modal } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import bookingSelectors from 'features/bookings/services/selectors';
import { useEffect, useMemo, useState } from 'react';
import { disabledDateBefore, disabledDateSameOrBefore } from 'utils/unit';
import { IClosedPeriodForm } from '.';
import DatePicker from 'components/common/DatePickerInput';
import styled from 'styled-components';
import MultipleSelect, { IOptionMulti } from 'components/common/MultipleSelect';

interface IFormModal {
  isModalOpen?: boolean;
  handleCancel?: () => void;
  form?: FormInstance;
  handleSubmit?: (values: IClosedPeriodForm) => Promise<boolean>;
  isDisableSubmit?: boolean;
  formData?: any;
}

const FormModal = ({
  isModalOpen,
  handleCancel,
  handleSubmit,
  formData,
  isDisableSubmit,
}: IFormModal) => {
  const [form] = Form.useForm();
  const dataForm = bookingSelectors.bookingOnline
    .getLocations()
    ?.filter((item) => item.status === 1);

  const locations: IOptionMulti[] = useMemo(
    () =>
      dataForm?.map((item: any) => ({
        value: item.id,
        title: item.name,
      })) ?? [],
    [dataForm]
  );

  const [selectedLocations, setSelectedLocations] = useState<any[]>(
    locations.map(({ value }) => value)
  );

  useEffect(() => {
    if (formData){
      form?.setFieldsValue(formData);

      const currentLocation = formData?.locations;
  
      if (currentLocation?.length) {
        setSelectedLocations(currentLocation);
      }else {
        setSelectedLocations([]);
      }
    }
  }, [formData]);

  useEffect(() => {
    const filterLocation =
      selectedLocations?.filter((id) =>
        locations.some((obj) => obj.value === id)
      ) || [];
    form?.setFieldValue('locations', [...filterLocation]);
  }, [selectedLocations]);

  return (
    <Modal
      maskClosable={false}
      open={isModalOpen}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      centered
    >
      <FormModalStyled>
        <h2 className='modal-title'>Add Closed Date</h2>

        <Form
          className='modal-form'
          onFinish={handleSubmit}
          autoComplete='off'
          form={form}
          layout='vertical'
          initialValues={{
            locations: null,
          }}
        >
          <div className='form-row'>
            <Form.Item
              label={
                <p>
                  Name<span>(Optional)</span>
                </p>
              }
              name='name'
            >
              <Input placeholder='Enter name' />
            </Form.Item>
          </div>
          <div className='form-row'>
            <Form.Item
              label='Start Date'
              name='startDate'
              dependencies={['endDate']}
              rules={[
                { required: true, message: 'Please select date start' },
                {
                  validator: (_, value) => {
                    const endTime = form.getFieldValue('endDate');
                    if (value && endTime && value.isAfter(endTime)) {
                      return Promise.reject(
                        'Start Date cannot be greater than End Date'
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <DatePicker
                disabledDate={(current: Dayjs) =>
                  disabledDateSameOrBefore(current, dayjs())
                }
                className='date-picker'
              />
            </Form.Item>
            <Form.Item
              label='End Date'
              name='endDate'
              rules={[{ required: true, message: 'Please select date end' }]}
            >
              <DatePicker
                disabledDate={(current: Dayjs) =>
                  disabledDateBefore(current, form?.getFieldValue('startDate'))
                }
                className='date-picker'
              />
            </Form.Item>
          </div>
          <div className='form-row'>
            <Form.Item
              label='Locations'
              name='locations'
              rules={[{ required: true, message: 'Please select locations' }]}
            >
              <MultipleSelect
                onChange={(arr) => {
                  setSelectedLocations(arr);
                }}
                value={selectedLocations}
                options={locations}
                placeholder='Select Locations'
                maxTagPlaceholder='locations'
              />
            </Form.Item>
            {/* <Form.Item
              label='Locations'
              name='locations'
              rules={[{ required: true, message: 'Please select locations' }]}
              initialValue={dataForm[0]?.id}
            >
              <Select
                placeholder='Select locations'
                defaultValue={dataForm[0]}
                options={
                  dataForm.length > 1
                    ? [
                        {
                          value: valueAllOptions,
                          label: 'All Locations',
                        },
                      ].concat(
                        dataForm?.map((item: any) => ({
                          value: item.id,
                          label: item.name,
                        })) ?? []
                      )
                    : dataForm?.map((item: any) => ({
                        value: item.id,
                        label: item.name,
                      })) ?? []
                }
              />
            </Form.Item> */}
          </div>
          {/* <div className='form-row'>
          <Form.Item label='Description' name='description'>
            <TextArea placeholder='Enter description' rows={4} />
          </Form.Item>
        </div> */}
          <div className='form-submit-wrapper'>
            <div data-testing='Cancel' onClick={handleCancel} className='common-btn is-white'>
              Cancel
            </div>
            <button
              data-testing='Done'
              disabled={isDisableSubmit}
              type='submit'
              className='common-btn'
            >
              Done
            </button>
          </div>
        </Form>
      </FormModalStyled>
    </Modal>
  );
};

const FormModalStyled = styled.div`
  .date-picker,
  .date-picker:hover {
    background: #f0f2f6 !important;
  }

`;

export default FormModal;
