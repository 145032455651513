import { Empty, Spin } from 'antd';
import bookingSelectors from 'features/bookings/services/selectors';
import { IServiceQuickBookingItem } from 'features/bookings/services/types/quickBooking';
import { findIndex } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import InputSearchServices from '../InputSearchServices';
import ListCategory from '../ListCategory';
import ServiceItem, { IFuncGetIsCheck, ServiceGroup } from './ServiceItem';
import settingSelectors from 'features/settings/services/selectors';
import ModalConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path.enum';

type Props = {
  handleChooseService?: any;
  currChooseServices?: any;
};

const ServicesSelection = ({
  handleChooseService,
}: Props) => {
  const dataConfigs = bookingSelectors.quickBooking.getDataConfigs();
  const selected = bookingSelectors.quickBooking.getSelectedServices();
  const isLoading = bookingSelectors.quickBooking.getLoadingDataConfigs();
  const merchant_location_id = bookingSelectors.quickBooking.getMerchantLocationId();
  const setting = settingSelectors.getSetting();
  const modalConfirmRef = useRef<ModalConfirmRef>(null);
  const navigate = useNavigate();

  const services = useMemo(() => {
    if (!dataConfigs?.services || dataConfigs?.services.length === 0) return [];
    
    return dataConfigs?.services.filter(o => {
      if (!o.merchant_location_id || o.merchant_location_id?.length === 0) return true;
      return !!o.merchant_location_id?.find(d => d?.toString() === merchant_location_id?.toString());
    }).map(o => {
        const data = {
          ...o,
          is_active: false,
          merchant_employees: o.merchant_employees.filter(o => {
            if (!o.id) return true;
            return o.merchant_location_id?.toString() === merchant_location_id?.toString();
          })};
        
        // if(setting.booking.is_team_member_booking && !o.merchant_employees.some(o => o.is_receive_book === 1 && o.id !== '')) {
        if(!(setting?.booking?.is_team_member_booking && !o.merchant_employees.some(o => o.is_receive_book === 1 && o.id !== '' && o.merchant_location_id.toString() === merchant_location_id?.toString()))) {
          return{
            ...data,
            is_active: false
          };
        } 
  
        return{
          ...data,
          is_active: true,
        };
    });
  }, [dataConfigs?.services, merchant_location_id]);

  const categories = useMemo(() => {
    if (services.length === 0) return [];

    return [
      {
        id: 'general',
        color: '#4A9D66',
        name: 'All',
        total_sale: Number.MAX_SAFE_INTEGER,
      },
    ]
      .concat(
        services.filter((o) => o.is_favourite === 1).length > 0
          ? [
              {
                id: 'favorite',
                color: '#4A9D77',
                name: 'Favorite',
                total_sale: Number.MAX_SAFE_INTEGER,
              },
            ]
          : []
      )
      .concat(
        dataConfigs?.categories.filter((o) =>
          services.find((e) => e.category?.id === o?.id)
        ) as []
      );
  }, [services]);

  const [search, setSearch] = useState('');
  const [category, setCategory] = useState<string>('general');
  const listCategoryRef = useRef<HTMLDivElement>(null);

  const _services = useMemo(() => {
    const _search = search.toLowerCase();
    return services.filter(o =>
      category === 'general' ? true 
      : category === 'favorite'? o.is_favourite === 1 
      : String(o.category?.id) === category
    ).filter(o => {
      if (o?.name?.toLowerCase().includes(_search)) {
        return true;
      }
      return !!o?.service_variants?.some(s => s?.name?.toLowerCase().includes(_search));
    });
  }, [services, category, search, selected]);


  const handleInputChange = (value: string) => {
    setSearch(value);
  };

  const handleCategoryChange = (value: string) => {
    if (value === category) {
      if (listCategoryRef.current) {
        listCategoryRef.current.scrollTo(0, 0); 
      }
      return setCategory('general');
    }
    setCategory(value);
  };

  const handleServiceChange = (
    isChecked: boolean,
    serviceItem: IServiceQuickBookingItem
  ) => {
    handleChooseService(serviceItem, isChecked);
  };

  const getIsChecked: IFuncGetIsCheck = ({ service_variant_id, service_id }) => {
    const currentIdx = findIndex(selected, (o) => o?.id === service_id && o.service_variant_id === service_variant_id);

    return currentIdx !== -1;
  };

  if (isLoading) return <CenterDiv><Spin /></CenterDiv>;

  if (services.length === 0) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No services'} />;

  return (
    <>
      <InputSearchServices handleChange={handleInputChange} isGray={true} />
      <ListCategory
        ref={listCategoryRef}
        data={categories}
        onChange={handleCategoryChange}
        value={category}
        disableScroll
      />
      <ServicesSelectionStyled>
        <table>
          <tbody>
            {
              _services.length > 0 ?
                _services?.map((service) => {
                  if (
                    service.service_variants &&
                    service.service_variants?.length > 0
                  ) {
                    return (
                      <ServiceGroup
                        isActive={service.is_active}
                        key={service?.id}
                        service={service}
                        getIsChecked={getIsChecked}
                        onServiceChange={(isChecked, serviceItem) => {
                          service.is_active ? modalConfirmRef.current?.show({
                            title: 'No team member',
                            msg: 'Currently, there is no team member enabled to the service page to assign another team member.',
                            submit: () => navigate(PATH.services),
                          }) : handleServiceChange(isChecked, serviceItem);
                        }}
                      />
                    );
                  }
                  return (
                    <ServiceItem
                      isActive={service.is_active}
                      key={service?.id}
                      service={service}
                      getIsChecked={getIsChecked}
                      onServiceChange={(isChecked, serviceItem) => {
                        service.is_active ? modalConfirmRef.current?.show({
                          title: 'No team member',
                          msg: 'Currently, there is no team member enabled to the service page to assign another team member.',
                          submit: () => navigate(PATH.services),
                        }) : handleServiceChange(isChecked, serviceItem);
                      }}
                    />
                  );
                }) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No services'} />}
          </tbody>
        </table>
      </ServicesSelectionStyled>
      <ModalConfirm titleConfirm='Go to service' ref={modalConfirmRef}/>

    </>
  );
};

export default ServicesSelection;
const CenterDiv = styled.div`
  height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
`;
const ServicesSelectionStyled = styled.div`
  @media (max-width: 992.98px) {
    // max-height: 230px;
  }
  max-height: 360px;
  overflow-y: scroll;
  table {
    width: 100%;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d9d9d9;
  }

  @media (max-width: 767.98px) {
    min-height: 300px;
  }
`;

