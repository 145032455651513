import message from 'components/common/Message';
import useSalesPayment from 'features/sales/hooks/useSalesPayment';
import salesActions from 'features/sales/services/actions';
import apisSales from 'features/sales/services/apis';
import { EErrorMethodKeys, EPaymentMethod } from 'features/sales/services/constants';
import salesSelectors from 'features/sales/services/selectors';
import { IBodyApiCreateSaleByMixin } from 'features/sales/services/types/checkout';
import { get } from 'lodash';
import { useMemo } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import { IPurchaseProps } from './type';
import { useDispatch } from 'react-redux';
import { getUniqueTransactionId, roundNumber } from 'utils/unit';
import settingSelectors from 'features/settings/services/selectors';
import { EResultTransactionType, ITransactionCompleteCallbackResponse } from 'hooks/useIClientTyro';
import useIClientTyroOptimize from 'hooks/useClientTyroOptimize';

export const useMixErrors = (): [string, (val: string) => void] => {
  const dispatch = useAppDispatch();
  const errors = salesSelectors.getPurchaseMethodErrors(EPaymentMethod.MIX);
  const errorText = useMemo(() => get(errors, EErrorMethodKeys.mix, ''), [errors]);


  const setErrorValid = (msg: string) => {
    dispatch(salesActions.setPurchaseMethodErrors({
      method: EPaymentMethod.MIX,
      errors: {
        [EErrorMethodKeys.mix]: msg,
      }
    }));
  };
  return [errorText, setErrorValid];
};

const Mixin = ({ onSuccess, isActive }: IPurchaseProps) => {
  const setLoadingPage = useSetLoadingPage();

  const amount = salesSelectors.getOriginTotalPrice();
  // const [typeCardModalStatus, setTypeCardModalStatus] = useState<ICardType>(null);
  const setting = settingSelectors.getSetting();
  const iClient = useIClientTyroOptimize();

  const curMixPayments = salesSelectors.getMixPayments();
  const cardValue = salesSelectors.getOriginalCardValue();

  const { cash, voucher } = useMemo(() => ({
    voucher: curMixPayments.find(o => o.type === EPaymentMethod.VOUCHER),
    cash: curMixPayments.find(o => o.type === EPaymentMethod.CASH),
    // card: curMixPayments.find(o => o.type === EPaymentMethod.CARD),
  }), [curMixPayments]);



  const dispatch  = useDispatch();

  const transactionCompleteCallback = (model: 'purchase' | 'refund') => async (response: ITransactionCompleteCallbackResponse) => {
    // setTypeCardModalStatus(null);
    switch (response.result) {
      case EResultTransactionType.APPROVED: {
        const paymentInfo = salesPayment.getPaymentInfo();
        const amount_given = amount - (voucher?.value ?? 0) > 0 ? amount - (voucher?.value ?? 0) ?? 0 : 0;
        
        const payload: any = {
          ...paymentInfo,
          amount_given,
          mixin_payment: {
            cash: cash?.value ? {
              amount_pay: cash?.value > amount_given ? amount_given : cash?.value,
              amount_received: cash?.value ?? 0
            } : undefined,
            voucher: voucher?.data?.voucher_code ? {
              voucher_code: voucher?.data?.voucher_code,
              amount_pay: voucher?.value ?? 0
            } : undefined,
            card: cardValue ? {
              transaction_id: response.transactionReference ?? getUniqueTransactionId(),
              MID: setting.general.merchant_id,
              TID: setting.general.teminal_id,
              amount_pay: cardValue ?? 0,
            }: undefined,
          }
        };
      

        setLoadingPage(true);
        try {
          const res = await apisSales.apiPaymentSale.byMixin(payload);
          const msg = get(res, 'data.data.message', '');
          const invoiceResult = get(res, 'data.data.notify_invoice', '');
          dispatch(salesActions.setInvoiceResult(invoiceResult));
          if (msg) {
            message.success(model === 'purchase' ? 'Purchase' : 'Refund' + ' Successful');
            onSuccess();
          } else {
            throw 'fail';
          }
        } catch (error) {
          message.error('An error occurred. Please try again');
          if (model === 'purchase') {
            iClient.refund(amount, {
              transactionCompleteCallback: transactionCompleteCallback('refund'),
            });
          }
        } finally {
          setLoadingPage(false);
        }
        return;
      }
      case EResultTransactionType.CANCELLED:
      case EResultTransactionType.REVERSED:
      case EResultTransactionType.DECLINED: {
        // setTypeCardModalStatus('wrongInfo');
        return;
      }
      case EResultTransactionType.SYSTEM_ERROR: {
        // setTypeCardModalStatus('systemError');
        return;
      }
      default: {
        // setTypeCardModalStatus('notPair');
        return;
      }
    }
  };



  const salesPayment = useSalesPayment();



  const onPurchase = async () => {
    // setErrorValid('');
    // if (curMixPayments.length === 0) {
    //   setErrorValid('Please add a payment method');
    //   return;
    // }

    // const totalPay = (cash?.value ?? 0) + (voucher?.value ?? 0) + (card?.value ?? 0);
    // if (amount > totalPay) {
    //   setErrorValid(checkoutLang('validate.mix.errorAmount'));
    //   return;
    // }

    if (cardValue && (cardValue ?? 0) > 0 && setting.general.merchant_id !== '' && setting.general.teminal_id !== '') {
      iClient.purchase(roundNumber(cardValue), { transactionCompleteCallback: transactionCompleteCallback('purchase') });
      return;
    }



    setLoadingPage(true);
    try {
      const paymentInfo = await salesPayment.getPaymentInfoAsync();
      if (!paymentInfo) return;
      const amount_given = amount - (voucher?.value ?? 0) > 0 ? amount - (voucher?.value ?? 0) ?? 0 : 0;
      // const valueCard = amount_given - (cash?.value ?? 0) - (card?.value ?? 0);     
       
      const payload: IBodyApiCreateSaleByMixin = {
        ...paymentInfo,
        amount_given: amount_given,
        mixin_payment: {
          cash: cash?.value ? {
            amount_pay: cash?.value > amount_given ? amount_given : cash?.value,
            amount_received: cash?.value ?? 0
          } : undefined,
          voucher: voucher?.data?.voucher_code ? {
            voucher_code: voucher?.data?.voucher_code ?? undefined,
            amount_pay: voucher?.value ?? 0
          } : undefined,
          card: (cardValue ?? 0) > 0 ? {
            transaction_id: getUniqueTransactionId(),
            MID: 'N/A',
            TID: 'N/A',
            amount_pay: cardValue ?? 0,
          }: undefined,
        },
        voucher_code: cash?.value === 0 ? voucher?.data?.voucher_code : undefined,
      };
      const res = await apisSales.apiPaymentSale.byMixin(payload);
      const msg = get(res, 'data.data.message', '');
      const invoiceResult = get(res, 'data.data.notify_invoice', '');
      dispatch(salesActions.setInvoiceResult(invoiceResult));
      if (msg) {
        message.success(msg);
        onSuccess();
      } else {
        const error = get(res, 'data.error.message', '');
        if (error) {
          message.error(error);
        } else
          throw 'fail';
      }
    } catch (error) {
      message.error('An error occurred. Please try again');
    } finally {
      setLoadingPage(false);
    }
  };
  return (
    <button
      type='button'
      className='form-booking-submit'
      onClick={onPurchase}
      disabled={isActive}
    >
      Checkout
    </button>
  );
};

export default Mixin;
