import bookingActions from 'features/bookings/services/actions';
import { BREAK_TIME_MINUTES } from 'features/bookings/services/constants';
import ServicesSelection from 'features/checkout/components/ServiceSelection';
import checkoutHook from 'features/checkout/hook/checkoutHook';
import checkoutSelectors from 'features/checkout/services/selectors';
import { IServiceCheckoutItem } from 'features/checkout/services/types/checkout';
import { IServiceSelectedItem } from 'features/checkout/services/types/service';
import ModalEditService, { IModalEditServiceProps } from 'features/sales/components/ServiceDetails/ModalEditService';
import settingSelectors from 'features/settings/services/selectors';
import { last } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ANONYMOUS_NAME } from 'utils/unit';

const ServicesTab = ({ isFavorite }: { isFavorite?: boolean }) => {
  const [currServices, setCurrServices] = checkoutHook.useSelectedServices();
  const setting = settingSelectors.getSetting();
  const location = checkoutSelectors.getLocationData();
  // const [serviceSelected,] = useState<IServiceSelectedItem>();
  // const [isFirst, setIsFirst] = useState<boolean>(true);
  const [currDraftService, setCurrDraftService] = useState<IServiceSelectedItem | null | undefined>(null);



  // useEffect(() => {

  //   if( !setting || !currServices) return;

  //   if( !isFirst || !isBookingTeamMemberRequired(setting, true )) return;
    
  //   const serviceAnyTeamMember = currServices.find(service => service.employee?.id === '');
    
  //   setCurrDraftService(serviceAnyTeamMember);
  //   setIsFirst(false);

  // }, [currServices, isFirst, setting]);
  

  // const selected = checkoutSelectors.getSelectedServices();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(bookingActions.quickBooking.getDataConfigs.fetch());
  }, []);

  const findServiceVariant = (service: IServiceCheckoutItem) => {
    return service?.service_variants?.find((item) => (service.service_variant_id === item.id));
  };

  const handleChooseService = (item: IServiceCheckoutItem, isChecked: boolean) => {

    const haveService = currServices.find((service) => {
      return service.service_variant_id ? (service.id === item.id && service.service_variant_id === item.service_variant_id) : (service.id === item.id);
    });

    isChecked = haveService ? true : false;

    if (isChecked) {
      // if(setting)
      const newServices = currServices.filter((service) => {
        return !(service.service_variant_id ? (service.id === item.id && service.service_variant_id === item.service_variant_id) : (service.id === item.id));
      });

      setCurrServices(newServices);
    } else {
      const isAnonymous = item?.merchant_employees?.length === 0;

      if (isAnonymous) {
        item.employee_id = undefined;
      }
      const temp = [...currServices];

      const price = (item.service_variant_id === null || item.service_variant_id === undefined) ?
        (item.sale_price || item.regular_price || item.service_price)
        : findServiceVariant(item)?.price;

      const lastService = last(currServices);
      const breakTimeMinutes = BREAK_TIME_MINUTES;
      const prevServiceMinutes = (lastService?.duration_time ?? 0) * (lastService?.quantity ?? 0);      
      const nextTimeStart = moment(lastService?.time_start).add(breakTimeMinutes + prevServiceMinutes, 'minute');      

      const serviceSelected = {
        ...item,
        quantity: 1,
        employee: item?.merchant_employees?.map((item) => ({
          id: item.id,
          name: item.full_name
        }))[0],
        time_start: nextTimeStart.format(),
        price,
        categoryName: item.category?.name,
      } as IServiceSelectedItem;      

      temp.push(serviceSelected);

      if(setting?.booking?.is_team_member_booking) {
        setCurrDraftService(serviceSelected);
      } else {
        
        const manager = location?.manager;
        const temp = [...currServices];
        temp.push(serviceSelected as IServiceSelectedItem);  

        const haveManager = !! serviceSelected?.merchant_employees?.find(o => o.id === manager?.id);        

        serviceSelected.employee = {
          id: haveManager ? manager?.id : '',
          name: haveManager ? manager?.full_name : ANONYMOUS_NAME
        };
        
        serviceSelected.quantity = 1;
        setCurrServices([...currServices, serviceSelected] ?? []);
      }
    }
  };

  const onEditService: IModalEditServiceProps['onSubmit'] = async (formValue, service) => {
    if (!service || !currDraftService) return;

    currDraftService.employee = formValue.employee;
    currDraftService.quantity = formValue.quantity;
    setCurrServices([...currServices, currDraftService] ?? []);
    

    // dispatch(checkoutActions.setServiceSelected(selected.map(item => {
    //   if (service.service_variant_id ? (service.id === item.id && service.service_variant_id === item.service_variant_id) : (service.id === item.id)) {
    //     return ({
    //       ...item,
    //       employee: formValue.employee,
    //       quantity: formValue.quantity,
    //     });
    //   }
    //   return item;
    // })));
  };


  return (
    <>
      <ServicesSelection
        handleChooseService={handleChooseService}
        currChooseServices={currServices}
        isFavorite={isFavorite}
      />
      {!!currDraftService && <ModalEditService
        isOpenModal={!!currDraftService}
        handleCancel={() => setCurrDraftService(null)}
        onSubmit={onEditService}
        data={currDraftService as any}
        isCheckout={true}
      />}
    </>
  );
};

export default ServicesTab;
