import { Form, Input, Radio, Spin } from 'antd';
import { AxiosResponse } from 'axios';
import DatePicker from 'components/common/DatePickerInput';
import FormRow, { IHelperLabelType } from 'components/common/Form/FormRow';
import dayjs from 'utils/dayjs';
import apisBooking from 'features/bookings/services/apis';
import settingSelectors from 'features/settings/services/selectors';
import { get } from 'lodash';
import moment from 'moment';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import NumberValidations from 'utils/validations/number';
import { HBox } from '../../UIBase';
import message from 'components/common/Message';
interface ICustomerResData {
  id: number;
  name: string;
  phone_number: string;
  email: string;
  gender: number;
  is_walkin_in?: boolean;
  date_of_birth: null;
  customer_code?: string;
  important_client_info: string | null
}
export type GeneralInformationRef = {
  checkCustomerInfo: () => Promise<boolean>;
};
type Props = {
  errors: Record<string, string>;
  isCheckIn?: boolean;
};
/* eslint-disable-next-line */
const GeneralInformation = forwardRef<GeneralInformationRef, Props>(
  ({ errors = {}, isCheckIn = false }, ref) => {
    const { merchant_code = '' } = useParams();
    const [loading, setLoading] = useState(false);
    const form = Form.useFormInstance();
    const [helperText, setHelperText] = useState('');
    const { t: formLang } = useTranslation('form');

    const setting = settingSelectors.getSettingBookingForm();
    const [status, setStatus] = useState<IHelperLabelType>('warning');
    const [successMessageDisplayed, setSuccessMessageDisplayed] = useState(false);
    const checkCustomerInfo = async () => {
      const phoneNumber = form.getFieldValue('phoneNumber');

      if (!phoneNumber || !merchant_code) {
        if (!successMessageDisplayed) {
          message.warning('Please enter phone number');
          setSuccessMessageDisplayed(true);
          setTimeout(() => {
            setSuccessMessageDisplayed(false);
          }, 4000);
          return false;
        }

      }
      /* eslint-disable-next-line */
      // const isPhoneValid = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/.test(phoneNumber);
      // if (!isPhoneValid) return false;

      const isPhoneValid = /^\d{8,12}$/.test(phoneNumber);

      if (!isPhoneValid) return false;

      form.setFieldsValue({
        name: '',
        email: '',
      });

      setLoading(true);
      setHelperText('');
      try {
        const res: AxiosResponse<{ data: ICustomerResData }> =
          await apisBooking.getCustomerDetailWithPhoneNumber(
            merchant_code,
            phoneNumber
          );
        if (res?.data?.data) {
          const resData = res?.data?.data ?? {};
          form.setFieldsValue({
            customerCode: resData.customer_code,
            email: resData.email,
            phoneNumber: resData.phone_number,
            name: resData.name,
            gender: resData.gender ?? 3,
            importantClientInfo: resData.important_client_info,
            birthDate: resData?.date_of_birth
              ? dayjs(moment(resData.date_of_birth).format())
              : null,
          });
          setStatus('success');
          setHelperText('Welcome back! Your information has been saved');
          return true;
        } else {
          throw 'fail';
        }
      } catch (error) {
        setHelperText(
          'Your contact isn’t existed on our store, please fill in remain information'
        );
        setStatus('warning');
        return false;
      } finally {
        setLoading(false);
      }
    };

    useImperativeHandle(ref, () => ({
      checkCustomerInfo,
    }));

    return (
      <SectionStyled>
        <FormRow
          name='phoneNumber'
          helperLabelType={status}
          helperText={helperText}
          extra={get(errors, 'phoneNumber', '')}
        >
          <HBox>
            <Form.Item
              label='Phone Number'
              name='phoneNumber'
              noStyle
              rules={[
                {
                  required: true,
                  message: 'Please input your phone number!',
                },
                NumberValidations.PHONE,
                // {
                //   /* eslint-disable-next-line */
                //   pattern: /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/,
                //   message: 'Your phone number format is invalid!'
                // },
              ]}
            >
              <Input type='tel'
                placeholder={formLang('EnterNumberPlaceholder') || ''}
                onChange={() => setHelperText('')}
              />
            </Form.Item>
            {/* <div className='btn-list-search' onClick={checkCustomerInfo}></div> */}
          </HBox>
        </FormRow>
        {!helperText && (
          <p className='text-note'>
            Enter your phone number to check if you have already had an account
          </p>
        )}
        
        {isCheckIn && (
          <CenterBtnStyled $helperText={helperText}>
            <button
              className='common-btn is-white'
              type='button'
              style={{
                minWidth: '96px',
                height: '64px',
                border: '1px solid var(--color-green)',
              }}
              onClick={checkCustomerInfo}
            >
              Ok
            </button>
          </CenterBtnStyled>
        )}

        {!!helperText && (
          <>
            <FormRow
              label='Name'
              name='name'
              rules={[{ required: true, message: 'Please input your name!' }]}
            >
              <Input placeholder='Enter your name' />
            </FormRow>
            {!isCheckIn && (
              <>
                <FormRow
                  label='Email'
                  name='email'
                  optional // HP742 - Email is not require
                  rules={
                    //   isCheckIn ? [
                    //   { type: 'email', message: 'is not a valid email!' }
                    // ] : [
                    //   {
                    //     required: true,
                    //     message: 'Please input your email!',
                    //   },
                    //   { type: 'email', message: 'is not a valid email!' }]} // comment for HP742 - Email is not require
                    [{ type: 'email', message: 'is not a valid email!' }]
                  }
                >
                  <Input type='email' placeholder='Enter your email' />
                </FormRow>
                <FormRow label='Gender' name='gender' optional>
                  <Radio.Group defaultValue={0}>
                    <Radio value={0}>Female</Radio>
                    <Radio value={1}>Male</Radio>
                    <Radio value={2}>Non-binary</Radio>
                  </Radio.Group>
                </FormRow>
              </>
            )}

            {setting?.view_birthday ? (
              <FormRow label='Birth Date' name='birthDate' optional>
                <DatePicker suffixIcon={false} allowClear={false} />
              </FormRow>
            ) : (
              <Form.Item name='birthDate' noStyle />
            )}

            <FormRow
              label='Important client info'
              name='importantClientInfo'
              className='importantClientInfo'
              optional
              extra={'This note will be displayed on all bookings. '}
            >
              <Input.TextArea
                rows={5}
                placeholder={'Eg. customer allergic to fragrance'}
              />
            </FormRow>
            <FormRow
              name='customerCode'
            >
              <Input style={{display: 'none'}} />
            </FormRow>
          </>
        )}

        {loading && (
          <div className='overlay'>
            <Spin />
          </div>
        )}
      </SectionStyled>
    );
  }
);

export default GeneralInformation;

const SectionStyled = styled.div`
  position: relative;
  z-index: 1;
  .overlay {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    display: flex;
    inset: 0;
    align-items: center;
    justify-content: center;
  }
  .importantClientInfo .ant-form-item-extra {
    font-style: italic;
    color:#7980BC!important;
  }
`;

const CenterBtnStyled = styled.div<{ $helperText?: string }>`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => (props.$helperText ? '8px' : '0')};
`;
