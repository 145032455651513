import { IApiBookingParams } from 'features/bookings/services/types/booking';
import { set } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';

const getAddBookingLink = (dateStore: number, merchant_location_id: IApiBookingParams['merchant_location_id']) => {
  const params = {};
  if (dateStore || dateStore !== 0) {
    const currDate = moment(dateStore);
    if (!currDate.isSame(moment(), 'day')) {
      currDate.set({ hour: 0, minute: 1, second: 0 });
    }
    set(params, 'booking_date', currDate.valueOf());
  } else {
    set(params, 'booking_date', moment().valueOf());
  }

  if (merchant_location_id)
    set(params, 'merchant_location_id', merchant_location_id);


  const queryAddBooking = queryString.stringify(
    params,
    { arrayFormat: 'bracket' }
  );
  return `/private/bookings/quick-booking?${queryAddBooking}`;
};

export default getAddBookingLink;