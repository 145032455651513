export const NAME_REDUCER = 'booking';

export const PREFIX_ACTIONS = 'BOOKING_FEATURE_';

export const PATH_LOADING = {
  getBookings: `loading.${NAME_REDUCER}.getBookings`,
  getBookingOnlineData: `loading.${NAME_REDUCER}.getBookingOnlineData`,
  getDataConfigs: `loading.${NAME_REDUCER}.getDataConfigs`,
  getActivities: `loading.${NAME_REDUCER}.getActivities`,
  getOptionFilter: `loading.${NAME_REDUCER}.getOptionFilter`,
  getParamOptionCalendar: `loading.${NAME_REDUCER}.getParamOptionCalendar`,
};

export const BREAK_TIME_MINUTES = 5;
export const TIME_START_FORMAT = 'DD.MM.YYYY HH:mm:ss';
export const TIME_START_FORMAT_RESPONSE = 'YYYY-MM-DD HH:mm:ss';
export const DATE_FORMAT_RESPONSE = 'YYYY-MM-DD';
export const TIME_DATE_TIMEZONE = 'YYYY-MM-DDTHH:mm:ssZ';
export const TIME_DATE_MERCHANT = 'MMM DD, YYYY';
export const TIME_DAY_DATE_MERCHANT = 'dddd DD MMM';
export const HOUR_MINUTE_FORMAT = 'HH:mm';
export const HOUR_MINUTE_FORMAT_AM_PM = 'HH:mma';
export const DATE_FORMAT_TIME_SCHEDULE = 'MMM DD';
export const DAY_OF_WEEK_FORMAT = 'ddd';


export const zoomViewOptionData = {
  ONE_HOUR: 60,
  HAlF_HOUR: 30,
  QUARTER_HOUR: 15,
};