import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { useEffect } from 'react';
import BookingsTab from '../components/BookingsTab';
import ClosedPeriodsTab from '../components/ClosedPeriodsTab';
import CustomerLinksTab from '../components/CustomerLinksTab';
import GeneralTab from '../components/GeneralTab';
import TyroTab from '../components/TyroTab';
import { useDispatch } from 'react-redux';
import settingActions from '../services/actions';
import CancellationsReasonsTab from '../components/CancellationsReasonsTab';
import ServiceChargesTab from '../components/ServiceChargesTab';
import NotificationTab from '../components/NotificationTab';
import AutoMessageTab from '../components/ AutoMessagesTab';
import { useLocation } from 'react-router-dom';
import settingSelectors from '../services/selectors';

const SettingsPage = () => {
  const onChange = undefined;
  const dispatch = useDispatch();
  const loading = settingSelectors.loadSetting();

  
  
  useEffect(() => {
    dispatch(settingActions.getSetting.fetch());
    dispatch(settingActions.getOptionBooking.fetch());
    dispatch(settingActions.getListClosedDate.fetch());
  }, []);

  const location = useLocation();

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Tyro',
      children: <TyroTab />,
      disabled: loading
    },
    {
      key: '2',
      label: 'Bookings',
      children: <BookingsTab />,
      disabled: loading
    },
    {
      key: '3',
      label: 'Booking Online',
      children: <CustomerLinksTab />,
      disabled: loading
    },
    {
      key: '4',
      label: 'General',
      children: <GeneralTab />,
      disabled: loading
    },
    {
      key: '5',
      label: 'Public Holiday',
      children: <ServiceChargesTab />,
      disabled: loading
    },
    {
      key: '6',
      label: 'Closed Periods',
      children: <ClosedPeriodsTab />,
      disabled: loading
    },
    {
      key: '7',
      label: 'Notifications',
      children: <NotificationTab />,
      disabled: loading
    },
    {
      key: '8',
      label: 'Cancellation Reasons ',
      children: <CancellationsReasonsTab />,
      disabled: loading
    },
    {
      key: '9',
      label: 'Auto messages',
      children: <AutoMessageTab />,
      disabled: loading
    },

  ];


  return (
    <div className='profile-page'>
      
      <Tabs defaultActiveKey={location?.state?.key?.toString() ?? '1'} items={items} onChange={onChange} />
    </div>
      
  );
};

export default SettingsPage;
