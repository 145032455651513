import bookingSelectors from 'features/bookings/services/selectors';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ANONYMOUS_NAME, EPromotionType, checkServiceRestrict, formatMoney, formatTimeMinutes } from 'utils/unit';
import { LabelStyled } from './BasicContent';
import { sumBy } from 'lodash';
import settingSelectors from 'features/settings/services/selectors';

interface Props  {
  isCheckIn?: boolean;
  isTestTyro?: boolean
}

const tax = 0;
const ServiceDetails: React.FC<Props> = ({isCheckIn, isTestTyro}) => {
  const formValue = bookingSelectors.bookingOnline.getFormValues();
  const services = bookingSelectors.bookingOnline.getSelectedServices();
  // const serviceSelected = bookingSelectors.bookingOnline.getSelectedServices();

  const promotion = bookingSelectors.bookingOnline.getPromotion();
  const setting = settingSelectors.getSettingBookingForm();
  const teamMemberSelected = bookingSelectors.bookingOnline.getTeamMemberSelected();

  const isTimeRoster: boolean = useMemo( () => setting.enable_timeroster_management, [setting.enable_timeroster_management]);


  // const [form] = Form.useForm();

  // const [helperText, setHelperText] = useState<string>('');
  // const [error, setError] = useState('');
  // const [warning, setWarning] = useState('');
  // const [isApplied, setIsApplied] = useState<boolean>();
  // const setLoadingPage = useSetLoadingPage();
  // const dispatch = useAppDispatch();
  // const { merchant_code = '' } = useParams();


  const subtotal = useMemo(() => {
    return formValue?.totalPrice ?? 0;
  }, [formValue]);

  const total = useMemo(() => {
    return subtotal + tax;
  }, [subtotal]);

  const valuePromo: number = useMemo(() => {
    const serviceAppliedPromo = services.filter(service => checkServiceRestrict(promotion.services_restricts ?? [], service));
    const sumService = sumBy(serviceAppliedPromo, o => o.price * o.quantity);


    const value = Number(promotion?.value);
    if (promotion?.type === EPromotionType.PERCENT) {
      return sumService * (value / 100);
    } else if (promotion?.type === EPromotionType.PRICE) {
      return sumService > value ? value : sumService;
    }
    return 0;
  }, [promotion]);

  // const errorBoundary = useMemo(() => {
  //   if (helperText) {
  //     return ({
  //       validateStatus: 'success',
  //       help: helperText,
  //     });
  //   }

  //   if (error) {
  //     return ({
  //       validateStatus: 'error',
  //       help: error,
  //     });
  //   }

  //   if(warning) {
  //     return ({
  //       validateStatus: 'warning',
  //       help: warning,
  //     });
  //   }

  //   return ({
  //     validateStatus: '',
  //     help: undefined,
  //   });
  // }, [helperText, error]);

  // const onchange = () => {
    
  //   setHelperText('');
  //   const payload = {
  //     ...formValue,
  //     promotionCode: form.getFieldValue('promotionCode'),
  //   } as IBookingOnlineFormInfoValue;
    
  //   dispatch(bookingActions.setBookingOnlineFormValues(payload));
  // };

  // @ts-ignore
  // const checkSuitableServices = useCheckServicesPromotion(serviceSelected as IService[]);

  // const checkPromotion = async () => {
  //   const promotion_code = form.getFieldValue('promotionCode');
  //   const location_id = formValue?.location || '';
  //   setLoadingPage(true);

  //   setError('');
  //   setHelperText('');
  //   setWarning('');

  //   try {
  //     const res: AxiosResponse<any> = await apisPromotion.checkPromotionOnlineValid(promotion_code, { merchant_code, location_id });
  //     const resData = res?.data?.data;
      
      
  //     if (resData) {
  //       const dataMapping = mappingPromotionInfo(resData);
  //       const isNoSuitableServices = !checkSuitableServices(dataMapping);

  //       if (isNoSuitableServices) {
  //         setWarning('Please check again, there are currently no services that match the service you selected');
  //         return;
  //       }

  //       setIsApplied(true);
  //       setHelperText('Promotion code is valid');
  //       dispatch(bookingActions.setBookingOnlineActivePromotion(res.data.data));
  //     } else {
  //       setError(res?.data?.error.message);
  //       return;
  //     }
  //   } catch (error: any) {
  //     const response = get<Record<string, string[]>>(
  //       error,
  //       'response.data.errors',
  //       {}
  //     );

  //     const errors = Object.values(response);
  //     errors.map((value) => {
  //       setError(get(value, [0], ''));
  //     });
  //   } finally {
  //     setLoadingPage(false);
  //   }

  // };

  return services.length > 0 ? (
    <>
      <ServiceDetailsStyled>
        <LabelStyled>Service Details</LabelStyled>
        <div className="service_details">
          <table className="services_table">
            <tbody>
              {services.map((data) => {
                const employeeName = data ? (!data?.employee_id ? ANONYMOUS_NAME : data.merchant_employees?.find(o => o.id === data?.employee_id)?.full_name) : '';
                return (<React.Fragment key={data.id + '-' + data?.service_variant_id ?? ''}>
                  <tr>
                    <td className='content' data-testing={`service_${data.id}`}>
                      <div>
                        <p className='service_name'>{data.name}</p>
                        <p className='service_time'>
                          {(data.duration ? formatTimeMinutes(data.duration) : '') + ' with ' + (isCheckIn && isTimeRoster ? employeeName : (isTimeRoster ? (teamMemberSelected?.[0]?.full_name ?? '') : employeeName))}
                        </p>
                      </div>
                    </td>
                    <td className="service_time">{data.quantity}</td>
                    <td className="text_price">{formatMoney(data.price)}</td>
                  </tr>
                  <tr>
                    <td colSpan={4} className="divider"></td>
                  </tr>
                </React.Fragment>);
              })}
              {/* <tr>
                <td >Subtotal</td>
                <td/>
                <td className="text_price">${subtotal}</td>
              </tr>
              <tr>
                <td >Taxes</td>
                <td/>
                <td className="text_price">${tax}</td>
              </tr>
              <tr>
                <td colSpan={4} className="divider"></td>
              </tr> */}
              {valuePromo > 0 && <tr>
                <td>
                  <p>Subtotal</p>
                </td>
                <td />
                <td className="text_price"> {formatMoney(total ?? 0)}</td>
              </tr>}
              {valuePromo > 0 && <tr>
                <td>
                  <p>Promotion</p>
                </td>
                <td />
                <td className="text_price"> - {formatMoney(valuePromo ?? 0)}</td>
              </tr>}
              <tr>
                <td>
                  <p>TOTAL</p>

                </td>
                <td />
                <td className="text_price sub_total">{formatMoney(total - valuePromo)}</td>
              </tr>

              {!isCheckIn && formValue?.is_pre_payment && formValue?.pre_payment_value !== 100 && isTestTyro && <tr>
                <td>
                  <p>Prepaid</p>
                </td>
                <td />
                <td className="text_price"> ${formValue?.total_prepaid ?? 0} </td>
              </tr>}

              {!isCheckIn && formValue?.is_pre_payment && isTestTyro && <tr>
                <td>
                  <p>Payment method</p>
                </td>
                <td />
                <td className="text_price"> CARD </td>
              </tr>}
              {/* <tr>
                <td colSpan={4} className="divider"></td>
              </tr> */}
            </tbody>
          </table>
          <p className='label_note'>*All pricing in $AUD and includes GST unless stated otherwise.</p>
        </div>
        {/* <PromotionInfoStyled>
          <LabelStyled>Have promotion</LabelStyled>
          <Form
            form={form}
          >
            <FormRow
              label='Promotion Code'
              name='promotionCode'
              // @ts-ignore
              validateStatus={errorBoundary?.validateStatus}
              help={errorBoundary?.help}
            >
              <HBox>
                <Form.Item
                  label='Promotion code'
                  name='promotionCode'
                  noStyle
                >
                  <Input disabled={isApplied} placeholder={'Enter your promotion'} onChange={onchange} />
                </Form.Item>
                <ButtonSuccessBig className='checkout-form-btn' style={isApplied ? {
                  border: '1px solid #f5222d',
                  color: '#f5222d'
                } : undefined} onClick={isApplied ? () => setIsApplied(false) : checkPromotion}> {isApplied ? 'Cancel' : 'Redeem'}</ButtonSuccessBig>
              </HBox>
            </FormRow>
          </Form>
        </PromotionInfoStyled> */}
      </ServiceDetailsStyled>
    </>
  ) : <></>;
};

export default ServiceDetails;
// const PromotionInfoStyled = styled.div`
//  p.text {
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 140%;
//   color: var(--color-primary);
//  }
// `;
const ServiceDetailsStyled = styled.div`
  .service_details {
    background: var(--color-white);
    border-radius: 6px;
    position: relative;
     margin-bottom: 24px;

    .label_note {
    font-size: 16px;
    }
      
    .services_table {
      width: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: var(--color-primary);

      .text-right {
        text-align: right;
      }

      .text-center {
        text-align: center;
      }

      .text_price {
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        text-align: right;
        &.sub_total {
          font-weight: 600;
          font-size: 32px;
          line-height: 140%;
          color: var(--color-green);
          padding: 24px 0;
        }
      }
      td {
        padding-top: 11px;
        padding-bottom: 11px;
        vertical-align: middle;
        &.content {
          width: 75%;
        }
        .service_name {
          font-weight: 500;
          font-size: 16px;
          line-height: 140%;
          display: flex;
          align-items: center;
        }
        .service_time {
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
          color: var(--color-purple-06);
        }
      }
      .divider {
        padding: 0;
        height: 2px;
        width: 100%;
        background: var(--color-overlay);
        border-radius: 6px;
      }
    }
  }
  .form_group {
    display: flex;
    align-items: flex-start;
    .form_item {
      flex:1;
      margin-right: 16px;
    }
  }
`;
