import bookingSelectors from 'features/bookings/services/selectors';
import { IBookingItemResData } from 'features/bookings/services/types/booking';
import { get, maxBy, minBy } from 'lodash';
import moment, { Moment } from 'moment';
import React, { createContext, useContext, useMemo } from 'react';
import styled from 'styled-components';
import UIBookingItem from './UIBookingItem';
import UIBookingMulti from './UIBookingMulti';
import { getDefaultHeight } from './constants';
import { IColCalendar } from './types';
type IBookingItemResDataCus = IBookingItemResData & { uniq: string; startMoment: Moment; endMoment: Moment };
type Props = {
  col_id?: string;
  grData: IColCalendar;
  rowSpan: number;
};
type IBookingMultiContext = {
  setPopover: (visible: boolean) => void;
};
const BookingMultiContext = createContext<IBookingMultiContext | null>(null);

export const useBookingMultiContext = () => useContext(BookingMultiContext);

const UITeamMemberMultiItem: React.FC<Props> = (props) => {
  const pixelPerMinute = bookingSelectors.getPixelPerMinute();
  const zoomCalendar = bookingSelectors.getZoomView();
  const { col_id, grData } = props;

  const mappingData = useMemo(() => {
    const rowTime = moment(get(grData.data, [0, 'time_start']), 'YYYY-MM-DD HH:mm:ss')
      .set({ hour: grData.rowTime.get('hour'), minute: grData.rowTime.get('minute'), second: grData.rowTime.get('second') });

    const _list: IBookingItemResDataCus[] = grData.data?.map(k => ({
      ...k,
      uniq: k.id + k.time_start + k.time_end,
      startMoment: moment(k.time_start, 'YYYY-MM-DD HH:mm:ss'),
      endMoment: moment(k.time_end, 'YYYY-MM-DD HH:mm:ss')
    })).sort((a, b) => a.startMoment.valueOf() - b.startMoment.valueOf());

    const data: { id: number, timeId: string, data: IBookingItemResDataCus[], item: IBookingItemResData, uniq: string, duration: number }[] = [];

    _list?.forEach(o => {
      const dataFilter: (typeof _list) = [];
      _list.forEach(a => {
        const timeStart = minBy(dataFilter, f => f.startMoment.valueOf())?.startMoment || o.startMoment;
        const timeEnd = maxBy(dataFilter, f => f.endMoment.valueOf())?.endMoment || o.endMoment;

        const child = moment(a.time_start, 'YYYY-MM-DD HH:mm:ss');
       
        const isExist = !!data.find(e => e.data.find(f => f.uniq === a.uniq));
        if (isExist) return;
        if (child.isSame(timeStart) || child.isBetween(timeStart, timeEnd)) {
          dataFilter.push(a);
        }
      });
      const maxTimeEnd = maxBy(dataFilter, a => moment(a.time_end, 'YYYY-MM-DD HH:mm:ss').valueOf())?.time_end;

      data.push({
        timeId: o.startMoment.format(),
        data: dataFilter,
        id: o.id,
        uniq: o.uniq,
        duration: maxTimeEnd ? moment(maxTimeEnd).diff(rowTime, 'minute') : 30,
        // @ts-ignore
        item: o,
      });
    });
    return data.filter(o => {
      if (o.data.length > 0) return true;
      const isExist = !!data.find(a => a.data.find(f => f.uniq === o.uniq));
      return !isExist;
    });
  }, [grData.data, grData.rowTime]);


  const rowTime = useMemo(() => {
    return moment(get(mappingData, [0, 'timeId']), 'YYYY-MM-DD HH:mm:ss')
      .set({ hour: grData.rowTime.get('hour'), minute: grData.rowTime.get('minute'), second: grData.rowTime.get('second') });
  }, [grData.rowTime]);

  const renderMultiple = (data: IBookingItemResData[]) => {
    return data.map((it, idx) => {
      const top = moment(it.time_start, 'YYYY-MM-DD HH:mm:ss').diff(rowTime, 'minute');
      const bottom = moment(it.time_end, 'YYYY-MM-DD HH:mm:ss').diff(rowTime, 'minute');

      return (
        <PositionStyled className='box UITeamMemberMultiItem_PositionStyled_renderMultiple' top={top} bottom={bottom} key={idx} pixelPerMinute={pixelPerMinute}>
          <UIBookingItem data={it} col_id={col_id} rowTime={rowTime} forceRender height={bottom - top} />
        </PositionStyled>
      );
    });
  };

  return (
    <UIBookingItemStyled distance={zoomCalendar} pixelPerMinute={pixelPerMinute} className='UITeamMemberMultiItem_UIBookingItemStyled'>
      {mappingData.map(o => {
        if (o.data.length === 1) return <React.Fragment key={o.timeId}>{renderMultiple(o.data)}</React.Fragment>;
        const top = moment(o.timeId).diff(rowTime, 'minute');
        const bottom = o.duration;

        return <PositionStyled key={o.timeId} className='box UITeamMemberMultiItem_PositionStyled_renderMulti' top={top} bottom={bottom} pixelPerMinute={pixelPerMinute}>
          <UIBookingMulti {...props} forceRender grData={{ ...props.grData, data: o.data, }} multiViewPopup height={bottom - top} />
        </PositionStyled>;
      })}

    </UIBookingItemStyled>
  );
};

export default UITeamMemberMultiItem;
type PositionStyledProps = {
  top?: number;
  bottom?: number;
  minHeight?: number;
  pixelPerMinute: number;
};
export const PositionStyled = styled.div`
  position: absolute;
  width:100%;
  z-index: 2;
  top:${({ top = 0, pixelPerMinute }: PositionStyledProps) => top * pixelPerMinute}px;
  min-height:${({ bottom = 0, top = 0, pixelPerMinute }: PositionStyledProps) => getDefaultHeight(bottom - top, pixelPerMinute)}px;
  bottom:${({ bottom = 0, pixelPerMinute }: PositionStyledProps) => getDefaultHeight(bottom, pixelPerMinute)}px;
  height:${({ bottom = 0, top = 0, pixelPerMinute }: PositionStyledProps) => getDefaultHeight(bottom - top, pixelPerMinute)}px;
`;

type StyleProps = {
  distance: number;
  pixelPerMinute: number;
};
const UIBookingItemStyled = styled.div`
  width: 100%;
  height: 100%;
  position:relative;
  min-height: ${({ distance = 0, pixelPerMinute }: StyleProps) => distance * pixelPerMinute}px;
`;
