import { Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import actions from '../services/actions';
import userApis from '../services/apis';
import selectors from '../services/selectors';
import UpdateUser from '../widgets/UpdateUser';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { get } from 'lodash';
import message from 'components/common/Message';
import { useMemo, useRef } from 'react';
import ModalConfirm, { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import userSelectors from '../services/selectors';
import styled from 'styled-components';

type StaffItem = {
  key: string;
  fullName: string;
  phoneNumber: string;
  accessLevel: string;
};

const StaffTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = useMemo(() => {
    return {
      page: searchParams.get('page') ?? 1,
      per_page: searchParams.get('per_page') ?? 10,
    };
  }, [searchParams]);

  const dispatch = useDispatch();

  const setPageLoading = useSetLoadingPage();
  const data = selectors.getStaffPaginateList();
  const loading = selectors.loadingStaffPaginateList();
  const getShowCreateStaffs = userSelectors.getShowCreateStaffs();
  const locationOptions = getShowCreateStaffs?.merchant_locations;
  const confirmRef = useRef<ModalConfirmRef>(null);

  const confirm = (id: string) => {
    const onOk = async () => {
      setPageLoading(true);
      try {
        const res = await userApis?.deleteStaffs(id.replace('#', ''));
        const msg = get(res, 'data.message', '');
        if (msg) {
          message.success(msg);
          dispatch(actions.setParams({}));
        } else {
          const errorMsg = get(res, 'data.error.message', '');
          if (errorMsg) {
            message.error(errorMsg);
          } else {
            throw 'fail';
          }
        }
      } catch (error) {
        message.error('An error occurred. Please try again!');
      } finally {
        setPageLoading(false);
      }
    };
    confirmRef.current?.show({
      title: 'Are you sure',
      icon: 'delete',
      msg: 'Are you sure remove this user?',
      submit: onOk
    });
  };

  const columns: ColumnsType<StaffItem> = [
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      key: 'full_name',
      fixed: 'left',
      width: '150px',
      render: (text: any) => {
        return (
          <FullNameOptionStyled>
            <div className='titleContainer'>
              <p className={`${text.length >= 15 ? 'service-name-ellipsis' : ''}`}>{`${text} `}</p>
            </div>
          </FullNameOptionStyled>
        );
      }
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
      className: 'nowrap',
    },
    // {
    //   title: 'Email',
    //   dataIndex: 'email',
    //   key: 'email',
    // },
    {
      title: 'Location',
      dataIndex: ['merchant_location', 'name'],
      key: 'merchant_location_name',
    },
    {
      title: 'Access Level',
      dataIndex: 'access_level_name',
      key: 'accessLevel',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '150px',
      render: (text: string, record: any) => (
        <ul className={'common-action'}>

          <UpdateUser record={record} />

          {/* <li className='action-remove'
            onClick={async () => {
              if (confirm('Do you want delete this item ?')) {
                await userApis.deleteStaffs(record.id);
                dispatch(actions.getListPaginateStaffs.fetch({
                  page: searchParams.get('page'),
                  per_page: searchParams.get('per_page')
                }));
              }
            }}
          ></li> */}
          {!record?.is_owner && <li className="action-remove" onClick={() => confirm(String(record?.id ?? ''))}></li>}
        </ul>
      ),
    },
  ];

  return (
    <>
      <Spin spinning={loading || !locationOptions}>
        <Table
          className={'custom-table'}
          dataSource={data?.data}
          columns={columns}
          scroll={{
            x: 900,
          }}
          // pagination={false}

          pagination={{
            defaultCurrent: Number(page?.page) ?? 1,
            total: data?.pagination?.total_record,
            pageSize: Number(page?.per_page),
            defaultPageSize: Number(page?.page),
            onChange: (pageChange) => {
              const params: any = {
                page: pageChange,
                per_page: page.per_page,
                keyword: searchParams.get('keyword') ?? ''

              };
              setSearchParams(params);
              dispatch(actions.getListPaginateStaffs.fetch(params));
            },


            showSizeChanger: false,
          }}
        />
      </Spin>
      <ModalConfirm ref={confirmRef} />
    </>
  );
};


export default StaffTable;

const FullNameOptionStyled = styled.div`
.service-name-ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 200px;
      @media only screen and (max-width: 767.98px) {
        width: 130px;
      }
      @media only screen and (min-width: 768px) and (max-width: 1180.98px) {
        width: 150px;
      }
    }
`;