import { Form, Input, Modal, ModalProps, Radio } from 'antd';
import FormRow from 'components/common/Form/FormRow';
import { ICustomerResData } from 'features/checkout/services/types/bookingDetail';
import { IVoucherForSalesSelectedItem } from 'features/checkout/services/types/voucher';
import { ICustomerItemResData } from 'features/customers/services/types/cutomer';
// import { ICustomerItemResData } from 'features/customers/services/types/cutomer';
import { IVoucherDataItem } from 'features/sales/services/types/voucher';
import { useEffect, useState } from 'react';
import NumberValidations from 'utils/validations/number';

interface ModalVoucherSaleProps extends ModalProps {
  customer: ICustomerResData | ICustomerItemResData| null,
  handleChooseVoucher: (voucher: IVoucherForSalesSelectedItem) => void,
  data: IVoucherDataItem,
  onClose: () => void
}

enum EVoucherType {
  FOR_THEMSELVES = 'for_themselves',
  FOR_FRIEND = 'for_friend'
}
interface IVoucherSaleForm {
  customer_name: string;
  email: string;
  voucherType: EVoucherType;
  phone_number: string;
}

const ModalVoucherSale = ({customer, handleChooseVoucher, data, onClose, ...props}: ModalVoucherSaleProps) => {

  const [form] = Form.useForm();

  const [voucherType, setVoucherType] = useState<EVoucherType>(EVoucherType.FOR_THEMSELVES);

  const onSubmit = (value: IVoucherSaleForm) => {

    const isGift = value.voucherType === EVoucherType.FOR_FRIEND;

    const voucher: IVoucherForSalesSelectedItem = {
      ...data,
      quantity: 1,
      is_gift: isGift,
      receiver_info: isGift ? {
        receiver_email: value?.email,
        receiver_phone: value?.phone_number,
        receiver_name: value?.customer_name
      } : undefined

    };

    handleChooseVoucher(voucher);
    onClose();
  };

  useEffect(() => {
    if(!customer) return;

    if(voucherType === EVoucherType.FOR_THEMSELVES) {
      form.setFieldsValue({
        customer_name: customer?.name,
        phone_number: customer?.phone_number,
        email: customer?.email
      });
      return;
    }

    form.setFieldsValue({
      customer_name: '',
      phone_number: '',
      email: ''
    });
  }, [voucherType, customer]);
  

  return (
    <Modal
      maskClosable={false}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      centered
      onCancel={onClose}
      forceRender {...props}>
        <Form
          layout='vertical'
          className='modal-form'  
          form={form}
          onFinish={onSubmit}
          initialValues={{
            voucherType: EVoucherType.FOR_THEMSELVES
          }}
        >
          <h2 className='modal-title'>{'Voucher Sale'}</h2>
          <Form.Item
            name='voucherType'
          >
            <Radio.Group onChange={(e) => setVoucherType(e.target.value)}>
              <Radio value={EVoucherType.FOR_THEMSELVES}>
                {'for themselves'}
              </Radio>
              <Radio value={EVoucherType.FOR_FRIEND}>
                {'buy for friend'}
              </Radio>
              
            </Radio.Group>
          </Form.Item>
          {voucherType === EVoucherType.FOR_FRIEND ? <>
          <FormRow
              label='Customer name'
              name='customer_name'
              rules={[
                {required: true}
              ]}
            >
              <Input placeholder='Enter your name' />
            </FormRow>
            <FormRow
              label='Email'
              name='email'
              rules={[
                {required: true} ,
                { type: 'email', message: 'is not a valid email!' }
              ]}
            >
              <Input type='email' placeholder='Enter your email' />
            </FormRow>
            <FormRow name='phone_number' label='Phone number'
              rules={[
                { required: true },
                NumberValidations.PHONE
              ]}
            >
              <Input type='tel' />
            </FormRow>
          </> : <>
            <FormRow
              label='Customer name'
              name='customer_name'
 
            >
              <Input disabled value={customer?.name} />
            </FormRow>
            <FormRow
              label='Email'
              name='email'
  
            >
              <Input type='email' disabled value={customer?.email} placeholder='Enter your email' />
            </FormRow>
            <FormRow name='phone_number' label='Phone number'
            >
              <Input type='tel' disabled value={customer?.phone_number} />
            </FormRow>
          </>}
          <div className='form-submit-wrapper'>
          <button
            data-testing='Close'
            onClick={onClose}
            type='button'
            className='common-btn is-white'
          >
            {'Close'}
          </button>
          <button
            data-testing='Confirm'
            // onClick={onSubmit}
            type='submit'
            className='common-btn'
          >
            {'Confirm'}
          </button>
        </div>
        </Form>
    </Modal>
  );
};

export default ModalVoucherSale;