import IconReward from 'assets/svg/IconReward';
import { IconAvatarWalkIn } from 'features/bookings/components/BookingForm/QuickBooking/CustomerDetail';
import checkoutSelectors from 'features/checkout/services/selectors';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { CUSTOMER_WALKIN_INDEX } from 'utils/unit';

const CustumerDetail = ({ step }: { step: number }) => {
    // const currCustomer = salesSelectors.getCustomer();
    const customerInfo = checkoutSelectors.getCustomerInfo();
    const total_point_program_will_earn = checkoutSelectors.getTotalPointWillEarn();
    const total_point_of_customer = customerInfo?.current_point;
    const selectedReward = checkoutSelectors.getSelectedReward();
    const point_left_to_earn_a_reward = customerInfo?.reward_info?.point_left_to_earn_a_reward ?? 0;
    const loyaltyPointCounter = checkoutSelectors.getLoyaltyPoint();
    const name = customerInfo?.name ?? '';
    const current_point = useMemo(() => {
        return customerInfo?.reward_info?.current_point ?? 0;
    }, [customerInfo]);

    const Rewards = () => {

        if (step === 3) {
            const totalPoint = (total_point_of_customer ?? 0) + (total_point_program_will_earn ?? 0);

            if (totalPoint != 0) {
                return <><IconReward /> <span>Have {totalPoint} loyalty point{totalPoint > 1 ? 's' : ''}</span></>;
            }
        }

        if (total_point_of_customer && total_point_of_customer > 0) {
            return <><IconReward /> <span>Have {total_point_of_customer} loyalty point{total_point_of_customer > 1 ? 's' : ''}</span></>;
        }

        if (total_point_program_will_earn && total_point_program_will_earn > 0) {
            return <><IconReward /> <span>Will earn {total_point_program_will_earn} loyalty points</span></>;
        }

        const totalLoyaltyAfterCheckout = () => {
            return (current_point ?? 0) - (selectedReward?.points_to_earn_reward ?? 0);
        };

        if (point_left_to_earn_a_reward && point_left_to_earn_a_reward > 0) {
            return (
                <div className='box-reward'>
                    <IconReward />
                    <span className='highlight-reward box'>{point_left_to_earn_a_reward} points left to earn a reward!</span>
                </div>
            );
        }

        if ((current_point ?? 0) > 0 && !customerInfo?.is_walkin_in && step < 3) {
            return (
                <>
                    <IconReward />
                    <span>Have {totalLoyaltyAfterCheckout()} loyalty points</span>
                </>
            );
        }

        if (step === 3 && loyaltyPointCounter > 0) {
            return <><IconReward /> <span>Earned {loyaltyPointCounter} loyalty points</span></>;
        }

        return null;
    };

    return (
        <>
            <CustumerDetailStyled>
                <div className="form-row">
                    <p className="form-label">Customer Details</p>
                </div>
            </CustumerDetailStyled>
            <div className='current-customer'>
                {Number(customerInfo?.is_walkin_in ?? 0) !== CUSTOMER_WALKIN_INDEX ? <p className='customer-avatar common-avatar'>
                    {name && Array.from(name)[0]}
                </p> : <IconAvatarWalkIn />}
                <div className='customer-detail'>
                <p style={{ textTransform: 'capitalize', ...(Number(customerInfo?.is_walkin_in) ?? 0) !== CUSTOMER_WALKIN_INDEX ? {} : { cursor: 'pointer' } }}
                        className={`detail-name ${(Number(customerInfo?.is_walkin_in) ?? 0) !== CUSTOMER_WALKIN_INDEX ? 'customer-link' : 'customer-link-disable'}`}>{name}</p>
                    {Number(customerInfo?.is_walkin_in ?? 0) !== CUSTOMER_WALKIN_INDEX ?
                        <>
                            <p>Customer ID: <span>{customerInfo?.customer_code ?? ''}</span></p>
                            <div className='reward'> <Rewards /></div>
                        </> : <></>}
                </div>
            </div>
        </>
    );
};

export default CustumerDetail;

const CustumerDetailStyled = styled.div`
    .form-row {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        .form-label {
            font-weight: 600;
            font-size: 18px;
            color: '#363565';
        }
    }
    .customer-link-disable {
        pointer-events: none;
    }
`;