import fetch from 'services/request';

const getListNotification = (params?: any) => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/notification/list-notification',
    params: { ...params, per_page: 10 },
  });
};

const getCheckViewNotification = (notification_id: string | number) => {
  return fetch({
    method: 'get',
    url: `api/v1/merchants/:merchant_code/notification/${notification_id}/check-viewed`,
    params: {viewed: 1} 
  });
};

const deleteNotification = () => {
  return fetch({
    method: 'delete',
    url: 'api/v1/merchants/:merchant_code/notification/delete'
  });
};

const apisNotification = {
  getListNotification,
  getCheckViewNotification,
  deleteNotification
};

export default apisNotification;
