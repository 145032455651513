import { Form, Modal } from 'antd';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import CardIcon from 'assets/images/setting/card-icon.png';
import RefreshIcon from 'assets/images/setting/refresh-icon.png';
import TopUpKind from './components/TopUpKind';
import { TopUpStatus, TopUpType } from 'features/settings/services/types/topUp';
import TopUpForm from './components/TopUpForm';
import TopUpChargeStatus from './components/TopUpChargeStatus';
import { useTranslation } from 'react-i18next';
import { TOPUP_OPTION } from 'features/settings/services/constants';
import { Stripe } from '@stripe/stripe-js';
import { PaymentMethodInputRef } from './components/PaymentMethodInput';
import { CancelType } from './SetupTopUpCard';
import { useDispatch } from 'react-redux';
import settingActions from 'features/settings/services/actions';

export interface ITopUpChargeItem {
  topUp: number;
  autoChargeWhen?: number;
  notifyYourWhen?: number;
  cardHolderFullName: string;
  cardNumber: number;
  expiryDate: string;
  cvv: number;
  abnNumber: number;
}

interface TopUpModalProps {
  isModalOpen?: boolean;
  handleCancelWithStep?: (CancelType: CancelType) => void;
  handleSubmit?: (values: ITopUpChargeItem) => Promise<TopUpStatus>;
  handleChangePopUpType: (type: TopUpType) => void;
  topUpType: TopUpType | '';
  progressNumber: number;
  handlePaymentStep: () => void;
  loading?: boolean;
  stripe: Stripe | null;
}

const TOPUP_TYPE = [
  {
    description: 'Configure your automatic top up settings',
    text: 'Automatic top-ups',
    icon: RefreshIcon,
    returnValue: TopUpType.AUTOMATIC,
  },
  {
    description: 'Manually top up your balance by a fixed amount',
    text: 'Manually charge',
    icon: CardIcon,
    returnValue: TopUpType.MANUALLY,
  },
];

/* eslint-disable-next-line */
const TopUpModal = forwardRef<PaymentMethodInputRef, TopUpModalProps>(
  (
    {
      isModalOpen,
      handleCancelWithStep,
      handleSubmit,
      handleChangePopUpType,
      topUpType,
      progressNumber,
      handlePaymentStep,
      loading,
      stripe,
    },
    ref
  ) => {
    const [topUpStatus, setTopUpStatus] = useState<{
      visible: boolean;
      status: TopUpStatus;
    }>({ visible: false, status: TopUpStatus.FAILED });

    const { t: topUpLang } = useTranslation('top_up');
    const [form] = Form.useForm();
    const paymentFormRef = useRef<any>();
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
      submitStripeAndElement: () =>
        paymentFormRef?.current?.submitStripeAndElement(),
    }));

    const checkValid = async () => {
      try {
        await form.validateFields();
        return true;
      } catch (error) {
        return false;
      }
    };

    const handleActionBtn = async () => {
      if (
        progressNumber === 2 &&
        topUpStatus?.visible &&
        topUpStatus?.status === TopUpStatus.FAILED
      ) {
        setTopUpStatus({ visible: false, status: TopUpStatus.FAILED });
        return handlePaymentStep?.();
      }

      if (progressNumber === 1) {
        const valid = await checkValid();
        if (!valid) return;
        form?.submit();
      } else {
        handlePaymentStep?.();
      }
    };

    const submitForm = async (data: ITopUpChargeItem) => {
      const result = await handleSubmit?.(data);

      if (result === TopUpStatus.SUCCESS) {
        return setTopUpStatus({
          status: TopUpStatus.SUCCESS,
          visible: true,
        });
      }

      setTopUpStatus({
        status: TopUpStatus.FAILED,
        visible: true,
      });
    };

    const handleCancel = () => {
      if (progressNumber === 1) {
        handleCancelWithStep?.(CancelType.BACK);
      } else if (
        progressNumber === 2 &&
        topUpStatus.status === TopUpStatus.SUCCESS &&
        topUpStatus.visible
      ) {
        handleCancelWithStep?.(CancelType.CLOSE);
        dispatch(settingActions.getTopUpInitData.fetch({page: 1, per_page: 10}));
      } else {
        handleCancelWithStep?.(CancelType.CLOSE);
      }
    };

    return (
      <Modal
        maskClosable={false}
        open={isModalOpen}
        onCancel={() => {
          if (
            progressNumber === 2 &&
            topUpStatus.status === TopUpStatus.SUCCESS &&
            topUpStatus.visible
          ) {
            dispatch(settingActions.getTopUpInitData.fetch({page: 1, per_page: 10}));
          }
          handleCancelWithStep?.(CancelType.CLOSE);
        }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={null}
        forceRender
        centered
        className='top-up-modal'
      >
        <TopUpFormStyled className='form-wrap'>
          <div className='form-content'>
            <h2 className='modal-title'>Top up</h2>
            {topUpStatus?.visible &&
            topUpStatus?.status === TopUpStatus.FAILED ? null : (
              <div
                className='top-up-progress'
                style={{ width: ((progressNumber + 1) / 3) * 100 + '%' }}
              />
            )}

            <div className='form-topup'>
              {progressNumber === 0
                ? TOPUP_TYPE?.map((item) => {
                    return (
                      <TopUpKind
                        key={item?.returnValue}
                        description={item.description}
                        text={item.text}
                        icon={item.icon}
                        isActive={topUpType === item.returnValue}
                        handleClick={() => {
                          if (topUpType !== item.returnValue)
                            handleChangePopUpType(item.returnValue);
                        }}
                      />
                    );
                  })
                : null}

              {progressNumber === 1 && topUpType ? (
                <Form
                  form={form}
                  layout='vertical'
                  onFinish={submitForm}
                  initialValues={{ topUp: TOPUP_OPTION?.[0]?.value }}
                >
                  <TopUpForm
                    topUpType={topUpType}
                    loading={loading}
                    stripe={stripe}
                    ref={paymentFormRef}
                  />
                </Form>
              ) : null}

              {progressNumber === 2 ? (
                <TopUpChargeStatus
                  type={topUpStatus.status}
                  amount={form.getFieldValue('topUp')}
                  autoChargeWhen={form.getFieldValue('autoChargeWhen')}
                  visible={topUpStatus.visible}
                  topUpType={topUpType}
                />
              ) : null}
            </div>
          </div>

          <div className='form-submit-wrapper'>
            <div
              onClick={handleCancel}
              className='common-btn is-white topup-btn'
            >
              {topUpLang(
                progressNumber === 0
                  ? 'topUpForm.formAction.cancel'
                  : progressNumber === 1
                  ? 'topUpForm.formAction.back'
                  : 'topUpForm.formAction.close'
              )}
            </div>

            {progressNumber < 2 ||
            (progressNumber === 2 &&
              topUpStatus?.visible &&
              topUpStatus?.status === TopUpStatus.FAILED) ? (
              <button
                type='submit'
                className={
                  'common-btn topup-btn ' +
                  (progressNumber === 0 && !topUpType?.length
                    ? 'is-disabled'
                    : '')
                }
                onClick={handleActionBtn}
                disabled={progressNumber === 0 && !topUpType?.length}
              >
                {topUpLang(
                  progressNumber === 0
                    ? 'topUpForm.formAction.continue'
                    : progressNumber === 2 &&
                      topUpStatus?.visible &&
                      topUpStatus?.status === TopUpStatus.FAILED
                    ? 'topUpForm.formAction.tryAgain'
                    : 'topUpForm.formAction.pay'
                )}
              </button>
            ) : null}
          </div>
        </TopUpFormStyled>
      </Modal>
    );
  }
);

const TopUpFormStyled = styled.div`
  &.form-wrap {
    justify-content: space-between;
  }

  @media (min-width: 1181px) {
    .form-submit-wrapper {
      padding-bottom: 36px;
    }
  }

  @media (max-width: 1180px) {
    .form-submit-wrapper {
      padding-bottom: 14px;
    }
  }

  @media only screen and (max-width: 767.98px) {
    .topup-btn {
      flex: unset;
    }
  }

  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;

  .modal-title {
    margin-bottom: 24px;
    border-bottom: none;
    text-align: left;
  }

  .top-up-progress {
    border-radius: 8px 0px 0px 8px;
    background: #4a9d77;
    width: 100%;
    height: 4px;
  }

  .common-btn {
    @media (max-width: 600px) {
      min-width: 30%;
    }
  }

  .is-disabled {
    background: rgba(74, 157, 119, 0.5);
    border: none;
  }

  .is-disabled:hover {
    color: #fff;
    cursor: unset;
  }

  @media (min-width: 1181px) {
    .form-topup {
      padding-left: 100px;
      padding-right: 100px;
    }
  }
`;

export default TopUpModal;
