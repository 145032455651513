import { Form } from 'antd';
import dayjs from 'dayjs';
import ActiveHoursElement from 'features/locations/pages/widgets/ActiveHoursElement';
import userActions from 'features/users/services/actions';
import userSelectors from 'features/users/services/selectors';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

type Props = {
  onCancel?: () => void;
  onNextStep?: () => void;
  onPrevStep?: () => void;
  onSubmit?: () => void;
  isEdit?: boolean;
};

export const dayOfWeek = [
  {
    label: 'SUN',
    value: 'sunday',
  },
  {
    label: 'MON',
    value: 'monday',
  },
  {
    label: 'TUE',
    value: 'tuesday',
  },
  {
    label: 'WED',
    value: 'wednesday',
  },
  {
    label: 'THU',
    value: 'thursday',
  },
  {
    label: 'FRI',
    value: 'friday',
  },
  {
    label: 'SAT',
    value: 'saturday',
  },
];

export enum WorkTimeStatus {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE'
}

const UserFormFouthStep = ({
  onPrevStep = () => undefined,
  onSubmit,
  isEdit
}: Props) => {
  const form = Form.useFormInstance();
  const dispatch = useDispatch();

  const locationId = userSelectors.getLocationSelectedId();
  const locationDetail = userSelectors.getLocationDetail();

  useEffect(() => {
    if (locationId) {
      dispatch(userActions.getLocationDetail.fetch(locationId));
    }
  }, [locationId]);

  const locationOpenDate = useMemo(() => {
    if (locationDetail){
      const weekDateWorkingTime = locationDetail?.weekday_working_times;
      
      const formattedData: { [weekday: string]: { time_start: string, time_end: string }[] } = {};

      weekDateWorkingTime?.forEach((item: any) => {
        const weekday = item?.weekday;
        const time_start = item?.time_start;
        const time_end = item?.time_end;
        if (!formattedData[weekday]) {
          formattedData[weekday] = [];
        }
        formattedData[weekday].push({ time_start, time_end });
      });

      const formattedResult = Object.keys(formattedData).map(weekday => ({
        weekday,
        time: formattedData[weekday]
      }));

      const workingTimeData = dayOfWeek?.map((item) => {
        const foundItem = formattedResult?.find((it: any) => it?.weekday === item?.value);
        return {
          ...item,
          timeWorking: foundItem?.time || [],
          status: foundItem ? WorkTimeStatus.AVAILABLE : WorkTimeStatus.UNAVAILABLE
        };
      });

      if (!isEdit) {
        form.setFieldValue(
          'workingTime',
          workingTimeData.reduce(
            (obj, item) =>
              Object.assign(obj, {
                [item.value]: item?.timeWorking?.map((it) => {
                  return {
                    time_start: dayjs(it?.time_start, 'H:m:s'),
                    time_end: dayjs(it?.time_end, 'H:m:s'),
                    weekday: item.value,
                    status: item?.status,
                  };
                }),
              }),
            {}
          )
        );
      }
      return workingTimeData;
    }
    return [];
  }, [locationDetail]);


  return (
    <>
      <ActiveHoursStyled className='form-calendar'>
        <Form.Item name='calenda'>
          {locationOpenDate?.map((item: any, index: number) => (
            <ActiveHoursElement
              name={item.value}
              key={index}
              label={item.label}
              form={form}
              timeWorking={item?.timeWorking}
              status={item?.status}
              addUserPage={true}
            />
          ))}
        </Form.Item>
      </ActiveHoursStyled>
      <div className='form-submit-wrapper'>
        <button
          data-testing='Back'
          className='common-btn is-white'
          type='button'
          onClick={onPrevStep}
        >
          {'Back'}
        </button>
        <button data-testing='Save' className='common-btn' type={'button'} onClick={onSubmit}>
          {'Save'}
        </button>
      </div>
    </>
  );
};

export default UserFormFouthStep;

const ActiveHoursStyled = styled.div`
  .calendar-row {
    padding: 8px 18px !important;
  }
  .ant-form-item-control {
    margin-bottom: 0px !important;
  }
`;
