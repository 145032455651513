import checkoutSelectors from 'features/checkout/services/selectors';
import settingSelectors from 'features/settings/services/selectors';
import { formatMoney } from 'utils/unit';

const PromotionRow = () => {
  const result = checkoutSelectors.getPromotionValue();
  const setting = settingSelectors.getSetting();
  const getOriginTotalPriceExceptPromotion = checkoutSelectors.getOriginTotalPriceExceptPromotion();


  if (!setting?.booking?.both_promotion_types_allow) {
    const promotionValue = result.promotionManualValue > result.promotionAutoValue ? result.promotionManualValue : result.promotionAutoValue;
    return (
    <>
      {
      promotionValue ? <p className='extra-price-row'>
        Promotion
        <span>- {formatMoney(promotionValue ?? 0)}</span>
      </p> : <></>
      }
    </>
    );
  }
  

  if (!result) return null;

  return (
    <>
      {result.promotionManualValue > 0 && <p className='extra-price-row'>
        Promotion
        <span>- {formatMoney(result.promotionManualValue ?? 0)}</span>
      </p>}
      {result.promotionAutoValue > 0 && getOriginTotalPriceExceptPromotion > 0 && <p className='extra-price-row'>
        Promotion (Auto)
        <span>- {formatMoney(result.promotionAutoValue ?? 0)}</span>
      </p>}
    </>
  );
};

export default PromotionRow;
