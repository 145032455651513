import { Form, Input, InputRef, Rate, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { MouseEventHandler, Ref, useEffect, useRef, useState } from 'react';
import styles from '../index.module.scss';
import reviewSelectors from '../services/selectors';
// import moment from 'moment';
import icon_send from 'assets/icons/ico_send_green.svg';
import styled from 'styled-components';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import reviewApis from '../services/apis';
import { get } from 'lodash';
import message from 'components/common/Message';
import { useDispatch } from 'react-redux';
import reviewActions from '../services/actions';
import { getFormatShortDate } from 'utils/moment/getSingleDateShortFormat';
type Props = {
  handleCancel?: MouseEventHandler<HTMLDivElement>;
};

const ReviewsDetail = ({ handleCancel = () => undefined }: Props) => {
  const refInput: Ref<InputRef> = useRef(null);
  const handleSubmit = () => undefined;
  const [replyMsg, setReplyMsg] = useState('');
  const reviewDetail = reviewSelectors.getDetailReview();
  const params = reviewSelectors.getReviewParam();
  const loadDetailReview = reviewSelectors.getLoadingDetailReview();
  const setPageLoading = useSetLoadingPage();
  const dispatch = useDispatch();

  useEffect(() => {
    setReplyMsg(reviewDetail.reply_content);
  }, [reviewDetail]);

  const handleReply = async () => {
    if (!replyMsg) return;
    setPageLoading(true);
    try {
      const res = await reviewApis.replyCustomer(reviewDetail.id, replyMsg);
      const msg = get(res, 'data.message');
      if (msg) {
        message.success(msg);
        dispatch(reviewActions.getDetailReview.fetch(reviewDetail.id.toString()));
        dispatch(reviewActions.getListReview.fetch(params));

      } else {
        const errorMsg = get(res, 'data.error.message');
        if (errorMsg) {
          message.error(errorMsg);
          return false;
        } else throw 'fail';
      }
    } catch (error: any) {
      const errors = Object.values(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map((valueArr) => {
          const msg = get(valueArr, [0], '');
          message.error(msg);
        });
        return false;
      }
      message.error('An error occurred. Please try again');
      return false;
    } finally {
      setTimeout(() => {
        refInput.current?.blur();
      }, 1);
      setPageLoading(false);
    }
  };

  const myFocus = useRef<any>();

  useEffect(() => {
    myFocus?.current?.input?.focus();
  }, []);

  return (
    <ReviewsDetailStyled>
      <Spin spinning={loadDetailReview}>

        <h2 className='modal-title'>
          <p>Review Details</p>
        </h2>
        <Form
          name='basic'
          className='modal-form'
          onFinish={handleSubmit}
          autoComplete='off'
          layout='vertical'
        >
          <div className='form-inner is-primary'>
            <div className='form-block flex-col'>
              <div>
                <div className='form-row is-wrap'>
                  <p className='form-label is-big'>Customer information</p>
                </div>
                <table className={styles.detail_table}>
                  <tr>
                    <th>Name</th>
                    <td>{reviewDetail?.customer?.name}</td>
                  </tr>
                  <tr>
                    <th>Client ID</th>
                    <td>{reviewDetail?.customer?.code}</td>
                  </tr>
                  <tr>
                    <th>Phone number</th>
                    <td>{reviewDetail?.customer?.phone_number}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{reviewDetail?.customer?.email}</td>
                  </tr>
                  <tr>
                    <th>Check-in Date</th>
                    {/* <td>{getSingleDateShortFormat(moment(reviewDetail?.invoice?.date_book))}</td> */}
                    <td>{getFormatShortDate(reviewDetail?.invoice?.date_book)}</td>
                  </tr>
                  <tr>
                    <th>Item(s)</th>
                    <td>{reviewDetail?.invoice?.amount_service ? reviewDetail?.invoice?.amount_service + ' service(s)' : ''}{reviewDetail.invoice?.amount_voucher ? `, ${reviewDetail.invoice?.amount_voucher} voucher(s)` : ''}</td>
                  </tr>
                  <tr>
                    <th>Payment</th>
                    <td>{reviewDetail?.invoice?.payment_methods}</td>
                  </tr>
                </table>
              </div>
              <div>
                <div className='form-row is-wrap'>
                  <p className='form-label is-big'>Review Information</p>
                </div>
                <table className={styles.detail_table}>
                  <tr>
                    <th>Invoice No</th>
                    <td>{reviewDetail?.invoice?.invoice_no}</td>
                  </tr>
                  <tr>
                    <th>Rating</th>
                    <td>
                      <div>
                        <Rate value={reviewDetail?.rating} disabled={true} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Review Date</th>
                    <td>{getFormatShortDate(reviewDetail?.review_date?.toString())}</td>
                  </tr>
                  <tr>
                    <th>Location</th>
                    <td>{reviewDetail?.location_name}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div className='form-block'>
              <div>
                <div className='form-row'>
                  <p className='form-label is-big'>Customer Review</p>
                </div>
                <TextArea
                  className='is-white'
                  placeholder='This is a review’s description'
                  rows={4}
                  disabled
                  value={reviewDetail?.content}
                />
                <ImageListStyled className={styles.list_images}>
                  {reviewDetail?.images?.map((s, index) => (
                    <li key={index}>
                      <img src={s} alt={s}></img>
                    </li>
                  ))}
                </ImageListStyled>
              </div>
              <div style={{ marginTop: '24px' }}>
                <div className='form-row'>
                  <p className='form-label is-big'>{reviewDetail.replier} reply</p>
                </div>
                <Input
                  disabled={!!reviewDetail.reply_content}
                  ref={refInput}
                  multiple
                  autoFocus
                  name='reply'
                  value={replyMsg}
                  onChange={(e) => setReplyMsg(e.target.value)}
                  placeholder='Type your reply'
                  suffix={reviewDetail.reply_content ? undefined : <button className='btn-reply' type='button' onClick={handleReply}><img src={icon_send} /></button>}
                />
                {!reviewDetail.reply_content && <p className='form-note'>You have 350 characters remaining. </p>}
              </div>
            </div>
          </div>
          <div className='form-submit-wrapper'>
            <div data-testing='Cancel' onClick={handleCancel} className='common-btn is-white'>
              Cancel
            </div>
            <button data-testing='Save' type='submit' className='common-btn'>
              Save
            </button>
          </div>
        </Form>
      </Spin>
    </ReviewsDetailStyled>
  );
};

export default ReviewsDetail;

const ImageListStyled = styled.div`
  li {
    width: 80px;
    height: 80px;
    border-radius: 12px;
    img {
      height: 100%;
      border-radius: 12px;

    }
  }
`;

const ReviewsDetailStyled = styled.div`
  .btn-reply {
    border-radius: 6px;
    background: #4A9D77;
    display: flex;
    width: 42px;
    height: 42px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
`;
