import { Form, Input, InputNumber } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ICardType } from 'features/checkout/components/CardPayment';
import settingSelectors from 'features/settings/services/selectors';
import { EResultTransactionType, ITransactionCompleteCallbackResponse } from 'hooks/useIClientTyro';
import { useState } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useHandleSubmit } from '../hooks/useHandleSubmit';
import invoiceSelectors from 'features/invoices/services/selectors';
import { roundNumber, getFormatInputCurrency } from 'utils/unit';
import { useDispatch } from 'react-redux';
import invoiceActions from 'features/invoices/services/actions';
import { EPaymentMethod } from 'features/sales/services/constants';
import bookingActions from 'features/bookings/services/actions';
import useIClientTyroOptimize from 'hooks/useClientTyroOptimize';

interface CardPaymentProps {
  onCancel: () => void
  onBack: () => void
  total: number;
}

const CardPayment = ({ onCancel, onBack, total }: CardPaymentProps) => {

  const [form] = useForm();

  const [, setTypeCardModalStatus] = useState<ICardType>(null);
  const setting = settingSelectors.getSetting();
  const setLoadingPage = useSetLoadingPage();


  const iClient = useIClientTyroOptimize();

  // const doSoftRedeem = useDoRedeem(detail?.merchant_location_id || '', {
  //   servicesSelected: selected,
  //   setErrorBoundary: (val) => dispatch(checkoutActions.setPromotionErrorBoundary(val)),
  // });

  const dispatch = useDispatch();
  const invoiceDetail = invoiceSelectors?.detailInvoice;
  const invoiceNo = invoiceSelectors?.detailInvoice?.getInvoiceNo();


  const transactionCompleteCallback = () => async (response: ITransactionCompleteCallbackResponse) => {
    setTypeCardModalStatus(null);

    switch (response.result) {
      case EResultTransactionType.APPROVED: {
        setLoadingPage(true);
        useHandleSubmit(response.baseAmount, invoiceNo ?? '', EPaymentMethod.CARD);
        dispatch(invoiceActions.getInvoiceDetail.fetch(invoiceNo?.replace('#', '') ?? ''));
        onCancel();

        return;
      }
      case EResultTransactionType.CANCELLED:
      case EResultTransactionType.REVERSED:
      case EResultTransactionType.DECLINED: {
        setTypeCardModalStatus('wrongInfo');
        return;
      }
      case EResultTransactionType.SYSTEM_ERROR: {
        setTypeCardModalStatus('systemError');
        return;
      }
      default: {
        setTypeCardModalStatus('notPair');
        return;
      }
    }
  };


  const handleSubmit = async (values: any) => {
    if (setting.general.merchant_id === '' || setting.general.teminal_id === '') {
      setLoadingPage(true);
      useHandleSubmit(values, invoiceNo ?? '', EPaymentMethod.CARD);
      setLoadingPage(false);
      dispatch(invoiceActions.getInvoiceDetail.fetch(invoiceNo?.replace('#', '') ?? ''));
      dispatch(bookingActions.getBookings.fetch({}));
      onCancel();
      return;
    }

    setTypeCardModalStatus('process');
    iClient.purchase(roundNumber(values.amount), { transactionCompleteCallback: transactionCompleteCallback() });
  };

  return (
    <>
      <h2 className='modal-title'>
        <p> Refund card payment</p>
      </h2>
      <Form
        form={form}
        name='basic'
        onFinish={handleSubmit}
        autoComplete='off'
        layout='vertical'
      >
        <div className='modal-body'>
          <Form.Item initialValue={total === 0 ? invoiceDetail?.getData()?.total_price : total} name={'amount'} label={'Amount given'} rules={[{ required: true }]}>
            <InputNumber
              precision={getFormatInputCurrency().precision}
              // formatter={(value: any) => {
              //   if (value === undefined) {
              //     return '';
              //   }
              //   return parseFloat(value)?.toFixed(2);
              // }}
              addonBefore={getFormatInputCurrency().addonBefore} min={1} max={invoiceDetail?.getData()?.total_price ?? 2} />
          </Form.Item>
          <Form.Item name={'note'} label={'Reason for refund'}>
            <Input placeholder='e.g why do customers return their purchases' />
          </Form.Item>
        </div>
        <div className='mixmethod-footer'>
          <div
            className='common-btn is-white'
            onClick={onBack}
          >
            Back
          </div>
          <button type='submit' className='common-btn'> Refund</button>
        </div>
      </Form>
    </>
  );
};

export default CardPayment;