import MultipleSelect from 'components/common/MultipleSelect';
import bookingActions from 'features/bookings/services/actions';
import bookingSelectors from 'features/bookings/services/selectors';
import { IApiBookingParams } from 'features/bookings/services/types/booking';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import apisBooking from 'features/bookings/services/apis';

const TeamMemberSelect = ({isCalendar = false}: {isCalendar?: boolean}) => {
  const dispatch = useAppDispatch();
  const teamMembers = bookingSelectors.getTeamMembers();
  const value = bookingSelectors.getCalendarParamValue('employee_id') as IApiBookingParams['employee_id'];
  const locationCurrent = bookingSelectors.getCalendarCurrentLocation()?.id;
  const locationParam = bookingSelectors.getCalendarParamValue('merchant_location_id');
  // const merchant_location_id = bookingSelectors.getCalendarCurrentLocation()?.id ?? bookingSelectors.getCalendarParamValue('merchant_location_id') as IApiBookingParams['merchant_location_id'];  
  // const [options, setOptions] = useState<any[]>([]);


  const options = useMemo(() => {
    const results = (teamMembers ?? [])?.filter(o => o.merchant_location_id?.toString() === (locationCurrent ?? locationParam)?.toString())?.map(item => {
      return ({
        value: item.id,
        title: item.full_name,
      });
    });
    if (results?.length < 1) {
      return [];
    } else {
      return results;
    }
  } , [locationCurrent]);


  const [allValue, setAllValue] = useState([] as (string | number)[]);

  // if(options?.length < 1) return null;

  useEffect(() => {
    if (options?.length > 0 ) {
      setAllValue(options.map(o => o.value));
      dispatch(bookingActions.setCalendarBookingParams({
        employee_id: options.map(o => o.value),
      }));
      onChange(options.map(o => o.value));
    } else {
      setAllValue([]);
    }
  }, [options]);

  // useEffect(() => {
  //   if(!value || !allValue) return;
  //   if ((value ?? [])?.length > (allValue ?? [])?.length) {
  //     dispatch(bookingActions.setCalendarBookingParams({
  //       employee_id: allValue,
  //     }));
  //   }
  // }, [value]);

  const onChange = async (val: (string | number)[]) => {
    dispatch(bookingActions.setCalendarBookingParams({
      employee_id: val,
    }));

    isCalendar ? await apisBooking.storeParamOptionCalendar({action_behavior: 'employee_filter', action_behavior_value: val}) : undefined;
  };

  return (
    <MultipleSelect
      onChange={onChange}
      disabled={options?.length < 1}
      value={options?.length < 1 ? ['No employee'] : (value === undefined ? allValue : value)}
      options={options}
      isWhiteInput
      maxTagPlaceholder={'employees'}
      placeholder={'Select employees'}
      showArrow={true}
    />
  );
};

export default TeamMemberSelect;
