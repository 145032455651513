import { TIME_START_FORMAT_RESPONSE } from 'features/bookings/services/constants';
import moment from 'moment';


/**
 * useRangeDate
 * @param unit string
 * @param amount number
 * @returns [dateStart: number, dateEnd: number]
 */
export const useRangeDate = ( unit: string, amount: number) => {
  const dateStart = unit === 'days' ? moment().unix() : moment().subtract(amount, unit as moment.unitOfTime.DurationConstructor).startOf(unit as moment.unitOfTime.DurationConstructor).unix();
  const dateEnd = unit === 'day' ? dateStart : moment(moment()).subtract(amount, unit as moment.unitOfTime.DurationConstructor).endOf(unit as moment.unitOfTime.DurationConstructor).unix();
  
  if(unit === 'days')
    return [dateEnd, dateStart];

  return [dateStart, dateEnd];
};

/**
 * useRangeDateTime
 * @param unit string
 * @param amount number
 * @returns [dateStart: string, dateEnd: string]
 */
export const useRangeDateTime = ( unit: string, amount: number ) => {

  const formatTime = TIME_START_FORMAT_RESPONSE;

  const dateStart = unit === 'days' ? moment().format(formatTime) : moment().subtract(amount, unit as moment.unitOfTime.DurationConstructor).startOf(unit as moment.unitOfTime.DurationConstructor).format(formatTime);
  const dateEnd = moment(moment()).subtract(amount, unit as moment.unitOfTime.DurationConstructor).endOf(unit as moment.unitOfTime.DurationConstructor).format(formatTime);
  
  if(unit === 'days')
    return [dateEnd, dateStart];

  return [dateStart, dateEnd];
};