import { Popover } from 'antd';
import IconHelpCircle from 'assets/svg/IconHelpCircle';
import salesSelectors from 'features/sales/services/selectors';
import settingSelectors from 'features/settings/services/selectors';
import styled from 'styled-components';
import { formatMoney } from 'utils/unit';

const HolidaySurchargeResultRow = () => {

  const getHolidaySurchargeValue = salesSelectors.getHolidayValue();
  const getCardSurchargeValue = salesSelectors.getCardSurcharge();
  const publicHoliday = settingSelectors.getHolidayInfoToday();

  const ContentNotification = () => (
    <PopoverStyled>
      <span>This is applicable only to the sale of services, excluding vouchers.</span>
    </PopoverStyled>
  );

  return (
    <HolidayChargeStyled>
      <div>
        {getHolidaySurchargeValue > 0 && <div className='extra-price-row'>
          <div className='label'>
            Public Holiday charge ({publicHoliday.is_each_service ? 'Each service' : 'Total booking'})
            <Popover placement="top" title={ContentNotification}>
              <button><IconHelpCircle /></button>
            </Popover>
          </div>
          <span> +{formatMoney(getHolidaySurchargeValue)}</span>
        </div>}
        {getCardSurchargeValue > 0 && <div className='extra-price-row'>
          <div className='label'>
            Card surcharge
            <Popover placement="top" title={ContentNotification}>
              <button><IconHelpCircle /></button>
            </Popover>
          </div>
          <span> +{formatMoney(getCardSurchargeValue)}</span>
        </div>}
      </div>
    </HolidayChargeStyled>
  );
};

export default HolidaySurchargeResultRow;
const HolidayChargeStyled = styled.div`
padding: 12px 0;
`;

const PopoverStyled = styled.div`
  padding: 12px 16px 8px 16px;
`;
