import { Form, Input, InputNumber, Select, Spin, Switch } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import SwitchInput from 'components/common/Input/SwitchInput';
import MultipleSelect, { IOptionMulti } from 'components/common/MultipleSelect';
import { debounce, get, set } from 'lodash';
import { MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import voucherSelectors from '../services/selectors';
import { IApiVoucherCreateBodyData } from '../services/types/api';
import styled from 'styled-components';
import vouchersApis from '../services/apis';
import settingSelectors from 'features/settings/services/selectors';
import { getFormatInputCurrency } from 'utils/unit';
// import NumberValidations from 'utils/validations/number';


export type IVoucherFormValue = {
  voucherCode: string;
  voucherName: string;
  value: number;
  retailPrice: number;
  validityPeriod: string;
  terms: string;
  restrictService: string[];
  enable?: boolean,
  redeemed?: number;
  remaining?: number;
  customerName?: string;
  expiryDate?: string;
  targetQuantity?: number;
  unlimited?: boolean;
};

export type VoucherFormProps = {
  isEdit: boolean;
  viewOnly?: boolean;
  onCancel?: () => void;
  onSubmit?: (payload: IApiVoucherCreateBodyData) => void;
  formData?: IVoucherFormValue;
  errors?: any;
  updateVoucherStatus?: (checked: boolean) => Promise<boolean>;
  loading?: boolean
};

const VoucherForm = ({ isEdit, errors = {}, viewOnly = false, formData, onCancel = () => undefined, onSubmit = () => undefined, updateVoucherStatus, loading }: VoucherFormProps) => {
  const { t: formLang } = useTranslation('form');
  const periodStore = voucherSelectors.getPeriodOpts();
  const setting = settingSelectors.getSetting();
  // const [, setIsUnlimited] = useState<boolean>(false);

  const periodOpts = useMemo(() => periodStore.map(o => ({
    value: o.key,
    label: o.value,
  })), [periodStore]);

  const [form] = Form.useForm();
  const [selected, setSelected] = useState<(string | number)[]>([]);
  const [valuePrice, setValuePrice] = useState<number>();
  const servicesStore = voucherSelectors.getServices();
  const [enableStatus, setEnable] = useState(false);
  const [, setLoadingVoucherCode] = useState(false);
  const params = voucherSelectors.getLstVoucherParams();




  

  const generateVoucherCode = debounce(async () => {
    if (isEdit) return;
    setLoadingVoucherCode(true);
    try {
      const res = await vouchersApis.generateVoucherCode();
      const voucher_code = get(res, 'data.data.voucher_code', '');
      if (voucher_code)
        form.setFieldValue('voucherCode', voucher_code);
    } catch (error) { } finally {
      setLoadingVoucherCode(false);
    }
  }, 100);

  useEffect(() => {
    if (isEdit) return;
    generateVoucherCode();
  }, []);

  const services = useMemo(() => {
    const result: IOptionMulti[] = [];
    servicesStore?.filter(name => name.enable_discount === 1 && name.enable_voucher_sale === 1)?.forEach((service) => {
      if (service?.service_variants?.length > 0) {
        service?.service_variants.forEach(serviceVariant => {
          result.push({
            value: service?.id + '-' + serviceVariant.id,
            title: service.name + ' - ' + serviceVariant.name,
            // value: serviceVariant.id,
            // title: serviceVariant.name,
          });
        });
      } else {
        result.push({
          value: service.id + '',
          title: service.name,
        });
      }
    });
    return result;
  }, [servicesStore]);


  // const services = useMemo(() => {
  //   const result: IOptionMulti[] = [];
  //   servicesStore?.filter(name => name.enable_discount === 1 && name.enable_voucher_sale === 1)?.forEach((service) => {
  //     result.push({
  //       value: service.id + '',
  //       title: service.name,
  //     });
  //   });
  //   return result;
  // }, [servicesStore]);

  const allServices = useMemo(() => {
    return services.map(o => o.value);
  }, [servicesStore]);

  useEffect(() => {
    if (isEdit) return;
    if (services.length > 0) {
      setSelected([...allServices]);
    }
  }, [services]);

  useEffect(() => {
    if (!isEdit) {
      const terms_conditions_voucher = setting?.booking?.terms_conditions;
      form.setFieldValue('terms', terms_conditions_voucher);
      return;
    }

    form.setFieldsValue(formData);
    setEnable(!!formData?.enable);
    setSelected(formData?.restrictService ?? []);
    setValuePrice(formData?.value || undefined);
  }, [formData]);

  useEffect(() => {
    form.setFieldValue('restrictService', selected);
  }, [selected]);

  const getPayload = (values: IVoucherFormValue) => {
    const result: IApiVoucherCreateBodyData = {
      name: values.voucherName,
      retail_price: values.retailPrice,
      validity_period_key: values.validityPeriod,
      value: values.value,
      services: [],
      is_enable: params.view === 'purchased' ? values.enable : undefined,
      status: !!values.enable,
      description: values.terms,
      voucher_code: values.voucherCode ?? '',
      target_quantity: values?.unlimited ? undefined : (values.targetQuantity ?? 1),
      unlimited: true
    };

    if (isEdit) {
      set(result, 'value_key', values.value);
    }

    const restrict_services: { id: number, service_variant_ids: number[] }[] = [];
    values.restrictService.forEach(o => {
      if (!o) return;
      const item = o?.split('-');
      const serviceId = get(item, 0, '');
      const serviceVariant = get(item, 1, '');
      const parent = restrict_services.find(o => o.id?.toString() === serviceId?.toString());
      if (!parent) {
        restrict_services.push({
          id: +serviceId,
          service_variant_ids: serviceVariant ? [+serviceVariant] : [],
        });
      } else {
        if (serviceVariant) parent.service_variant_ids.push(+serviceVariant);
      }
    });
    result.services = restrict_services;

    return result;
  };

  const handleSubmit = (values: IVoucherFormValue) => {
    const payload = getPayload(values);
    if (!payload) return;
    onSubmit(payload);
  };

  const getValid = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };

  const _submit: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    const isValid = getValid();
    if (!isValid) return;
    form.submit();
  };

  const errorsText = useMemo(() => ({
    voucherCode: get(errors, 'voucher_code', ''),
    voucherName: get(errors, 'name', ''),
    retailPrice: get(errors, 'retail_price', ''),
    validityPeriod: get(errors, 'validity_period_key', ''),
    value: get(errors, 'value', ''),
    restrictService: get(errors, 'services', ''),
    enable: get(errors, 'status', ''),
    terms: get(errors, 'description', ''),
  }), [errors]);

  const _updateVoucherStatus = async (checked: boolean) => {
    if (typeof updateVoucherStatus !== 'function') return;
    setEnable(checked);
    try {
      const accept = await updateVoucherStatus(checked);
      if (accept) setEnable(checked);
      else {
        setEnable(!!formData?.enable);
      }
    } catch (error) { }
  };

  const myFocus = useRef<any>();

  useEffect(() => {
    myFocus?.current?.input?.focus();
  }, []);

  return (
    <VoucherFormStyled>
      <Spin spinning={services.length === 0 || !!loading}>
        <Form
          className='modal-form'
          autoComplete='off'
          form={form}
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={{
            enable: formData?.enable ?? true,
            validityPeriod: 'years_3',
            targetQuantity: 1, errorsText
          }}
        >
          {/* <Spin spinning={loadingVoucherCode}>
          <div className='form-row'>
            <Form.Item label='Voucher Code' name='voucherCode'
              rules={[{ required: true }]}
              validateStatus={errorsText?.voucherCode ? 'error' : undefined}
              help={errorsText?.voucherCode ? errorsText?.voucherCode : undefined}
            >
              <Input placeholder='Enter voucher code' disabled={viewOnly} />
            </Form.Item>
          </div>
        </Spin> */}
          <div className='form-row'>
            <Form.Item label='Voucher Name' name='voucherName'
              rules={[{ required: true }]}
              validateStatus={errorsText?.voucherName ? 'error' : undefined}
              help={errorsText?.voucherName ? errorsText?.voucherName : undefined}
            >
              <Input ref={myFocus} placeholder='Enter voucher name' disabled={viewOnly} />
            </Form.Item>
          </div>
          {!viewOnly &&
            <>
              <div className='form-row'>
                <Form.Item label='Value' name='value' rules={[
                  {
                    validator(_, valuePrice,) {
                      if (valuePrice === null || valuePrice === undefined) return Promise.reject(new Error('Please enter Value'));
                      if (valuePrice < 1) {
                        return Promise.reject(new Error('Value must be less than or equal to 1'));
                      }
                      return Promise.resolve();
                    },
                  }]}
                  validateStatus={errorsText?.value ? 'error' : undefined}
                  help={errorsText?.value ? errorsText?.value : undefined}
                >
                  <InputNumber disabled={viewOnly} onChange={e => setValuePrice(e as number)} addonBefore={getFormatInputCurrency().addonBefore} placeholder={formLang('AmountPlaceholder') || ''} />
                </Form.Item>
                <Form.Item label='Retail Price' name='retailPrice' rules={[
                  {
                    validator(_, priceRetail,) {
                      if (priceRetail === null || priceRetail === undefined) return Promise.reject(new Error('Please enter Retail Price'));
                      if (priceRetail < 1) {
                        return Promise.reject(new Error('Retail Price must be less than or equal to 1'));
                      }
                      if (priceRetail > (valuePrice ?? 0)) {
                        return Promise.reject(new Error('Retail Price cannot be greater than Value'));
                      }
                      return Promise.resolve();
                    },
                  }]}
                  validateStatus={errorsText?.retailPrice ? 'error' : undefined}
                  help={errorsText?.retailPrice ? errorsText?.retailPrice : undefined}
                >
                  <InputNumber disabled={viewOnly} addonBefore={getFormatInputCurrency().addonBefore} placeholder={formLang('AmountPlaceholder') || ''} />
                </Form.Item>
              </div>

              {/* <div className='form-row is-wrap'>
              <Form.Item label='Quantity' name='targetQuantity' rules={[
                NumberValidations.ONLY_NUMBER,
                { required: true },
              ]}
                validateStatus={errorsText?.validityPeriod ? 'error' : undefined}
                help={errorsText?.validityPeriod ? errorsText?.validityPeriod : undefined}
              >
                <InputNumber disabled={isUnlimited} placeholder='Enter voucher quantity' style={{ width: '100%' }} onChange={() => {
                  form.setFieldValue('unlimited', false);
                  setIsUnlimited(false);
                }} />
              </Form.Item>
            </div> */}
              {/* <div className='form-row is-wrap'>
              <Form.Item name='unlimited' valuePropName='checked'>
                <Checkbox onChange={() => {
                  const IsUnlimited = form.getFieldValue('unlimited');
                  setIsUnlimited(IsUnlimited);
                  if (IsUnlimited) {
                    form.setFieldValue('targetQuantity', 0);
                    form.validateFields(['targetQuantity']);
                  }

                }}>Unlimited </Checkbox>
              </Form.Item>
            </div> */}

              <div className='form-row is-wrap'>
                <Form.Item label='Validity Period' name='validityPeriod' valuePropName='value' rules={[{ required: true }]}
                  validateStatus={errorsText?.validityPeriod ? 'error' : undefined}
                  help={errorsText?.validityPeriod ? errorsText?.validityPeriod : undefined}
                >
                  <Select disabled={viewOnly} options={periodOpts} />
                </Form.Item>
              </div>
            </>
          }
          {viewOnly && (
            <>
              <div className='form-row'>
                <Form.Item label='Customer name' name='customerName'>
                  <Input disabled={viewOnly} placeholder='Enter customer name' />
                </Form.Item>
              </div>
              <div className='form-row'>
                <Form.Item label='Retail Price' name='retailPrice'>
                  <InputNumber disabled={viewOnly} addonBefore={getFormatInputCurrency().addonBefore} placeholder={formLang('AmountPlaceholder') || ''} />
                </Form.Item>
              </div>
              <div className='form-row'>
                <Form.Item label='Redeemed' name='redeemed'>
                  <InputNumber disabled={viewOnly} addonBefore={getFormatInputCurrency().addonBefore} placeholder={formLang('AmountPlaceholder') || ''} />
                </Form.Item>
              </div>
              <div className='form-row'>
                <Form.Item label='Remaining' name='remaining'>
                  <InputNumber disabled={viewOnly} addonBefore={getFormatInputCurrency().addonBefore} placeholder={formLang('AmountPlaceholder') || ''} />
                </Form.Item>
              </div>
              <div className='form-row'>
                <Form.Item label='Expiry date' name='expiryDate'>
                  <Input disabled={viewOnly} placeholder='Enter voucher code' />
                </Form.Item>
              </div>
            </>
          )}

          <div className='form-row'>
            <Form.Item label='Service Applied' name='restrictService'
              validateStatus={errorsText?.restrictService ? 'error' : undefined}
              help={errorsText?.restrictService ? errorsText?.restrictService : undefined}
              rules={[{ required: true }]}
            >

              <MultipleSelect
                onChange={setSelected}
                value={selected}
                options={services}
                maxTagPlaceholder='services'
                placeholder='Select services'
                disabled={viewOnly}
              />

            </Form.Item>
          </div>
          <div className='form-row'>
            <Form.Item label='Terms & Conditions' name='terms'
              validateStatus={errorsText?.terms ? 'error' : undefined}
              help={errorsText?.terms ? errorsText?.terms : undefined}
            >
              <TextArea
                placeholder='Enter terms & conditions of this voucher'
                rows={4}
                disabled={viewOnly}
              />
            </Form.Item>
          </div>
          <div className='form-row'>
            {
              viewOnly ?
                <>
                  <Form.Item name={'enable'} className='form-row'>

                    <div className='form-row'>
                      <p className='form-label'>{'Enable this voucher'}</p>
                      <Switch
                        checked={enableStatus}
                        onChange={_updateVoucherStatus}
                        checkedChildren=''
                        unCheckedChildren=''
                      />
                    </div>
                  </Form.Item>
                </>
                :
                <SwitchInput
                  label='Enable this voucher'
                  name='enable'
                />
            }
          </div>

          <div className='form-submit-wrapper'>
            <div
              data-testing={!viewOnly ? 'Cancel' : 'Close'}
              className='common-btn is-white'
              onClick={onCancel}
            >
              {!viewOnly ? 'Cancel' : 'Close'}
            </div>
            {!viewOnly && <button data-testing='Save' className='common-btn' onClick={_submit} >
              Save
            </button>}
          </div>
        </Form>
      </Spin>
    </VoucherFormStyled>
  );
};

export default VoucherForm;

const VoucherFormStyled = styled.div`
 .ant-input-disabled {
    background-color:#F0F2F7;
    color: #7980BC;
  }
  .ant-input-number-disabled {
    .ant-input-number-input {
      color: #7980BC;
      background-color:#F0F2F7;
    }
  }
  .ant-input-number-group .ant-input-number-group-addon:first-child {
    background-color:#F0F2F7;
    color: #7980BC;
  }
  .ant-select-disabled {
    &.ant-select {
      .ant-select-selector {
        background-color:#F0F2F7;
        color: #7980BC;
      }
    }
  }
  .ant-select-multiple.ant-select-disabled.ant-select{
    .ant-select-selector {
      background:#F0F2F7;
      background-color:#F0F2F7 !important;
      color: #7980BC !important;
    }
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    .ant-select-selection-item-content {
      // color: #7980BC !important;
    }
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-picker .ant-picker-input > input {
    color: #7980BC !important;
  }
`;
