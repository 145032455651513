import { Form, Input, Modal } from 'antd';
import { AxiosResponse } from 'axios';
import DatePicker from 'components/common/DatePickerInput';
// import FormRow from 'components/common/Form/FormRow';
import message from 'components/common/Message';
import ModalEditLoyalty from 'components/common/Modal/ModalEditLoyalty';
import { Dayjs } from 'dayjs';
import checkoutActions from 'features/checkout/services/actions';
import checkoutSelectors from 'features/checkout/services/selectors';
import apisCustomer from 'features/customers/services/apis';
import { IApiUpdateCustomerBody } from 'features/customers/services/types/cutomer';
import { get, set } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import dayjs from 'utils/dayjs';
import storage from 'utils/sessionStorage';
import NumberValidations from 'utils/validations/number';
const { TextArea } = Input;

const IconReward = () => <div className='icon'><svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.7858 5.71261L15.5001 9.99833L10.5001 2.85547L5.50007 9.99833L1.21436 5.71261V14.9983C1.21436 15.5666 1.44012 16.1117 1.84198 16.5136C2.24385 16.9154 2.78889 17.1412 3.35721 17.1412H17.6429C18.2113 17.1412 18.7563 16.9154 19.1582 16.5136C19.56 16.1117 19.7858 15.5666 19.7858 14.9983V5.71261Z" fill="#FAAD14" />
</svg></div>;

const CustomerDetail = ({ step }: { step: number }) => {
  const customerInfo = checkoutSelectors.getCustomerInfo();
  const loyaltyPointCounter = checkoutSelectors.getLoyaltyPoint();
  const selectedReward = checkoutSelectors.getSelectedReward();
  const total_point_of_customer = customerInfo?.current_point;
  const dispatch = useDispatch();

  const name = customerInfo?.name ?? '';
  const current_point = useMemo(() => {
    return customerInfo?.current_point ?? customerInfo?.reward_info?.current_point ?? 0;
  }, [customerInfo]);
  const point_left_to_earn_a_reward = customerInfo?.reward_info?.point_left_to_earn_a_reward ?? 0;
  const [modalDetail, setModalDetail] = useState<boolean>(false);
  const total_point_program_will_earn = checkoutSelectors.getTotalPointWillEarn();

  const [openEditLoyalty, setOpenEditLoyalty] = useState<boolean>(false);
  const [loadingEditLoyalty, setLoadingEditLoyalty] = useState<boolean>(false);

  const handleOpenEditLoyalty = () => {
    setOpenEditLoyalty(true);
  };

  const handleCancelEditLoyalty = () => {
    setOpenEditLoyalty(false);
  };

  const Rewards = () => {
    // const { current_point, point_left_to_earn_a_reward } = customerInfo?.reward_info ?? {};
    // if (point_left_to_earn_a_reward && current_point) {
    //   return (
    //     <>
    //       <IconReward />
    //       <span>Have {current_point} points</span>
    //       <span className='highlight-reward'>({point_left_to_earn_a_reward} points left to earn a reward)</span>
    //     </>
    //   );
    // }

    if (step === 3) {
      const totalPoint = (total_point_of_customer ?? 0) + (total_point_program_will_earn ?? 0);
      return <><IconReward /> <span>Have {totalPoint} loyalty point{totalPoint > 1 ? 's' : ''}</span></>;

    }


    return <><IconReward /> <span>Have {customerInfo?.current_point ?? 0} loyalty point{(customerInfo?.current_point ?? 0) > 1 ? 's' : ''}</span></>;
    

    // if (total_point_program_will_earn) {
    //   return <><IconReward /> <span>Will earn {total_point_program_will_earn} points</span></>;
    // }

    const totalLoyaltyAfterCheckout = () => {
      return (current_point ?? 0) - (selectedReward?.points_to_earn_reward ?? 0);
    };

    if (point_left_to_earn_a_reward) {
      return (
        <div className='box-reward'>
          <IconReward />
          <span className='highlight-reward box'>{point_left_to_earn_a_reward} points left to earn a reward!</span>
        </div>
      );
    }

    if ((current_point ?? 0) > 0 && !customerInfo?.is_walkin_in && step < 3) {
      return (
        <>
          <IconReward />
          <span>Have {totalLoyaltyAfterCheckout()} point(s)</span>
        </>
      );
    }

    if (step === 3) {
      return <><IconReward /> <span>Earned {loyaltyPointCounter} point(s)</span></>;
    }

    return null;
  };

  const handleUpdateLoyalty = async (values: any) => {
    try {
      setLoadingEditLoyalty(true);
      const customerEdit = {
        ...customerInfo,
        loyalty_point: values.loyaltyPoint
      };
      const res: AxiosResponse<{ message: string }> = await apisCustomer.updateCustomerInfo(customerInfo?.id.toString() ?? '' ,customerEdit as IApiUpdateCustomerBody);  
      
      if(res?.data) {
        message.success(res?.data?.message);
        dispatch(checkoutActions.updateCusInfo.success({
          ...customerEdit,
          loyalty_point: values.loyaltyPoint,
          current_point: values.loyaltyPoint,
          reward_info: {
            current_point: values.loyaltyPoint,
            point_left_to_earn_a_reward: 0
          }
        }));
        !customerInfo?.is_walkin_in && dispatch(checkoutActions.getRewardOfCustomer.fetch({ id: customerInfo?.customer_code?.replace('#', '') }));
        handleCancelEditLoyalty();
      }
    } catch (error) {
      const textError = get(error, 'response.data.error.message', '');
      if (textError) {
        message.error(textError);
      }
    } finally {
      setLoadingEditLoyalty(false);
    }
  };

  return (
    <CustomerDetailStyled>
      <div className='block-title'>Customer Details</div>
      {customerInfo &&
        <div className='group-customer-content'>
          <div className='current-customer'>
            <div className='customer-avatar common-avatar'>
              {name[0]}
            </div>
            <div className='customer-detail'>
              <p onClick={() => setModalDetail(true)} className='detail-name customer-link'>{name}</p>
              <p>Customer ID: <span>{customerInfo?.customer_code ?? ''}</span></p>
              <div className='reward' onClick={handleOpenEditLoyalty}> <Rewards /></div>
            </div>
          </div>
          {customerInfo.important_client_info && <div className='customer-note'>
            <ImportantIcon /> <span>{customerInfo.important_client_info}</span>
          </div>}
        </div>
      }
      {modalDetail && <ModalDetail viewOnly={step !== 1} visible={modalDetail} setVisible={setModalDetail} />}
      <ModalEditLoyalty loading={loadingEditLoyalty} loyaltyPoint={total_point_of_customer ?? 0} isOpen={openEditLoyalty} handleCancel={handleCancelEditLoyalty} handleUpdate={handleUpdateLoyalty} />
    </CustomerDetailStyled>
  );
};

export default CustomerDetail;

export interface IFormGeneralValue {
  name: string;
  phoneNumber: string;
  email: string;
  birthday: Dayjs;
  gender: number;
  importantClientInfo: string;
}

const ModalDetail = ({ visible, setVisible, viewOnly = false }: {
  visible: boolean,
  setVisible: (v: boolean) => void;
  viewOnly?: boolean;
}) => {
  const customerInfo = checkoutSelectors.getCustomerInfo();
  const [form] = Form.useForm();
  const setPageLoading = useSetLoadingPage();
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState<any>({});

  // useEffect(() => {
  //   form.setFieldsValue({
  //     name: customerInfo?.name,
  //     email: customerInfo?.email,
  //     phoneNumber: customerInfo?.phone_number,
  //     birthday: customerInfo?.birthday ? dayjs(customerInfo?.birthday) : null,
  //     importantClientInfo: customerInfo?.important_client_info,
  //     gender: customerInfo?.gender
  //   });
  // }, [customerInfo]);

  const checkValid = async () => {
    try {
      await form.validateFields([
        'phoneNumber',
        'name',
        'email',
      ]);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const _onSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    const isFormValid = await checkValid();
    if (!isFormValid) return;
    form.submit();
  };


  const handleSubmit = async (values: IFormGeneralValue) => {
    const merchantId = storage.merchantId.get();
    if (!merchantId) return;
    const payload: IApiUpdateCustomerBody = {
      merchant_id: merchantId,
      email: values.email ?? '',
      name: values.name ?? '',
      gender: values?.gender ?? null,
      important_client_info: values?.importantClientInfo ?? '',
      date_of_birth: values.birthday ? values.birthday.format('YYYY-MM-DD') : null,
      phone_number: values.phoneNumber ?? '',
    };

    setPageLoading(true);
    try {
      const res = await apisCustomer.updateCustomerInfo(String(customerInfo?.id ?? ''), payload);
      const msg = get(res, 'data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(checkoutActions.updateCusInfo.success({
          email: payload.email,
          name: payload.name,
          birthday: payload.date_of_birth,
          phone_number: payload.phone_number,
          gender: payload?.gender,
          important_client_info: payload?.important_client_info
        }));
        handleCancel();
      } else {
        const msgError = get(res, 'data.error.message', '');
        if (msgError) message.error(msgError);
      }

    } catch (error: any) {
      const errorText = get(error, 'response.data.error.message', '');
      if (errorText) {
        message.error(errorText);
        return;
      }
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.entries(response);
      if (errors.length > 0) {
        const errorsObj = {};
        errors.forEach(([key, value]) => {
          set(errorsObj, [key], value?.[0]);
        });
        setErrors(errorsObj);
        errors.map(([, value]) => {
          message.error(get(value, [0], ''));
        });
        return;
      }
      message.error('An error occurred. Please try again');
    } finally {
      setPageLoading(false);
    }
  };

  const errorsText = useMemo(() => {
    return ({
      name: get(errors, 'name', ''),
      phone_number: get(errors, 'phone_number', ''),
      email: get(errors, 'email', ''),
      date_of_birth: get(errors, 'date_of_birth', ''),
      important_client_info: get(errors, 'important_client_info', ''),
    });
  }, [errors]);

  const myFocus = useRef<any>();

  useEffect(() => {
    myFocus?.current?.input?.focus();
  }, []);

  return (
    <Modal
      maskClosable={false}
      open={visible}
      okButtonProps={{ style: { display: 'none' } }}
      onCancel={handleCancel}
      width={574}
      footer={null}
      forceRender
      centered
    >
      <h2 className='modal-title'>
        <p>Customer Details</p>
      </h2>
      <Form
        name='basic'
        className='modal-form'
        autoComplete='off'
        disabled={viewOnly}
        form={form}
        data-testing='form-customer-detail'
        initialValues={{
          name: customerInfo?.name,
          email: customerInfo?.email,
          phoneNumber: customerInfo?.phone_number,
          birthday: customerInfo?.birthday ? dayjs(customerInfo?.birthday) : (customerInfo?.date_of_birth ? dayjs(customerInfo?.date_of_birth) : null),
          importantClientInfo: customerInfo?.important_client_info,
          gender: customerInfo?.gender || 0,
        }}
        onFinish={handleSubmit}
        onKeyPress={(e) => {
          if(e.key === 'Enter') {
            form.submit();
          }
        }}
        layout='vertical'
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: 'Please input your name!' }
          ]}
          validateStatus={errorsText.name ? 'error' : undefined}
          help={errorsText.name ? errorsText.name : undefined}
        >
          <Input ref={myFocus} />
        </Form.Item>
        <Form.Item label="Phone number" name="phoneNumber"
          rules={[
            {
              required: true,
              message: 'Please input your phone number!'
            },
             NumberValidations.PHONE
          ]}
          validateStatus={errorsText.phone_number ? 'error' : undefined}
          help={errorsText.phone_number ? errorsText.phone_number : undefined}
        >
          <Input type='tel' placeholder="Enter your phone number" />
        </Form.Item>
        <Form.Item label="Email" name="email"
          rules={[{ type: 'email', message: 'is not a valid email!' }]}
          validateStatus={errorsText.email ? 'error' : undefined}
          help={errorsText.email ? errorsText.email : undefined}
        >
          <Input type='email'/>
        </Form.Item>
        {/* <FormRow label='Gender' name='gender' optional>
          <Radio.Group>
            <Radio value={0}>Female</Radio>
            <Radio value={1}>Male</Radio>
            <Radio value={2}>Non-binary</Radio>
          </Radio.Group>
        </FormRow> */}
        <Form.Item
          name="birthday"
          label={
            <p>
              Birth Date <span>(Optional)</span>
            </p>
          }
          validateStatus={errorsText.date_of_birth ? 'error' : undefined}
          help={errorsText.date_of_birth ? errorsText.date_of_birth : undefined}
        >
          <DatePicker clearIcon={false} />
        </Form.Item>
        <Form.Item
          className='important-note'
          label={
            <p>
              Important client info
              <span>(Optional)</span>
            </p>
          }
          name='importantClientInfo'
          extra={'This note will be displayed on all bookings. '}
          validateStatus={errorsText.important_client_info ? 'error' : undefined}
          help={errorsText.important_client_info ? errorsText.important_client_info : undefined}
        >
          <TextArea
            rows={5}
            placeholder={'Eg. customer allergic to fragrance'}
          />
        </Form.Item>
        <div className='form-row form-submit-wrapper'>
          <button data-testing='Cancel_customer_form' type='button' className='common-btn is-white' onClick={handleCancel}>
            Cancel
          </button>
          {!viewOnly && <button data-testing='Save_customer_form' type='button' className='common-btn' onClick={_onSubmit} >Save</button>}
        </div>
      </Form>
    </Modal>
  );
};

const CustomerDetailStyled = styled.div`
.block-title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 24px;
}
.current-customer {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 12px;
  transition: all 0.3s ease;
  border-radius: 6px;
  .customer-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
  }

  .customer-detail {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 6px;
    p {
      font-size: 12px;
      span {
        font-weight: 600;
      }
    }
    .detail-name {
      font-weight: 600;
      font-size: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      text-transform: capitalize;
    }
    .customer-link-disable {
        pointer-events: none;
    }
    .detail-point {
      color: #4A9D77;
      font-weight: 600;
      font-size: 16px;
    }
    .reward {
      display: flex;
      align-items: center;
      gap: 4px;
      text-decoration: underline;
      cursor: pointer;
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #363565;
      }
      .icon {
        display: flex;
      }
      .box-reward {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        background: #FFFBE6;
        border-radius: 6px;
        padding: 6px 8px;
        gap:12px;
      }
      .highlight-reward {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #FAAD14;
      }
    }
  }

  .btn-reset {
    background: #F0F2F7;
    border-radius: 6px;
  }

  .customer-link {
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    text-decoration-line: underline;
    color: var(--color-green);
    cursor: pointer;
    &.is-text {
      text-decoration-line: none;
    }
  }
  .customer-link-disable {
    pointer-events: none;
  }
}

`;

const ImportantIcon = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.3535 21.6797C16.8764 21.6797 21.3535 17.2025 21.3535 11.6797C21.3535 6.15684 16.8764 1.67969 11.3535 1.67969C5.83067 1.67969 1.35352 6.15684 1.35352 11.6797C1.35352 17.2025 5.83067 21.6797 11.3535 21.6797Z" stroke="#2F54EB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.3535 15.6797V11.6797" stroke="#2F54EB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.3535 7.67969H11.3635" stroke="#2F54EB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

