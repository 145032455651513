import { Form } from 'antd';
// import StickyPromotion from 'features/bookings/components/BookingForm/StickyPromotion';
import bookingActions from 'features/bookings/services/actions';
import React, { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import FormInfo from '../components/FormInfo';

const CheckInPage: React.FC = () => {
  const { merchant_code = '' } = useParams();

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(bookingActions.getBookingOnlineData.fetch(merchant_code));
    dispatch(bookingActions.getOnlineLocations.fetch(merchant_code));
  }, []);

  return (
    <CheckInPageStyled>
      {/* <StickyPromotion label='Some promotion - book something get another thing free!' /> */}
      <div className='form_container'>
        <Form layout='vertical'>
          <FormInfo />
          {/* <FormConfirm />
          <FormDone /> */}
        </Form>
      </div>
    </CheckInPageStyled>
  );
};

export default memo(CheckInPage);

const CheckInPageStyled = styled.div`
  background: var(--color-white-01);
  min-height: 100vh;
  font-family: var(--font-base);
  @media only screen and (min-width: 1025px) {
    padding-bottom: 80px;
  }
  @media only screen and (max-width: 767.98px) {
    padding-bottom: 20px;
  }
  .form_container {
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 24px;

    @media only screen and (min-width: 768px) and (max-width: 1024.98px) {
      padding: 40px 58px;
    }

    @media only screen and (max-width: 767.98px) {
      padding: 40px 12px 0;
    }
  }
  .hide {
    display: none;
  }
`;
