import { Checkbox, DatePicker, Form, Select, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import timeScheduleSelectors from '../services/selectors';
import { TIME_OFF_REPEAT_OPTS } from '../services/constants';
import TimePickerAutoChange from 'components/common/TimePickerAutoChange';
import {
  TimeOffModalType,
  TimeScheduleRepeatType,
} from '../types/timeSchedule';
import { IApiTimeScheduleListReq } from '../types/api';
import { useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';

interface TimeOffFormProps {
  modalType?: TimeOffModalType;
}
const TimeOffForm = ({ modalType }: TimeOffFormProps) => {
  const [repeatOption, setRepeatOption] = useState(TIME_OFF_REPEAT_OPTS);
  const { t: formLang } = useTranslation('form');
  const form = Form.useFormInstance();

  const teamMembers = timeScheduleSelectors.getMerchantTeamMember();
  const locationValue = (timeScheduleSelectors.getTimeScheduleParamValue(
    'merchant_location_id'
  ) ?? 'All') as IApiTimeScheduleListReq['merchant_location_id'];
  const locationDetail = timeScheduleSelectors.getLocationDetail();

  const TEAM_OPTS = useMemo(() => {
    const lstTeamMemberOpt = teamMembers
      ?.filter((it) => it?.location_id === locationValue)
      .map((item) => {
        return {
          value: item.id,
          label: item.full_name,
        };
      });
    if (lstTeamMemberOpt?.length === 1) {
      form.setFieldValue('teamMember', lstTeamMemberOpt?.[0]?.value);
    }
    return lstTeamMemberOpt;
  }, [teamMembers, locationValue]);

  const allListTeamMember = useMemo(() => {
    const lstTeamMemberOpt = teamMembers
      ?.filter((it) => it?.location_id === locationValue)
      .map((item) => {
        return {
          id: item.id,
          employee_shifts: item?.employee_shifts,
        };
      });
    return lstTeamMemberOpt;
  }, [teamMembers, locationValue]);

  const handleRepeatTextClick = () => {
    const repeatCheck = form.getFieldValue('repeatCheck');
    form.setFieldValue('repeatCheck', !repeatCheck);
  };

  const date_closed: any = useMemo(() => {
    return locationDetail?.date_closed.map((o: any) => ({
      start: dayjs(o.start_date, 'YY-MM-DD'),
      end: dayjs(o.end_date, 'YY-MM-DD'),
    }));
  }, [locationDetail?.date_closed]);

  const isDisableDate = (d: Dayjs) => {
    const isDateClosed = !!date_closed?.some(
      (o: any) =>
        d.format('YY-MM-DD') == o.start.format('YY-MM-DD') ||
        d.isBetween(o.start, o.end.add(1, 'day'))
    );
    if (isDateClosed) return true;

    return !locationDetail?.weekday_working_times?.some(
      (o: any) => o?.weekday === d?.format('dddd')?.toLowerCase()
    );
  };

  const getDisabledHours = () => {
    const hours: any = [];
    const hoursDisable: any = [];
    const selectDate = form.getFieldValue('dayOff');

    const timeShifts = locationDetail?.weekday_working_times?.filter(
      (o: any) => o?.weekday === selectDate?.format('dddd')?.toLowerCase()
    );

    for (let i = 0; i < timeShifts?.length; i++) {
      const time_start = timeShifts?.[i]?.time_start;
      const time_end = timeShifts?.[i]?.time_end;
      const getTimeStart = moment(time_start, 'HH:mm:ss').get('hours');
      const getTimeEnd = moment(time_end, 'HH:mm:ss').get('hours');
      for (let i = getTimeStart; i <= getTimeEnd; i++) {
        hours.push(i);
      }
    }

    for (let i = 0; i < 24; i++) {
      if (!hours?.includes(i)) {
        hoursDisable?.push(i);
      }
    }

    return hoursDisable;
  };

  const getDisabledMinutes = (selectedHour: number) => {
    const minutes: any = [];
    const selectDate = form.getFieldValue('dayOff');
    const timeShifts = locationDetail?.weekday_working_times?.filter(
      (o: any) => o?.weekday === selectDate?.format('dddd')?.toLowerCase()
    );

    for (let i = 0; i < timeShifts?.length; i++) {
      const time_start = timeShifts?.[i]?.time_start;
      const time_end = timeShifts?.[i]?.time_end;
      const getTimeStart = moment(time_start, 'HH:mm:ss').get('hours');
      const getTimeEnd = moment(time_end, 'HH:mm:ss').get('hours');

      if (selectedHour === getTimeStart) {
        for (
          let i = 0;
          i < moment(time_start, 'HH:mm:ss').get('minutes');
          i++
        ) {
          minutes.push(i);
        }
      }

      if (selectedHour === getTimeEnd) {
        for (
          let i = moment(time_end, 'HH:mm:ss').get('minutes') + 1;
          i < 60;
          i++
        ) {
          minutes.push(i);
        }
      }
    }

    return minutes;
  };

  const checkTimeInvalid = (value: Dayjs | null, timeType: 'start' | 'end') => {
    const formatValue = dayjs(value).format('HH:mm:ss');
    const selectDate = form.getFieldValue('dayOff');
    const timeShifts = locationDetail?.weekday_working_times?.filter(
      (o: any) => o?.weekday === selectDate?.format('dddd')?.toLowerCase()
    );

    const timeList: any = [];

    for (let i = 0; i < timeShifts?.length; i++) {
      const time_start = timeShifts?.[i]?.time_start;
      const time_end = timeShifts?.[i]?.time_end;

      timeList?.push(time_start);
      timeList?.push(time_end);
    }

    timeList.sort();

    const time_start = timeList[0];
    const time_end = timeList[timeList?.length - 1];

    if (
      moment(formatValue, 'HH:mm:ss').isBefore(moment(time_start, 'HH:mm:ss'))
    ) {
      if (timeType === 'start') {
        form.setFieldValue('startTime', dayjs(time_start, 'HH:mm:ss'));
      } else {
        form.setFieldValue('endTime', dayjs(time_start, 'HH:mm:ss'));
      }
    }

    if (moment(formatValue, 'HH:mm:ss').isAfter(moment(time_end, 'HH:mm:ss'))) {
      if (timeType === 'start') {
        form.setFieldValue('startTime', dayjs(time_end, 'HH:mm:ss'));
      } else {
        form.setFieldValue('endTime', dayjs(time_end, 'HH:mm:ss'));
      }
    }
  };

  return (
    <TimeOffFormStyled>
      <div className='wrap-timeoff-form'>
        <div className='form-row'>
          <Form.Item
            label={formLang('timeSchedule.timeOffForm.teamMember')}
            name='teamMember'
            rules={[
              {
                required: true,
                message: 'Please select team member',
              },
            ]}
          >
            <Select
              options={TEAM_OPTS}
              placeholder={formLang(
                'timeSchedule.timeOffForm.placeHolder.teamMember'
              )}
            />
          </Form.Item>
        </div>

        <div className='form-row one-date-leave-switch'>
          <p className='form-label'>
            {formLang('timeSchedule.timeOffForm.oneDayLeave')}
          </p>
          <Form.Item noStyle name={'oneDayLeave'} valuePropName='checked'>
            <Switch
              onChange={(value) => {
                if (value) {
                  const getCurrRepeatType =
                    form.getFieldValue('timeOffRepeatType');

                  if (getCurrRepeatType === TimeScheduleRepeatType.DAILY) {
                    form.setFieldValue(
                      'timeOffRepeatType',
                      TimeScheduleRepeatType.WEEKLY
                    );
                  }

                  if (repeatOption?.length === 3) {
                    setRepeatOption(
                      TIME_OFF_REPEAT_OPTS?.filter(
                        (item) => item?.value !== TimeScheduleRepeatType.DAILY
                      )
                    );
                  }
                } else {
                  if (repeatOption?.length === 2) {
                    setRepeatOption(TIME_OFF_REPEAT_OPTS);
                  }
                }
              }}
            />
          </Form.Item>
        </div>

        <div className='form-row'>
          <Form.Item
            label={formLang('timeSchedule.timeOffForm.dayOff')}
            name='dayOff'
            dependencies={['teamMember']}
            rules={[
              { required: true, message: 'Please select day off' },
              {
                validator: (_, value) => {
                  const currentMember = form.getFieldValue('teamMember');
                  const findTeamMemberData = allListTeamMember?.find(
                    (item) => Number(item?.id) === Number(currentMember)
                  );

                  const checkInValid =
                    !findTeamMemberData?.employee_shifts?.some(
                      (o: any) =>
                        o?.weekday === value?.format('dddd')?.toLowerCase()
                    );

                  if (checkInValid && findTeamMemberData?.employee_shifts) {
                    return Promise.reject(
                      'Can not add to employee\'s day off'
                    );
                  }

                  const checkDateDisable = isDisableDate(value);
                  if (checkDateDisable){
                    return Promise.reject(
                      'Can not add to store\'s day off'
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <DatePicker
              format={'DD.MM.YYYY'}
              picker='date'
              placeholder={'DD.MM.YYYY'}
              className='time-off-datepicker'
              disabled={modalType === TimeOffModalType.UPDATE}
              disabledDate={isDisableDate}
            />
          </Form.Item>
        </div>

        <Form.Item dependencies={['oneDayLeave']} noStyle>
          {({ getFieldValue }: any) => {
            // getFieldValue defined here
            return !getFieldValue('oneDayLeave') ? (
              <div className='form-row'>
                <Form.Item
                  label={formLang('timeSchedule.timeOffForm.startTime')}
                  name='startTime'
                  dependencies={['endTime']}
                  rules={[
                    { required: true, message: 'Please select start time' },
                    {
                      validator: (_, value) => {
                        const endTime = form.getFieldValue('endTime');
                        if (
                          value &&
                          endTime &&
                          (value.isAfter(endTime) || value.isSame(endTime))
                        ) {
                          return Promise.reject(
                            'Start Time cannot be greater than or equal to End Time'
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <TimePickerAutoChange
                    format={'HH:mm'}
                    allowClear={false}
                    className='is-time-picker time-off-timepicker'
                    minuteStep={15}
                    showNow={false}
                    popupClassName='time-picker-custom'
                    disabledHours={getDisabledHours}
                    disabledMinutes={getDisabledMinutes}
                    onChange={(value) => checkTimeInvalid(value, 'start')}
                  />
                </Form.Item>
                <Form.Item
                  label={formLang('timeSchedule.timeOffForm.endTime')}
                  name='endTime'
                  rules={[
                    { required: true, message: 'Please select end time' },
                  ]}
                >
                  <TimePickerAutoChange
                    format={'HH:mm'}
                    allowClear={false}
                    className='is-time-picker time-off-timepicker'
                    minuteStep={15}
                    showNow={false}
                    popupClassName='time-picker-custom'
                    disabledHours={getDisabledHours}
                    disabledMinutes={getDisabledMinutes}
                    onChange={(value) => checkTimeInvalid(value, 'end')}
                  />
                </Form.Item>
              </div>
            ) : null;
          }}
        </Form.Item>

        <div className='form-row repeat-check'>
          <Form.Item noStyle name={'repeatCheck'} valuePropName='checked'>
            <Checkbox />
          </Form.Item>
          <p
            className='form-label is-center'
            onClick={() => handleRepeatTextClick()}
            style={{ cursor: 'pointer' }}
          >
            {formLang('timeSchedule.timeOffForm.repeat')}
          </p>
        </div>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.repeatCheck !== currentValues.repeatCheck
          }
        >
          {({ getFieldValue }: any) => {
            return getFieldValue('repeatCheck') ? (
              <>
                <div className='form-row'>
                  <Form.Item
                    label={formLang('timeSchedule.timeOffForm.repeat')}
                    name='timeOffRepeatType'
                    rules={[
                      {
                        required: true,
                        message: 'Please select repeat type',
                      },
                    ]}
                  >
                    <Select options={repeatOption} />
                  </Form.Item>
                </div>
                <div className='form-row'>
                  <Form.Item
                    label={
                      <p>
                        {formLang('timeSchedule.timeOffForm.endRepeatDate')}
                        <span>(Optional)</span>
                      </p>
                    }
                    name='endRepeatDate'
                  >
                    <DatePicker
                      format={'DD.MM.YYYY'}
                      picker='date'
                      placeholder={'DD.MM.YYYY'}
                      className='time-off-datepicker'
                      disabled={modalType === TimeOffModalType.UPDATE}
                      disabledDate={(value: Dayjs) => {
                        if (
                          !value ||
                          value.isSameOrBefore(dayjs().subtract(1, 'days'))
                        )
                          return true;
                        return false;
                      }}
                    />
                  </Form.Item>
                </div>
              </>
            ) : null;
          }}
        </Form.Item>
      </div>
    </TimeOffFormStyled>
  );
};

const TimeOffFormStyled = styled.div`
  .ant-picker {
    min-width: unset;
  }

  .time-off-datepicker,
  .time-off-timepicker,
  .time-off-datepicker:hover,
  .time-off-timepicker:hover {
    background: #f0f2f6 !important;
  }

  .wrap-timeoff-form {
    margin-top: 28px;
  }

  .repeat-check {
    margin-bottom: 16px;
  }

  .one-date-leave-switch {
    margin-bottom: 16px;
  }
`;

export default TimeOffForm;
