const IconBookingOnline = () => (
  <svg
    style={{ fill: '#000000' }}
    height='17'
    width='17'
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 512 512'
    xmlSpace='preserve'
  >
    <g>
      <g>
        <path
          d='M256,0C114.84,0,0,114.841,0,256c0,141.161,114.84,256,256,256c141.16,0,256-114.839,256-256C512,114.841,397.16,0,256,0z
           M441.167,111.126c29.553,37.556,47.93,84.121,50.118,135.321H378.777c-0.685-38.4-5.182-74.12-12.742-105.878
          C393.927,133.356,419.445,123.543,441.167,111.126z M427.555,94.815c-19.452,10.665-42.105,19.287-66.765,25.652
          c-11.728-39.845-28.565-72.148-48.755-93.234C356.95,38.238,396.847,62.153,427.555,94.815z M153.708,246.448
          c0.715-35.84,5.215-71.234,12.595-101.844c28.847,6.096,59.77,8.996,91.357,8.996c30.407,0,60.225-2.694,88.187-8.372
          c7.293,30.454,11.733,65.38,12.445,101.22H153.708z M358.292,266.928c-0.712,35.84-5.18,70.301-12.515,100.827
          c-28.378-5.872-58.715-9.355-89.708-9.355c-31,0-61.402,3.488-89.84,9.386c-7.338-30.535-11.81-65.019-12.522-100.859H358.292z
           M256,20.48c34.64,0,65.922,42.004,84.547,104.61c-26.235,5.231-54.253,8.03-82.887,8.03c-29.81,0-58.917-3.002-86.025-8.637
          C190.285,62.215,221.473,20.48,256,20.48z M199.965,27.234c-20.055,20.944-36.8,52.959-48.518,92.436
          c-24.385-6.53-46.727-15.286-65.872-26.07C116.125,61.555,155.602,38.101,199.965,27.234z M71.89,109.643
          c21.423,12.561,46.632,22.884,74.267,30.285c-7.677,31.96-12.242,68.12-12.935,106.52H20.715
          C22.93,195.248,41.725,147.421,71.89,109.643z M71.385,402.591c-29.87-37.674-48.467-84.464-50.67-135.664h112.507
          c0.688,38.4,5.222,73.755,12.842,105.621C118.305,379.86,92.942,390.105,71.385,402.591z M85.037,417.823
          c19.29-10.721,41.778-19.416,66.298-25.868c11.723,39.65,28.51,71.801,48.63,92.812
          C155.34,473.835,115.662,450.161,85.037,417.823z M256,491.52c-34.58,0-65.815-41.861-84.453-104.29
          c26.703-5.445,55.295-8.35,84.523-8.35c29.218,0,57.75,2.899,84.392,8.318C321.825,449.644,290.587,491.52,256,491.52z
           M312.035,484.768c20.128-21.02,36.923-53.187,48.643-92.861c24.53,6.445,47.02,15.139,66.33,25.869
          C396.375,450.138,356.682,473.829,312.035,484.768z M440.67,402.351c-21.587-12.491-46.96-22.38-74.725-29.682
          c7.615-31.853,12.145-67.341,12.833-105.741h112.508C489.085,318.128,470.51,364.689,440.67,402.351z'
        />
      </g>
    </g>
  </svg>
);

export default IconBookingOnline;
