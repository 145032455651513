import PrimaryButton from 'components/common/Buttons/PrimaryButton';
import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import Section from '../Section';
import { BookingFormProps } from '../types';
import bookingFormImage from 'assets/images/booking/bookingForm';
import { useAppDispatch } from 'store/hooks';
import userActions from 'features/users/services/actions';
import userSelectors from 'features/users/services/selectors';
interface Props extends BookingFormProps {
  isCheckIn?: boolean;
  title?: string; 
  subtitle?: string;
}
const InValidLocation: React.FC<Props> = ({title, subtitle}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(userActions.getProfileStaffDetail.fetch());
  },[]);

  const user = userSelectors.getProfileStaffDetail();

  return (
    <InValidLocationStyled>
      <Section>
        <Fragment>
          <img className='banner_form_img' src={bookingFormImage.closed_tag} />
          <p className='title'>{title || 'Sorry! Our store has temporary closed for maintenance.'}</p>
          <p className="content">{subtitle || 'Our store is temporarily closed for renovations, creating an even more serene and luxurious retreat for your relaxation. We&apos;ll be back soon, offering an new place of tranquility and bliss.'}</p>
        </Fragment>
      </Section>
    {!!user?.phone &&  <PrimaryButton type='button' label={`Call us at ${user?.phone}`} className='primary_button' />}
    </InValidLocationStyled>
  );
};

export default InValidLocation;

const InValidLocationStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px 0px;
  width: 100%;
  justify-content: center;
  text-align:center;
  .banner_form_img {
    width: 140px;
    margin-bottom: 5%;
  }
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 140%;
    text-align: center;
    color: #363565;
    margin-bottom: 5%;
    @media screen and (max-width: 555px) {
      font-size: 20px;
    }
  }
  .content {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #363565;
    @media screen and (max-width: 555px) {
      font-size: 14px;
    }
  }
  .primary_button {
    display: flex;
    align-self: center;
    &.outline{
      color: var(--color-primary);
      background-color: transparent;
      
    }
  }
`;