import { Modal, Spin } from 'antd';
import settingApis from 'features/settings/services/apis';
import useIClientTyroOptimize from 'hooks/useClientTyroOptimize';
import React, { useState } from 'react';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';

enum STATUS_PARING {
  PROCESSING = 1,
  FAILURE = 2,
  SUCCESS = 3,
}

interface Props {
  tyroMID?: string;
  tyroTID?: string;
}

const PairButtonOptimize = ( {
  tyroMID = '', 
  tyroTID = ''
}: Props ) => {

  const mid = tyroMID;
  const tid = tyroTID;

  const [showModalPair, setShowModalPair] = useState(false);
  const [status, setStatus] = useState<STATUS_PARING | null>(null);
  const [message, setMessage] = useState('');
  const iClient = useIClientTyroOptimize();


  
  const responseReceivedCallback = (response: any) => {

    setMessage(response?.message ?? '');
    if (response.status == 'inProgress') {
      setShowModalPair(true);
      setStatus(STATUS_PARING.PROCESSING);
    }
    if (response.status == 'failure') {
      setStatus(STATUS_PARING.FAILURE);
    }
    if (response.status == 'success') {
      setStatus(STATUS_PARING.SUCCESS);
      storage.mid.set(mid);
      storage.tid.set(tid);

      const payload = {
        MID: mid,
        TID: tid
      };
      settingApis?.pairPOS(payload);

    }
  };

  const onPairTerminal = () => {
    iClient.pairTerminal(mid, tid, responseReceivedCallback);
  };

  const handleCancel = () => {
    setShowModalPair(false);
  };

  return (
    <PairButtonOptimizeStyled>
      <button
        className="common-btn is-big no-flex"
        onClick={onPairTerminal}>
        Pair Tyro Terminal
      </button>
      <Modal
        open={showModalPair}
        onCancel={handleCancel}
        okButtonProps={{ style: { display: 'none' } }}
        width={574}
        footer={null}
        destroyOnClose
        closable={false}
        forceRender
        className='is-small'
        maskClosable={false}
        centered
      >
        <PairModalStyled>
          <div className="modal-title">
            <p>TYRO Configuration</p>

          </div>
          <div className='box_content'>
            <p>{message ? message : 'On the Tyro Terminal, press the Start button'}</p>
            {status === STATUS_PARING.PROCESSING && <Spin size='small' />}
          </div>

          <div className="form-submit-wrapper">
            <div onClick={handleCancel} className="common-btn is-white">
              Cancel
            </div>

            {status === STATUS_PARING.FAILURE && (
              <button onClick={onPairTerminal} className="common-btn">
                Try again
              </button>
            )}
          </div>
        </PairModalStyled>
      </Modal>
    </PairButtonOptimizeStyled>
  );
};

export default PairButtonOptimize;

const PairButtonOptimizeStyled = styled.div`
`;
const PairModalStyled = styled.div`
.modal-title {
  margin-bottom: 20px;
  display:flex;
  align-items: center;
} 
  .box_content{
    display:flex;
    flex-direction: column;
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      margin-bottom: 16px;
    }
  }
`;