import { IServiceSelectedItem as CheckoutSer } from 'features/checkout/services/types/service';
import { IServiceSelectedItem as SalesSer } from 'features/sales/services/types/service';
import { IPromotionDetailInput } from 'features/promotions/services/types/promotion';
import { checkServiceRestrict } from 'utils/unit';

export type IService = CheckoutSer | SalesSer;

const useCheckServicesPromotion = (selected: IService[]) => {
  return (info: IPromotionDetailInput | null) => {
    if (!info) return false;

    const services = info.services_restricts;

    const validServices = selected.some(o => {
      
      return checkServiceRestrict(services, o);
    });

    return validServices;
  };
};

export default useCheckServicesPromotion;
