import { CopyOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { AxiosResponse } from 'axios';
import SwitchInput from 'components/common/Input/SwitchInput';
import message from 'components/common/Message';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import storage from 'utils/sessionStorage';
import settingActions from '../services/actions';
import settingApis from '../services/apis';
import settingSelectors from '../services/selectors';
import FormTabSetting from './FormTabSetting';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import styled from 'styled-components';
import TextArea from 'antd/lib/input/TextArea';
import TooltipComponent, { tooltipConfig } from './TooltipComponent';
// import TextArea from 'antd/es/input/TextArea';

interface ICustomerLink {
  fullDomain: string;
  checkinQR: boolean;
  selectServive: boolean;
  online_promotionText: string;
  online_headerText: string;
  online_descriptionText: string;
  checkIn_promotionText: string;
  checkIn_headerText: string;
  checkIn_descriptionText: string;
  viewBirthday?: boolean;
  urlView?: string;
  isPromotion?: boolean;
  enable_timeroster_management?: any;
  featured_category: boolean;
  per_payment?: boolean,
  notice?: string,
  termsAndConditions?: string,
}

const CustomerLinksTab = () => {

  const getSetting = settingSelectors.getSetting();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const location = useLocation();



  useEffect(() => {
    form.setFieldsValue(getInitValues());
  }, [getSetting]);  

  const getInitValues = () => {
    const data = getSetting?.customer_link;
    const merchantCode: string = storage.merchantCode.get();
    const merchantName: string = storage.merchantName.get();

    return {
      fullDomain: `${window.location.href.replace(location?.pathname, '')}/${merchantName.replaceAll(' ', '-')}/booking-form/${merchantCode}`.toLowerCase(),
      checkinQR: data?.checkin_qr,
      selectServive: data?.customer_services,
      online_promotionText: getSetting?.booking_online_text?.promotion_text,
      online_headerText: getSetting?.booking_online_text?.header_text,
      online_descriptionText: getSetting?.booking_online_text?.description_text,
      checkIn_promotionText: getSetting?.checkin_text?.promotion_text,
      checkIn_headerText: getSetting?.checkin_text?.header_text,
      checkIn_descriptionText: getSetting?.checkin_text?.description_text,
      viewBirthday: !!getSetting?.booking?.view_birthday,
      urlView: getSetting?.review?.url_review,
      isPromotion: data?.is_promotion,
      enable_timeroster_management: getSetting?.notification_setting?.enable_timeroster_management,
      featured_category: getSetting?.booking?.featured_category,
      per_payment: data?.per_payment,
      notice: getSetting?.booking?.merchant_note,
      termsAndConditions: getSetting?.booking?.terms_conditions,
    };
  };

  const getPayload = (values: ICustomerLink) => {
    return {
      checkin_qr: values?.checkinQR,
      customer_services: values?.selectServive,
      promotion_text_booking: values?.online_promotionText,
      herder_text_booking: values?.online_headerText,
      description_text_booking: values?.online_descriptionText,
      promotion_text_checkin: values?.checkIn_promotionText,
      herder_text_checkin: values?.checkIn_headerText,
      description_text_checkin: values?.checkIn_descriptionText,
      view_birthday: !!values?.viewBirthday,
      url_review: values?.urlView,
      is_promotion: values?.isPromotion,
      enable_timeroster_management: values?.enable_timeroster_management,
      featured_category: values?.featured_category,
      per_payment: values?.per_payment,
      merchant_note: values?.notice,
      terms_conditions: values?.termsAndConditions,
    };
  };
  const handleSubmit = async (values: ICustomerLink) => {

    const payload = getPayload(values);
    try {
      const rest: AxiosResponse<{ message: string }> = await settingApis.updateCustomerLink(payload);
      // settingApis.updateNotification(payload);

      const msg = get(rest, 'data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(settingActions.getSetting.fetch());
        form.setFieldsValue(getInitValues());
        return true;
      }
      return false;
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.values(response);
      errors.map((value) => {
        message.error(get(value, [0], ''));
      });

      return false;
    }
  };

  const checkValid = async () => {
    try {
      await form.validateFields([
        // 'phoneNumber',
        // 'name',
        // 'email',
        // 'service',
        // 'bookingDate',
        // 'bookingTime',
      ]);
      return true;
    } catch (error) {
      return false;
    }
  };

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return await document.execCommand('copy', true, text);
    }
  };

  const handleCopy = async (text: string) => {
    
    try {

        await copyTextToClipboard(text);
        message.success('Copied');
    } catch (error) { }
  };

  return (
    <FormTabSetting
      form={form}
      handleSubmit={handleSubmit}
      checkValid={checkValid}
      initialValues={getInitValues()}
    >
      <div className='cards-wrapper'>
        <div className='cards-col'>
          <div className='common-card'>
            <div className='card-title'>
              <p>Booking Online</p>
            </div>
            <div className='card-body'>
              <FullDomainStyled>
                <Form.Item name='fullDomain' label='Booking Online' tooltip={tooltipConfig('Link which customers can use to make online bookings.')}>
                  <Input className='is-blur' id="myInput" disabled suffix={<Button onClick={() => handleCopy(getInitValues().fullDomain)} icon={<CopyOutlined />} />}></Input>
                </Form.Item>

                <Form.Item name='urlView' label='Google review URL'>
                  <Input placeholder='Enter google review url' className='is-blur' id="myInput" suffix={<Button onClick={() => handleCopy(form.getFieldValue('urlView'))} icon={<CopyOutlined />} />}></Input>
                </Form.Item>
              </FullDomainStyled>
              <FullDomainStyled>
                <div className='form-row'>
                  <Form.Item noStyle name='checkinQR' />
                  <div>
                    <div className='d-flex' style={{ gap: '10px' }}>
                      <SwitchInput
                        dataTesting={'promotion-redemption'}
                        name='isPromotion'
                        label='Promotion redemption'
                        helperText='Allow redeem promotion on the check-out screen.'
                      />
                    </div>
                  </div>
                </div>
              </FullDomainStyled>

              <Form.Item label={'Promotion text'} name='online_promotionText'>
                <Input placeholder='Enter promotion text' />
              </Form.Item>
              <Form.Item label={'Header text'} name='online_headerText'>
                <Input placeholder='Enter header text' />
              </Form.Item>
              <Form.Item label={'Add description'} name='online_descriptionText'>
                <Input placeholder='Enter description text' />
              </Form.Item>
              <FullDomainStyled>
                {/* <Form.Item label={'Add description'} name='online_descriptionText'>
                  <Input placeholder='Add description' />
                  <p className='form-note'>
                    You can set up the description displayed on the top page of the booking link.
                  </p>
                </Form.Item> */}
                {/* <Form.Item label={'Banner Copy'} name='online_headerText'>
                  <Input placeholder='Add title' />
                  <TextArea rows={4} placeholder="Add subtext" />
                  <p className='form-note'>
                    You can set up the banner copy displayed on the top page of the booking link.
                  </p>
                </Form.Item> */}
              </FullDomainStyled>
              {/* <Form.Item label={'Description text'} name='online_descriptionText'>
                <Input />
              </Form.Item> */}
              {/* <div className='form-row'>
                <Form.Item noStyle name='checkinQR' />
                <div>
                  <div className='d-flex' style={{ gap: '10px' }}>
                    <SwitchInput
                      name='isPromotion'
                      label='D.O.B input'
                    />
                  </div>
                  <p className='form-note'>
                    Allow this input to be displayed in the booking online link.
                  </p>
                </div>
              </div> */}
              <div className='form-row'>
                <Form.Item noStyle name='checkinQR' />
                <div>
                  <div className='d-flex' style={{ gap: '10px' }}>
                    <SwitchInput
                      dataTesting={'online-featured-category'}
                      name='featured_category'
                      label='Featured category'
                      helperText='Display your category at the top of your online booking menu of services.'
                    />
                  </div>
                </div>
              </div>
              <div className='card-body'>
                <div>
                  <SwitchInput
                    dataTesting={'online-enable-time-roster'}
                    label='Enable Time Roster '
                    name='enable_timeroster_management'
                  />
                </div>
              </div>
              <div className='card-body'>
                <div>
                  <SwitchInput
                    dataTesting={'online-enable-per-payment'}
                    label='Enable Prepaid for Booking Online'
                    name='per_payment'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='common-card'>
            <h2 className='card-title'>QR Check-in</h2>
            <div className='card-body'>
              <div className='form-row'>
                <Form.Item noStyle name='checkinQR' />
                <div>
                  <div className='d-flex' style={{ gap: '10px' }}>
                    <SwitchInput
                      dataTesting={'qr-service-selection'}
                      name='selectServive'
                      label='Service selection'
                      helperText='Allow customers to select services in the check-in link.'
                    />
                  </div>
                </div>
              </div>
              <div className='form-row'>
                <Form.Item noStyle name='checkinQR' />
                <div>
                  <div className='d-flex' style={{ gap: '10px' }}>
                    <SwitchInput
                      dataTesting={'qr-featured-category'}
                      name='featured_category'
                      label='Featured category'
                      helperText='Display your category at the top of your online booking menu of services.'
                    />
                  </div>
                </div>
              </div>
              {/* <Form.Item name='' label='Booking Online'>
                <Input className='is-blur' id="myInput" disabled suffix={<Button onClick={() => handleCopy(getInitValues().fullDomain)} icon={<CopyOutlined />} />}></Input>
              </Form.Item> */}
              {/* <p className='form-note'>
                Link that customers can use to make online bookings.
              </p> */}
              <Form.Item label={'Promotion text'} name='checkIn_promotionText'>
                <Input placeholder='Enter promotion text' />
              </Form.Item>
              <Form.Item label={'Header text'} name='checkIn_headerText'>
                <Input placeholder='Enter header text' />
              </Form.Item>
              <Form.Item label={'Add description'} name='checkIn_descriptionText' tooltip={tooltipConfig('You can set up the description displayed on the top page of the check-in link.')}>
                <Input placeholder='Enter description text' />
              </Form.Item>
              {/* <FullDomainStyled>
                <Form.Item label={'Banner Copy'} name='online_headerText'>
                  <Input placeholder='Add title' />
                  <TextArea rows={4} placeholder="Add subtext" />
                  <p className='form-note'>
                    You can set up the banner copy displayed on the top page of the booking link.
                  </p>
                </Form.Item>
              </FullDomainStyled> */}
              {/* <div className='form-row'>
                <Form.Item noStyle name='checkinQR' />
                <div>
                  <div className='d-flex' style={{ gap: '10px' }}>
                    <SwitchInput
                      name='isPromotion'
                      label='D.O.B input'
                    />
                  </div>
                  <p className='form-note'>
                    Allow this input to be displayed in the check-in link.
                  </p>
                </div>
              </div> */}
            </div>
          </div>
          <div className='common-card'>
            <h2 className='card-title'>Configs</h2>
            <div className='card-body'>
              <div>
                <SwitchInput
                  dataTesting='config-view-birthday'
                  label='Enable show input birthday'
                  name='viewBirthday'
                  helperText='Allow entering input date of birth on the checkin and booking form page'
                />
              </div>
            </div>
          </div>
          <div className='common-card'>
              <h2 className='card-title form-helper-text'>
                Displayed store’s note
                <TooltipComponent title='Customize the displayed content for your customers.'/>
              </h2>
              <div className='card-body'>
                <div>
                  <SwitchInput
                    label='Displayed store’s note'
                    name='isDisplayNote'
                    helperText='You can set up the voucher terms and conditions here.'
                  />
                </div>
                <div className='form-row'>
                  <Form.Item label={<p>Store’s note</p>} name='notice'>
                    <TextArea placeholder='Enter your note here' rows={4} />
                  </Form.Item>
                </div>
                <div className='form-row'>
                  <Form.Item label='Voucher Terms' name='termsAndConditions'>
                    <TextArea placeholder='Enter Voucher Terms' rows={4} />
                  </Form.Item>
                </div>
              </div>
            </div>
        </div>
      </div>
    </FormTabSetting>
  );
};

export default CustomerLinksTab;

const FullDomainStyled = styled.div`
 border-bottom: 1px solid #F0F2F7;
  padding: 0 0 24px;
  margin: 0 0 24px;
  display: grid;
  gap: 24px;
  .ant-form-item-control {
    margin: 0 !important;
  }
  .ant-form-item-control-input-content {
    display: grid;
    gap: 8px;
  }
`;
