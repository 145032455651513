import {
  TreeSelect
} from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { EBookingStatus } from 'constants/index';

export type IOptionMulti = {
  title: string;
  value: string | number;
  isDisabledUnSelect?: boolean;
};
type ICheckStatus = {
  check: boolean;
  color: string;
};
type MultipleSelectProps = {
  value: IOptionMulti['value'][],
  onChange: (value: IOptionMulti['value'][]) => void
  options: IOptionMulti[],
  placeholder?: string;
  maxTagPlaceholder?: string;
  isWhiteInput?: boolean;
  disabled?: boolean;
  showArrow?: boolean;
};
const MultipleSelect: React.FC<MultipleSelectProps> = ({ isWhiteInput, disabled, onChange = () => undefined, options = [], value = [], placeholder, maxTagPlaceholder, showArrow }) => {
  const allIds = useMemo(() => options.map(({ value }) => value), [options]);
  const { t: formLang } = useTranslation('form');
  const checkStatus = (option: IOptionMulti) => {
    let check: ICheckStatus =  { check: false, color: '' };
    switch (option.value) {
      case EBookingStatus.OPEN:
        check = {check: true, color: 'var(--color-green)'};
        break;
      case EBookingStatus.IN_PROGRESS:
        check = {check: true, color: 'var(--color-yellow-02)'};
        break;
      case EBookingStatus.COMPLETED:
        check = {check: true, color: 'var(--color-green)'};
        break;
      case EBookingStatus.NO_SHOW:
        check = {check: true, color: 'var(--color-purple)'};
        break;
      case EBookingStatus.DRAFT:
        check = {check: true, color: 'var(--color-gray-01)'};
        break;
      case EBookingStatus.CANCELLED:
        check = {check: true, color: 'var(--color-red-02)'};
        break;
      default:
        check = {check: false, color: ''};
    }
    return check;
  };
  return (
    <MultipleStyled className={`${isWhiteInput ? 'inputSelect' : ''}`}>
      <TreeSelect
        disabled={disabled}
        allowClear={false}
        placeholder={placeholder ?? 'Select items'}
        treeCheckable
        showArrow={showArrow || !disabled}
        showCheckedStrategy={TreeSelect.SHOW_CHILD}
        dropdownStyle={{ maxHeight: '300px' }}
        onChange={ids => onChange(ids)}
        value={value}
        maxTagCount={1}
        tagRender={(props: any) => {
          return value?.length > 1 ? <></> : <>{props?.label}</>;
        }}
        size={!isWhiteInput ? 'large' : 'middle'}
        maxTagPlaceholder={() => value?.length === options?.length ? formLang('All') + ' ' + (maxTagPlaceholder ?? 'items') : `${value?.length} ${maxTagPlaceholder ?? 'items'}`}
        treeData={options.length > 0 ?
          disabled ?
            options.map(o => ({ ...o, disabled })) : [
              {
                title:
                  value?.length === options?.length ? (
                    <span
                      onClick={() => onChange([])}
                      style={{
                        display: 'block',
                        color: '#286FBE',
                        cursor: 'pointer',
                      }}
                    >
                      {formLang('UnselectAll')} 
                    </span>
                  ) : (
                    <span
                      onClick={() => onChange(allIds)}
                      style={{
                        display: 'block',
                        color: '#286FBE',
                        cursor: 'pointer'
                      }}
                    >
                      {formLang('SelectAll')}
                    </span>
                  ),
                value: 'xxx',
                checkable: false,
                disableCheckbox: true,
                disabled,
              },
              ...(options?.map((item) => {
                if (item?.isDisabledUnSelect){
                  return {
                    ...item,
                    disabled: value?.find(val => Number(val) === Number(item?.value)),
                  };
                }
                const newItem = { 
                  ...item,
                  title: (
                    <ItemStatus>
                      <span>{item.title}</span>
                      {
                        checkStatus(item).check && (
                          <div
                            className='circle'
                            style={{backgroundColor: item.title === 'Completed' ? checkStatus(item).color : '#fff', border: `3px solid ${checkStatus(item).color}`}}
                          />
                        )
                      }
                    </ItemStatus>
                  ) as string | React.ReactNode,
                 };
                return newItem;
              }) || []),
            ] : []}
      />
    </MultipleStyled>
  );
};
export default MultipleSelect;

const MultipleStyled = styled.div`
      &.inputSelect {
        background:#fff;
      border-radius: 6px;
      height: 42px;
      &:hover{
        cursor: pointer;
      }
      .ant-select {
        height:100%;
      display:flex;
  }
      .ant-select-selector {
        &:hover{
          cursor: pointer;
        }
        display: flex;
      -webkit-box-align: center;
      align-items: center;
      width: 100%;
      min-width: 248px;
      min-width: unset;
      border-radius: 6px;
      font-weight: 500;
      font-size: 14px;
      font-family: var(--font-base);
      padding: 0px 24px !important;
      background-color: #fff  !important;
      border: none !important;
      color: var(--color-primary)  !important;
      box-shadow: none !important;
      min-width: 200px;
      height: 100%;
      background-color: var(--color-white);
      padding: 0px 32px 0px 16px;
      border-radius: 6px;
}
}

      .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest {
        width:90%;
}
      .ant-select-selection-item {
        &:hover{
          cursor: pointer;
        }
        width: 100%;
      background: none !important;
      border: none !important;
      padding:0 !important;
}
      .ant-select-selection-overflow-item {
        width: 90%;
      color: var(--color-primary) !important;
      .ant-select-selection-item-content {
        box-shadow: none !important;
        font-size: 14px;
        font-weight: 500;
        font-family: var(--font-base);
        color: var(--color-primary) !important;
  }
}
      .ant-select-selection-overflow-item-suffix {
        display:none !important;
}
      .ant-select-selector {
        display: flex;
      align-items: center;
      width: 100%;
      padding: 0 24px !important;
      min-width: 248px;
      height: 64px;
      background-color: var(--color-white-01) !important;
      border-radius: 6px;
      border: none !important;
      color: var(--color-primary) !important;
      font-weight: 500;
      box-shadow: none !important;
      font-size: 14px;
      font-family: var(--font-base);
}
      `;

const ItemStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .circle {
    border-radius: 100%;
    width: 12px;
    height: 12px;
    margin-left: 8px;
  }
`;