import bookingSelectors from 'features/bookings/services/selectors';
import styled from 'styled-components';
import { formatMoney } from 'utils/unit';

function ReviewBooking() {
  const formValue = bookingSelectors.bookingOnline.getFormValues();

  return (
    <ReviewBookingStyled>
      <p className='review-booking-header'>Review booking</p>
      <p className='review-booking-desc'>Review the application fees of your booking.</p>
      <div className='value-wrap'>
        <div className='line-horizontal'>
          <p className='line-content'>Subtotal</p>
          <p className='line-value'>{formatMoney(formValue?.total_sub as number)}</p>
        </div>
        {formValue?.sum_promotion !== undefined && formValue?.sum_promotion > 0 && (
          <div className='line-horizontal'>
            <p className='line-content'>Promotion</p>
            <p className='line-value'>
              {formValue?.sum_promotion && formValue?.sum_promotion > 0
                ? formatMoney(formValue?.sum_promotion)
                : 0}
            </p>
          </div>
        )}
      </div>
      <div className={formValue?.pre_payment_value !== 100 ? 'value-wrap' : ''}>
        <div className='line-horizontal'>
          <p className='line-content total-content'>Total</p>
          <p className='line-value total-value'>{formatMoney(formValue?.total_pay as number)}</p>
        </div>
        {formValue?.pre_payment_value !== 100 ? (
          <div className='line-horizontal'>
            <p className='line-content'>Prepaid</p>
            <p className='line-value'>{formatMoney(formValue?.total_prepaid as number)}</p>
          </div>
        ) : (
          <></>
        )}
      </div>

      {formValue?.pre_payment_value !== 100 ? (
        <div>
          <div className='line-horizontal'>
            <p className='line-content'>Balance remaining</p>
            <p className='line-value'>
            {formatMoney((formValue?.total_pay ?? 0) - (formValue?.total_prepaid ?? 0))}
            </p>
          </div>
          <p className='line-content'>
            *You will be charged {formValue?.pre_payment_value}% of the service price for making an
            appointment.
          </p>
        </div>
      ) : (
        <></>
      )}
    </ReviewBookingStyled>
  );
}

export default ReviewBooking;

const ReviewBookingStyled = styled.div`
  .review-booking-header {
    font-weight: 600;
    font-size: 24px;
    line-height: 33.6px;
    color: #363565;
  }

  .review-booking-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 19.6px;
    color: #7980bc;
    margin-bottom: 24px;
  }

  .line-horizontal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  .line-content,
  .line-value {
    font-size: 16px;
    line-height: 22.4px;
    color: #363565;
  }

  .line-content {
    font-weight: 400;
  }

  .line-value {
    font-weight: 600;
  }

  .total-content {
    font-weight: 500;
  }

  .total-value {
    font-weight: 600;
    font-size: 24px;
    line-height: 33.6px;
  }

  .value-wrap {
    border-bottom: 1px solid #f0f2f7;
    margin-bottom: 12px;
  }
`;
