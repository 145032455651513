import { Form, Input, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import CardNumberIcon from 'assets/images/setting/card-number-icon.png';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { forwardRef, useImperativeHandle } from 'react';

export interface PaymentMethodInputRef {
  submitStripeAndElement: () => any;
}

interface PaymentMethodInput {}

/* eslint-disable-next-line */
const PaymentMethodInput = forwardRef<
  PaymentMethodInputRef,
  PaymentMethodInput
>((props, ref) => {
  const { t: topUpLang } = useTranslation('top_up');
  const stripe = useStripe();
  const elements = useElements();
  const form = Form.useFormInstance();

  const submitStripeAndElement: PaymentMethodInputRef['submitStripeAndElement'] =
    () => {
      if (!stripe || !elements) {
        return null;
      }
      const cardNumberElement = elements.getElement(CardNumberElement);

      return cardNumberElement;
    };

  useImperativeHandle(ref, () => ({
    submitStripeAndElement,
  }));

  const handleCardChange = (value: any) => {
    form.setFieldValue('cardNumber', value);
    form.validateFields(['cardNumber']);
  };

  return (
    <div className='box-wrap'>
      <p className='box-title'>{topUpLang('topUpForm.paymentMethod.text')}</p>
      <p className='box-desc'>{topUpLang('topUpForm.paymentMethod.desc')}</p>
      <div>
        <Form.Item
          label={topUpLang('topUpForm.formLabel.cardHolderFullName')}
          name='cardHolderFullName'
          rules={[{ required: true }]}
        >
          <Input
            placeholder={
              topUpLang('topUpForm.formPlaceholder.cardHolderFullName') || ''
            }
          />
        </Form.Item>
      </div>
      <div className='form-card-number'>
        <Form.Item
          label={topUpLang('topUpForm.formLabel.cardNumber')}
          name='cardNumber'
          rules={[
            {
              validator(rule, value, callback) {
                if (!value || value?.empty){
                  return callback('Please enter card number');
                }

                if (value && !value?.complete) {
                  callback('Card number is invalid');
                }
                callback();
              },
            },
          ]}
        >
          <div className='card-number-wrap'>
            <StyledCardNumberElement
              options={{
                placeholder:
                  topUpLang('topUpForm.formPlaceholder.cardNumber') || '',
                style: {
                  base: {
                    fontSize: '16px',
                    '::placeholder': {
                      color: '#C2C2E1',
                    },
                  },
                },
              }}
              onChange={handleCardChange}
            />
            <div className='card-number-icon'>
              <img src={CardNumberIcon} />
            </div>
          </div>
        </Form.Item>
      </div>
      <div className='form-row'>
        <Form.Item
          label={topUpLang('topUpForm.formLabel.expiryDate')}
          name='expiryDate'
          rules={[
            { required: true, message: 'Please select expiry date' },
            {
              validator(rule, value, callback) {
                if (value && !value?.complete) {
                  callback('Expiration time is invalid');
                }
                callback();
              },
            },
          ]}
        >
          <StyledCardExpiryElement
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  '::placeholder': {
                    color: '#C2C2E1',
                  },
                },
              },
            }}
          />
        </Form.Item>
        <Form.Item
          label={topUpLang('topUpForm.formLabel.cvv')}
          name='cvc'
          rules={[
            {
              required: true,
            },
            {
              validator(rule, value, callback) {
                if (value && !value?.complete) {
                  callback('CVC is invalid');
                }
                callback();
              },
            },
          ]}
        >
          <StyledCardCvcElement
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  '::placeholder': {
                    color: '#C2C2E1',
                  },
                },
              },
            }}
          />
        </Form.Item>
      </div>
      <div>
        <Form.Item
          label={
            <p>
              {topUpLang('topUpForm.formLabel.abnNumber')}
              <span>(Optional)</span>
            </p>
          }
          name='abnNumber'
        >
          <InputNumber
            style={{ width: '100%' }}
            inputMode='numeric'
            placeholder={topUpLang('topUpForm.formPlaceholder.abnNumber') || ''}
            className='abnNumber'
          />
        </Form.Item>
      </div>
    </div>
  );
});

const StyledCardNumberElement = styled(CardNumberElement)`
  font-size: 16px;
  border: none;
  border-radius: 6px;
  padding: 24px;
  padding-right: 100px;
  color: rgba(0, 0, 0, 0.65);
  background-color: var(--Offwhite, #f0f2f7);
  height: 64px;
`;

const StyledCardExpiryElement = styled(CardExpiryElement)`
  font-size: 16px;
  border: none;
  border-radius: 6px;
  padding: 24px;
  color: rgba(0, 0, 0, 0.65);
  background-color: var(--Offwhite, #f0f2f7);
  height: 64px;
`;

const StyledCardCvcElement = styled(CardCvcElement)`
  font-size: 16px;
  border: none;
  border-radius: 6px;
  padding: 24px;
  color: rgba(0, 0, 0, 0.65);
  background-color: var(--Offwhite, #f0f2f7);
  height: 64px;
`;

export default PaymentMethodInput;
