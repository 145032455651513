import { useEffect, useState } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import { PATH } from '../../../constants/path.enum';
import styles from './index.module.scss';
type BreadcrumbFunc = (pathName: string) => string[];
type MenuConfigType = {
  path: string;
  breadcrumbs: string[] | BreadcrumbFunc;
};
const IMenuConfig = [
  {
    path: PATH.bookings,
    breadcrumbs: ['Bookings'],
  },
  {
    path: PATH.quick_booking,
    breadcrumbs: ['Add Booking'],
  },
  {
    path: PATH.edit_booking,
    breadcrumbs: ['Edit Booking'],
  },
  {
    path: PATH.sales,
    breadcrumbs: ['Sales'],
  },
  {
    path: PATH.customers,
    breadcrumbs: ['customers'],
  },
  {
    path: PATH.customersDetail,
    breadcrumbs: (pathname: string) => pathname.split('/'),
  },
  {
    path: PATH.services,
    breadcrumbs: ['services'],
  },
  {
    path: PATH.analytics,
    breadcrumbs: ['analytics'],
  },
  {
    path: PATH.invoices,
    breadcrumbs: ['invoices'],
  },
  {
    path: PATH.invoicesDetail,
    breadcrumbs: (pathname: string) => pathname.split('/'),
  },
  {
    path: PATH.more,
    breadcrumbs: ['more'],
  },
  {
    path: PATH.reviews,
    breadcrumbs: ['More', 'reviews'],
  },
  {
    path: PATH.promotions,
    breadcrumbs: ['More', 'promotions'],
  },
  {
    path: PATH.vouchers,
    breadcrumbs: ['More', 'vouchers'],
  },
  {
    path: PATH.locations,
    breadcrumbs: ['More', 'locations'],
  },
  {
    path: PATH.locations_addnew,
    breadcrumbs: ['More', 'locations', 'add new'],
  },
  {
    path: PATH.locations_edit,
    breadcrumbs: ['More', 'locations', 'edit'],
  },
  {
    path: PATH.loyaltyProgram,
    breadcrumbs: ['More', 'loyalty program'],
  },
  {
    path: PATH.users,
    breadcrumbs: ['More', 'Users'],
  },
  {
    path: PATH.timeSchedule,
    breadcrumbs: ['More', 'Time Schedule'],
  },
  {
    path: PATH.workingHour,
    breadcrumbs: ['Working hour'],
  },
  {
    path: PATH.workingHourDetail,
    breadcrumbs: ['Working hour', 'View details'],
  },
  {
    path: PATH.categories,
    breadcrumbs: ['Service Categories'],
  },
  {
    path: PATH.placeholder,
    breadcrumbs: ['placeholder'],
  },
  {
    path: PATH.settings,
    breadcrumbs: ['settings'],
  },
  {
    path: PATH.notification,
    breadcrumbs: ['notification'],
  },
  {
    path: PATH.editProfile,
    breadcrumbs: ['Edit Profile'],
  },
  {
    path: PATH.checkout,
    breadcrumbs: ['Checkout'],
  },
];

const getListPaths = (pathname: string) => pathname?.split('/') ?? [];

const Breadcrumbs = () => {
  // @ts-ignore
  const pathname = useResolvedPath().pathname;
  const [breadcrumbsList, setBreadcrumbsList] = useState<string[]>([]);
  const navigate = useNavigate();

  const findingBreadcrumbItem = (item: MenuConfigType) => {
    // case with static path
    if (item.path === pathname) return true;

    // case with dynamic path
    if (
      pathname?.includes(item.path) &&
      getListPaths(pathname).length === getListPaths(item.path).length
    )
      return true;

    return false;
  };

  const getBreadcrumbs = (
    breadcrumbs: MenuConfigType['breadcrumbs']
  ): string[] => {
    return typeof breadcrumbs === 'function'
      ? breadcrumbs(pathname?.replace('/private/', ''))
      : breadcrumbs;
  };

  useEffect(() => {
    if (pathname !== '') {
      const currentBreadcrumbs = IMenuConfig.find(findingBreadcrumbItem);

      if (currentBreadcrumbs) {
        const breadcrumbs: string[] = getBreadcrumbs(
          currentBreadcrumbs.breadcrumbs
        );
        setBreadcrumbsList(breadcrumbs);
      }
    }
  }, [pathname]);

  const handleBreadcrumbClick = () => {
    navigate(`/private/${getListPaths(pathname.replace('/private/', ''))[0]}`);
  };

  return (
    <ol className={styles.breadcrumbs}>
      {breadcrumbsList &&
        breadcrumbsList.map((breadcrumb, index) => {
          // return <li key={index}>{commonLang(`Breadcrumb.${breadcrumb.toLocaleLowerCase()}`)}</li>;
          return (
            <li data-testing={`${breadcrumb}_page`}  key={index} onClick={() => handleBreadcrumbClick()}>
              {breadcrumb}
            </li>
          );
        })}
    </ol>
  );
};

export default Breadcrumbs;
