import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface ITooltip {
  title?: string;
}
const defaultIconColor  = '#4a9d77';
function TooltipComponent(props: ITooltip) {
  const { title = '' } = props;
  return (
    <Tooltip placement='topLeft' title={title} className='icon-helper-text'>
      <InfoCircleOutlined style={{fontSize: '1rem', color: defaultIconColor }}/>
    </Tooltip>
  );
}

export const tooltipConfig = (title = '', icon: any = <InfoCircleOutlined style={{ fontSize: '0.9rem', color: defaultIconColor  }} />) => ({
  title: title,
  icon: icon,
});

export default TooltipComponent;
