import { IBookingItemResData } from 'features/bookings/services/types/booking';
import { useCallback, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { IDndDataCalendar } from './RowCalendar';
import moment, { Moment } from 'moment';
import { BREAK_TIME_MINUTES, TIME_START_FORMAT_RESPONSE } from 'features/bookings/services/constants';
import apisBooking from 'features/bookings/services/apis';
import message from 'components/common/Message';
import bookingActions from 'features/bookings/services/actions';
import bookingSelectors from 'features/bookings/services/selectors';
import { CalendarViewType } from 'constants/index';
import { get } from 'lodash';

export const useUpdateDateView = (): [boolean, ((book: IBookingItemResData, overDnD: IDndDataCalendar, pin_number?: string) => Promise<void>)] => {
  const dispatch = useAppDispatch();
  const activeLocation = bookingSelectors.getCalendarCurrentLocation();
  const viewType = bookingSelectors.getCalendarViewType();
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const bookingDateStore = bookingSelectors.getCalendarParamValue('date') as number;
  const checkIsBreakDayWeekView = useCallback(
    (timeStart: Moment) => {
      const openDate = activeLocation?.time_opening.filter(
        (o) => o.weekday === timeStart.format('dddd').toLocaleLowerCase()
      );
      
      const isWorkingTime = openDate?.find((o) => {
        const startTime = moment(timeStart).set({ hour: o.start, minute: o.startMinute, second: 0 });
        const endTime = moment(timeStart).set({ hour: o.end, minute: o.endMinute, second: 0 });
        const isBetween = timeStart.isBetween(startTime, endTime, null, '[]');
        const isSameStart = timeStart.isSame(startTime);
        const isSameEnd = timeStart.isSame(endTime);
        return isSameStart || isBetween || isSameEnd;
      });
      return !isWorkingTime;
    },
    [activeLocation]
  );

  const updateBooking = async (book: IBookingItemResData, overDnD: IDndDataCalendar, pin_number?: string) => {
    let timeStart;
    const booking_date_store = moment(bookingDateStore);
    if (viewType === CalendarViewType.DaysView) {
      timeStart = moment(overDnD.id, 'HH:mm/DD/MM/YYYY');
    } else {
      timeStart = moment(overDnD.rowTime).set({
        date: booking_date_store.date(),
        month: booking_date_store.month(),
        year: booking_date_store.year()
      });
    }

    const bookId = book.id?.toString();
    if (!bookId) return;

    const _time_start = timeStart.clone();
    const book_assignment_services = book.book_assignment_services.map((o) => {
      const result = {
        service_id: o?.service_id,
        time_start: _time_start.format(TIME_START_FORMAT_RESPONSE),
        duration_time: o?.duration_time,
        quantity: o?.quantity,
        employee_id: o?.assigned_employee?.employee?.id ?? null,
        sale_price: o?.service_price,
        service_variant_id: o?.service_variant_id ?? null,
      };
      const breakTimeMinutes = BREAK_TIME_MINUTES;
      const quantity = (o.quantity ?? 0);
      const prevServiceMinutes = ((o.duration_time ?? 0) * quantity) + (quantity - 1) * breakTimeMinutes;
      _time_start.add(breakTimeMinutes + prevServiceMinutes, 'minute');
      return result;
    });

    const payload = {
      book_start: timeStart.format(TIME_START_FORMAT_RESPONSE),
      customer_id: book?.customer?.id,
      customer_code: book?.customer?.customer_code,
      note: book?.appointment_note || book?.noted_client,
      book_assignment_services,
      is_walkin_in: !!book?.customer?.is_walkin_in,
      action_update: 'drag_drop',
      pin_number
    };
    setLoadingUpdate(true);
    if (checkIsBreakDayWeekView(timeStart)) {
      message.error('This time is break time');
      setLoadingUpdate(false);
      return;
    } else {
      try {
        const response = await apisBooking.updateBooking(bookId || '', payload);
        if (response?.data.error) {
          message.error(response?.data.error.message);
        } else {
          message.success('You have successfully edit this booking!');
          dispatch(bookingActions.getBookings.fetch({}));
        }
      } catch (error: any) {
        const response = get<Record<string, string[]>>(
          error,
          'response.data.errors',
          {}
        );
  
        const errors = Object.entries(response);
        errors.map(([, value]) => {
          message.error(value[0]);
        });
  
      } finally {
        setLoadingUpdate(false);
      }
    }
  };

  return ([loadingUpdate, updateBooking]);
};

export default useUpdateDateView;
