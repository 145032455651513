import fetch from 'services/request';
import { IHolidayParam } from './types/holiday';
import { StripeManualReq } from './types/topUp';

const BASE_URL = 'api/v1/merchants/:merchant_code/settings';
const STRIPE_BASE_URL = 'api/v1/merchants/:merchant_code/stripe';
const SMS_BASE_URL = 'api/v1/merchants/:merchant_code/notification';

const getSetting = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/settings/general',
    // params: { ...params },
  });
};

const getSettingBookingForm = (merchant_code: string) => {
  return fetch({
    method: 'get',
    url: `api/v1/merchants/${merchant_code}/settings/booking-form`,
  });
};

const updateGeneral = (params?: any) => {
  return fetch({
    method: 'put',
    url: `${BASE_URL}/general`,
    body: { ...params },
  });
};

const updateBooking = (params?: any) => {
  return fetch({
    method: 'put',
    url: `${BASE_URL}/booking`,
    body: { ...params },
  });
};

const addClosedDate = (body?: any) => {
  return fetch({
    method: 'post',
    url: `${BASE_URL}/add-closed-date`,
    body: { ...body },
  });
};

const updateClosedDate = (id?: number, body?: any) => {
  return fetch({
    method: 'put',
    url: `${BASE_URL}/update-closed-date/${id}`,
    body: { ...body },
  });
};

const updateNotification = (params?: any) => {
  return fetch({
    method: 'put',
    url: `${BASE_URL}/notification`,
    body: { ...params },
  });
};

const deleteClosedDate = (id?: number) => {
  return fetch({
    method: 'delete',
    url: `${BASE_URL}/delete-closed-date/${id}`,
  });
};

const getListClosedDate = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/settings/list-closed-date',
    // params: { ...params },
  });
};

const getOptionBooking = (params?: any) => {
  return fetch({
    method: 'get',
    url: `${BASE_URL}/option-booking`,
    params: { ...params },
  });
};

const updateCustomerLink = (body?: any) => {
  return fetch({
    method: 'put',
    url: `${BASE_URL}/booking-link`,
    body: { ...body },
  });
};

const pairPOS = (body?: any) => {
  return fetch({
    method: 'post',
    url: `${BASE_URL}/repair-pos`,
    body: { ...body },
  });
};

const getNumberOfNotification = (params?: any) => {
  return fetch({
    method: 'get',
    url: `${BASE_URL}/number-of-notification`,
    params: { ...params },
  });
};

const getListCancellationReason = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/cancellation-reason/list',
  });
};

const createCancellationReason = (body?: any) => {
  return fetch({
    method: 'post',
    url: 'api/v1/merchants/:merchant_code/cancellation-reason/create',
    body: body,
  });
};

const updateCancellationReason = (id: number, body?: any) => {
  return fetch({
    method: 'put',
    url: `api/v1/merchants/:merchant_code/cancellation-reason/${id}`,
    body: body,
  });
};

const deleteCancellationReason = (id: number) => {
  return fetch({
    method: 'delete',
    url: `api/v1/merchants/:merchant_code/cancellation-reason/${id}`,
  });
};

const getListServiceCharge = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/surcharge/list',
  });
};

const createServiceCharge = (body?: any) => {
  return fetch({
    method: 'post',
    url: 'api/v1/merchants/:merchant_code/surcharge/create',
    body: { ...body },
  });
};

const getDetailServiceCharge = (id: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/surcharge/detail/' + id,
    params: { id: id },
  });
};

const getShowServiceCharge = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/merchants/:merchant_code/surcharge/show',
  });
};

const updateServiceChage = (id: number, body?: any) => {
  return fetch({
    method: 'put',
    url: `api/v1/merchants/:merchant_code/surcharge/${id}`,
    body: { ...body },
  });
};

const deleteServiceCharge = (id: number) => {
  return fetch({
    method: 'delete',
    url: `api/v1/merchants/:merchant_code/surcharge/${id}`,
  });
};

const postTopUpCharge = (body?: StripeManualReq) => {
  return fetch({
    method: 'post',
    url: 'api/v1/merchants/:merchant_code/stripe/manual/store',
    body: { ...body },
  });
};

const getTopUpHistory = (params?: {page?: number, per_page?: number}) => {
  const newParam = {
    page: params?.page || 1,
    per_page: params?.per_page || 10
  };

  return fetch({
    method: 'get',
    url: STRIPE_BASE_URL + '/history-send-message',
    params: {
      ...newParam
    }
  });
};

const getTopUpSMSHistory = (params?: {page?: number, per_page?: number}) => {
  const newParam = {
    page: params?.page || 1,
    per_page: params?.per_page || 10
  };

  return fetch({
    method: 'get',
    url: SMS_BASE_URL + '/sms/list',
    params: {
      ...newParam
    }
  });
};

const getMerchantStripeBalance = () => {
  return fetch({
    method: 'get',
    url: STRIPE_BASE_URL + '/balance',
  });
};

const getHolidayInfoToday = (params: IHolidayParam) => {
  return fetch({
    method: 'get',
    url: `api/v1/merchants/:merchant_code/surcharge/holiday/${params.merchant_location_id}/check-apply`,
    params: {
      today: params.today,
    } as any,
  });
};

const getResetPinNumber = () => {
  return fetch({
    method: 'get',
    url: `${BASE_URL}/reset-pin-number`,
  });
};

const postResetPinNumber = (body?: any) => {
  return fetch({
    method: 'post',
    url: `${BASE_URL}/verify-pin-otp`,
    body: { ...body },
  });
};

const putSwitchAutoTopup = (autoTopUp: boolean) => {
  return fetch({
    method: 'put',
    url: `${BASE_URL}/switch-auto-topup`,
    body: { enable_auto_top_up: autoTopUp },
  });
};

const settingApis = {
  getSetting,
  updateGeneral,
  updateBooking,
  updateNotification,
  getOptionBooking,
  addClosedDate,
  getListClosedDate,
  updateCustomerLink,
  pairPOS,
  updateClosedDate,
  deleteClosedDate,
  getSettingBookingForm,
  getNumberOfNotification,
  createCancellationReason,
  updateCancellationReason,
  getListCancellationReason,
  deleteCancellationReason,
  // Service charge
  getListServiceCharge,
  createServiceCharge,
  getDetailServiceCharge,
  getShowServiceCharge,
  updateServiceChage,
  deleteServiceCharge,

  postTopUpCharge,
  getTopUpHistory,
  getTopUpSMSHistory,
  getMerchantStripeBalance,
  getHolidayInfoToday,
  getResetPinNumber,
  postResetPinNumber,
  putSwitchAutoTopup
};
export default settingApis;
