import { Switch } from 'antd';
import message from 'components/common/Message';
import { ModalConfirmRef } from 'components/common/Modal/ModalConfirm';
import settingActions from 'features/settings/services/actions';
import settingApis from 'features/settings/services/apis';
import { get } from 'lodash';
import { RefObject } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';

type Props = {
  id: any;
  status: any;
  confirmRef?: RefObject<ModalConfirmRef>;
};

const HolidaySwitchStatus = ({ id, status, confirmRef }: Props) => {
  const dispatch = useAppDispatch();
  const setPageLoading = useSetLoadingPage();

  const handleUpdateStatus = async () => {
    if (!id) return;
    setPageLoading(true);
    
    const updatedStatus = status === 1 ? 0 : 1;
    const payload = { status: updatedStatus, id };
    
    try {
      const res = await settingApis.updateServiceChage(id?.toString(), payload);
      dispatch(settingActions.getListServiceCharge.fetch());
      const msg = get(res, 'data.message', '');
      if (msg) {
        message.success(msg);
      }
    } catch (error) {
      const errors = Object.entries(get(error, 'response.data.errors', {}));
      if (errors.length > 0) {
        errors.map(([, valueArr]) => {
          message.error(get(valueArr, [0], ''));
        });
        return;
      }
      message.error('An error occurred. Please try again');
    } finally {
      setPageLoading(false);
    }
  };

  const onClick = () => {
    if (!confirmRef) return;
    confirmRef.current?.show({
      title: 'Are you sure',
      msg: `Are you sure ${status === 1 ? 'disable' : 'enable'} this holiday?`, 
      submit: handleUpdateStatus,
    });
  };

  return (
    <>
      <Switch onClick={onClick} checked={status === 1} />
    </>
  );
};

export default HolidaySwitchStatus;
