import storage from 'utils/sessionStorage';

const merchantCode: string = storage.merchantCode.get();
const merchantName: string = storage.merchantName.get();


export const PATH = {
  bookings: '/private/bookings',
  quick_booking: '/private/bookings/quick-booking',
  edit_booking: '/private/bookings/edit-booking',
  checkout: '/private/bookings/checkout/',
  sales: '/private/sales',
  customers: '/private/customers',
  customersDetail: '/private/customers/',
  services: '/private/services',
  categories: '/private/services/categories',
  analytics: '/private/analytics',
  invoices: '/private/invoices',
  invoicesDetail: '/private/invoices/',
  more: '/private/more',
  reviews: '/private/reviews',
  promotions: '/private/promotions',
  vouchers: '/private/vouchers',
  locations: '/private/locations',
  locations_addnew: '/private/locations/add-new',
  locations_edit: '/private/locations/edit/',
  loyaltyProgram: '/private/loyalty-program',
  users: '/private/users',
  workingHour: '/private/working-hour',
  unsubcribedEmail: '/unsubcribed-email',
  timeSchedule: '/private/time-schedule',
  workingHourDetail: '/private/working-hour/detail',
  placeholder: '/private/placeholder',
  settings: '/private/settings',
  editProfile: '/private/edit-profile',
  notification: '/private/notification',
  sign_in: '/sign-in',
  team_check_in: '/staff-check-in',
  booking_online: `/${merchantName.replaceAll(' ','-')}/booking-form/${merchantCode}`.toLowerCase(),
  privacy_policy: `/${merchantName.replaceAll(' ','-')}/privacy-policy`.toLowerCase(),
  refund_policy: `/${merchantName.replaceAll(' ','-')}/refund-policy`.toLowerCase(),
  // check_in: `/${merchantName.replaceAll(' ','')}/checkin/${merchantCode}/2`,
};


export const IMenuConfig = [
  {
    path: PATH.booking_online,
    breadcrumbs: ['Booking Online'],
  },
  {
    path: PATH.team_check_in,
    breadcrumbs: ['Team Check In'],
  },
  {
    path: PATH.sign_in,
    breadcrumbs: ['Sign in'],
  },
  {
    path: PATH.bookings,
    breadcrumbs: ['Bookings'],
  },
  {
    path: PATH.quick_booking,
    breadcrumbs: ['Add Booking'],
  },
  {
    path: PATH.edit_booking,
    breadcrumbs: ['Edit Booking'],
  },
  {
    path: PATH.sales,
    breadcrumbs: ['Sales'],
  },
  {
    path: PATH.customers,
    breadcrumbs: ['Customers'],
  },
  {
    path: PATH.customersDetail,
    breadcrumbs: (pathname: string) => pathname.split('/'),
  },
  {
    path: PATH.services,
    breadcrumbs: ['Services'],
  },
  {
    path: PATH.analytics,
    breadcrumbs: ['Analytics'],
  },
  {
    path: PATH.invoices,
    breadcrumbs: ['Invoices'],
  },
  {
    path: PATH.invoicesDetail,
    breadcrumbs: (pathname: string) => pathname.split('/'),
  },
  {
    path: PATH.more,
    breadcrumbs: ['more'],
  },
  {
    path: PATH.reviews,
    breadcrumbs: ['More', 'Reviews'],
  },
  {
    path: PATH.promotions,
    breadcrumbs: ['More', 'Promotions'],
  },
  {
    path: PATH.vouchers,
    breadcrumbs: ['More', 'Vouchers'],
  },
  {
    path: PATH.locations,
    breadcrumbs: ['More', 'Locations'],
  },
  {
    path: PATH.locations_addnew,
    breadcrumbs: ['More', 'Locations', 'Add new'],
  },
  {
    path: PATH.locations_edit,
    breadcrumbs: ['More', 'Locations', 'Edit'],
  },
  {
    path: PATH.loyaltyProgram,
    breadcrumbs: ['More', 'Loyalty program'],
  },
  {
    path: PATH.users,
    breadcrumbs: ['More', 'Users'],
  },
  {
    path: PATH.timeSchedule,
    breadcrumbs: ['More', 'Time Schedule'],
  },
  {
    path: PATH.workingHour,
    breadcrumbs: ['Working hour'],
  },
  {
    path: PATH.workingHourDetail,
    breadcrumbs: ['Working hour', 'View details'],
  },
  {
    path: PATH.categories,
    breadcrumbs: ['Service Categories'],
  },
  {
    path: PATH.placeholder,
    breadcrumbs: ['Placeholder'],
  },
  {
    path: PATH.settings,
    breadcrumbs: ['Settings'],
  },
  {
    path: PATH.notification,
    breadcrumbs: ['Notification'],
  },
  {
    path: PATH.editProfile,
    breadcrumbs: ['Edit Profile'],
  },
  {
    path: PATH.unsubcribedEmail,
    breadcrumbs: ['Unsubcribed Email'],
  }, 
];