import * as React from 'react';
import { useState, useEffect, createRef } from 'react';

export const defaultSettings = {
  paySecret: '',
  theme: 'default',
  styleProps: {
    bodyBackgroundColor: '#fff',
  },
  options: {
    applePay: {
      enabled: true,
    },
    googlePay: {
      enabled: true,
    },
  },
};

enum TyroJSErrorType {
  INVALID = 'invalid'
}

const tyroJSRef = createRef<any>();

const SimpleTyroJSSample = () => {

  const [configuration, setConfiguration] = useState(defaultSettings);

  const [error, setError] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [libraryReady, setLibraryReady] = useState(false);
  const [fetchingPaySecret, setFetchingPaySecret] = useState(false);
  const [payRequestReady, setPayRequestReady] = useState(false);
  const [payFormReady, setPayFormReady] = useState(false);
  const [submittingOverlay, setSubmittingOverlay] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [payComplete, setPayComplete] = useState(false);
  // const [amount, setAmount] = useState(0);

  const { paySecret, theme, styleProps, options } = configuration;

  // STEP 1, Attach TyroJS Library
  // Installs the TyroJS Library onto the DOM
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'tyro-js-library';
    script.src = 'https://pay.connect.tyro.com/v1/tyro.js';
    script.crossOrigin = 'anonymous';
    script.async = true;
    script.onload = () => setLibraryReady(true);
    document.body.appendChild(script);
  }, []);

  // STEP 2, Fetch the Pay Secret
  useEffect(() => {
    setError({});
    if (!paySecret?.length && !fetchingPaySecret) {
      fetchPaySecret();
    }
  }, [paySecret]);

  // Fetch the Pay Secret
  // This would be replaced with your own implementation to obtain your pay request
  const fetchPaySecret = async (): Promise<void> => {
    setFetchingPaySecret(true);
    try {
      const response = await fetch('https://api.tyro.com/connect/pay/requests', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InJmRi1VaEpOR0Q4dF9TS2xhcG9WQiJ9.eyJpc3MiOiJodHRwczovL2F1dGguY29ubmVjdC50eXJvLmNvbS8iLCJzdWIiOiJoU2pOSHlBYlR4RFFvVU5mNTByTERTbUV4aTM1MUplS0BjbGllbnRzIiwiYXVkIjoiaHR0cHM6Ly9hcHAuY29ubmVjdC50eXJvIiwiaWF0IjoxNzExMDE0NDM0LCJleHAiOjE3MTEwNTc2MzQsImd0eSI6ImNsaWVudC1jcmVkZW50aWFscyIsImF6cCI6ImhTak5IeUFiVHhEUW9VTmY1MHJMRFNtRXhpMzUxSmVLIn0.DNcpPPe4oSDL44wCqKbeH3IAL9WYw2pKfDEKQ2epOQLip0uI1cugFDTrsliVKeaeWlFJv6611ZVqmE6qUw3f_tzUnQJCWkbJM_MUsv3DNyuxAZ5CQgDxqdaEodG04c7-m67tfzSB0-JZXL0VNktDIYr8bC-bHmsNv7kORcTphgrC8DvmhWrNzXt83Wb-4D7pSHu1O3fF-y4ZtqeyvW0W7Mw3KSuVd6Mqpfx1wkvNQa_PWdB68Y7s0Uc5CQm7xd0Sn8nPvy8NTKUrW464zqeVpKvZitIJIIhmeymV2SDFQD3_zWwWHjUvTz5qt7Rpgw2T66FVzJ5UlYPTY82Q7bY6pg'
        },
        body: JSON.stringify({'total': {
          'amount': 1,
          'currency': 'AUD'
        },
        'origin': {
          'orderId': '123456789'
        },
        'payMethod': {
            'save': true
        },
        'provider': {
          'name': 'TYRO',
          'method': 'CARD'
        },
        'locationId': 'tc-damospay-test-4000'}),
      });
      const { paySecret } = await response.json();
      console.log(paySecret );
      
      setConfiguration({
        ...configuration,
        paySecret,
      });
    } catch (error: any) {
      console.log(error);
      
      setError({ type: 'critical', errorCode: error.errorCode, message: 'Failed to generate Demo Pay Request', debug: { error } });
    }
    setFetchingPaySecret(false);
  };

  // STEP 3, Load the Pay Request into TyroJS
  useEffect(() => {
    if (libraryReady && paySecret?.length) {
      initPayRequest();
    }
  }, [
    libraryReady,
    paySecret,
  ]);

  // Initialize Tyro.js with the Pay Request
  async function initPayRequest(): Promise<void> {
    setLoading(true);
    setPayRequestReady(false);
    setPayFormReady(false);
    setSubmitting(false);
    try {
      // @ts-ignore
      tyroJSRef.current.tyroJSInstance = Tyro({
        liveMode: true,
      });
      
      await tyroJSRef.current.tyroJSInstance.init(paySecret);
      const payFormElement = document.getElementById('tyro-pay-form');
      if (payFormElement !== null) {
        payFormElement.innerHTML = '';
      }
      setPayRequestReady(true);
    } catch (error: any) {
      setError({ type: 'critical', errorCode: error.errorCode, message: `${error.toString()}`, debug: { error } });
      setLoading(false);
    }
  }

  // STEP 4, Embed the Pay Form into your Document
  useEffect(() => {
    if (libraryReady && payRequestReady && !payFormReady) {
      initPayForm();
    }
  }, [payRequestReady]);

  // Initialize the Pay Form
  async function initPayForm(): Promise<void> {
    setPayFormReady(false);
    setSubmitting(false);
    try {
      const payFormElement = document.getElementById('tyro-pay-form');
      if (payFormElement === null) {
        throw new Error('Pay Form is not mounted');
      }
      payFormElement.innerHTML = '';
      const payForm = tyroJSRef.current.tyroJSInstance.createPayForm({
        theme,
        styleProps,
        options,
      });
      // Attach the Wallet Listeners to the form
      payForm.setWalletPaymentBeginListener((paymentType: any) => {
        setSubmittingOverlay(true);
        if(paymentType === 'APPLE_PAY') {
          // optionally do something specific to Apple Pay
        } else if(paymentType === 'GOOGLE_PAY'){
          // optionally do something specific to Google Pay
        }
      });
      payForm.setWalletPaymentCancelledListener((paymentType: any) => {
        setSubmittingOverlay(false);
        if(paymentType === 'APPLE_PAY') {
          // optionally do something specific to Apple Pay
        } else if(paymentType === 'GOOGLE_PAY'){
          // optionally do something specific to Google Pay
        }
      });
      payForm.setWalletPaymentCompleteListener((paymentType: any, error: any) => {
        if(error) {
          setError({ type: 'critical', errorCode: error.errorCode, message: `${error.toString()}`, debug: { error } });
          setSubmittingOverlay(false);
        } else {
          getPaymentResult();
        }
      });
      payForm.inject('#tyro-pay-form');
      setPayFormReady(true);
      setLoading(false);
    } catch (error: any) {
      setError({ type: 'critical', errorCode: error.errorCode, message: `${error.toString()}`, debug: { error } });
      setLoading(false);
    }
  }

  // STEP 5, Handle submitting the payment
  async function submitPayForm(): Promise<void> {
    setError({});
    setSubmitting(true);
    let result;
    try {
      result = await tyroJSRef.current.tyroJSInstance.submitPay();
      await getPaymentResult();
      setSubmitting(false);
    } catch (error: any) {
      if (error?.type === 'CLIENT_VALIDATION_ERROR' && !error?.errorCode) {
        // can ignore these errors as handled by validation
      } else {
        // display other errors
        setError({ type: 'critical', errorCode: error.errorCode, message: `${error.toString()}`, debug: { error, result } });
      }
      setSubmitting(false);
    }
  }

  // Used to fetch the Pay Request Response
  async function getPaymentResult(): Promise<void> {
    const payRequest = await tyroJSRef.current.tyroJSInstance.fetchPayRequest();
    // display result
    if (payRequest.status === 'SUCCESS') {
      setPayComplete(payRequest);
    } else {
      setError({ type: TyroJSErrorType.INVALID, errorCode: payRequest.errorCode, message: `${payRequest.status}`, debug: { payRequest } });
    }
    setSubmittingOverlay(false);
  }

  return (
    <>
      {/* <input type='text' value={amount} onChange={(e) => setAmount(Number(e.target.value ?? 0) ?? 0)}/> */}
      <div ref={tyroJSRef}>
        {error.type ? (
          <div className={'error-container'}>
            <p>({error.errorCode ?? 'UNKNOWN_ERROR'}) {error.message}</p>
          </div>
        ) : null}
        {payComplete ? (
          <>Payment has been completed.</>
        ) : (
          <form id='pay-form'>
            <div id='pay-form-submitting-overlay' style={{ display: submittingOverlay ? 'block' : 'none', position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.5)' }}>
              ... Submitting ...
            </div>
            <div style={{ visibility: loading ? 'visible' : 'hidden' }}> ... Loading ... </div>
            <div id='tyro-pay-form' style={{ visibility: payRequestReady ? 'visible' : 'hidden' }}></div>
            <button id='pay-form-submit' onClick={submitPayForm} style={{ visibility: payRequestReady ? 'visible' : 'hidden' }} disabled={submitting}>Pay</button>
          </form>
        )}
      </div>
    </>
  );
};

export default SimpleTyroJSSample;