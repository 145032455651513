import { Form, Input, InputNumber, Modal, Select, Spin } from 'antd';
import FormRow from 'components/common/Form/FormRow';
import settingActions from 'features/settings/services/actions';
import settingSelectors from 'features/settings/services/selectors';
import { first, get } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { isNumeric } from 'utils/unit';

const { TextArea } = Input;

enum ENUM_STEP_CANCEL {
  INPUT_REASON = 'input_reason',
  CONFIRM_WITH_PIN = 'confirm_with_pin',
}

export enum EFields {
  'PIN' = 'pin',
  'NOTE' = 'note',
  'REASON' = 'reason',
}

type IFormValues = {
  _one: number;
  _two: number;
  _three: number;
  _four: number;
  note: string;
  reason: string;
}


type Errors = {
  [EFields.NOTE]?: string | null,
  [EFields.REASON]?: string | null,
  [EFields.PIN]?: string | null,
};


export type IModalCancelSubmitFunc = (values: {
  reason: string;
  note: string;
  PIN: string;
}, setErrors: (errors: Errors) => void, resetPin: () => void) => void
type Props = {
  visible?: boolean;
  onCancel?: () => void;
  onSubmit?: IModalCancelSubmitFunc;
  isPin?: boolean;
};

const ModalCancel = ({ onCancel = () => undefined, visible = false, onSubmit = () => undefined, isPin = true }: Props) => {
  const [form] = Form.useForm();

  const _one = useRef<HTMLInputElement>(null);
  const _two = useRef<HTMLInputElement>(null);
  const _three = useRef<HTMLInputElement>(null);
  const _four = useRef<HTMLInputElement>(null);

  const pinNumbers = [
    {
      name: '_one',
      ref: _one
    },
    {
      name: '_two',
      ref: _two
    },
    {
      name: '_three',
      ref: _three
    },
    {
      name: '_four',
      ref: _four
    },
  ];

  const [step, setStep] = useState(ENUM_STEP_CANCEL.INPUT_REASON);
  const [errors, setErrors] = useState<Errors>({});
  const [errorPin, setErrorPin] = useState('');

  const dispatch = useDispatch();
  const cancellationReasons = (settingSelectors.getListCancellationReason() ?? []) as { id: number, name: string }[];
  const reasonOptions = useMemo(() => cancellationReasons?.map(o => ({ label: o.name, value: o.id })), [cancellationReasons]);
  const resetPin = () => {
    form.setFieldsValue({
      _one: null,
      _two: null,
      _three: null,
      _four: null,
    });
  };

  useEffect(() => {
    dispatch(settingActions?.getListCancellationReason.fetch());
  }, []);

  useEffect(() => {
    form.setFieldValue('reason', get(first(reasonOptions), 'value', ''));
  }, [reasonOptions]);

  useEffect(() => {
    if (errors['note'] || errors['reason']) setStep(ENUM_STEP_CANCEL.INPUT_REASON);
    else if (errors['pin']) setStep(ENUM_STEP_CANCEL.CONFIRM_WITH_PIN);
  }, [errors]);

  const _onCancel = () => {
    onCancel();
  };

  const _onNext = () => {
    if (isPin) {
      setStep(ENUM_STEP_CANCEL.CONFIRM_WITH_PIN);
    }
    return;
  };

  const handleSubmit = (values: IFormValues) => {

    if(!isPin) {
      onSubmit({
        note: values?.note ?? '',
        PIN: '',
        reason: values?.reason ?? '',
      }, setErrors, resetPin);
      return;
    }

    setErrorPin('');
    setErrors({});
    const pinValue = String(values['_one'] ?? '') + String(values['_two'] ?? '') + String(values['_three'] ?? '') + String(values['_four'] ?? '');
    if (!pinValue || pinValue.length !== 4) {
      setErrorPin('Please enter digits PIN!');
      return;
    }
    onSubmit({
      note: values?.note ?? '',
      PIN: pinValue,
      reason: values?.reason ?? '',
    }, setErrors, resetPin);

    resetPin();
  };


  const StepInputReason = () => {
    return (
      <>
        <Spin spinning={!cancellationReasons}>
          <FormRow label="Cancellation reason" name="reason" extra={get(errors, [EFields.REASON])}>
            <Select
              placeholder={'No reason provided'}
              options={reasonOptions}
            />
          </FormRow>
          <FormRow label="Note" name="note" optional extra={get(errors, [EFields.NOTE])}>
            <TextArea placeholder="Enter some note here" rows={4} />
          </FormRow>
          <div className='form-row form-submit-wrapper form-submit-floating'>
            <button className='common-btn is-white' onClick={_onCancel}>
              Cancel
            </button>
            <button className='common-btn' type={isPin ? 'button' : 'submit'} onClick={() => _onNext()} >
              Continue
            </button>
          </div>
        </Spin>
      </>
    );
  };

  const StepInputConfirm = () => {
    return (
      <div className='PIN_form'>
        <p>Please enter a 4 digits PIN to confirm this action</p>
        <Form.Item extra={errorPin || get(errors, [EFields.PIN])}>
          <div className="PIN_group">
          {pinNumbers.map(({name, ref}, index, arr) => (
            <FormRow key={index} shouldUpdate noStyle name={name}>
              <InputNumber
                ref={ref}
                maxLength={1}
                minLength={1}
                autoFocus={index === 0}
                inputMode='numeric'
                className='PIN_input'
                onFocus={() => {
                  form?.setFieldValue(name, undefined);
                }}
        
                onKeyUp={e => {
                  if (e === null || e === undefined) return;

                  if( isNumeric(e.key)) {
                    const item = arr[index + 1] ? arr[index + 1] : undefined;
                    if(item) {
                      if(form.getFieldValue(item.name)) {
                        return;
                      }
                      form?.setFieldValue(item.name, undefined);
                      item.ref.current?.focus();
                    }
                  }
                }}
                onKeyDown={e => {
                  if (e.key === 'Backspace') {                    
                    if( ! form.getFieldValue(name) ) {
                      const item = arr[index - 1] ? arr[index - 1] : undefined;
                      form?.setFieldValue(item?.name ?? '', undefined);
                      setTimeout(() => item?.ref?.current?.focus(), 1);
                    }
                  }
                }}
              />
            </FormRow>
          ))}
            {/* <FormRow shouldUpdate noStyle name="_one">
              <InputNumber
                ref={_one}
                maxLength={1}
                minLength={1}
                autoFocus
                className='PIN_input'
                onChange={e => {
                  if (e === null || e === undefined) return;
                  form?.setFieldValue('_two', undefined);
                  setTimeout(() => _two.current?.focus(), 1);
                }}
                onFocus={() => {
                  setTimeout(() => _one.current?.setSelectionRange(0, 2), 1);
                }}
              />
            </FormRow>
            <FormRow shouldUpdate noStyle name="_two">
              <InputNumber
                className='PIN_input'
                ref={_two}
                maxLength={1}
                minLength={1}
                onChange={e => {
                  if (e === null || e === undefined) return;
                  form?.setFieldValue('_three', undefined);
                  setTimeout(() => _three.current?.focus(), 1);
                }}
                onFocus={() => {
                  setTimeout(() => _two.current?.setSelectionRange(0, 2), 1);
                }}
                onKeyDown={e => {
                  if (e.key === 'Backspace') {
                    setTimeout(() => { _one.current?.focus(); }, 1);
                  }
                }}
              />
            </FormRow>
            <FormRow shouldUpdate noStyle name="_three">
              <InputNumber
                className='PIN_input'
                ref={_three}
                maxLength={1}
                minLength={1}
                onChange={e => {
                  if (e === null || e === undefined) return;
                  form?.setFieldValue('_four', undefined);
                  setTimeout(() => _four.current?.focus(), 1);
                }}
                onFocus={() => {
                  setTimeout(() => _three.current?.setSelectionRange(0, 2), 1);
                }}
                onKeyDown={e => {
                  if (e.key === 'Backspace') {
                    setTimeout(() => { _two.current?.focus(); }, 1);
                  }
                }}
              />
            </FormRow>
            <FormRow shouldUpdate noStyle name="_four">
              <InputNumber
                className='PIN_input'
                ref={_four}
                accept='/^[0-9]*$/'
                maxLength={1}
                minLength={1}
                onFocus={() => {
                  setTimeout(() => _four.current?.setSelectionRange(0, 2), 1);
                }}
                onKeyDown={e => {
                  if (e.key === 'Backspace') {
                    setTimeout(() => { _three.current?.focus(); }, 1);
                  }
                }}
              />
            </FormRow> */}
          </div>
        </Form.Item>
        <p>Don’t remember the number? <Link to='/private/settings' className='label-highlight'><b>Reset PIN</b></Link></p>
        <ModalCancelStyled>
        <div className='form-row form-submit-wrapper form-submit-floating'>
          <button className='common-btn is-white' onClick={() => setStep(ENUM_STEP_CANCEL.INPUT_REASON)}>
            Back
          </button>
          <button className='common-btn' type='submit'>
            Confirm
          </button>
        </div>
        </ModalCancelStyled>
      </div>
    );
  };

  const getTitle = () => {
    if (step === ENUM_STEP_CANCEL.INPUT_REASON) return 'Cancel appointment';
    if (step === ENUM_STEP_CANCEL.CONFIRM_WITH_PIN) return 'Enter PIN';
    return null;
  };

  return (
    <Modal
      maskClosable={false}
      open={visible}
      onCancel={_onCancel}
      forceRender
      footer={null}
      className='modal-cancel-booking'
      centered
    >
      <ModalCancelStyled>
        <h2 className='modal-title'>
          <p>{getTitle()}</p>
        </h2>
        <Form
          form={form}
          className='modal-form'
          layout='vertical'
          onFinish={handleSubmit}
        >
          <div className={`${step !== ENUM_STEP_CANCEL.INPUT_REASON ? 'hide' : ''}`}><StepInputReason /></div>
          <div className={`${step !== ENUM_STEP_CANCEL.CONFIRM_WITH_PIN ? 'hide' : ''}`}><StepInputConfirm /></div>
        </Form>
      </ModalCancelStyled>
    </Modal>
  );
};

export default ModalCancel;

const ModalCancelStyled = styled.div`
.form-submit-floating {
  position: relative;
}
  .ant-form-item-extra {
    color:#ff4d4f;
  }
  .hide {
    display:none;
  }
  .form-confirm {
    text-align: center;
  }
  .PIN_form {
    text-align: center;
    .label-highlight {
      color:#4A9D77;
    }
  }
  .PIN_group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width:100%;
    margin-top: 10%;
    margin-bottom: 5%;
  }
  .PIN_input {
    flex:1;
    background: none;
    border: unset;
    border-radius: 0;
    border-bottom: 1px solid #C3C2DE;
    input {
      padding: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 140%;
      text-align: center;
      color: #363565;
      background: none;
    }
    &.ant-input-number {
      width: 40px;
    }
  }
`;