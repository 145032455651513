import { Spin } from 'antd';
import { AxiosResponse } from 'axios';
import message from 'components/common/Message';
import dayjs, { Dayjs } from 'dayjs';
import bookingActions from 'features/bookings/services/actions';
import serviceActions from 'features/services/services/actions';
import settingActions from 'features/settings/services/actions';
import settingApis from 'features/settings/services/apis';
import settingSelectors from 'features/settings/services/selectors';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { valueAllOptions } from 'utils/unit';
import styles from '../../pages/index.module.scss';
import ClosedItem from './ClosedItem';
import FormModal from './FormModal';

export interface IClosedPeriodForm {
  startDate: Dayjs;
  endDate: Dayjs;
  description: string;
  locations?: number[];
}

interface IClosedPeriodItem {
  id: number;
  start_time: string;
  end_time: string;
  description: string;
  location_ids: number[];
  name: string;
}

const ClosedPeriodsTab = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [idFeild, setIdFeild] = useState<number>(0);
  const [isDisableSubmit, setIsDisableSubmit] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(bookingActions.getOnlineLocations.fetch(''));
  }, []);

  const showModal = () => {
    setIsEdit(false);
    setIsModalOpen(true);
    setIsDisableSubmit(false);
    setFormData(undefined);
  };



  const handleCancel = () => {
    setIsModalOpen(false);
    setFormData(undefined);
  };

  const settingClosed = settingSelectors.getListClosedDate();  
  const loadingClosed = settingSelectors.loadListClosedDate();

  useEffect(() => {
    dispatch(serviceActions.getShowCreateService.fetch());
  }, []);

  // useEffect(() => {
  //   setClosedPeroidFirst(settingClosed[0]);
  // },[settingClosed]);

  const getPayload = (values: any) => {
    const start_time = values?.startDate?.format('YYYY-MM-DD 00:00:00');
    const end_time = values?.endDate.format('YYYY-MM-DD 00:00:00');
    const description = values?.description;
    const merchant_location_ids = values?.locations != valueAllOptions ? values?.locations : null;
    const name = values.name;


    return {
      start_time,
      end_time,
      description,
      merchant_location_ids,
      name
    };
  };

  const handleSubmitEdit = async (values: IClosedPeriodForm) => {
    setIsDisableSubmit(true);
    const payload = getPayload(values);

    try {
      const rest: AxiosResponse<{ message: string }> = await settingApis.updateClosedDate(idFeild, payload);

      const msg = get(rest, 'data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(settingActions.getListClosedDate.fetch());
        handleCancel();
        return true;
      }
      return false;
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.values(response);
      errors.map((value) => {
        message.error(get(value, [0], ''));
      });

      return false;
    } finally {
      setIsDisableSubmit(false);
    }
  };

  const handleSubmit = async (values: IClosedPeriodForm) => {
    setIsDisableSubmit(true);
    const payload = getPayload(values);

    try {
      const rest: AxiosResponse<{ message: string }> = await settingApis.addClosedDate(payload);

      const msg = get(rest, 'data.message', '');
      if (msg) {
        message.success(msg);
        dispatch(settingActions.getListClosedDate.fetch());
        handleCancel();
        return true;
      }
      return false;
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.values(response);
      errors.map((value) => {
        message.error(get(value, [0], ''));
      });

      return false;
    } finally {
      setIsDisableSubmit(false);
    }
  };

  const handleEdit = (item: IClosedPeriodItem) => {
    showModal();
    setIsEdit(true);
    setIdFeild(item.id);

    setFormData({
      startDate: dayjs(item.start_time),
      endDate: dayjs(item.end_time),
      description: item.description,
      locations: item.location_ids      ,
      name: item.name
    });
  };

  // const checkValid = async () => {
  //   try {
  //     await form.validateFields([
  //       // 'phoneNumber',
  //       // 'name',
  //       // 'email',
  //       // 'service',
  //       // 'bookingDate',
  //       // 'bookingTime',
  //     ]);
  //     return true;
  //   } catch (error) {
  //     return false;
  //   }
  // };



  return (
    <>
      <Spin spinning={loadingClosed}>
        <div className='cards-wrapper'>
          <div className='cards-col'>
            <div className='common-card'>
              <div className='card-title'>
                <div className='d-flex'>
                  <div>
                    <div className='d-flex' style={{ gap: '10px' }}>
                      <p className='form-label is-biggest'>Closed Periods </p>
                    </div>
                    <p className='form-note'>
                      Set the period when your business is closed.
                    </p>
                  </div>
                  <button
                    className='common-btn'
                    style={{ marginLeft: 'auto' }}
                    onClick={showModal}
                  >
                    Add New
                  </button>
                </div>
              </div>
              <div className='card-body'>
                <ul className={styles.periods_list}>
                  {settingClosed.map((item: any, key: number) => (
                    <li key={key}>
                      <ClosedItem {...item}
                        handleEdit={() => handleEdit(item)} />
                    </li>

                  ))}

                </ul>
              </div>
            </div>
          </div>
        </div>
        {isModalOpen && <FormModal
          isDisableSubmit={isDisableSubmit}
          formData={formData}
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          handleSubmit={isEdit ? handleSubmitEdit : handleSubmit}
        />}
      </Spin>
    </>
  );
};

export default ClosedPeriodsTab;
