import { DatePicker } from 'antd';
import dayjs from 'utils/dayjs';
import React from 'react';
import styled from 'styled-components';
type IDateRangePickerProps = {
  start_date?: number,
  end_date?: number,
  date?: number,
  onChange: (values: any) => void
};
const DateRangePicker: React.FC<IDateRangePickerProps> = ({start_date, end_date, date, onChange}) => {  
  
  return (
    <DateRangePickerStyled>
      <div className="common-select">
      <DatePicker.RangePicker
        onChange={onChange}
        allowClear={false}
        suffixIcon={false}
        value={[dayjs((start_date ?? date ?? 0)*1000).tz(), dayjs((end_date ?? date ?? 0)*1000).tz()]}
        inputReadOnly
      />
    </div>
    </DateRangePickerStyled>
  );
};

export default DateRangePicker;
const DateRangePickerStyled = styled.div``;
