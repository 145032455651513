import { createSlice } from '@reduxjs/toolkit';
import { NAME_REDUCER } from './constants';
import actions from './actions';
import authActions from 'features/auth/services/actions';

const initialState: any = {
  listSetting: null,
  listOptionBooking: null,
  listClosedDate: [],
  listNumberOfNotification: null,
  listCancellationReason: [],
  listSettingBookingForm: {
    customer_services: false,
    is_team_member_booking: false,
    is_promotion: false,
    enable_booking_tax: false,
    value_booking_tax: null,
    merchant_closed: [],
    currency_unit: null,
    cancel_before_scheduled_time: null,
    cancel_before_scheduled_time_lable: null,
  },
  holidayInfoToday: null,
  topUp: {
    pagination: null,
    messageHistoryPagination: null,
    initData: {
      current_balance: 0,
      enable_auto_top_up: false,
      number_sms: 0,
      fee_sms: 0,
      auto_top_up: {
        number_sms: 0,
        balance_amount: 0,
        balance_drop: 0
      }
    },
  }
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(authActions.logout.success, (state) => {
        state.listSetting = null;
        state.listOptionBooking = null;
        state.listClosedDate = [];
        state.listNumberOfNotification = null;
        state.listCancellationReason = [];
        state.listSettingBookingForm = {
          enable_timeroster_management: false,
          customer_services: false,
          is_team_member_booking: false,
          is_promotion: false,
          enable_booking_tax: false,
          value_booking_tax: null,
          merchant_closed: []
        };
        state.topUp = {
          pagination: null,
          messageHistoryPagination: null,
          initData: {
            current_balance: 0,
            enable_auto_top_up: false,
            number_sms: 0,
            fee_sms: 0,
            auto_top_up: {
              number_sms: 0,
              balance_amount: 0,
              balance_drop: 0
            }
          },
        };
      })
      .addCase(actions.getSetting.success, (state, { payload }) => {
        const data = payload as any[];
        state.listSetting = data;
      })
      .addCase(actions.getSettingBookingForm.success, (state, { payload }) => {
        const data = payload as any[];
        state.listSettingBookingForm = data;
      })
      .addCase(actions.getOptionBooking.success, (state, { payload }) => {
        const data = payload;
        state.listOptionBooking = data;
      })
      .addCase(actions.getListClosedDate.success, (state, { payload }) => {
        const data = payload;
        state.listClosedDate = data;
      })
      .addCase(actions.getNumberOfNotification.success, (state, { payload }) => {
        const data = payload as any[];
        state.listNumberOfNotification = data;
      })
      .addCase(actions.getListCancellationReason.success, (state, { payload }) => {
        const data = payload as any[];
        state.listCancellationReason = data;
      })
      .addCase(actions.getListServiceCharge.success, (state, { payload }) => {
        const data = payload as any[];
        state.listServiceCharge = data;
      })
      .addCase(actions.getDetailServiceCharge.success, (state, { payload }) => {
        state.serviceChargeDetail = payload;
      })
      .addCase(actions.getShowServiceCharge.success, (state, { payload }) => {
        const data = payload as any[];
        state.getShowServiceCharge = data;
      })
      .addCase(actions.getTopUpHistory.success, (state, { payload }) => {
        const data = payload as any;
        state.listTopUpHistory = data?.data;
        state.topUp.pagination = data?.pagination;
      })
      .addCase(actions.getTopUpSMSHistory.success, (state, { payload }) => {
        const data = payload as any;
        state.listTopUpSMSHistory = data?.data;
        state.topUp.messageHistoryPagination = data?.pagination;
      })
      .addCase(actions.getMerchantStripeBalance.success, (state, { payload }) => {
        const balanceData = {
          current_balance: payload?.current_balance,
          enable_auto_top_up: payload?.enable_auto_top_up,
          number_sms: payload?.number_sms,
          fee_sms: payload?.fee_sms,
          auto_top_up: payload?.auto_top_up
        };

        state.topUp.initData = {
          ...balanceData
        };
      })
      .addCase(actions.getHolidayInfoToday.success, (state, { payload }) => {
        state.holidayInfoToday = payload;
      })
      .addCase(actions.setTopUpInitData, (state, { payload }) => {
        const initData = {
          current_balance: payload?.current_balance,
          enable_auto_top_up: payload?.enable_auto_top_up,
          number_sms: payload?.number_sms,
          fee_sms: payload?.fee_sms,
          auto_top_up: payload?.auto_top_up
        };

        state.topUp.initData = {
          ...initData
        };
      })
      .addCase(actions.updateAutomaticTopUp, (state, { payload }) => {
        state.topUp.initData.enable_auto_top_up = payload;
      });
  },
});
const settingServiceReducer = Slice.reducer;
export default settingServiceReducer;
