import { Form, InputNumber, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IPromotionFormValue } from '../types/promotion';
import { getFormatInputCurrency } from 'utils/unit';

type Props = {
  errorsText?: Record<string, string>;
  isUsed?: boolean;
  formData?: IPromotionFormValue | null;
};
const PromotionsFormSecondStep = ({errorsText, formData, isUsed}: Props) => {
  const { t: formLang } = useTranslation('form');
  const form = Form.useFormInstance();
  const [price, setPrice] = useState(form.getFieldValue('amountValue'));
  const [percent, setPercent] = useState(form.getFieldValue('percentValue'));
  const [initValue, setInitValue] = useState<{percent?:number, amount?: number}>();

  useEffect(() => {
    setPrice(form.getFieldValue('amountValue'));
    setPercent(form.getFieldValue('percentValue'));

    
  }, [form]);

  useEffect(() => {
    if(!formData?.amountValue && !formData?.percentValue) return;
    // if(!initValue ) {
      setInitValue({
        amount: formData?.amountValue ?? undefined,
        percent: formData?.percentValue ?? undefined
      });
    // }
  }, [formData]);

  return (
    <>
      <div className='form-row groupPrice items-end'>
        <Form.Item label={formLang('promotion.moneyValue')} 
          name='amountValue'
          rules={[
            {
              validator(rule, value, callback) {
                
                if(!initValue?.amount) return callback(undefined);
                
                if ( isUsed && value < initValue?.amount ) {
                    callback('A value must be greater previous value');
                }
                else callback(undefined);
              },
            },
          ]}>
          <InputNumber
            addonBefore={getFormatInputCurrency().addonBefore}
            placeholder='Price'
            disabled={isUsed && !initValue?.amount}
            onChange={(val: any) => {
              setPrice(val);
              setPercent(null);
              form.setFieldValue('percentValue', null);
            }}
          />
        </Form.Item>
        <p className='center-text'>or</p>
        <Form.Item
          label={formLang('promotion.percentValue')}
          name='percentValue'
          rules={[
            {
              validator(rule, value, callback) {
                
                if(!initValue?.percent) return callback(undefined);
                
                if ( isUsed && value < initValue?.percent ) {
                    callback('A value must be greater previous value');
                }
                else callback(undefined);
              },
            },
          ]}
        >
          <InputNumber
            addonBefore='%'
            placeholder={'Percent'}
            max={100}
            disabled={isUsed && !initValue?.percent}
            onChange={(val: any) => {
              setPercent(val);
              setPrice(null);
              form.setFieldValue('amountValue', null);
            }}
            min={0.01}
          />
        </Form.Item>
      </div>

      <Form.Item
        className='messageError'
        name={'priceOrPercent'}
        rules={[
          {
            validateTrigger: 'onChange',
            validator() {
              const priceValue = price ?? form.getFieldValue('amountValue');
              const percentValue =
                percent ?? form.getFieldValue('percentValue');

              if (
                (!priceValue && !percentValue) ||
                (!!priceValue && !!percentValue)
              ) {
                return Promise.reject(
                  new Error('Please fill in value price or percent a field')
                );
              } else if (
                percentValue &&
                (percentValue < 0.01 || percentValue > 100)
              ) {
                return Promise.reject(
                  new Error(
                    'Percent value need to be greater than 0.01 and less than 100'
                  )
                );
              }
              return Promise.resolve();
            },
          },
        ]}
        validateStatus={errorsText?.value ? 'error' : undefined}
        help={errorsText?.value ? errorsText?.value : undefined}
        dependencies={['amountValue', 'percentValue']}
      />

      <div className='form-row switch-check'>
        <p className='form-label'>{formLang('promotion.limitToUsePerCustomer')}</p>
        <Form.Item noStyle name={'limitToUsePerCustomer'} valuePropName='checked'>
          <Switch />
        </Form.Item>
      </div>

      <div className='input-number-custom'>
        <Form.Item dependencies={['limitToUsePerCustomer']} noStyle>
          {({ getFieldValue }: any) => {
            // getFieldValue defined here
            return getFieldValue('limitToUsePerCustomer') === true ? (
              <Form.Item label={formLang('promotion.limitToUsePerCustomer')} 
                name='limitToUsePerCustomerValue'
                rules={[
                  {
                    required: true
                  },
                ]}>
                <InputNumber placeholder='Limit to use per customer' />
              </Form.Item>
            ) : null;
          }}
        </Form.Item>
      </div>
      
      <div className='form-row switch-check'>
        <p className='form-label'>{formLang('promotion.limitTotalNumberOfUse')}</p>
        <Form.Item noStyle name={'limitTotalNumberOfUse'} valuePropName='checked'>
          <Switch />
        </Form.Item>
      </div>

      <div className='input-number-custom'>
        <Form.Item dependencies={['limitTotalNumberOfUse']} noStyle>
          {({ getFieldValue }: any) => {
            // getFieldValue defined here
            return getFieldValue('limitTotalNumberOfUse') === true ? (
              <Form.Item label={formLang('promotion.limitTotalNumberOfUse')} 
                name='limitTotalNumberOfUseValue'
                rules={[
                  {
                    required: true
                  },
                ]}>
                <InputNumber placeholder='Limit total number of uses' />
              </Form.Item>
            ) : null;
          }}
        </Form.Item>
      </div>

      <div className='form-row switch-check'>
        <p className='form-label'>{formLang('promotion.applyWithOtherPromotion')}</p>
        <Form.Item noStyle name={'applyWithOtherPromotion'} valuePropName='checked'>
          <Switch />
        </Form.Item>
      </div>

      <Form.Item dependencies={['promotionType']} noStyle>
        {({ getFieldValue }: any) => {
          // getFieldValue defined here
          return getFieldValue('promotionType') === 'code' ? (
            <div className='form-row switch-check'>
              <p className='form-label'>
                {formLang('promotion.availableForBookingOnline')}
              </p>
              <Form.Item
                noStyle
                name={'enableBookingOnline'}
                valuePropName='checked'
              >
                <Switch defaultChecked />
              </Form.Item>
            </div>
          ) : null;
        }}
      </Form.Item>

      <div className='form-row switch-check'>
        <p className='form-label'>{formLang('promotion.enableVoucherSales')}</p>
        <Form.Item noStyle name={'enableVoucherSale'} valuePropName='checked'>
          <Switch />
        </Form.Item>
      </div>

      <Form.Item dependencies={['promotionType']} noStyle>
        {({ getFieldValue }: any) => {
          // getFieldValue defined here
          return getFieldValue('promotionType') === 'auto_offer' ? (
            <div className='form-row switch-check'>
              <p className='form-label'>
                {formLang('promotion.applyForWalkin')}
              </p>
              <Form.Item
                noStyle
                name={'enableApplyWalkIn'}
                valuePropName='checked'
              >
                <Switch defaultChecked />
              </Form.Item>
            </div>
          ) : null;
        }}
      </Form.Item>

      <div className='form-row switch-check'>
        <p className='form-label'>{formLang('promotion.enablePromotion')}</p>
        <Form.Item noStyle name={'enablePromotion'} valuePropName='checked'>
          <Switch defaultChecked />
        </Form.Item>
      </div>
    </>
  );
};

export default PromotionsFormSecondStep;
