import { Button, Modal, QRCode } from 'antd';
import * as htmlToImage from 'html-to-image';
import { useRef } from 'react';
import styled from 'styled-components';

interface ModalQRCOdeProps {
  isModalVisible: boolean, 
  handleModalCancel: () => void,
  QRpath?: string,
  locationName?: string
}

const ModalQRCode = ({isModalVisible, handleModalCancel, QRpath, locationName}: ModalQRCOdeProps) => {

  const locationQRRef = useRef<any>(null);

  const downloadQRCode = async () => {
    
    const dataUrl = await htmlToImage.toPng(locationQRRef.current);
 
    // download image
    const link = document.createElement('a');
    link.download = 'qr-location.png';
    link.href = dataUrl;
    link.click();
  };

  return (
    <ModalStyled
      visible={isModalVisible}
      onCancel={handleModalCancel}
      closable={false} // Hide close button
      footer={null} // Hide footer (buttons)
    >
    <div className='qr-code-location' ref={locationQRRef}>

      <p>{locationName}</p>
      <div className='qr-code'>
      <QRCode size={250} value={QRpath ?? ''} />
      </div>
      <Button onClick={downloadQRCode}>Scan this code to Check-in</Button>
    </div>

    </ModalStyled>
  );
};

export default ModalQRCode;

const ModalStyled = styled(Modal)`
.ant-modal-content{
  background: #E2E5FF;
  padding: 60px 58px;
  display: flex;
  justify-content: center;
  .ant-modal-body {
    display: grid;
    gap: 24px;
    text-align: center;
    .qr-code-location {
      display: flex;
      gap: 24px;
      flex-direction: column;
      background-color: #E2E5FF;
      padding: 24px 46px;
      border-radius: 12px;
      p {
        color: #000;
        font-size: 24px;
        font-weight: 600;
        line-height: 140%;
      }
      /* .qr-code {
        padding: 24px;
        background: white;
        border-radius: 20px;
        canvas {
          width: 256px !important;
          height: 256px !important;
        }
      } */
      .ant-btn-default {
        width: 100%;
        background: transparent;
      }
    }
  }
}
`;