import { IBookingItemResData } from 'features/bookings/services/types/booking';
import { useCallback, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { IDndDataCalendar } from './RowCalendar';
import moment, { Moment } from 'moment';
import { BREAK_TIME_MINUTES, TIME_START_FORMAT_RESPONSE } from 'features/bookings/services/constants';
import apisBooking from 'features/bookings/services/apis';
import message from 'components/common/Message';
import bookingActions from 'features/bookings/services/actions';
import { get, set } from 'lodash';
import SYSTEM_VARIABLES from 'constants/variables';
import bookingSelectors from 'features/bookings/services/selectors';
export type IBookingItemResDataNew = IBookingItemResData & { serviceChangeId?: string | number };
type IUpdateMemberViewFunc = (masterData: {
  serviceId: number;
  service_variant_id: number;
}, book: IBookingItemResDataNew, overDnD: IDndDataCalendar, pin_number?: string) => Promise<void>
const useUpdateMemberView = (): [boolean, IUpdateMemberViewFunc] => {
  const dispatch = useAppDispatch();
  const activeLocation = bookingSelectors.getCalendarCurrentLocation();

  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const checkIsBreakDayWeekView = useCallback(
    (timeStart: Moment) => {
      const openDate = activeLocation?.time_opening.filter(
        (o) => o.weekday === timeStart.format('dddd').toLocaleLowerCase()
      );
      
      const isWorkingTime = openDate?.find((o: any) => {
        const startTime = moment(timeStart).set({ hour: o.start, minute: o.startMinute, second: 0 });
        const endTime = moment(timeStart).set({ hour: o.end, minute: o.endMinute, second: 0 });
        const isBetween = timeStart.isBetween(startTime, endTime, null, '[]');
        const isSameStart = timeStart.isSame(startTime);
        const isSameEnd = timeStart.isSame(endTime);
        return isSameStart || isBetween || isSameEnd;
      });
      return !isWorkingTime;
    },
    [activeLocation]
  );

  const updateBooking: IUpdateMemberViewFunc = async (masterData, book, overDnD, pin_number) => {
    if (masterData.serviceId == null || masterData.serviceId == undefined) return;

    const rowTime = overDnD.rowTime;
    const colDate = moment(book.time_start);

    const timeStart = colDate.set({
      hour: rowTime.get('hour') ?? 0,
      minute: rowTime.get('minute') ?? 0,
      second: 0,
    });

    if (checkIsBreakDayWeekView(timeStart)) {
      message.error('This time is break time');
      setLoadingUpdate(false);
      return;
    }

    const bookId = book.id?.toString();
    if (!bookId) return;

    const _time_start = timeStart.clone();
    const book_assignment_services = book.book_assignment_services.map((o) => {
      const result = {
        service_id: o?.service_id,
        time_start: _time_start.format(TIME_START_FORMAT_RESPONSE),
        duration_time: o?.duration_time,
        quantity: o?.quantity,
        employee_id: o?.assigned_employee?.employee?.id ?? null,
        sale_price: o?.service_price,
        service_variant_id: o?.service_variant_id ?? null,
      };
      if (
        result.service_id?.toString() === masterData?.serviceId?.toString() &&
        result?.service_variant_id?.toString() === masterData?.service_variant_id?.toString()
      ) {
        const employeeId = overDnD?.colData?.id;
        set(result, 'employee_id', employeeId !== SYSTEM_VARIABLES.UNASSIGNED ? +employeeId : '');
      }

      const breakTimeMinutes = BREAK_TIME_MINUTES;
      const quantity = (o.quantity ?? 0);
      const prevServiceMinutes = ((o.duration_time ?? 0) * quantity) + (quantity - 1) * breakTimeMinutes;
      _time_start.add(breakTimeMinutes + prevServiceMinutes, 'minute');
      return result;
    });

    const payload = {
      book_start: timeStart.format(TIME_START_FORMAT_RESPONSE),
      customer_id: book?.customer?.id,
      customer_code: book?.customer?.customer_code,
      note: book?.appointment_note || book?.noted_client,
      book_assignment_services,
      is_walkin_in: !!book?.customer?.is_walkin_in,
      action_update: 'drag_drop',
      pin_number
    };
    setLoadingUpdate(true);
    try {
      const response = await apisBooking.updateBooking(bookId || '', payload);
      if (response?.data.error) {
        message.error(response?.data.error.message);
      } else {
        message.success('You have successfully edit this booking!');
        dispatch(bookingActions.getBookings.fetch({}));
      }
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.entries(response);
      errors.map(([, value]) => {
        message.error(value[0]);
      });
    } finally {
      setLoadingUpdate(false);
    }
  };

  return ([loadingUpdate, updateBooking]);
};

export default useUpdateMemberView;
