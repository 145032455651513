import { Form, Input } from 'antd';
import IconCheck from 'assets/icons/ico_check.svg';
import IconBack from 'assets/svg/IconBack';
import apis from 'features/auth/services/apis';
import { get } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';

type Props = {
  avatar?: string;
  email: string;
  backToSignIn: () => void;
};
const ForgotPassword = ({
  email,
  backToSignIn = () => undefined,
}: Props) => {
  const { t: signInLang } = useTranslation('sign_in');

  const [isSendSuccess, setIsSendSuccess] = useState(false);
  const [errorText, setErrorText] = useState('');
  const setIsLoading = useSetLoadingPage();
  const navigate = useNavigate();
  const handleResetPassword = async (values: { email: string }) => {
    setIsLoading(true);
    setErrorText('');
    try {
      const res = await apis.forgotPassword(values.email);
      if (res && res.data) {
        setIsSendSuccess(true);
      }
    } catch (error) {
      const message = get(
        error,
        'response.data.error.message',
        signInLang('AnErrorHasOccurredPleaseTryAgain!')
      );
      setErrorText(message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {!isSendSuccess ? (
        <>
          <h1 className='auth-title'>{signInLang('forgotPass.title')}</h1>
          <div style={{ marginBottom: 50 }}>
            <p className='auth-text' style={{ marginBottom: 0 }}>
              {signInLang('forgotPass.label')} {''}
              <strong>{email}</strong>
            </p>
            {!!errorText && (
              <p style={{ color: 'var(--color-red)' }}>{errorText}</p>
            )}
          </div>
          <Form
            autoComplete='off'
            layout='vertical'
            onFinish={handleResetPassword}>

            <Form.Item name={'email'}
              rules={[{ required: true, message: signInLang('PleaseEnterEmail') || '' }]}>
              <Input type='email' placeholder={signInLang('EnterYourEmail') || ''} />
            </Form.Item>

            <div className='form-row dl-flex'>
              <button
                type='button'
                onClick={() => navigate(-1)}
                className='icon-back'>
                <IconBack />
              </button>
              <button className='btn-submit' type='submit'>
                {signInLang('forgotPass.btn')}
              </button>
            </div>
          </Form>
        </>
      ) : (
        <>
          <div className='forgot-logo'>
            <img src={IconCheck} alt=''></img>
          </div>
          <p className='auth-text'>{signInLang('forgotPass.successText')}</p>
          <button className='btn-submit' onClick={backToSignIn}>
            {signInLang('forgotPass.backBtn')}
          </button>
        </>
      )}
    </>
  );
};

export default ForgotPassword;
