import { Form, Input, Select, Switch } from 'antd';
import FormTabSetting from './FormTabSetting';
import settingActions from '../services/actions';
import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';
import settingApis from '../services/apis';
import { get } from 'lodash';
import settingSelectors from '../services/selectors';
import { useEffect, useState } from 'react';
import message from 'components/common/Message';
import { ECurrencyUnit } from 'constants/index';
import { VIEW } from 'constants/view.enum';
import bookingSelectors from 'features/bookings/services/selectors';
import ModalPasswordConfirm from './widgets/ModalPasswordConfirm';
import ModalPin from './widgets/ModalPin';
import TooltipComponent from './TooltipComponent';

const GeneralTab = () => {

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const currentBookingLayout = bookingSelectors.getBookingLayout();
  const data = settingSelectors.getSetting();
  const [enablePin, setEnablePin] = useState<boolean>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      terminalId: data?.general?.teminal_id,
      merchantId: data?.general?.merchant_id,
      pin: data?.general?.pin_number,
      isPinNumber: data?.general?.is_pin_number,
      bookingLayout: data?.general?.booking_layout || currentBookingLayout,
      currencyUnit: data?.general?.currency_unit || ECurrencyUnit.AUD,
    });
    setEnablePin(data?.general?.is_pin_number);

  }, [data]);




  const getPayload = (values: any) => {
    const teminal_id = values?.terminalId;
    const merchant_id = values?.merchantId;
    const pin_number = values?.pin;
    const status_pair_tyro = true;
    const is_pin_number = enablePin;

    return {
      teminal_id,
      merchant_id,
      pin_number,
      status_pair_tyro,
      is_pin_number,
      booking_layout: values?.bookingLayout,
      currency_unit: values?.currencyUnit,
    };
  };
  const handleSubmit = async (values: any) => {
    dispatch(settingActions.getSetting.fetch());
    const payload = getPayload(values);
    try {
      const rest: AxiosResponse<{ message: string }> = await settingApis.updateGeneral(payload);

      const msg = get(rest, 'data.message', '');
      if (msg) {
        dispatch(settingActions.getSetting.fetch());
        message.success(msg);
        form.resetFields();
        return true;
      }
      return false;
    } catch (error: any) {
      dispatch(settingActions.getSetting.fetch());
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      message.error(...Object.values(response)[0]);

      return false;
    }
  };

  const [isValuePinNumber, setIsValuePinNumber] = useState<string>('');

  const getPayloadPin = (values: any) => {
    return {
      pin_number: values?.pin_number,
      password: values?.password,
    };
  };

  const handleSubmitPin = async (values: any) => {
    const { _one, _two, _three, _four, new_pin } = values;
    const valuesPin = `${_one || ''}${_two || ''}${_three || ''}${_four || ''}`;
    const payloadPin = getPayloadPin(values);

    if (valuesPin) {
      if (valuesPin === data?.general?.pin_number || data?.general?.pin_number === '') {
        setIsValuePinNumber(valuesPin);
        setIsModalOpen(false);
      } else {
        message.error('Pin number does not exist');
      }
    } else {
      setIsValuePinNumber(new_pin);
    }

    const rest = await settingApis.postResetPinNumber(payloadPin);

    if (rest?.data?.error) {
      const msg = get(rest, 'data.data.message', rest?.data?.error?.message);

      // if(get(rest, 'data'))
      message.error(msg);
      return false;
    } else {
      setIsValuePinNumber(payloadPin?.pin_number);
    }

    setIsModalOpen(false);
    return true;
  };

  useEffect(() => {
    form.setFieldValue('pin', isValuePinNumber);
  }, [isValuePinNumber]);



  const checkValid = async () => {
    try {
      await form.validateFields([
        // 'phoneNumber',
        // 'name',
        // 'email',
        // 'service',
        // 'bookingDate',
        // 'bookingTime',
      ]);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    if (data?.general?.is_pin_number) {
      setIsModalOpen(true);
    }
  };

  useEffect(() => {
    setIsValuePinNumber(data?.general?.pin_number);
  }, [data?.general]);


  return (
    <>

      <FormTabSetting
        form={form}
        handleSubmit={handleSubmit}
        checkValid={checkValid}
        initialValues={{
          terminalId: data?.general?.teminal_id,
          merchantId: data?.general?.merchant_id,
          pin: data?.general?.pin_number,
          isPinNumber: data?.general?.is_pin_number,
          bookingLayout: data?.general?.booking_layout || currentBookingLayout,
        }}
      >
        <div className='cards-wrapper'>
          <div className='cards-col'>
            <div className='common-card'>
              <div className='card-body'>
                <div className='form-row'>
                  <Form.Item
                    label='Terminal ID'
                    name='terminalId'
                  >
                    <Input autoFocus placeholder='Enter terminal id' />
                  </Form.Item>
                </div>
                <div className='form-row'>
                  <Form.Item
                    label='Merchant ID'
                    name='merchantId'
                  >
                    <Input placeholder='Enter Merchant ID' />
                  </Form.Item>
                </div>
                <div className='form-row' onClick={openModal}>
                  <Form.Item
                    label='PIN number'
                    name='pin'
                    rules={[
                      { required: form?.getFieldValue('isPinNumber') },
                      {
                        pattern: /^[\d]{4}$/,
                        message: 'Pin number must have 4 number',
                      },
                    ]}
                  >
                    <Input.Password
                      style={{ cursor: 'pointer' }}
                      visibilityToggle={false}
                      placeholder='Enter PIN number'
                    />
                    {/* <p style={{ display: 'none' }}>{isValuePinNumber}</p> */}
                  </Form.Item>

                </div>
                <div>
                  <Form.Item valuePropName='checked' initialValue={form?.getFieldValue('isPinNumber') ?? data?.general?.is_pin_number} name={'isPinNumber'} noStyle>
                    <div className='form-row'>
                      <p className='form-label'>{'Enable pin number'}
                        <TooltipComponent title='Enable pin in checkout page.'/>
                      </p>
                      <Switch
                        checked={enablePin}
                        onChange={() => {
                          setEnablePin(!enablePin);
                          setTimeout(() => {
                            form.validateFields(['pin']);
                          }, 100);
                        }}
                        checkedChildren=''
                        unCheckedChildren=''
                      />
                    </div>
                  </Form.Item>

                  <Form.Item
                    label='Booking Layout'
                    name='bookingLayout'>
                    <Select
                      placeholder='Select booking Layout'
                      options={[
                        {
                          value: VIEW.GRID,
                          label: 'Grid',
                        },
                        {
                          value: VIEW.LIST,
                          label: 'List',
                        },
                        {
                          value: VIEW.CALENDAR,
                          label: 'Calendar',
                        },
                      ]}
                    />
                  </Form.Item>

                  <Form.Item
                    label='Currency Unit'
                    name='currencyUnit'>
                    <Select
                      placeholder=' Select currency unit'
                      options={[
                        {
                          value: ECurrencyUnit.AUD,
                          label: 'AUD',
                        },
                        {
                          value: ECurrencyUnit.VND,
                          label: 'VND',
                        },
                      ]}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormTabSetting>
      <ModalPasswordConfirm onsubmit={() => undefined} visible={false} />
      {isModalOpen && <ModalPin
        // isDisableSubmit={isDisableSubmit}
        // formData={formData}
        valuePin={setIsValuePinNumber}
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handleSubmit={handleSubmitPin}
      />}
    </>
  );
};

export default GeneralTab;
