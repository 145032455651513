import { call, put, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import apis from './apis';
import { AxiosResponse } from 'axios';
import { ISagaFunc } from 'services/actionConfigs';
import { setLoading } from 'services/UI/sagas';
import { PATH_LOADING } from './constants';
import { IHolidayParam } from './types/holiday';

const getSetting: ISagaFunc<any> = function* () {
  try {
    
    yield setLoading(PATH_LOADING.getSetting, true);
    const res: AxiosResponse<{ data: any[] }> = yield call(apis.getSetting);
    if (res?.data?.data) {
      yield put(actions.getSetting.success(res.data.data));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getSetting.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getSetting, false);
  }
};

const getListCancellationReason: ISagaFunc = function* () {
  try {
    
    yield setLoading(PATH_LOADING.getListCancellationReason, true);
    const res: AxiosResponse<{ data: any[] }> = yield call(apis.getListCancellationReason);
    
    if (res?.data?.data) {
      yield put(actions.getListCancellationReason.success(res.data.data));

    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getListCancellationReason.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getListCancellationReason, false);
  }
};

const getSettingBookingForm: ISagaFunc<any> = function* ({payload}) {
  const merchant_code = payload;

  try {
    
    yield setLoading(PATH_LOADING.getSettingBookingForm, true);
    const res: AxiosResponse<{ data: any[] }> = yield call(apis.getSettingBookingForm, merchant_code);
    if (res?.data?.data) {
      yield put(actions.getSettingBookingForm.success(res.data.data));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getSettingBookingForm.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getSettingBookingForm, false);
  }
};

const getOptionBooking: ISagaFunc<any> = function* () {
  try {
    yield setLoading(PATH_LOADING.getOpionBooking, true);

    const res: AxiosResponse<{ data: any[] }> = yield call(apis.getOptionBooking);
    
    if (res?.data?.data) {
      yield put(actions.getOptionBooking.success(res.data.data));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getOptionBooking.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getOpionBooking, false);
  }
};

const getListClosedDate: ISagaFunc<any> = function* () {
  try {
    yield setLoading(PATH_LOADING.getListClosedDate, true);

    const res: AxiosResponse<{ data: any[] }> = yield call(apis.getListClosedDate);
    
    if (res?.data?.data) {
      yield put(actions.getListClosedDate.success(res.data.data));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getListClosedDate.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getListClosedDate, false);
  }
};

const getNumberOfNotification: ISagaFunc<any> = function* () {
  try {
    
    yield setLoading(PATH_LOADING.getNumberOfNotification, true);
    const res: AxiosResponse<{ data: any[] }> = yield call(apis.getNumberOfNotification);
    if (res?.data?.data) {
      yield put(actions.getNumberOfNotification.success(res.data.data));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getNumberOfNotification.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getNumberOfNotification, false);
  }
};

// const getListServiceCharge: ISagaFunc<any> = function* () {
//   try {
    
//     yield setLoading(PATH_LOADING.getListServiceCharge, true);
//     const res: AxiosResponse<{ data: any[] }> = yield call(apis.getListServiceCharge);
//     if (res?.data?.data) {
//       yield put(actions.getListServiceCharge.success(res.data.data));
//     } else {
//       throw 'fail';
//     }
//   } catch (error) {
//     yield put(actions.getListServiceCharge.fail({}));
//   } finally {
//     yield setLoading(PATH_LOADING.getListServiceCharge, false);
//   }
// };

const getListServiceCharge: ISagaFunc = function* () {
  try {
    
    yield setLoading(PATH_LOADING.getListServiceCharge, true);
    const res: AxiosResponse<{ data: any[] }> = yield call(apis.getListServiceCharge);
    
    if (res?.data?.data) {
      yield put(actions.getListServiceCharge.success(res.data.data));

    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getListServiceCharge.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getListServiceCharge, false);
  }
};

const getDetailServiceCharge: ISagaFunc<string> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getDetailServiceCharge, true);
  try {
    const res: AxiosResponse<{ data: any }> = yield call(apis.getDetailServiceCharge, payload);
    if (res?.data?.data) {
      yield put(actions.getDetailServiceCharge.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.getDetailServiceCharge.fail({}));
  }
  yield setLoading(PATH_LOADING.getDetailServiceCharge, false);
};

const getShowServiceCharge: ISagaFunc = function* () {
  try {
    
    yield setLoading(PATH_LOADING.getShowServiceCharge, true);
    const res: AxiosResponse<{ data: any[] }> = yield call(apis.getShowServiceCharge);
    
    if (res?.data?.data) {
      yield put(actions.getShowServiceCharge.success(res.data.data));

    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getShowServiceCharge.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getShowServiceCharge, false);
  }
};

const getTopUpHistory: ISagaFunc = function* ({payload}) {
  try {
    
    yield setLoading(PATH_LOADING.getTopUpHistory, true);
    const res: AxiosResponse<{ data: any[], pagination: any }> = yield call(apis.getTopUpHistory, payload);
    
    if (res?.data?.data) {
      yield put(actions.getTopUpHistory.success({
        data: res?.data?.data || [],
        pagination: res?.data?.pagination || {}
      }));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getTopUpHistory.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getTopUpHistory, false);
  }
};

const getTopUpSMSHistory: ISagaFunc = function* ({payload}) {
  try {
    yield setLoading(PATH_LOADING.getTopUpSMSHistory, true);
    const res: AxiosResponse<{ data: any[], pagination: any }> = yield call(apis.getTopUpSMSHistory, payload);
    
    if (res?.data?.data) {
      yield put(actions.getTopUpSMSHistory.success({
        data: res?.data?.data || [],
        pagination: res?.data?.pagination || {}
      }));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getTopUpSMSHistory.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getTopUpSMSHistory, false);
  }
};

const getTopUpInitData: ISagaFunc = function* ({payload}) {
  try {
    yield setLoading(PATH_LOADING.getTopUpInitData, true);

    const topUpHistory: AxiosResponse<{ data: any[], pagination: any }> = yield call(apis.getTopUpHistory, payload);
    const topUpInfo: AxiosResponse< {data: any }> = yield call(apis.getMerchantStripeBalance);

    if (topUpHistory?.data?.data) {
      yield put(actions.getTopUpHistory.success({
        data: topUpHistory?.data?.data || [],
        pagination: topUpHistory?.data?.pagination || {}
      }));
    }

    if (topUpInfo?.data?.data){
      yield put(actions.setTopUpInitData(topUpInfo?.data?.data));
    }

  }catch (error) {
    yield put(actions.getTopUpHistory.fail({}));
  }finally {
    yield setLoading(PATH_LOADING.getTopUpInitData, false);
  }
};

const getMerchantStripeBalance: ISagaFunc = function* () {
  try {
    
    yield setLoading(PATH_LOADING.getMerchantStripeBalance, true);
    const res: AxiosResponse<{ data: any }> = yield call(apis.getMerchantStripeBalance);

    if (res?.data?.data) {
      yield put(actions.getMerchantStripeBalance.success(res.data.data));
    } else {
      throw 'fail';
    }
  } catch (error) {
    yield put(actions.getMerchantStripeBalance.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getMerchantStripeBalance, false);
  }
};


const getHolidayInfoToday: ISagaFunc<IHolidayParam> = function* ({ payload }) {
  const param = payload;
  
  try {
    const res: AxiosResponse<any> = yield call(apis.getHolidayInfoToday, param);
    
    if (res?.data?.data) {
      const data = res.data.data;      
      yield put(actions.getHolidayInfoToday.success(data));
    } else {
      throw 'fail';
    }
  } catch (error) {
  }
};

export default function* settingServiceSaga() {
  yield takeLatest(actions.getSetting.fetch, getSetting);
  yield takeLatest(actions.getSettingBookingForm.fetch, getSettingBookingForm);
  yield takeLatest(actions.getOptionBooking.fetch, getOptionBooking);
  yield takeLatest(actions.getListClosedDate.fetch, getListClosedDate);
  yield takeLatest(actions.getNumberOfNotification.fetch, getNumberOfNotification);
  yield takeLatest(actions.getListCancellationReason.fetch, getListCancellationReason);
  yield takeLatest(actions.getListServiceCharge.fetch, getListServiceCharge);
  yield takeLatest(actions.getDetailServiceCharge.fetch, getDetailServiceCharge);
  yield takeLatest(actions.getShowServiceCharge.fetch, getShowServiceCharge);
  yield takeLatest(actions.getTopUpHistory.fetch, getTopUpHistory);
  yield takeLatest(actions.getTopUpSMSHistory.fetch, getTopUpSMSHistory);
  yield takeLatest(actions.getMerchantStripeBalance.fetch, getMerchantStripeBalance);
  yield takeLatest(actions.getTopUpInitData.fetch, getTopUpInitData);

  yield takeLatest(actions.getHolidayInfoToday.fetch, getHolidayInfoToday);
}
