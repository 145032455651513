import { Form, Input, Spin, message } from 'antd';
import { IProfileForm, IProfileRequest } from '../service/types/request';
import { AxiosResponse } from 'axios';
import apisEditProfile from '../service/apis';
import { get } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const PasswordTab = () => {

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { t: headerLang } = useTranslation('header');
  const getPayload = (values: IProfileForm) => {
    return {
      new_password: values.newPassword,
      old_password: values.password,
      confirm_password: values.confirmPassword,
    } as IProfileRequest;
  };
  let messageKey = 0;
  const handleSubmit = async (values: IProfileForm) => {
    setLoading(true);
    const payload = getPayload(values);

    try {
      const rest: AxiosResponse<{ message: string }> = await apisEditProfile.updateProfilePassword(payload);
      
      const msg = get(rest, 'data.error.message', '');
      const msg2 = get(rest, 'data.message', '');
      if (msg||msg2) {
        messageKey++;
        message.success(msg || msg2, messageKey);
        form.resetFields();
        setLoading(false);
        return true;
      } else {
        setLoading(false);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 422) {
        const errorMessages = error.response.data.errors.confirm_password;
        const errorMessagesOldPassword = error.response.data.errors.old_password;
        if (errorMessagesOldPassword && errorMessagesOldPassword.length > 0) {
          const msg = errorMessagesOldPassword[0];
          messageKey++;
          message.error(msg, messageKey);
        }
        if (errorMessages && errorMessages.length > 0) {
          const msg = errorMessages[0];
          messageKey++;
          message.error(msg, messageKey);
        }
      } else {
        const response = get<Record<string, string[]>>(
          error,
          'response.data.error',
          {}
        );
        message.error(Object.values(response)[0]);
      }
    } finally {
      setLoading(false);
    }

  };

  return (
    <div className='cards-wrapper'>
      <div className='cards-col'>
        <div className='common-card'>
          <Spin spinning={loading}>
            <Form
              form={form}
              layout='vertical'
              onFinish={handleSubmit}
            >
              <div className='card-body' style={{ margin: 0 }}>
                <div className='form-row'>
                  <Form.Item label={headerLang('CurrentPassword')} name='password' rules={[{
                    required: true,
                    message: headerLang('PleaseEnterCurrentPassword') || ''
                  }]}>
                    <Input type='password' placeholder={headerLang('EnterCurrentPassword') || ''} />
                  </Form.Item>
                </div>
                <div className='form-row'>
                  <Form.Item label={headerLang('NewPassword')} name='newPassword' rules={[{
                    required: true,
                    message: headerLang('PleaseEnterNewPassword') || ''
                  }]}>
                    <Input type='password' placeholder={headerLang('EnterNewPassword') || ''} />
                  </Form.Item>
                </div>
                <div className='form-row'>
                  <Form.Item label={headerLang('ConfirmPassword')} name='confirmPassword' rules={[{
                    required: true,
                    message: headerLang('PleaseEnterConfirmPassword') || ''
                  }]}>
                    <Input type='password' placeholder={headerLang('EnterConfirmPassword') || ''} />
                  </Form.Item>
                </div>
              </div>

              <div className='form-row form-submit-wrapper'>
                <button data-testing='Save' className='common-btn'>{headerLang('Save')}</button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default PasswordTab;
