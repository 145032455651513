import { IResInvoiceItem } from 'features/invoices/services/types/invoice';
import InvoiceInfoItem from './InvoiceInfoItem.tsx';

const InfoIssueBy = ({data} : {data?: IResInvoiceItem | null}) => {

  const storeName = localStorage.getItem('merchantName');
  

  const dataInfoIssueBy: {issueBy?: string, invoiceTo?: string}[] = [
    {
      issueBy: storeName ?? '',
      invoiceTo: data?.customer.name
    },
    {
      issueBy: data?.location.contact_number,
      invoiceTo: data?.customer.phone_number
    },
    {
      issueBy: data?.location.address,
      invoiceTo: data?.customer.email
    },
    {
      issueBy: data?.location.abn ?? '',
    },
  ];

  return (
    <table className='invoice-info'>
      <tr>
        <th className='invoice-info-title'>ISSUED BY</th>
        <th className='invoice-info-title'>INVOICE TO</th>
      </tr>
      {dataInfoIssueBy.map((o, index) => (
        <tr key={index}>
            <InvoiceInfoItem content={o.issueBy ?? ''}/>
            <InvoiceInfoItem content={o.invoiceTo ?? ''} />
        </tr>
      ))}
    </table>
  );
};

export default InfoIssueBy;