import { Form, Input, Modal, Select, TimePicker } from 'antd';
import IconDecrease from 'assets/svg/IconDecrease';
import IconIncrease from 'assets/svg/IconIncrease';
import dayjs from 'utils/dayjs';
import { IServiceSelectedItem } from 'features/sales/services/types/service';
import userSelectors from 'features/users/services/selectors';
import { uniqBy } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// import formatNumber from 'utils/formatNumber';
import { ANONYMOUS_NAME, formatMoney, formatTimeMinutes } from 'utils/unit';
import VerifyPin from 'widgets/VerifyPin';
// import salesHook from 'features/sales/hooks/salesHook';
// import salesSelectors from 'features/sales/services/selectors';

type IFormEditServiceValue = {
  members: number;
  employee: { id: number, name: string };
  quantity: number;
};
export type IModalEditServiceProps = {
  isOpenModal: boolean;
  handleCancel: () => void;
  data?: IServiceSelectedItem | null;
  onSubmit?: (val: IFormEditServiceValue, data: IModalEditServiceProps['data']) => void;
  selector?: any;
  hook?: any;
  isCheckout?: boolean;
  disableSelectTeamMember?: boolean;
};

const ModalEditService = (props: IModalEditServiceProps) => {
  const {
    isOpenModal,
    handleCancel = () => undefined,
    data,
    onSubmit = () => undefined,
    disableSelectTeamMember
    // selector = salesSelectors,
    // hook = salesHook,
    // isCheckout = false
  } = props;

  const [quantity, setQuantity] = useState<number>(1);
  const [form] = Form.useForm();
  const [error, setError] = useState<string>();
  const { t: formLang } = useTranslation('form');
  
  
  const [visibleVerifyPin, setVisibleVerifyPin] = useState(false);
  const actionSubmitRef = useRef<() => void>(() => undefined);

  const users = userSelectors.getStaffPaginateList().data;

  const memberOpts = useMemo(() => {
    //@ts-ignore
    const memberData = data?.merchant_employees?.filter(o => o.id !== '') ?? users.filter(o => o.id !== '');

    const anonymous = memberData?.length > 0 ? memberData?.map((o: any) => ({
      ...o,
      is_receive_book: 1
    }))[0] : {
      full_name: ANONYMOUS_NAME,
      id: '',
      is_receive_book: 1,
      merchant_location_id: '',
      title_name: ANONYMOUS_NAME
    };    
    
    const _data = [...memberData ?? [], anonymous];
    const result = _data?.filter(o => o.is_receive_book === 1).map((employe: any) => {
      const { full_name, id } = employe;
      return {
        value: id,
        label: full_name,
      };
    }).concat([{
      value: '',
      label: ANONYMOUS_NAME,
    }]);

    return uniqBy(result, o => o.value);
  }, [data, users]);  

  const currentTotalService = useMemo(() => {    
    const price = (data?.sale_price ?? 0) || (data?.regular_price ?? 0) || (data?.price ?? 0);
    return +(price * quantity).toFixed(2);
  }, [data, quantity]);  

  const handleDecrease = () => {
    if (quantity === 1) {
      return;
    }
    setQuantity(() => quantity - 1);
  };

  const handleIncrease = () => {
    setQuantity(() => quantity + 1);
  };

  useEffect(() => {
    form.setFieldValue('quantity', quantity);
  }, [quantity]);

  useEffect(() => {
    form.resetFields();
    setQuantity(1);
  }, [data]);

  

  const getTimeStart = () => {     
    const curTimeStart = moment(data?.time_start);    
    return dayjs(curTimeStart.format()).tz();
  };

  const getSelectedMember = () => {    
    return memberOpts.find(o => o.value === (data?.employee?.id ?? data?.employee_id)) ? data?.employee_id ?? data?.employee?.id : (memberOpts.length > 0 ? memberOpts[0].value : '');
  };

  useEffect(() => {
    form.setFieldsValue({
      duration: formatTimeMinutes(data?.duration_time ?? 0),
      time_start: getTimeStart(),
      members: getSelectedMember(),
    });
    setQuantity(data?.quantity || 1);
  }, [isOpenModal, data]);

  const format = 'HH:mm';

  const handleFinish = (valueForm: IFormEditServiceValue) => {    
    const employee = memberOpts.find(o => valueForm.members === o.value && o.value !== '');
    if(! employee) {
      setError(`The team member in checkout must not be ${ANONYMOUS_NAME}.`);
      return;
    }
    onSubmit({
      quantity: valueForm.quantity ?? 1,
      employee: {
        id: employee?.value,
        name: employee?.label,
      },
      members: valueForm.members,
    }, data);

    handleCancel();
  };

  return (
    <>
      <Modal
        maskClosable={false}
        open={isOpenModal}
        onCancel={handleCancel}
        okButtonProps={{ style: { display: 'none' } }}
        width={574}
        footer={null}
        forceRender
        centered
      >
        <NewServicesModalStyled>
          <h2 className='modal-title'>
            <p>Service details</p>
          </h2>
          <Form
            data-testing='form-service-detail'
            name='basic'
            form={form}
            className='modal-form'
            autoComplete='off'
            onFinish={handleFinish}
            layout='vertical'
          >
            <div className='form-label'>{data?.name}</div>
            {/* <div>{data?.description}</div> */}
            <div className='form-row'></div>
            <div className='form-price'>
              <span className='price-value'>
                
                {formatMoney(currentTotalService)}
              </span>
              <div className='quantity-control'>
                <Form.Item name='quantity' className='hidden'>
                  <Input disabled={true} value={quantity}></Input>
                </Form.Item>
                <span
                  onClick={handleDecrease}
                  className={`control-btn ${quantity === 1 && 'is-disabled'}`}
                >
                  <IconDecrease />
                </span>
                <span className='quantity-value'>{quantity}</span>
                <span onClick={handleIncrease} className='control-btn'>
                  <IconIncrease />
                </span>
              </div>
            </div>
            <div className='form-row'>
              <Form.Item
                name='time_start'
                label='Start Time'
                hasFeedback
              >
                <TimePicker
                  format={format}
                  allowClear={false}
                  className='is-time-picker'
                  showNow={false}
                  disabled
                  minuteStep={5}
                  dropdownClassName='time-picker-custom'
                />
              </Form.Item>
              <Form.Item name='duration' label='Duration'>
                <Input disabled={true} />
              </Form.Item>
            </div>
            <Form.Item
              label='Team member'
              name='members'
              rules={[{ required: false, message: 'Member is required' }]}
            >
              <Select placeholder={formLang('SelectTeamMemberPlaceholder') || ''} onChange={() => setError('')} defaultValue={''} options={memberOpts} disabled={disableSelectTeamMember}/>
            </Form.Item>

            {disableSelectTeamMember ?
              <div className='disable-teammember-helptext'>
                Require choose team member has been disabled
              </div> : null
            }

            {error && <p className='text-error'>{error}</p>}
            <div className='form-row form-submit-wrapper'>
              <button data-testing='Cancel_service_form' className='common-btn is-white' onClick={handleCancel}>
                Cancel
              </button>
              <button data-testing='Save_service_form' className='common-btn' type='submit'>
                Save
              </button>
            </div>
          </Form>
        </NewServicesModalStyled>
      </Modal>
      <VerifyPin visible={visibleVerifyPin} onCancel={() => setVisibleVerifyPin(false)} onSubmit={actionSubmitRef.current} />
    </>
  );
};

export default ModalEditService;

const NewServicesModalStyled = styled.div`
  @media (max-width: 767.98px) {
    .form-label {
      margin-top: 24px;
    }
  }

  .disable-teammember-helptext {
    margin-top: -20px;
    color: #B8B8B8;
  }
`;
