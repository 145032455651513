import { EBookingStatus } from 'constants/index';
import bookingSelectors from 'features/bookings/services/selectors';
import moment, { Moment } from 'moment';
import { useMemo } from 'react';
import styled from 'styled-components';
import DraggableItem from './DnD/DraggableItem';
import PopoverEdit from './PopoverEdit';
import UIPopover from './UIBookingItem/Popover';
import UIBookingItemStyled, { IconCheckCircle, IconLock } from './UIBookingItem/styles';
import { PositionStyled } from './UITeamMemberMultiItem';
import { getDefaultHeight } from './constants';
import { BookingItem } from './types';
import useOpenPopover from './useOpenPopover';

export type IServiceFilter = {
  id: number;
  category_service_color: string;
  service_name: string;
  service_id: number;
  time_start: string;
  time_end: string;
  duration_time: number;
  quantity: number;
  employee_id: number;
  sale_price: number;
  service_variant_id?: number;
};

type Props = {
  col_id?: string;
  data: BookingItem;
  isChildMultiple?: boolean;
  rowTime: Moment;
};

const TeamMemberItem = ({ parentData, service, rowTime, multiViewPopup }: { service: IServiceFilter, parentData: Props['data'], rowTime: Moment, multiViewPopup?: boolean }) => {
  const [open, setOpen] = useOpenPopover();
  const id = parentData.id + '_' + service.id + '_' + service.service_variant_id;
  const book_status = parentData.book_status;
  const pixelPerMinute = bookingSelectors.getPixelPerMinute();
  const zoomCalendar = bookingSelectors.getZoomView();
  const customerName = book_status === EBookingStatus.DRAFT ? 'Draft booking' : parentData?.customer?.name ?? '';
  // const [isExpanded, setIsExpanded] = useState(false);

  // const onClickExpand: React.MouseEventHandler<HTMLButtonElement> = (e) => {
  //   e.stopPropagation();
  //   setIsExpanded(!isExpanded);
  // };

  const IconStatus = useMemo(() => {
    switch (book_status) {
      case EBookingStatus.NO_SHOW:
      case EBookingStatus.IN_PROGRESS:
      case EBookingStatus.OPEN: {
        if (service.employee_id == null) {
          return null;
        }
        return <IconLock />;
      }

      case EBookingStatus.COMPLETED:
        return <IconCheckCircle />;
      default:
        return null;
    }
  }, [book_status]);

  const offset = useMemo(() => {
    const [hour, minute] = service.time_start?.split(':') ?? [];
    const timeStart = moment(rowTime).set({ hour: +hour, minute: +minute });
    const top = moment(timeStart, 'YYYY-MM-DD HH:mm:ss').diff(rowTime, 'minute');
    const bottom = service.duration_time * service.quantity;
    return ({
      top, bottom
    });
  }, [service, rowTime]);

  return (
    <UIPopover open={open} setOpen={setOpen} data={parentData} isMemberView>
      <DraggableItem
        key={id}
        id={id.toString()}
        data={{ serviceId: service.service_id, service_variant_id: service.service_variant_id }}
      >
        <PositionStyled className='box TeamMemberItem_TeamMemberItemStyled_PositionStyled' top={offset.top} bottom={multiViewPopup ? zoomCalendar : offset.bottom} pixelPerMinute={pixelPerMinute}>
          <TeamMemberItemStyled className={'TeamMemberItem_TeamMemberItemStyled'} distance={multiViewPopup ? zoomCalendar : (service.duration_time * service.quantity)} pixelPerMinute={pixelPerMinute}>
            <UIBookingItemStyled
              bookStatus={parentData.book_status}
              className={'memberView'}
            >
              <div className='item-container' onClick={() => setOpen(true)}>
                <div className='item-inner'>
                  <p className='item-title'>{customerName}</p>
                  <p className='item-time'>
                    {service.time_start} -{' '}
                    {service.time_end}
                  </p>
                  <div className='services is-expanded'>
                    <div className='service-item'>
                      <div
                        className='circle'
                        style={{ backgroundColor: service.category_service_color }}
                      />
                      <span className={`name ${service.service_name.length > 20 ? 'service-name-ellipsis' : ''}`}>{service.service_name}</span>
                    </div>
                  </div>
                </div>
                <div className='dash' onClick={e => e.stopPropagation()}>
                  {IconStatus}
                  <PopoverEdit bookId={parentData.id} bookStatus={book_status} data={parentData} />
                </div>
              </div>
            </UIBookingItemStyled>
          </TeamMemberItemStyled>
        </PositionStyled>
      </DraggableItem>
    </UIPopover>
  );
};
export default TeamMemberItem;
type StyleProps = {
  distance: number;
  isLast?: boolean;
  pixelPerMinute: number;
  isChildMultiple?: boolean;
};
export const TeamMemberItemStyled = styled.div`
  width: 100%;
  height: ${({ distance = 0, pixelPerMinute = 0, isChildMultiple = false }: StyleProps) => isChildMultiple ? 'auto': getDefaultHeight(distance, pixelPerMinute)}px;
  min-height: ${({ distance = 0, pixelPerMinute = 0, isChildMultiple = false }: StyleProps) => isChildMultiple ? 'auto': getDefaultHeight(distance, pixelPerMinute)}px;
  max-height: ${({ distance = 0, pixelPerMinute = 0, isChildMultiple = false }: StyleProps) => isChildMultiple ? 'auto': getDefaultHeight(distance, pixelPerMinute)}px;
`;