import { Form, Input, Modal, ModalProps, Spin } from 'antd';
import IconReward from 'assets/svg/IconReward';
import { useEffect } from 'react';
import styled from 'styled-components';
import NumberValidations from 'utils/validations/number';

export interface IModalEditLoyaltyForm {
  loyaltyPoint: number
}

interface IModalEditLoyaltyProps extends ModalProps {
  isOpen: boolean,
  handleCancel: () => void
  handleUpdate: (values: IModalEditLoyaltyForm) => void,
  loyaltyPoint: number;
  loading: boolean;
}

const ModalEditLoyalty = ({isOpen, handleCancel, handleUpdate, loyaltyPoint, loading}: IModalEditLoyaltyProps) => {


  const [form] = Form.useForm<IModalEditLoyaltyForm>();

  useEffect(() => {
    form.setFieldValue('loyaltyPoint', loyaltyPoint);
  }, [loyaltyPoint]);
  

  return (
      <ModalStyled
        maskClosable={false}
        open={isOpen}
        onCancel={handleCancel}
        okButtonProps={{ style: { display: 'none' } }}
        width={574}
        footer={null}
        centered
        forceRender
        
      >
      <Spin spinning={loading}>

      <h2 className='modal-title'>
        <p>Edit loyalty points</p>
      </h2>
      <Form
        name='basic'
        className='modal-form'
        onFinish={(values) => handleUpdate(values)}
        autoComplete='off'
        form={form}
        layout='vertical'
        initialValues={{
          loyaltyPoint: loyaltyPoint
        }}
      >
        <Form.Item
          label='Loyalty point'
          name='loyaltyPoint'
          rules={[
            { required: true},
            NumberValidations.ONLY_NUMBER,
          ]}
        >
          <Input
            type='number'
            prefix={<IconReward />}
            defaultValue={0}
            min={0}
            style={{
              width: '100%'
            }}
            placeholder='Enter loyalty point'/>
        </Form.Item>

        <BtnModalStyled>
          <div className='form-row form-submit'>
            <button type='button' className='common-btn is-white' onClick={handleCancel}>
              Cancel
            </button>
            <button type='submit' className='common-btn'>Save</button>
          </div>
        </BtnModalStyled>
      </Form>

      </Spin>
    </ModalStyled>
  );
};

export default ModalEditLoyalty;

const BtnModalStyled = styled.div`
.form-row {
  justify-content: center;
}

`;

const ModalStyled = styled(Modal)`
  .form-submit {
    margin-top: 150px;
  }
  .ant-input-number-affix-wrapper  {
    background-color: #f0f2f6;
  }
`;