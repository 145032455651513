import Section from 'features/bookings/components/BookingForm/Section';
import React, { Fragment, createRef, useState } from 'react';
import styled from 'styled-components';
import ReviewBooking from './ReviewBooking';
import TyroPaymentForm from './TyroPaymentForm';
import { BOOKING_FORM, BookingFormProps } from '../types';
import IconBack from 'assets/svg/IconBack';
import PrimaryButton from 'components/common/Buttons/PrimaryButton';
import { Spin } from 'antd';

interface Props extends BookingFormProps {
  isCheckIn?: boolean;
  setBookingCode?: (code: string) => void;
  wallet?: boolean;
  live?: boolean;
}

const PrepaidPage: React.FC<Props> = ({ setFormActive, setBookingCode, wallet, live }) => {
  const [disablePaymentBtn, setDisablePaymentBtn] = useState<boolean>(true);
  const tyroJSRef = createRef<any>();
  const [isLoadingBtn, setIsLoadingBtn] = useState<boolean>(false);

  const submitPrePaidForm = () => {
    tyroJSRef.current.submitPrepardForm();
  };

  return (
    <div style={{ background: '#F0F2F7' }}>
      <ConfirmStyled>
        <Section label='Review and confirm'>
          <Fragment>
            <ReviewBooking />
            <Spin spinning={isLoadingBtn}>
              <TyroPaymentForm
                ref={tyroJSRef}
                enablePaymentBtn={() => {
                  if (disablePaymentBtn) {
                    setDisablePaymentBtn(false);
                  }
                }}
                setBookingCode={setBookingCode}
                setFormActive={setFormActive}
                setIsLoadingBtn={setIsLoadingBtn}
                wallet={wallet}
                live={live}
              />
            </Spin>
          </Fragment>
        </Section>
        <div className='search-wrapper'>
          <button
            className='icon-back'
            onClick={() => setFormActive(BOOKING_FORM.CONFIRM)}
          >
            <IconBack />
          </button>
          <PrimaryButton
            disabled={disablePaymentBtn || isLoadingBtn}
            type='button'
            label={isLoadingBtn ? <Spin/> :'Payment'}
            className='primary_button'
            onClick={() => submitPrePaidForm()}
          />
        </div>
      </ConfirmStyled>
    </div>
  );
};

export default PrepaidPage;

const ConfirmStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  width: 100%;

  .search-wrapper {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    .icon-back {
      width: 64px;
      height: 64px;
      background: #fff;
      border-radius: 6px;
    }
  }
`;
