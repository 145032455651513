import {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useAppDispatch} from 'store/hooks';
import styled from 'styled-components';
import apisBooking from 'features/bookings/services/apis';
import bookingActions from 'features/bookings/services/actions';
import bookingSelectors from 'features/bookings/services/selectors';
import settingActions from 'features/settings/services/actions';
import settingsSelectors from 'features/settings/services/selectors';
import {IMerchantPublicInfoResData} from 'features/bookings/services/types/bookingOnline';

const RefundPolicy: React.FC = () => {
  const {merchant_code = ''} = useParams();
  const dispatch = useAppDispatch();

  const locationsStore = bookingSelectors.bookingOnline.getLocations();
  const listSettingsBookingForm = settingsSelectors.getSettingBookingForm();
  const [merchantPublicInfo, setMerchantPublicInfo] =
    useState<IMerchantPublicInfoResData>();

  useEffect(() => {
    document.title = 'Refund Policy';

    (async () => {
      try {
        const res = await apisBooking.getPublicMerchantInfo(merchant_code);
        setMerchantPublicInfo(res.data.data);
      } catch (error) {
        console.log('Error get public merchant info: ', error);
      }
    })();

    dispatch(bookingActions.getBookingOnlineData.fetch(merchant_code));
    dispatch(settingActions.getSettingBookingForm.fetch(merchant_code));
  }, []);

  return (
    <RefundPolicyStyled>
      <h1>HEYA Refund Policy</h1>
      <div className="param">
        <h4>Introdution</h4>
        <p>
          Thank you for choosing{' '}
          {merchantPublicInfo && merchantPublicInfo.full_name}. We understand
          that plans can change, and we strive to accommodate our
          customers&apos; needs. This Refund Policy outlines the terms and
          conditions for refunds on payments made in advance of a booking before
          the service is rendered.
        </p>
      </div>
      <div className="param">
        <h4>Cancellation and Refunds</h4>
        <p>
          To ensure a smooth and fair process, please review the following
          guidelines for cancellations and refunds:
        </p>
        <ul className="list-number">
          <li>
            <strong>Full Refund Eligibility:</strong>
          </li>
          <ul>
            <li>
              You are eligible for a full refund if you cancel your booking at
              least{' '}
              {listSettingsBookingForm.cancel_before_scheduled_time_lable || ''}{' '}
              before the scheduled service date.
            </li>
            <li>
              Refunds will be processed back to the original payment method used
              at the time of booking.
            </li>
          </ul>
          <li>
            <strong>No Refunds:</strong>
          </li>
          <ul>
            <li>
              Cancellations made less than{' '}
              {listSettingsBookingForm.cancel_before_scheduled_time_lable || ''}{' '}
              before the scheduled service date, or failure to show up for the
              appointment, will not be eligible for a refund.
            </li>
          </ul>
        </ul>
      </div>
      <div className="param">
        <h4>How to Cancel and Request a Refund</h4>
        <p>
          You will receive an email confirmation after making your booking.
          Please ensure you provide a correct and valid email address. To
          request a cancellation and refund:
        </p>
        <ul className="list-number">
          <li>
            In your confirmation email, please click on the link to cancel your
            booking.
          </li>
          <li>
            The merchant will cancel your booking and initiate the refund if it
            is within the allowed time.
          </li>
          <li>
            We will send you a message when your cancellation and refund request
            is completed.
          </li>
        </ul>
      </div>
      <div className="param">
        <h4>Processing Time</h4>
        <p>
          Refunds are typically processed within 5 business days from the date
          of cancellation. Please note that it may take additional time for the
          refund to appear on your payment method statement, depending on your
          bank or payment provider.
        </p>
      </div>
      <div className="param">
        <h4>Changes to This Policy</h4>
        <p>
          We reserve the right to update or modify this Refund Policy at any
          time. Any changes will be effective immediately upon posting on our
          website. We encourage you to review this policy periodically to stay
          informed about our refund practices.
        </p>
      </div>
      <div className="param">
        <h4>Contact Us</h4>
        <p>
          If you have any questions or need further assistance regarding our
          Refund Policy, please contact us at:
        </p>
        {locationsStore.length > 0 && <p>{locationsStore[0].address}</p>}
        {merchantPublicInfo && (
          <>
            <p>{merchantPublicInfo.full_name}</p>
            <p>{merchantPublicInfo.email}</p>
            <p>{merchantPublicInfo.phone}</p>
          </>
        )}
      </div>
      <p>
        By making a booking and pre-payment, you agree to the terms and
        conditions outlined in this Refund Policy.
      </p>
    </RefundPolicyStyled>
  );
};

export default RefundPolicy;

const RefundPolicyStyled = styled.div`
  padding-inline: 20%;
  padding-top: 100px;
  padding-bottom: 30vh;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media only screen and (min-width: 768px) and (max-width: 1024.98px) {
    padding: 40px 58px;
  }

  @media only screen and (max-width: 767.98px) {
    padding: 40px 12px;
  }

  * {
    color: #37352f;
  }

  h1 {
    font-size: 40px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  .param {
    display: flex;
    flex-direction: column;
    gap: 10px;

    ul {
      padding-inline-start: 26px;
      list-style-type: number;

      li {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      > ul {
        list-style-type: disc;
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;
