import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Checkbox, Form } from 'antd';
import { IServiceQuickBookingItem } from 'features/bookings/services/types/quickBooking';
import React, { useState } from 'react';
import styled from 'styled-components';
import { formatMoney, formatTimeMinutes } from 'utils/unit';
import { IServiceVariant } from '../../types';

export type IFuncGetIsCheck = (o: { service_id: number, service_variant_id?: number }) => boolean;
export type IServiceItem = {
  id?: number;
  name: string;
  value: string;
  amount?: number;
  regular_price?: number;
  duration_time?: number;
  time: number;
  timeUnit: string;
  category?: any;
  onServiceChange?: any;
  checked?: boolean;
  members?: string;
  time_start?: any;
  quantity?: string;
  book_assignment_id?: number;
  service_name?: string;
  service_price?: string;
  service_variants?: IServiceItem[];
};

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  service: IServiceQuickBookingItem;
  onServiceChange: (isChecked: boolean, serviceItem: IServiceQuickBookingItem) => void;
  getIsChecked: IFuncGetIsCheck
  isActive?: boolean
}

const ServiceItem: React.FC<Props> = ({
  service,
  className,
  onServiceChange,
  getIsChecked,
  isActive
}) => {
  const isChecked = getIsChecked({ service_id: service.id });

  return (
    <ServiceItemStyled disabled={isActive} className={className} onClick={() => onServiceChange(!isChecked, service)} >
      <td className='service_content'>
        <div className='group_name'>
          <div className='checkbox'>
            <Form.Item name='service'>
              <Checkbox checked={isChecked} />
            </Form.Item>
          </div>
          <div className='service_item'>
            <p className='service_name '>
              <span className='service-name-ellipsis'>{service.name || service.service_name}</span>
            </p>
            <p className='service_time'>
              {service.duration_time
                ? formatTimeMinutes(service.duration_time)
                : ''}
            </p>
          </div>
        </div>
      </td>
      <td className='service_amount'>
        <p>{formatMoney((service.sale_price || service.regular_price || service.service_price) ?? 0)}</p>
      </td>
    </ServiceItemStyled>
  );
};

interface ServiceVariantProps extends React.HTMLAttributes<HTMLDivElement> {
  serviceVariant: IServiceVariant;
  className: string;
  isChecked: boolean;
  onServiceChange: (checked: boolean) => void;
  isActive?: boolean
}

const ServiceVariant: React.FC<ServiceVariantProps> = ({
  serviceVariant,
  isChecked,
  className,
  onServiceChange,
  isActive
}) => {

  return (
    <ServiceItemStyled disabled={isActive} className={className} onClick={() => onServiceChange(!isChecked)}>
      <td className='service_content'>
        <div className='group_name'>
          <div className='checkbox'>
            <Form.Item name='service'>
              <Checkbox checked={isChecked} />
            </Form.Item>
          </div>
          <div>
            <p className='service_name'>
              <span className='service-name-ellipsis'>{serviceVariant.name || serviceVariant.service_name}</span>
            </p>
            <p className='service_time'>
              {serviceVariant.duration
                ? formatTimeMinutes(serviceVariant.duration)
                : ''}
            </p>
          </div>
        </div>
      </td>
      <td className='service_amount'>
        <p>{formatMoney(serviceVariant.price)}</p>
      </td>
    </ServiceItemStyled>
  );
};

export default ServiceItem;

interface ServiceGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  service: IServiceQuickBookingItem;
  onServiceChange?: (isChecked: boolean, serviceItem: IServiceQuickBookingItem) => void;
  getIsChecked: IFuncGetIsCheck;
  isActive?: boolean;
}

export const ServiceGroup: React.FC<ServiceGroupProps> = ({
  service,
  className,
  onServiceChange,
  getIsChecked,
  isActive
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const handleToggle = () => setIsOpen(!isOpen);
  if (service?.service_variants && service.service_variants?.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <ServiceItemStyled disabled={isActive} className={`have_child ${className}`} onClick={handleToggle}>
        <td className='service_content'>
          <div className='group_name'>
            <div className='checkbox'>
              {isOpen ? <UpOutlined /> : <DownOutlined />}
            </div>
            <div>
              <p className='service_name'>
                <span className='service-name-ellipsis'>{service.name}</span></p>
              {/* <p className='service_time'>{service.duration_time}</p> */}
            </div>
          </div>
        </td>
        <td className='service_amount'>
        </td>
      </ServiceItemStyled>
      {isOpen &&
        service?.service_variants?.map((item: IServiceVariant) => (
          <ServiceVariant
            isActive={isActive}
            className='is_child'
            key={item.id}
            serviceVariant={item}
            isChecked={getIsChecked({ service_id: service.id, service_variant_id: item.id })}
            onServiceChange={(checked) => {

              onServiceChange &&
                onServiceChange(checked, {
                  ...service,
                  service_price: item?.price,
                  duration_time: item?.duration,
                  sale_price: item.price,
                  serviceVariantName: item.name,
                  service_variant_id: item.id,
                });
            }}
          />
        ))}
    </React.Fragment>
  );
};

const ServiceItemStyled = styled.tr<{ disabled?: boolean }>`

  opacity: ${props => props.disabled ? 0.5 : 1};

  &:hover {
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  &:not(.have_child):hover {
    cursor: pointer;
    background: #F0F2F7;
  }
  &.is_child {
    background-color: var(--color-white-01);
    padding-left: 10px;
    .group_name {
      padding-left: 28px;
    }
    &:hover {
      background-color:#e1e2e6;
    }
  }

  button {
    background: transparent;
    outline: none;
    border: none;
  }
  td {
    vertical-align: middle;
    padding: 10px 0px;
    color: var(--color-primary);
  }

  .service_content {
    padding-left: 8px;
    width: 100%;
    .checkbox {
      margin-right: 16px;
    }
    .ant-form-item-row {
      display: block;
    }
    .ant-form-item-control {
      margin-bottom: 0 !important;
    }
    .group_name {
      display: flex;
      align-items: center;
      @media only screen and (max-width: 767.98px) {
        width: 150px
      }
    }
    .service_name {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      display: flex;
      align-items: center;
      /* white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; */
    }
    .service-name-ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 200px;
      @media only screen and (max-width: 767.98px) {
        width: 130px;
      }
      @media only screen and (min-width: 768px) and (max-width: 1180.98px) {
        width: 150px;
      }
    }
    .service_time {
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: var(--color-purple-06);
    }
  }

  .service_amount {
    display: block;
    padding-right: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-align: right;
    span {
      display: block;
      font-weight: 400;
      font-size: 12px;
    }
  }
`;
