import { Modal, Spin } from 'antd';
import PromotionForm, { IPromotionFormProps } from './Form';
import promotionSelectors from '../services/selectors';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
interface IPromotionModalProps extends IPromotionFormProps {
  visible?: boolean;
}
const PromotionModal = ({ visible, form, ...rest }: IPromotionModalProps) => {
  const { t: formLang } = useTranslation('form');
  const loading = promotionSelectors.getLoadingDetailPromotion();


  return (
    <Modal
      maskClosable={false}
      open={visible}
      onCancel={rest.onClose}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      centered
      className='promotion-manager-modal'
    >
      <PromotionModalStyled className='form-wrap'>
        <h2 className='modal-title'>
          {rest.isEdit ? formLang('promotion.editPromotionModalTitle') : formLang('promotion.addPromotionModalTitle')}
        </h2>
        <Spin spinning={loading}>
          <div className='form-promotion'>
            <PromotionForm {...rest} form={form} />
          </div>
        </Spin>
      </PromotionModalStyled>
    </Modal>

  );
};

const PromotionModalStyled = styled.div`
  &.form-wrap {
    justify-content: flex-start;;
  }

  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;

  .modal-title {
    margin-bottom: 24px;
    border-bottom: none;
    text-align: left;
  }

  @media (min-width: 1181px) {
    .form-promotion {
      padding-left: 100px;
      padding-right: 100px;
    }
  }

  .ant-form {
    justify-content: center;
    align-items: center;
  }
`;

export default PromotionModal;
