import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Route, Routes, useResolvedPath } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import store from 'store';
import './App.scss';

import NotFoundPage from 'features/NotFoundPage';
import AnalyticsPage from 'features/analytics/pages/AnalyticsPage';
import BookingForm from 'features/bookings/pages/BookingForm';
import EditBookingPage from 'features/bookings/pages/EditBookingPage';
import QuickBookingPage from 'features/bookings/pages/QuickBookingPage';
import CheckoutPage from 'features/checkout/pages/CheckoutPage';
import EditProfilePage from 'features/editProfile/pages/EditProfilePage';
import LocationsEditPage from 'features/locations/pages/LocationsEditPage';
import LoyaltyProgramPage from 'features/loyaltyProgram/pages/LoyaltyProgramPage';
import NotificationPage from 'features/notification/pages/NotificationPage';
import PromotionsPage from 'features/promotions/pages/PromotionsPage';
import ReviewsPage from 'features/reviews/pages/ReviewsPage';
import SettingsPage from 'features/settings/pages/SettingsPage';
import VouchersPage from 'features/vouchers/pages/VouchersPage';
import useRefreshToken from 'hooks/useRefreshToken';
import { ToastContainer } from 'react-toastify';
import LoadingPage from 'services/UI/LoadingPage';
import BaseLayout from './components/layout/BaseLayout';
import SignInPage from './features/auth/pages/SignInPage';
import BookingsPage from './features/bookings/pages/BookingsPage';
import CustomersDetailPage from './features/customers/pages/CustomersDetailPage';
import CustomersPage from './features/customers/pages/CustomersPage';
import HomePage from './features/home/pages/HomePage';
import InvoicesDetailPage from './features/invoices/pages/InvoicesDetailPage';
import InvoicesPage from './features/invoices/pages/InvoicesPage';
import LocationsAddNewPage from './features/locations/pages/LocationsAddNewPage';
import LocationsPage from './features/locations/pages/LocationsPage';
import ServicesPage from './features/services/pages/ServicesPage';
import UsersPage from './features/users/pages/UsersPage';
import ForCustomerPage from 'features/reviews/pages/ForCustomer';
import RefundPage from 'features/refund/page/RefundPage';
import CheckInPage from 'features/checkin/pages/CheckInPage';
import UnsubcribedEmail from 'features/UnsubcribedEmail';

// import './assets/i18n/i18n';
import { useTranslation } from 'react-i18next';
import ResetPasswordPage from 'features/auth/pages/ResetPasswordPage';
import SalesPage from 'features/sales/pages/SalesPage';
import CategoriesPage from 'features/services/pages/CategoriesPage';
import WorkingHourPage from 'features/workingHour/pages/workingHourPage';
import UserCheckInPage from 'features/users/pages/UserCheckIn';
import WorkingHourDetail from 'features/workingHour/pages/workingHourDetail';
import moment from 'moment-timezone';
import 'moment-timezone';
import TimeSchedulePage from 'features/timeSchedule/pages/TimeSchedulePage';
import PaymentPage from 'features/payment/page';
import { IMenuConfig } from 'constants/path.enum';
import BookingCancelPage from 'features/bookings/pages/BookingCancel';
import CancelSuccessPage from 'features/cancelSuccess/pages/CancelSuccessPage';
import SimpleTyroJSSample from 'features/payonline';
import ThermalPrinter from 'features/invoices/widgets/ThermalPrinter';
import ThermalPrinterSerialPort from 'features/invoices/widgets/ThermalPrinterSerialPort';
import PrintInvoiceBooking from 'features/bookings/components/BookingForm/QuickBooking/PrintInvoiceBooking';
import PrivacyPolicy from 'features/bookings/pages/PrivacyPolicy';
import RefundPolicy from 'features/bookings/pages/RefundPolicy';
// import QuickBookingPageV2 from 'features/bookings/pages/QuicknookingPageV2';
// import Wellknow from 'features/wellKnown';



const CalendarBooking = React.lazy(
  () => import('./features/bookings/components/BookingCalendar')
);

function AppUI() {
  const { loading, shield } = useRefreshToken();
  if (loading) return null;

  return (
    <React.Fragment>
      <Routes>
        <Route path='/pay-online' element={<SimpleTyroJSSample/>}/>
        <Route path='/print-invoice' element={<ThermalPrinter/>}/>
        <Route path='/print-invoice-serial-port' element={<ThermalPrinterSerialPort/>}/>
        <Route path='/print-invoice-booking' element={<PrintInvoiceBooking />} />
        {/* <Route path='/.well-known/apple-developer-merchantid-domain-association' element={<Wellknow />} /> */}
        <Route path='/private' element={shield(BaseLayout)}>
          <Route path='bookings' element={<BookingsPage />} />
          <Route path='bookings/quick-booking' element={<QuickBookingPage />} />
          {/* <Route path='bookings/quick-booking-v2' element={<QuickBookingPageV2 />} /> */}
          <Route path='bookings/edit-booking' element={<EditBookingPage />} />
          <Route
            path='calendar-bookings'
            element={
              <React.Suspense>
                <CalendarBooking />
              </React.Suspense>
            }
          />
          <Route
            path='bookings/checkout/:book_assignment_id'
            element={<CheckoutPage />}
          />
          <Route path='sales' element={<SalesPage />} />
          <Route path='customers' element={<CustomersPage />} />
          <Route path='customers/:customer_code' element={<CustomersDetailPage />} />
          <Route path='services' element={<ServicesPage />} />
          <Route path='services/categories' element={<CategoriesPage />} />
          <Route path='analytics' element={<AnalyticsPage />} />
          <Route path='invoices' element={<InvoicesPage />} />
          <Route path='invoices/:invoice_no' element={<InvoicesDetailPage />} />
          <Route path='reviews' element={<ReviewsPage />} />
          <Route path='promotions' element={<PromotionsPage />} />
          <Route path='vouchers' element={<VouchersPage />} />
          <Route path='locations' element={<LocationsPage />} />
          <Route path='locations/add-new' element={<LocationsAddNewPage />} />
          <Route path='locations/edit/:id' element={<LocationsEditPage />} />
          <Route path='loyalty-program' element={<LoyaltyProgramPage />} />
          <Route path='users' element={<UsersPage />} />
          <Route path='settings' element={<SettingsPage />} />
          <Route path='edit-profile' element={<EditProfilePage />} />
          <Route path='notification' element={<NotificationPage />} />
          <Route path='working-hour' element={<WorkingHourPage />} />
          <Route path='working-hour/detail' element={<WorkingHourDetail />} />
          <Route path='time-schedule' element={<TimeSchedulePage />} />
        </Route>
        <Route path='/' element={<HomePage />} />
        <Route path='/sign-in' element={<SignInPage />} />
        <Route path='/reset-password' element={<ResetPasswordPage />} />
        <Route
          path='/:store_name/booking-form/:merchant_code'
          element={<BookingForm isTestTyro wallet={false} live/>}
        />
        <Route
          path='/:store_name/booking-form-test-v2-live/:merchant_code'
          element={<BookingForm isTestTyro v2 wallet live/>}
        />
        <Route
          path='/:store_name/booking-form-test-v2-sandbox/:merchant_code'
          element={<BookingForm isTestTyro v2 wallet/>}
        />
        <Route
          path='/:store_name/check-in/:merchant_code'
          element={<BookingForm isCheckIn />}
        />
        <Route
          path='/:store_name/privacy-policy/:merchant_code'
          element={<PrivacyPolicy />}
        />
        <Route
          path='/:store_name/refund-policy/:merchant_code'
          element={<RefundPolicy />}
        />
        <Route
          path='/:store_name/checkin/:merchant_code/:location_id'
          element={<BookingForm isCheckIn />}
        />
         <Route
          path='/booking-cancel/:merchant_code/:book_id'
          element={<BookingCancelPage />} 
        />
        <Route
          path='/check-in'
          element={<CheckInPage />}
        />
        <Route
          path='/staff-check-in'
          element={shield(UserCheckInPage)}
        />
        <Route
          path='/:store_name/reviews/:merchant_code/:customer_code/:invoice_code'
          element={<ForCustomerPage />}
        />
        <Route path='/refund' element={<RefundPage />} />
        <Route
          path='booking-form/:merchant_code'
          element={<BookingForm />}
        />
        <Route path='unsubcribed-email' element={<UnsubcribedEmail/>}/>
        <Route path='*' element={<NotFoundPage />} />
        <Route path='book-cancel-success' element={<CancelSuccessPage />} />
        <Route path='/create-intent' element={<PaymentPage />} />
      </Routes>
    </React.Fragment>
  );
}
const App = () => {
  type BreadcrumbFunc = (pathName: string) => string[];
  type MenuConfigType = {
    path: string;
    breadcrumbs: string[] | BreadcrumbFunc;
  };

  const getListPaths = (pathname: string) => pathname?.split('/') ?? [];
  // @ts-ignore
  const pathname = useResolvedPath().pathname;
  const [breadcrumbsList, setBreadcrumbsList] = useState<string[]>([]);

  const findingBreadcrumbItem = (item: MenuConfigType) => {
    if (item.path === pathname) return true;
    if (
      pathname?.includes(item.path) &&
      getListPaths(pathname).length === getListPaths(item.path).length
    )
      return true;

    return false;
  };
  const getBreadcrumbs = (
    breadcrumbs: MenuConfigType['breadcrumbs']
  ): string[] => {
    return typeof breadcrumbs === 'function'
      ? breadcrumbs(pathname?.replace('/private/', ''))
      : breadcrumbs;
  };
  useEffect(() => {
    if (pathname !== '') {
      const currentBreadcrumbs = IMenuConfig.find(findingBreadcrumbItem);

      if (currentBreadcrumbs) {
        const breadcrumbs: string[] = getBreadcrumbs(
          currentBreadcrumbs.breadcrumbs
        );
        setBreadcrumbsList(breadcrumbs);
      }
    }
  }, [pathname]);

  const { t, i18n } = useTranslation();
  moment.tz.setDefault('Australia/Sydney');
  // dayjs.tz.setDefault('America/New_York');  

  const changeLanguage = (languageValue: any) => {
    i18n.changeLanguage(languageValue);
    document.title = t('HEYA');
    localStorage.setItem('selectedLanguage', languageValue);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    const defaultLanguage = 'en';
    const selectedLanguage = storedLanguage || defaultLanguage;

    if (selectedLanguage !== i18n.language) {
      changeLanguage(selectedLanguage);
    }
  }, []);

  useEffect(() => {
    if (document.title === undefined) {
      document.title = 'HEYA';
      return;
    }

    if (breadcrumbsList[2]) {
      document.title = breadcrumbsList[0] + ' > ' + breadcrumbsList[1] + ' > ' + breadcrumbsList[2];
      return;
    }
    if (breadcrumbsList[1]) {
      document.title = breadcrumbsList[0] + ' > ' + breadcrumbsList[1];
      return;
    }
    document.title = breadcrumbsList[0];
  }, [breadcrumbsList]);

  return (
    <Provider store={store}>
      <AppUI />
      <LoadingPage />
      <ToastContainer />
    </Provider>
  );
};
export default App;
