import { Form, message } from 'antd';
import IconUnsubcribedEmail from 'assets/svg/IconUnsubcribedEmail';
// import StickyPromotion from 'features/bookings/components/BookingForm/StickyPromotion';
import React, { memo, useEffect, } from 'react';
import styled from 'styled-components';
import apiUnsubcribedEmail from './service/apis';
import { get } from 'lodash';

const index: React.FC = () => {
  const [form] = Form.useForm();
  const urlParams = new URLSearchParams(window.location.search);
  const nameStore = urlParams.get('name_store');
  const customerCode = urlParams.get('customer_code');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const rest = await apiUnsubcribedEmail.storeUnsubcribedEmail(customerCode);
        const msg = get(rest, 'data.data.message', '');
        if (msg) {
          message.success(msg);
          return true;
        }
      } catch (error) {
        console.error('Error:', error);
        return false;
      }
    };

    fetchData();
  }, [customerCode]);

  return (
    <ForCustomerPageStyled className=''>
      {/* <StickyPromotion label='Some promotion - book something get another thing free!' /> */}
      <div className='form_container'>
        <Form
          className='form-info'
          form={form}
          scrollToFirstError
          layout='vertical'>
          <div className='review-block'>
            <div className='block-inner'>
              <div className='block-image'>
                <IconUnsubcribedEmail />
              </div>
              <div className='title-container'>
                <h1 className='block-title'>You have been succesfully unsubscribed.</h1>
                <p className='block-content'>You can re-subscribe at any time by visiting the Booking link of <span>{nameStore}</span></p>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </ForCustomerPageStyled>
  );
};

export default memo(index);

const ForCustomerPageStyled = styled.div`
  background: var(--color-white-01);
  min-height: 100vh;
  font-family: var(--font-base);
  @media only screen and (min-width: 1025px) {
    padding-bottom: 80px;
  }
  @media only screen and (max-width: 767.98px) {
    padding-bottom: 20px;
  }
  .form_container {
    padding-top: 24px;
    max-width: 1166px;
    margin: 0 auto;
    padding: 24px;

    @media only screen and (min-width: 768px) and (max-width: 1024.98px) {
      padding: 40px 58px;
    }

    @media only screen and (max-width: 767.98px) {
      padding: 40px 12px 0;
    }
  }

  .block-inner {
    display: grid;
    gap: 48px;
    justify-content: center;
  }

  .block-title {
    color: #363565;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 120%;
  }

  .block-content {
    color: #363565;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 140%;
    span {
        font-weight: 700;
    }
  }

  .title-container {
    display: grid;
    gap: 12px;
    width: 562px;
  }

  .review-block {
    max-width: 1166px;
    background: #ffffff;
    border-radius: 6px;
    padding: 48px 24px;
    text-align: center;
  }


`;
