import moment, { Moment } from 'moment';
import TimeScheduleItemPicker from './TimeScheduleItemPicker';
import { HOUR_MINUTE_FORMAT } from 'features/bookings/services/constants';
import { getTimeList } from 'utils/unit';
import { uniq } from 'lodash';
import bookingSelectors from 'features/bookings/services/selectors';
import settingSelectors from 'features/settings/services/selectors';
import { Form } from 'antd';

interface ITimeSchedulePicker {
  timeSchedule: {time_start: string, time_end: string}[]
}

export interface IPeriod {
  label: string;
  duration: number;
  timeStart: string;
  timeEnd: string;
  // timeSchedule?: Moment[]
}

const periodsTime: IPeriod[] = [
  {
    label: 'Morning',
    duration: 15,
    timeStart: '00:00',
    timeEnd: '12:00'
  },
  {
    label: 'Afternoon',
    duration: 15,
    timeStart: '12:00',
    timeEnd: '17:00'
  },
  {
    label: 'Evening',
    duration: 15,
    timeStart: '17:00',
    timeEnd: '23:59'
  },
];

const TimeSchedulePicker = ({timeSchedule, ...props} : ITimeSchedulePicker) => {


  const bookingTime = bookingSelectors.bookingOnline.getBookingTime();
  const setting = settingSelectors.getSettingBookingForm();
  const timeSelected = bookingSelectors.bookingOnline.getBookingTime();


  const getTimesPeriod = (period: IPeriod) => {

    const timeStart = moment(period.timeStart, HOUR_MINUTE_FORMAT);
    const timeEnd = moment(period.timeEnd, HOUR_MINUTE_FORMAT);
    const now = moment();

    const timesAvailable:{timeStart: Moment, timeEnd: Moment}[] = [];
    const timeScheduleAvailable: string[] = [];
    

    timeSchedule.forEach(schedule => {

      let timeStartBoundary = timeStart;
      let timeEndBoundary = timeEnd;

      if(now.isSame(moment(bookingTime?.start), 'date')) {
        if(!now.add(setting?.cancel_reschedule ?? 0, 'minutes').isBetween(timeStartBoundary, timeEndBoundary)) return;

        timeStartBoundary = now.add(setting?.cancel_reschedule ?? 0, 'minutes');
      }
      
      const timeStartSchedule = moment(schedule.time_start, HOUR_MINUTE_FORMAT);
      const timeEndSchedule = moment(schedule.time_end, HOUR_MINUTE_FORMAT);

      if(timeStartBoundary.isBefore(timeStartSchedule))
        timeStartBoundary = timeStartSchedule;

      if(timeEndBoundary.isAfter(timeEndSchedule))
        timeEndBoundary = timeEndSchedule;

      timesAvailable.push({
        timeStart: timeStartBoundary,
        timeEnd: timeEndBoundary
      });

    });

    if(period.label === 'Evening') {
      timesAvailable?.[timesAvailable.length - 1]?.timeEnd.add(1, 'minute');
    }

    timesAvailable.forEach(time => timeScheduleAvailable.push(...getTimeList(time.timeStart, time.timeEnd, period.duration)));
    
    return uniq(timeScheduleAvailable);
  };

  const getTimeSchedule = periodsTime.map(period => ({
    ...period,
    timeSchedule: getTimesPeriod(period)
  }));

  // const {morningSchedule, afternoonSchedule, eveningSchedule} = getTimeSchedule();
  
  return (
    <div {...props}>
      <Form.Item name='timeSchedule' rules={[
          {
            validator: () => {

              if (!getTimeSchedule.some(schedule => 
                  schedule?.timeSchedule?.some(time => moment(timeSelected?.start).format(HOUR_MINUTE_FORMAT) === time))) {
                return Promise.reject(
                  'Time schedule must be require'
                );
              }
              return Promise.resolve();
          },}]}>

        {getTimeSchedule.map((period, index) => (
          <TimeScheduleItemPicker key={index} timeSchedule={period.timeSchedule} label={period.label}/>
        ))}
      </Form.Item>
    </div>
  );
};

export default TimeSchedulePicker;