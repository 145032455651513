import { Form, Input, InputNumber, Radio, RadioChangeEvent } from 'antd';
import { EARN_POINT_PROGRAM_TYPE } from 'features/loyaltyProgram/services/constants';
import loyalSelectors from 'features/loyaltyProgram/services/selectors';
import { IApiProgramReq, IProgramFormValues } from 'features/loyaltyProgram/services/types/program';
import { first } from 'lodash';
import { MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getFormatInputCurrency } from 'utils/unit';

export type ProgramFormProps = {
  formData?: IProgramFormValues;
  isEdit?: boolean;
  onSubmit: (values: IApiProgramReq) => void;
  onCancel: () => void;
};
const ProgramForm = ({ isEdit, formData, onSubmit, onCancel }: ProgramFormProps) => {
  const [form] = Form.useForm();
  const { t: formLang } = useTranslation('form');
  const [valueEarnReward, setValueEarnReward] = useState<string>(EARN_POINT_PROGRAM_TYPE.VISIT);
  const onChange = (e: RadioChangeEvent) => {
    setValueEarnReward(e.target.value);
  };

  const locationsStore = loyalSelectors.getListLocations();

  const locations = useMemo(() => locationsStore?.map((o) => ({
    value: o.id, title: o.name, label: o.name,
  })) ?? [], [locationsStore]);
  const allLocations = useMemo(() => locations.map(o => o.value), [locations]);
  const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
  useEffect(() => {
    if (isEdit) return;
    setSelectedLocations(allLocations);
  }, [locationsStore]);

  useEffect(() => {
    form.setFieldValue('location_ids', selectedLocations);
  }, [selectedLocations]);

  useEffect(() => {
    if (!isEdit) return;
    form.setFieldsValue(formData);
    if (formData?.location_ids?.length === 0) {
      form.setFieldValue('location_ids', allLocations);
    }
    if (formData?.earnReward === EARN_POINT_PROGRAM_TYPE.VISIT) {
      setValueEarnReward(EARN_POINT_PROGRAM_TYPE.VISIT);
    } else {
      setValueEarnReward(EARN_POINT_PROGRAM_TYPE.AMOUNT_SPENT);
    }
  }, [formData]);


  const checkValid = () => {
    try {
      form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };

  const getPayload = (values: IProgramFormValues) => {
    const result: IApiProgramReq = {
      name: values.name,
      amount_spent: values.amountSpent ?? 0,
      earn_point: values.earnReward ?? '',
      point: values.points,
      location_id: values.location_ids?.length !== allLocations.length ?
        (first(values.location_ids) ?? null)
        : null,
      minimum_spend: values.minimumSpend ?? 0,
    };
    return result;
  };

  const _onSubmit = (values: IProgramFormValues) => {
    const payload = getPayload(values);
    onSubmit(payload);
  };

  const _submit: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    const isValid = checkValid();
    if (!isValid) return;
    form.submit();
  };

  const myFocus = useRef<any>();

  useEffect(() => {
    myFocus?.current?.input?.focus();
  }, []);

  return (
    <FormStyled>
      <Form
        form={form}
        name='basic'
        className='modal-form'
        onFinish={_onSubmit}
        autoComplete='off'
        layout='vertical'
        initialValues={{
          earnReward: valueEarnReward,
          minimumSpend: 1,
        }}
      >
        <>
          <div className='form-row'>
            <Form.Item label='Name' name='name' rules={[{ required: true }]}>
              <Input ref={myFocus} placeholder='Enter name' />
            </Form.Item>
          </div>
          <div className='form-row'>
            <Form.Item label='Earn Reward By' name='earnReward'>
              <Radio.Group onChange={onChange}>
                <Radio value={EARN_POINT_PROGRAM_TYPE.VISIT}>Visits</Radio>
                <Radio value={EARN_POINT_PROGRAM_TYPE.AMOUNT_SPENT}>Amount Spent</Radio>
              </Radio.Group>
            </Form.Item>
          </div>

          <div className='form-row'>
            {valueEarnReward === EARN_POINT_PROGRAM_TYPE.VISIT ? (
              <>
                <Form.Item label='Minimum Spend' name='minimumSpend'>
                  <InputNumber min={0} addonBefore={getFormatInputCurrency().addonBefore} placeholder={formLang('AmountPlaceholder') || ''} onChange={(val) => form.setFieldValue('amountSpent', val)} />
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item label='Amount Spent' name='amountSpent' rules={[{ required: true }]}>
                  <InputNumber
                    min={0}
                    addonBefore={getFormatInputCurrency().addonBefore}
                    placeholder={formLang('AmountPlaceholder') || ''}
                    onChange={(val) => form.setFieldValue('minimumSpend', val)}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item label='Points' name='points' rules={[{ required: true }]}>
              <InputNumber
                min={1}
                addonAfter='Points'
                placeholder='Point'
                className='is-last'
              />
            </Form.Item>
          </div>
          {/* <div className='form-row'>
            <Form.Item label='Locations' name='location_ids' rules={[{ required: true }]}>
              {locations?.length > 0 ? (
                <MultipleSelect
                  onChange={e => {
                    setSelectedLocations(e);
                  }}
                  value={selectedLocations ?? []}
                  options={locations}
                  placeholder='Select Locations'
                  maxTagPlaceholder='locations'
                />
              ) : (
                <Select
                  options={[
                    {
                      label: 'All locations',
                      value: SYSTEM_VARIABLES.ALL_LOCATIONS,
                    },
                  ]}
                />
              )}
            </Form.Item>
          </div> */}
          <div className='form-submit-wrapper'>
            <div data-testing='Cancel' onClick={onCancel} className='common-btn is-white'>
              Cancel
            </div>
            <button data-testing='Save' type='button' onClick={_submit} className='common-btn'>
              Save
            </button>
          </div>
        </>
      </Form>
    </FormStyled>
  );
};

export default ProgramForm;

const FormStyled = styled.div`
`;