import { Form, Input, Radio } from 'antd';
import DatePicker from 'components/common/DatePickerInput';
import FormRow from 'components/common/Form/FormRow';
import { get } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberValidations from 'utils/validations/number';
type Props = {
  onCancel: () => void;
  onNextStep: () => void;
  errors: any;
};
const { TextArea } = Input;
const CustomersFormFirstStep = ({
  errors,
  onCancel = () => undefined,
  onNextStep = () => undefined,
}: Props) => {
  const [errorsText, setErrorsText] = useState<any>();
  const form = Form.useFormInstance();
  const { t: formLang } = useTranslation('form');
  const { t: commonLang } = useTranslation('common');
  const checkValid = async () => {
    try {
      await form.validateFields([
        'name',
        'email',
        'phoneNumberCode',
        'phoneNumber',
        'birthDate',
      ]);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleNextStep: React.MouseEventHandler<HTMLButtonElement> = async (
    e
  ) => {
    e.preventDefault();
    const isFormValid = await checkValid();
    if (!isFormValid) return;
    onNextStep();
  };
  useEffect(() => {
    const _errorsText = ({
      name: get(errors, 'name', ''),
      phone_number: get(errors, 'phone_number', ''),
      email: get(errors, 'email', ''),
      date_of_birth: get(errors, 'date_of_birth', ''),
      important_client_info: get(errors, 'important_client_info', ''),
    });
    setErrorsText(_errorsText);
  }, [errors]);

  const onChangeKey = (key: string) => () => {
    if (!errorsText) return;
    if (errorsText[key] === undefined) return;
    const newErrorsText = { ...errorsText };
    delete newErrorsText[key];
    setErrorsText(newErrorsText);
  };

  const myFocus = useRef<any>();

  useEffect(() => {
    myFocus?.current?.input?.focus();
  }, []);

  return (
    <>
      <div className='form-row'>
        <Form.Item
          label={formLang('NameLabel')}
          name='name'
          validateStatus={errorsText?.name ? 'error' : undefined}
          help={errorsText?.name ? errorsText?.name : undefined}
          rules={[{ required: true }]}
        >
          <Input ref={myFocus} placeholder={formLang('EnterCustomerNamePlaceholder') || ''} onChange={onChangeKey('name')} />
        </Form.Item>
      </div>
      <Form.Item
        name='phoneNumber'
        label={formLang('PhoneNumberLabel')}
        validateStatus={errorsText?.phone_number ? 'error' : undefined}
        help={errorsText?.phone_number ? errorsText?.phone_number : undefined}
        rules={[{ required: true, message: 'Phone number is required!' }, NumberValidations.PHONE]}
      >
        <Input type='tel' placeholder={formLang('EnterYourNumberPlaceholder') || ''} onChange={onChangeKey('phone_number')} />
      </Form.Item>

      <div className='form-row'>
        <Form.Item
          label={
            <p>
              Email <span>(Optional)</span>
            </p>
          }
          name='email'
          validateStatus={errorsText?.email ? 'error' : undefined}
          help={errorsText?.email ? errorsText?.email : undefined}
          rules={[{ type: 'email' }]}
        >
          <Input type='email' placeholder={formLang('EnterCustomerEmailPlaceholder') || ''} onChange={onChangeKey('email')} />
        </Form.Item>
      </div>
      <FormRow label='Gender' name='gender' optional>
        <Radio.Group>
          <Radio value={0}>Female</Radio>
          <Radio value={1}>Male</Radio>
          <Radio value={2}>Non-binary</Radio>
        </Radio.Group>
      </FormRow>
      <div className='form-row'>
        <Form.Item
          label={
            <p>
              {formLang('BirthDateLabel')}
              <span>(Optional)</span>
            </p>
          }
          name='birthDate'
          validateStatus={errorsText?.date_of_birth ? 'error' : undefined}
          help={errorsText?.date_of_birth ? errorsText?.date_of_birth : undefined}
        >
          <DatePicker
            onChange={onChangeKey('date_of_birth')}
          />
        </Form.Item>
      </div>
      <div className='form-row'>
        <Form.Item
          label={
            <p>
              {formLang('ImportantClientInfoLabel')}
              <span>(Optional)</span>
            </p>
          }
          name='importantClientInfo'
          validateStatus={errorsText?.important_client_info ? 'error' : undefined}
          help={errorsText?.important_client_info ? errorsText?.important_client_info : undefined}
          extra={'This note will be displayed on all bookings. '}
        >
          <TextArea
            rows={5}
            placeholder={'Eg. customer allergic to fragrance'}
          />
        </Form.Item>
      </div>
      <div className='form-submit-wrapper'>
        <button data-testing='Cancel_customer_form' className='common-btn is-white' onClick={onCancel}>
          {commonLang('Cancel')}
        </button>
        <button data-testing='Apply_customer_form' className='common-btn' onClick={handleNextStep}>
          {commonLang('Done')}
        </button>
      </div>
    </>
  );
};

export default CustomersFormFirstStep;
