import { DatePicker, Form, FormInstance, Input, InputNumber, Modal, Radio, RadioChangeEvent, Spin, Switch } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { IServiceChageItem, IServiceChargeReason } from '.';
import FormRow from 'components/common/Form/FormRow';
import styled from 'styled-components';
import MultipleSelect, { IOptionMulti } from 'components/common/MultipleSelect';
// import serviceSelectors from 'features/services/services/selectors';
import { useDispatch } from 'react-redux';
import serviceActions from 'features/services/services/actions';
import dayjs, { Dayjs } from 'dayjs';
import { disabledDateSameOrBefore } from 'utils/unit';
import settingActions from '../../services/actions';
import settingSelectors from '../../services/selectors';


interface IFormModal {
    isModalOpen?: boolean;
    handleCancel?: () => void;
    form?: FormInstance;
    handleSubmit?: (values: IServiceChageItem) => Promise<boolean>;
    isDisableSubmit?: boolean;
    formData?: IServiceChargeReason;
    isEdit?: boolean;
}

const FormModal = ({
    isModalOpen,
    handleCancel,
    handleSubmit,
    formData,
    isDisableSubmit,
    isEdit,
}: IFormModal) => {
    const [form] = Form.useForm();
    const dataDayLocation = settingSelectors.getShowServiceCharge();
    const dispatch = useDispatch();


    const myFocus = useRef<any>();

    useEffect(() => {
        checkDay();
        myFocus?.current?.input?.focus();
    }, [formData]);

    const checkDay = () => {
        if (dayjs(formData?.end_date).format('YYYY-MM-DD') !== 'Invalid Date') {
            form?.setFieldsValue(formData);
        } else {
            form?.setFieldsValue({ ...formData, end_date: dayjs(), start_date: dayjs() });
        }
    };

    useEffect(() => {
        dispatch(serviceActions.getShowCreateService.fetch());
        dispatch(settingActions.getShowServiceCharge.fetch());
    }, []);

    const locations: IOptionMulti[] = useMemo(
        () =>
            (dataDayLocation?.locations as { id: string | number; name: string }[])
                ?.filter((o) => o.id != 99999)
                ?.map((o) => ({ value: o.id, title: o.name })) ?? [],
        [dataDayLocation?.location, dataDayLocation]
    );

    const listDays: IOptionMulti[] = useMemo(
        () =>
            (dataDayLocation?.days as { id: string | number; name: string }[])
                ?.filter((o) => o.id != 99999)
                ?.map((o) => ({ value: o.id, title: o.name })) ?? [],
        [dataDayLocation?.days, dataDayLocation]
    );

    const [selectedLocations, setSelectedLocations] = useState<any[]>(locations.map(({ value }) => value));
    const [selectedDays, setSelectedDays] = useState<any[]>(listDays.map(({ value }) => value));



    useEffect(() => {
        if (!isEdit) {
            form.setFieldValue('location', locations.map(o => o.value));
            // form.setFieldValue('day_of_week', listDays.map(o => o.value));
        } else {
            form.setFieldValue('location', formData?.location?.map((o: { id: any; }) => o.id));
            form.setFieldValue('day_of_week', formData?.day_of_week?.map((o: { id: any; }) => o.id));
        }

    }, [isModalOpen, locations]);

    const [, setPrice] = useState<number | null>();
    const [, setPercent] = useState<number | null>();
    const [RepeatWeekly, setRepeatWeekly] = useState(formData?.day_of_week?.length === 0 ? 'visit' : '1');

    const onChange = (e: RadioChangeEvent) => {
        setRepeatWeekly(e.target.value);
    };


    const [startValue, setStartValue] = useState<Dayjs | null>(null);

    const handleStartDateChange = (date: any) => {
        setStartValue(date);
        form.setFieldsValue({ end_date: null });
    };

    return (
        <Modal
            maskClosable={false}
            open={isModalOpen}
            onCancel={handleCancel}
            okButtonProps={{ style: { display: 'none' } }}
            width={574}
            footer={null}
            forceRender
            centered
        >
            <h2 className='modal-title'>{!isEdit ? 'Add' : 'Edit'} surcharge</h2>

            <Spin spinning={locations.length === 0 || listDays.length === 0}>
                <Form
                    className='modal-form'
                    onFinish={handleSubmit}
                    autoComplete='off'
                    form={form}
                    layout='vertical'
                    initialValues={{
                        location_ids: null,
                        surcharge_day_ids: null,
                        start_date: dayjs(),
                        end_date: dayjs(),
                        name: 'Public holiday'
                    }}
                >
                    <div className='form-row'>
                        <Form.Item
                            label='Name'
                            name='name'
                            rules={[{ required: true }]}
                        >
                            <Input ref={myFocus} placeholder='Enter name change' />
                        </Form.Item>
                    </div>
                    <FormRow label='Choose date'>
                        <Radio.Group onChange={onChange} name='day_of_week'
                            defaultValue={formData?.day_of_week?.length === 0 ? 'visit' : '1'}>
                            <Radio checked value={'1'} >Repeat weekly</Radio>
                            <Radio value={'visit'}>Select specific date</Radio>
                        </Radio.Group>
                    </FormRow>
                    <>

                        {RepeatWeekly === 'visit' ?
                            <div style={{ display: 'flex', gap: '8px' }}>

                                <Form.Item
                                    name='start_date'
                                    label='Start Date'
                                    rules={[
                                        { required: true, message: 'Please select date start' }
                                    ]}
                                >
                                    <DatePicker
                                        placeholder="MMM DD, YYYY"
                                        clearIcon={false}
                                        disabledDate={(current) => disabledDateSameOrBefore(current, dayjs())}
                                        onChange={handleStartDateChange}
                                    />

                                </Form.Item>

                                <Form.Item
                                    name='end_date'
                                    label='End Date'
                                    rules={[
                                        { required: true, message: 'Please select date end' }
                                    ]}
                                >
                                    <DatePicker placeholder='MMM DD,YYYY' clearIcon={false}
                                        disabledDate={(current) =>
                                            startValue ? disabledDateSameOrBefore(current, startValue) : false
                                        } />
                                </Form.Item>
                            </div>
                            :
                            <div className='form-row'>
                                <Form.Item
                                    label='Day'
                                    name='day_of_week'
                                    rules={[{ required: true }]}
                                >
                                    <MultipleSelect
                                        onChange={(arr) => {
                                            setSelectedDays(arr);
                                            form?.setFieldValue('day_of_week', arr);
                                        }}
                                        value={selectedDays}
                                        options={listDays}
                                        placeholder='Sellect Day'
                                        maxTagPlaceholder='days'
                                    />
                                </Form.Item>
                            </div>
                        }
                    </>
                    <>
                        <Form.Item
                            label='Locations'
                            name='location'
                            rules={[{ required: true }]}
                        >
                            <MultipleSelect
                                onChange={(arr) => {
                                    setSelectedLocations(arr);
                                    form?.setFieldValue('location', arr);
                                }}
                                value={selectedLocations}
                                options={locations}
                                placeholder='Locations'
                                maxTagPlaceholder='locations'
                            />
                        </Form.Item>
                    </>
                    <div className='form-row groupPrice items-end'>
                        <Form.Item label='Value'
                            name='value'
                            rules={[{ required: true }]}>
                            <InputNumber
                                addonBefore='%'
                                placeholder={'Amount'}
                                min={0.01}
                                max={100}
                                name='type'
                                onChange={(val: any) => {
                                    setPercent(val);
                                    setPrice(null);
                                    form.setFieldValue('amountValue', null);
                                }}
                            />
                        </Form.Item>
                    </div>
                    <FormRow label='Set up' name='is_each_service'>
                        <Radio.Group defaultValue={0}>
                            <Radio value={0}>Total cost of booking value</Radio>
                            <Radio value={1}>Count on each service</Radio>
                        </Radio.Group>
                    </FormRow>
                    <ServiceChargesStyled>
                        <p className='note-service-charges'>The surcharge is applicable only to services sold at checkout, not including vouchers.</p>
                    </ServiceChargesStyled>
                    <SwitchChargesStyled>
                        <p>Surcharge enablement</p>
                        <Form.Item
                            name="status"
                            noStyle
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </SwitchChargesStyled>
                    <div className='form-submit-wrapper'>
                        <div data-testing='Cancel' onClick={handleCancel} className='common-btn is-white'>
                            Cancel
                        </div>
                        <button data-testing='Save' disabled={isDisableSubmit} type='submit' className='common-btn'>
                            Save
                        </button>
                    </div>
                </Form>
            </Spin>
        </Modal>
    );
};

export default FormModal;

const SwitchChargesStyled = styled.div`
    display: flex;
    gap: 6px;
    margin-bottom: 15px;
    width: 100%;
    p {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: var(--color-primary);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const ServiceChargesStyled = styled.div`
.note-service-charges {
    color: #949BDA;
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    line-height: 140%;
}
`;