import { AxiosResponse } from 'axios';
import bookingActions from 'features/bookings/services/actions';
import apisBooking from 'features/bookings/services/apis';
import bookingSelectors from 'features/bookings/services/selectors';
import { ITyroPaymentSuccessBody } from 'features/bookings/services/types/booking';
import {
  useState,
  useEffect,
  createRef,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import styled from 'styled-components';
import { BOOKING_FORM, BookingFormProps } from '../types';
import { message } from 'antd';
import { useParams } from 'react-router-dom';

export const defaultSettings = {
  paySecret: '',
  locationId: '',
  originName: '',
  method: '',
  providerName: '',
  status: '',
  supportedNetworks: null,
  currency: '',
  theme: 'default',
  styleProps: {
    bodyBackgroundColor: '#fff',
  },
  options: {
    applePay: {
      enabled: false,
    },
    googlePay: {
      enabled: false,
      merchantInfo: {
        merchantName: 'Hida Pos Pty Ltd',
        merchantId: 'BCR2DN4TWXH3TLJQ',
      },
    },
    creditCardForm: {
      enabled: true,
    }
  },
};

enum TyroJSErrorType {
  INVALID = 'invalid',
}

enum TyroPaymentType {
  CREDIT = 'CREDIT',
  GOOGLE_PAY = 'GOOGLE_PAY',
  APPLE_PAY = 'APPLE_PAY',
}

interface TyroPaymentFormProps extends BookingFormProps {
  enablePaymentBtn: () => void;
  setBookingCode?: (code: string) => void;
  setIsLoadingBtn?: any;
  wallet?: boolean;
  live?: boolean;
}

const tyroJSRef = createRef<any>();

const TyroPaymentForm = forwardRef((props: TyroPaymentFormProps, ref) => {
  const [configuration, setConfiguration] = useState(defaultSettings);
  const [error, setError] = useState<any>({});
  const [libraryReady, setLibraryReady] = useState(false);
  const [payRequestReady, setPayRequestReady] = useState(false);
  const [payFormReady, setPayFormReady] = useState(false);
  const isInitPayRequest = useRef<any>(false);
  const { merchant_code = '' } = useParams();

  const formValue = bookingSelectors.bookingOnline.getFormValues();
  const setIsLoading = useSetLoadingPage();
  const dispatch = useDispatch();
  let tyroPaymentType: TyroPaymentType = TyroPaymentType.CREDIT;

  const {
    paySecret,
    locationId,
    originName,
    method,
    providerName,
    status,
    supportedNetworks,
    currency,
    theme,
    styleProps,
    options,
  } = configuration;

  // STEP 1, Attach TyroJS Library
  // Installs the TyroJS Library onto the DOM
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'tyro-js-library';
    script.src = 'https://pay.connect.tyro.com/v1/tyro.js';
    script.crossOrigin = 'anonymous';
    script.async = true;
    script.onload = () => setLibraryReady(true);
    document.body.appendChild(script);
  }, []);

  // STEP 2, Fetch the Pay Secret
  useEffect(() => {
    setError({});
    if (!paySecret?.length) {
      fetchPaySecret();
    }
  }, [paySecret]);

  // Fetch the Pay Secret
  // This would be replaced with your own implementation to obtain your pay request
  const fetchPaySecret = async (): Promise<void> => {
    setIsLoading(true);
    try {
      if (formValue?.id) {
        const res: AxiosResponse<any> = await apisBooking.getTyroPaymentSecret(
          merchant_code,
          formValue.id,
          formValue?.total_prepaid
        );
        if (res?.data?.id) {
          const paySecret = res?.data?.paySecret;
          const locationId = res?.data?.locationId;
          const originName = res?.data?.origin?.name;
          const method = res?.data?.provider?.method;
          const providerName = res?.data?.provider?.name;
          const status = res?.data?.status;
          const supportedNetworks = res?.data?.supportedNetworks;
          const currency = res?.data?.total?.currency;
          setConfiguration({
            ...configuration,
            paySecret,
            locationId,
            originName,
            method,
            providerName,
            status,
            supportedNetworks,
            currency,
          });

          dispatch(
            bookingActions.setBookingOnlineFormValueItem({
              key: 'transaction_id',
              value: res?.data?.id,
            })
          );
        } else {
          message.error('Failed to get secret key');
          setIsLoading(false);
        }
      }
    } catch (error: any) {
      setError({
        type: 'critical',
        errorCode: error.errorCode,
        message: 'Failed to generate Demo Pay Request',
        debug: { error },
      });
      setIsLoading(false);
    }
  };

  // STEP 3, Load the Pay Request into TyroJS
  useEffect(() => {
    if (libraryReady && paySecret?.length && !isInitPayRequest.current) {
      initPayRequest();
    }
  }, [libraryReady, paySecret]);

  // Initialize Tyro.js with the Pay Request
  async function initPayRequest(): Promise<void> {
    setPayRequestReady(false);
    setPayFormReady(false);
    isInitPayRequest.current = true;

    try {
      // @ts-ignore
      tyroJSRef.current.tyroJSInstance = Tyro({
        liveMode: props.live === true,
      });

      await tyroJSRef.current.tyroJSInstance.init(paySecret);

      const payFormElement = document.getElementById('tyro-pay-form');

      if (payFormElement !== null) {
        payFormElement.innerHTML = '';
      }

      setPayRequestReady(true);
    } catch (error: any) {
      setError({
        type: 'critical',
        errorCode: error.errorCode,
        message: `${error.toString()}`,
        debug: { error },
      });
      setIsLoading(false);
    }
  }

  // STEP 4, Embed the Pay Form into your Document
  useEffect(() => {
    if (libraryReady && payRequestReady && !payFormReady) {
      initPayForm();
    }
  }, [payRequestReady]);

  // Initialize the Pay Form
  async function initPayForm(): Promise<void> {
    setPayFormReady(false);
    try {
      const payFormElement = document.getElementById('tyro-pay-form');
      if (payFormElement === null) {
        throw new Error('Pay Form is not mounted');
      }
      payFormElement.innerHTML = '';
      console.log({
        'here':'now',
        theme,
        styleProps,
        options,
        location: window.location,
      });

      if (props.wallet === true) {
        options.creditCardForm.enabled = true;
        options.applePay.enabled = true;
        options.googlePay.enabled = true;
      } 
      
      if(props.wallet === false) {
        options.creditCardForm.enabled = true;
        options.applePay.enabled = false;
        options.googlePay.enabled = false;
      }

      const payForm = tyroJSRef.current.tyroJSInstance.createPayForm({
        theme,
        styleProps,
        options,
      });

      props?.enablePaymentBtn?.();

      // Attach the Wallet Listeners to the form
      payForm.setWalletPaymentBeginListener((paymentType: any) => {
        if (paymentType === 'APPLE_PAY') {
          props.setIsLoadingBtn(true);
        } else if (paymentType === 'GOOGLE_PAY') {
          props.setIsLoadingBtn(true);
        }
      });
      payForm.setWalletPaymentCancelledListener((paymentType: any) => {
        if (paymentType === 'APPLE_PAY') {
          props.setIsLoadingBtn(false);
        } else if (paymentType === 'GOOGLE_PAY') {
          props.setIsLoadingBtn(false);
        }
      });
      payForm.setWalletPaymentCompleteListener(
        (paymentType: any, error: any) => {
          if (paymentType === TyroPaymentType.GOOGLE_PAY) {
            tyroPaymentType = TyroPaymentType.GOOGLE_PAY;
          }

          if (paymentType === TyroPaymentType.APPLE_PAY) {
            tyroPaymentType = TyroPaymentType.APPLE_PAY;
          }

          if (error) {
            setError({
              type: 'critical',
              errorCode: error.errorCode,
              message: `${error.toString()}`,
              debug: { error },
            });
          } else {
            getPaymentResult();
          }
          props.setIsLoadingBtn(false);
        }
      );
      payForm.inject('#tyro-pay-form');
      setPayFormReady(true);
      setIsLoading(false);
    } catch (error: any) {
      setError({
        type: 'critical',
        errorCode: error.errorCode,
        message: `${error.toString()}`,
        debug: { error },
      });
      setIsLoading(false);
    }
  }


  // STEP 5, Handle submitting the payment
  async function submitPayForm(): Promise<void> {
    setError({});
    let result;
    // const timeOutSubmit = setTimeout(() => {
    //   setIsLoading(true);
    // }, 500);
    props.setIsLoadingBtn(true);
    try {
      result = await tyroJSRef.current.tyroJSInstance.submitPay();
      await getPaymentResult();
    } catch (error: any) {
      if (error?.type === 'CLIENT_VALIDATION_ERROR' && !error?.errorCode) {
        // can ignore these errors as handled by validation
      } else {
        // display other errors
        setError({
          type: 'critical',
          errorCode: error.errorCode,
          message: `${error.toString()}`,
          debug: { error, result },
        });
      }
      props.setIsLoadingBtn(false);
      // clearTimeout(timeOutSubmit);
      // setIsLoading(false);
    }
    props.setIsLoadingBtn(false);
  }

  // Used to fetch the Pay Request Response
  async function getPaymentResult(): Promise<void> {
    const payRequest = await tyroJSRef.current.tyroJSInstance.fetchPayRequest();
    // display result
    if (payRequest.status === 'SUCCESS') {
      const updateTyroSuccessBody: ITyroPaymentSuccessBody = {
        book_assignment_id: formValue?.id,
        total_sub: formValue?.total_sub,
        total_pay: formValue?.total_pay,
        per_payment_value: formValue?.pre_payment_value,
        sum_discount: formValue?.sum_promotion,
        payment: { 
          payment_method: 'Tyro',
          amount: formValue?.total_prepaid,
          transaction_id: formValue?.transaction_id,
          result: {
            id: formValue?.transaction_id,
            paySecret: paySecret,
            status: status,
            supportedNetworks: supportedNetworks,
            total: {
                amount: formValue?.total_prepaid,
                currency: currency,
            },
            origin: {
                name: originName,
                orderId: formValue?.id?.toString()
            },
            provider: {
                name: providerName,
                method: method,

            },
            locationId: locationId,
            transactionResults: []
        }
        },
        payment_type: tyroPaymentType,
      };

      const res: AxiosResponse<any> = await apisBooking.tyroPaymentSucess(
        merchant_code,
        updateTyroSuccessBody
      );

      if (res?.data?.data?.id) {
        props.setBookingCode?.((res?.data?.data?.book_assignment_code) ?? '');
        props.setFormActive(BOOKING_FORM.SUCCESS);
      }
    } else {
      setError({
        type: TyroJSErrorType.INVALID,
        errorCode: payRequest.errorCode,
        message: `${payRequest.status}`,
        debug: { payRequest },
      });
    }
    setIsLoading(false);
  }

  useImperativeHandle(ref, () => ({
    submitPrepardForm: () => submitPayForm(),
  }));

  return (
    <TyroPaymentFormStyled>
      <p className='payment-form-header'>Payment Method</p>
      <p className='payment-form-desc'>
        Your card details are stored securely by a 3rd Party Provider and may be
        used to take payment for services.
      </p>

      <div ref={tyroJSRef}>
        {error.type ? (
          <div className={'error-container'}>
            <p style={{ color: 'red' }}>
              ({error.errorCode ?? 'UNKNOWN_ERROR'}) {error.message}
            </p>
          </div>
        ) : null}

        <form id='pay-form'>
          <div
            id='tyro-pay-form'
            style={{ visibility: payRequestReady ? 'visible' : 'hidden' }}
          ></div>
        </form>
      </div>
    </TyroPaymentFormStyled>
  );
});

TyroPaymentForm.displayName = 'TyroPaymentForm';

export default TyroPaymentForm;

const TyroPaymentFormStyled = styled.div`
  margin-top: 46px;

  .payment-form-header {
    font-weight: 600;
    font-size: 24px;
    line-height: 33.6px;
    color: #363565;
  }

  .payment-form-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 19.6px;
    color: #7980bc;
    margin-bottom: 24px;
  }

  // #tyro-pay-wallet-buttons-wrapper,
  // #wallet-payments-divider {
  //   display: none;
  // }
`;
