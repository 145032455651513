import { ClockCircleOutlined } from '@ant-design/icons';
import { DndContext, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { Spin } from 'antd';
import message from 'components/common/Message';
import { CalendarViewType, EBookingStatus } from 'constants/index';
import SYSTEM_VARIABLES from 'constants/variables';
import useCalendarFilter from 'features/bookings/components/BookingFilter/useCalendarFilter';
import bookingSelectors from 'features/bookings/services/selectors';
import settingSelectors from 'features/settings/services/selectors';
import moment from 'moment';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { DraggableOverlay } from './DnD/DraggableOverlay';
import HeaderCalendar from './HeaderCalendar';
import RowCalendar, { IDndDataCalendar } from './RowCalendar';
import { ICalendarBookingHeader as _ICalendarBookingHeader } from './types';
import useMappingCalendar from './useMappingCalendar';
import useUpdateDateView from './useUpdateDateView';
import useUpdateMemberView, { IBookingItemResDataNew } from './useUpdateMemberView';
import { useNavigate } from 'react-router-dom';
import ModalConfirmOTP, { ModalConfirmOTPRef } from 'components/common/Modal/ModalOTP';
import bookingActions from 'features/bookings/services/actions';
import { useAppDispatch } from 'store/hooks';
import { get, set } from 'lodash';
import apisBooking from 'features/bookings/services/apis';
import storage from 'utils/sessionStorage';

export type ICalendarBookingHeader<Modal = any> =
  _ICalendarBookingHeader<Modal>;
type IBookAssignmentServices = { employeeId: number | null; serviceId: number | null; serviceVariantId: number | null, day?: any | null};
type IDroppedBookings = { bookingId: number | null; employeeId: number | null; serviceId: number | null; serviceVariantId: number | null; time: string | null, day?: any | null};
type IFilterBookings = {
  booking_id: number;
  book_assignment_services: {
    idEmployee: number;
    service_id: number;
    timeStart: string;
    duration: string;
    timeEnd: string;
  }[];
}[];
type IServicesByEmployeeId = {
  id: number;
  name: string;
  sale_price: number;
  service_variants: [];
}

const CalendarBookingTable = ({calendarBookingRef}: {calendarBookingRef: any}) => {
  const schema = useCalendarFilter();
  const data = bookingSelectors.getBookings();
  const loading = bookingSelectors.loadingBookings();
  const [rows, rowsSpanInfo] = useMappingCalendar(schema, data);
  const isSingleCol = schema.isSingleCol ?? false;
  const [updatingDateView, updateDateView] = useUpdateDateView();
  const [updatingMemberView, updateMemberView] = useUpdateMemberView();
  const loadingBooking = updatingDateView || updatingMemberView;
  const viewType = bookingSelectors.getCalendarViewType();
  const dateStore = bookingSelectors.getCalendarParamValue('date') as number;
  const pixelPerMinute = bookingSelectors.getPixelPerMinute();
  const pinRequired = settingSelectors.getIsRequirePin();
  const listBooking = bookingSelectors.getBookings();

  // const calendarBookingRef = useRef<HTMLDivElement>(null);
  const isScrolled = useRef<boolean>(false);
  const activeLocation = bookingSelectors.getCalendarCurrentLocation();
  const workingHour = bookingSelectors.getCalendarWorkingHour();
  const navigate = useNavigate();

  const merchant_location_id = bookingSelectors.getCalendarParamValue('merchant_location_id');
  const modalConfirmOTPRef = useRef<ModalConfirmOTPRef>(null);
  const realTimeRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loadingFilterOption = bookingSelectors.getLoadingOptionFilter();
  const loadingParamOptionCalendar = bookingSelectors.getParamOptionCalendar();

  const isNow = useMemo(() => {
    const _date = moment(dateStore);
    const today = moment();
    return _date.isSame(today, 'day');
  }, [dateStore]);
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    // Press delay of 250ms, with tolerance of 5px of movement
    activationConstraint: {
      delay: 500,
      tolerance: 8,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor,);

  const getStatusDraftLabel = (status: string) => {
    switch (status) {
      case EBookingStatus.COMPLETED: return 'completed';
      case EBookingStatus.CANCELLED: return 'cancelled';
      case EBookingStatus.NO_SHOW: return 'no-show';
      case EBookingStatus.IN_PROGRESS: return 'in progress';
      default: return '';
    }
  };

  const checkScroll = () => {
    // if (isScrolled.current) return;
    const minutes = (workingHour?.open ?? 0) * 60 + (workingHour?.openMinute ?? 0);
    setTimeout(() => calendarBookingRef.current?.scrollTo({ top: minutes * pixelPerMinute, behavior: 'smooth' }), 0);
    isScrolled.current = true;
  };

  useEffect(() => {
    if (!activeLocation) return;
    !isNow && checkScroll();
  }, [activeLocation]);

  const isEmployeeBusy = (
    book: IBookingItemResDataNew,
    masterData: {
      serviceId: number;
      service_variant_id: number;
    },
    overDnD: IDndDataCalendar
  ) => {
    if (
      book.book_status === EBookingStatus.OPEN ||
      book.book_status === EBookingStatus.DRAFT ||
      book.book_status === EBookingStatus.IN_PROGRESS
    ) {
      const droppedBooking: IDroppedBookings[] = book.book_assignment_services.map((o) => {
        const result: IDroppedBookings = {
          bookingId: book?.id ?? null,
          employeeId: o?.assigned_employee?.employee?.id ?? null,
          serviceId: o?.service_id ?? null,
          serviceVariantId: o?.service_variant_id ?? null,
          time: null,
        };

        if (
          result.serviceId === masterData?.serviceId &&
          result.serviceVariantId === masterData?.service_variant_id
        ) {
          if (viewType === CalendarViewType.MemberView) {
            const employeeId = overDnD?.colData?.id;
            set(
              result,
              'employeeId',
              employeeId !== SYSTEM_VARIABLES.UNASSIGNED ? +employeeId : ''
            );
          } else {
            const day = overDnD?.colData?.id;
            set(result, 'day', day !== SYSTEM_VARIABLES.UNASSIGNED ? day : '');
          }
          const time = overDnD?.rowTime.format('HH:mm:ss');
          const bookingId = book?.id as unknown as string;

          set(result, 'time', time !== SYSTEM_VARIABLES.UNASSIGNED ? time : '');
          set(result, 'bookingId', bookingId !== SYSTEM_VARIABLES.UNASSIGNED ? +bookingId : null);
        }

        return result;
      });

      const filteredBookings: IFilterBookings = listBooking
        .filter(
          (booking) =>
            booking.book_status === EBookingStatus.OPEN ||
            booking.book_status === EBookingStatus.DRAFT ||
            booking.book_status === EBookingStatus.IN_PROGRESS
        )
        .map((booking) => ({
          booking_id: booking.id,
          book_assignment_services: booking.book_assignment_services.map((o: any) => {
            const timeStart = moment(o?.assigned_employee?.time_start).format('HH:mm:ss');
            const duration = moment.duration(o?.assigned_employee?.duration);
            const timeEnd = moment(o?.assigned_employee?.time_start)
              .add(duration)
              .format('HH:mm:ss');
            const day = moment(o?.assigned_employee?.time_start).format('DD/MM/YYYY');

            return {
              idEmployee: o?.assigned_employee?.employee?.id,
              service_id: o?.service_id,
              timeStart: timeStart,
              duration: o?.assigned_employee?.duration,
              timeEnd: timeEnd,
              day: day,
            };
          }),
        }));

      const isBusy: boolean = droppedBooking.some((o) => {
        const bookingTime = moment(o.time, 'HH:mm:ss');
        return filteredBookings.some(
          (o1) =>
            o1.booking_id !== o.bookingId &&
            o1.book_assignment_services.some((o2: any) => {
              if (viewType === CalendarViewType.MemberView) {
                const isBetween = bookingTime.isBetween(
                  moment(o2.timeStart, 'HH:mm:ss'),
                  moment(o2.timeEnd, 'HH:mm:ss'),
                  undefined,
                  '[)' // This specifies that the range is inclusive of the start and exclusive of the end
                );

                return o2.idEmployee === o.employeeId && isBetween;
              } else {
                if (o.day === o2.day) {
                  const isBetween = bookingTime.isBetween(
                    moment(o2.timeStart, 'HH:mm:ss'),
                    moment(o2.timeEnd, 'HH:mm:ss'),
                    undefined,
                    '[)'
                  );
                  return o2.idEmployee === o.employeeId && isBetween;
                }
                return false;
              }
            })
        );
      });
      if (isBusy) message.warning('Employee is busy');
    }
  };

  const isEmployeeInService = async (
    book: IBookingItemResDataNew,
    masterData: {
      serviceId: number;
      service_variant_id: number;
    },
    overDnD: IDndDataCalendar
  ) => {
    if (
      viewType === CalendarViewType.MemberView &&
      (book.book_status === EBookingStatus.OPEN ||
        book.book_status === EBookingStatus.DRAFT ||
        book.book_status === EBookingStatus.IN_PROGRESS)
    ) {
      const merchantCode: string = storage.merchantCode.get();
      const book_assignment_services: IBookAssignmentServices[] = book.book_assignment_services.map(
        (o) => {
          const result: IBookAssignmentServices = {
            employeeId: o?.assigned_employee?.employee?.id ?? null,
            serviceId: o?.service_id ?? null,
            serviceVariantId: o?.service_variant_id ?? null,
          };

          if (
            result.serviceId === masterData?.serviceId &&
            result.serviceVariantId === masterData?.service_variant_id
          ) {
            const employeeId = overDnD?.colData?.id;
            set(result, 'employeeId', employeeId !== SYSTEM_VARIABLES.UNASSIGNED ? +employeeId : '');
          }

          return result;
        }
      );

      try {
        const checkServicesValid = await Promise.all(
          book_assignment_services.map(async (service: IBookAssignmentServices) => {
            if (service.employeeId === null) {
              return false;
            }

            try {
              const res = await apisBooking.getServicesByIdEmployee(
                service.employeeId,
                merchantCode
              );
              const data: IServicesByEmployeeId[] = get(res, 'data.data', []);
              
              // Check if the service exists for the employee
              const isValid: boolean = data.some((o: IServicesByEmployeeId) => {
                if (service.serviceVariantId !== null) {
                  // Check if both serviceId and serviceVariantId match
                  return (
                    o.id === service.serviceId &&
                    o.service_variants.some((o1: any) => o1.id === service.serviceVariantId)
                  );
                } else {
                  // Check if only serviceId matches
                  return o.id === service.serviceId;
                }
              });

              if (!isValid) {
                return false;
              }

              return true;
            } catch (error) {
              console.error(`Error checking service for employeeId ${service.employeeId}:`, error);
              return false;
            }
          })
        );

        // Check the result to see if all services are valid or not
        return checkServicesValid.every(Boolean);
      } catch (error) {
        console.error('Error during service validation:', error);
        return false;
      }
    }

    return true;
  };
  
  // Realtime Line
  const RealTimeLine = ({
    workingHour,
    distanceMinutes,
  }: {
    workingHour: { open: number; close: number };
    distanceMinutes: number;
  }) => {
    const pixelPerMinute = bookingSelectors.getPixelPerMinute();
    const [currentTime, setCurrentTime] = useState<string>(moment().format());
    const _workingHour = useMemo(() => {
      const open = moment().set({ hour: workingHour.open, minute: 0, second: 0 });
      const close = moment().set({
        hour: workingHour.close,
        minute: 0,
        second: 0,
      });
      return {
        open,
        close,
      };
    }, [workingHour]);

    useEffect(() => {

      const time = setInterval(() => {
        const { close, open } = _workingHour;
        const now = moment().set({ second: 0 });
        if (now.isBetween(open, close)) {
          setCurrentTime(now.format());
        } else {
          clearInterval(time);
        }
      }, 30000);
      return () => {
        clearInterval(time);
      };
    }, [_workingHour]);

    const top = useMemo(() => {
      const { close, open } = _workingHour;
      const now = moment(currentTime);
      const currentSpaceMinutes = now.diff(open, 'minutes');

      // const selector = useAppSelector(state => state.booking.hasScrolled);
      // if (selector === false) {
      //   setTimeout(() => calendarBookingRef.current?.scrollTo({ top: currentSpaceMinutes * pixelPerMinute, behavior: 'smooth' }), 0);
      //   dispatch(bookingActions.setHasScrolled(true));
      // }   
      let space = 0;
      if (now.isBetween(open, close))
        space =  pixelPerMinute * (1 + currentSpaceMinutes);

      return space;
    }, [_workingHour, distanceMinutes, currentTime, pixelPerMinute]);

    if (!top) return null;

    useEffect(() => {
      if(data.length === 0) {
      const { open } = _workingHour;
        const currentSpaceMinutes = moment(currentTime).diff(open, 'minutes');        
      setTimeout(() => calendarBookingRef.current?.scrollTo({ top: currentSpaceMinutes * pixelPerMinute, behavior: 'smooth' }), 0);
        dispatch(bookingActions.setHasScrolled(true));
      }
    }, []);

    return (
      <div ref={realTimeRef} className='realtime-line' style={{ top }}>
        <div className='circle left'></div>
        <div className='line'></div>
        <div className='circle'></div>
      </div>
    );
  };

  return (
    <WrapCalendarBookingStyled>
      {(loading || loadingFilterOption || loadingParamOptionCalendar) && (
        <div className='loading-overLay'>
          <Spin />
        </div>
      )}
      {loadingBooking && (
        <div className='loading-overLay'>
          <Spin />
        </div>
      )}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={async (e) => {
          const book = data.find(o => String(e.active.id ?? '').includes(o?.id?.toString()));
          if (!book || !e.over?.data.current) return;
          const masterData = {
            serviceId: e.active?.data?.current?.serviceId,
            service_variant_id: e.active?.data?.current?.service_variant_id,
          };
          const employeeInService = await isEmployeeInService(
            book,
            masterData,
            e.over?.data.current as IDndDataCalendar
          );
          switch (book.book_status) {
            case EBookingStatus.DRAFT:
            case EBookingStatus.OPEN: {
              isEmployeeBusy(book,
                masterData,
                e.over?.data.current as IDndDataCalendar);
              if (viewType === CalendarViewType.MemberView) {
                if (!employeeInService) return message.warning('We cannot select team member who cannot perform the service.');
                return updateMemberView(
                  {
                    serviceId: e.active?.data?.current?.serviceId,
                    service_variant_id: e.active?.data?.current?.service_variant_id,
                  },
                  book, e.over?.data.current as IDndDataCalendar);
              }
              return updateDateView(book, e.over?.data.current as IDndDataCalendar);
            }
            case EBookingStatus.IN_PROGRESS: {
              isEmployeeBusy(book,
                masterData,
                e.over?.data.current as IDndDataCalendar);
              if (viewType === CalendarViewType.DaysView && !e.active.data.current?.serviceId) {
                return message.info('Please select a service to choose a member');
              } else {
                if (!e.active.data.current?.serviceId)
                  return navigate(`/private/bookings/edit-booking?bookId=${e?.active?.data?.current?.bookingId}&merchant_location_id=${merchant_location_id}`);
                else {
                  if (!e?.active?.data?.current?.assigned_employee?.employee || !pinRequired) {
                    if (!employeeInService) return message.warning('We cannot select team member who cannot perform the service.');
                    if (viewType === CalendarViewType.MemberView) {
                      return updateMemberView(
                        {
                          serviceId: e.active?.data?.current?.serviceId,
                          service_variant_id: e.active?.data?.current?.service_variant_id,
                        },
                        book, e.over?.data.current as IDndDataCalendar);
                    }
                    return updateDateView(book, e.over?.data.current as IDndDataCalendar);
                  } else {
                    if (!employeeInService) return message.warning('We cannot select team member who cannot perform the service.');
                    modalConfirmOTPRef.current?.show({
                      title: 'Enter pin number',
                      msg: '',
                      submit: async ({ pin }: { pin: string }) => {
                        if (viewType === CalendarViewType.MemberView) {
                          return updateMemberView(
                            {
                              serviceId: e.active?.data?.current?.serviceId,
                              service_variant_id: e.active?.data?.current?.service_variant_id,
                            },
                            book, e.over?.data.current as IDndDataCalendar, pin
                          );
                        }
                        return updateDateView(book, e.over?.data.current as IDndDataCalendar, pin);
                      },
                    });                   
                  }
                  return;
                }
              }
            }
            case EBookingStatus.COMPLETED:
              return message.warning('We cannot be re-scheduled ' + getStatusDraftLabel(book.book_status));
            case EBookingStatus.CANCELLED:
            case EBookingStatus.NO_SHOW: {
              updateDateView(book, e.over?.data.current as IDndDataCalendar);
              return;
            }
            default: {
              return message.warning('We cannot be re-scheduled ' + getStatusDraftLabel(book.book_status));
            }
          }
        }}
      >
        <CalendarBookingStyled ref={calendarBookingRef} distance={schema.distanceMinute} pixelPerMinute={pixelPerMinute} className='CalendarBookingStyled'>
          <table className={`table-overflow ${viewType}`}>
            <thead>
              <tr>
                <th className='time'>
                  <div className='col-table'>
                    <ClockCircleOutlined style={{ fontSize: '23px' }} />
                  </div>
                </th>
                {schema.headers.map((item) => {
                  if (typeof schema.renderHeader === 'function') {
                    return (
                      <th
                        key={item.id}
                        className={item.id === SYSTEM_VARIABLES.UNASSIGNED ? SYSTEM_VARIABLES.UNASSIGNED : ''}
                      >
                        <div className='col-table'>
                          {schema.renderHeader(item)}
                        </div>
                      </th>
                    );
                  }
                  return <HeaderCalendar key={item.id} label={item.label} />;
                })}
                {/* <th className='row-span' ><div className='col-table' /></th> */}
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => (
                <RowCalendar
                  key={row.id}
                  data={row}
                  rowsSpanInfo={rowsSpanInfo}
                  isSingleCol={isSingleCol}
                  distance={schema.distanceMinute}
                />
              ))}
              {isNow && <RealTimeLine
                distanceMinutes={schema.distanceMinute}
                workingHour={{
                  open: schema.timeHourStart,
                  close: schema.timeHourEnd,
                }}
              />}
            </tbody>
          </table>
        </CalendarBookingStyled>
        <DraggableOverlay data={data} />
      </DndContext>
      <ModalConfirmOTP ref={modalConfirmOTPRef} />
    </WrapCalendarBookingStyled >

  );
};

export default memo(CalendarBookingTable);

const heightRow = 92;

const WrapCalendarBookingStyled = styled.div`
  position: relative;
  .loading-overLay {
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
  }
`;
type CalendarBookingStyledProps = {
  pixelPerMinute: number;
  distance: number;
};
const CalendarBookingStyled = styled.div`
  position: relative;
  max-height: 75vh;
  z-index: 1;
  width: 100%;
  overflow: scroll;
  @media only screen and (max-width: 767.98px) {
    margin-bottom: 68px;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c3c2e0;
  }
  .table-overflow {
    min-width: 100%;
    tbody {
      position: relative;
      .realtime-line {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: 11;
        display: flex;
        align-items: center;
        .line {
          height: 2px;
          background: #e95060;
          width: 100%;
        }
        .circle {
          width: 10px;
          height: 10px;
          background: #e95060;
          border-radius: 30px;
          &.left {
            position: sticky;
            left: 0;
            z-index: 11;
          }
        }
      }
    }
  }
  tr:first-child th {
    div:not(.item-inner) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }
  td,
  th {
    color: var(--color-primary);
    text-align: center;
    vertical-align: middle;
    height: ${heightRow}px;

    &.row-span {
      width:100%;
    }

    // @media only screen and (max-width: 767.98px) {
    //   height: 50px;
    // }

    .col-table {
      border: 1px solid var(--color-white);
      height: ${heightRow}px;
      width: 100%;
      padding: 5px 0px;
      &.MemberView {
        padding:0px;
      }
      vertical-align: middle;
      font-size: 16px;
      .singleCol,
      &.singleCol {
        width: 250px;
      }
      &.flex-center {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.isBreakTime {
        position: relative;
        overflow: hidden;
        .frameBreakingTime {
          position: absolute;
          inset: 0;
          z-index:-1;
          display: flex;
          flex-direction: column;
          .breakingTimeOverlay {
            height: 100%;
            /* height:${({ distance = 0, pixelPerMinute }: CalendarBookingStyledProps) => (distance * pixelPerMinute) - 1}px; */
            border-bottom: 1px solid var(--color-white);
          }
        }
      }

      @media only screen and (max-width: 767.98px) {
        font-size: 16px;
      }
    }
    .wrap {
      height: 100%;
      width: 100%;
      overflow:hidden;
      // transform: translate(5px,5px);
      // padding-top:5px;
      // padding-left:5px;
      padding-right: 5px;

      &.first {
        padding-left: 0px;
      }
    }
    .subtext {
      display: block;
      width: 100%;
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: var(--color-primary);
      opacity: 0.6;
    }
    &.time {
      min-width: 100px;
      width: 100px;
      min-height: 60px;
      position: sticky;
      left: 0;
      background: #eef0f6;
      z-index: 10;
      @media only screen and (max-width: 767.98px) {
        min-width: 58px;
        min-height: 50px;
        height: 50px;
      }
    }
    &.${SYSTEM_VARIABLES.UNASSIGNED} {
      position: sticky;
      left: 100px;
      z-index: 10;
      background: #eff1f6;
      @media only screen and (max-width: 767.98px) {
        display: none;
      }
    }
  }
  th {
    min-width: 170px;
    height: 60px;
    position: sticky;
    top: 0;
    background: #eef0f6;
    z-index: 12 !important;
    &.unassigned {
      @media only screen and (max-width: 767.98px) {
        // min-width: 76px;
        display: none;
      }
    }
  }
  th.time {
    z-index: 14 !important;
  }
  th.${SYSTEM_VARIABLES.UNASSIGNED} {
    left: 100px;
    z-index: 14 !important;
    background: #eff1f6;
  }
  td, th {
    /* height:${({ distance = 0, pixelPerMinute }: CalendarBookingStyledProps) => distance * pixelPerMinute}px; */
    /* height: 105px; */
    .wrap {
      padding-top:0px;
    }
  }  
`;
