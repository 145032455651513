import { Switch } from 'antd';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

type Props = {
  onChange?: any;
  defaultChecked?: boolean;
  label: string;
  dataTesting?: string
  helperText?: string;
};

const FormSwitch = (props: Props) => {
  const { defaultChecked = true, onChange, label, dataTesting, helperText = '' } = props;

  return (
    <div className='form-row'>
      <div className='form-helper-text'>
        <p className='form-label'>{label}</p>
        {helperText && (
          <Tooltip placement='top' title={helperText} className='icon-helper-text'>
            <InfoCircleOutlined style={{fontSize: '0.9rem', color: '#4a9d77' }}/>
          </Tooltip>
        )}
      </div>
      <Switch
        data-testing={dataTesting}
        defaultChecked={defaultChecked}
        onChange={onChange}
        checkedChildren=''
        unCheckedChildren=''
      />
    </div>
  );
};

export default FormSwitch;
