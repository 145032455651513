import { Form, Modal, Spin } from 'antd';
import { AxiosResponse } from 'axios';
import message from 'components/common/Message';
import userActions from 'features/users/services/actions';
import userApis from 'features/users/services/apis';
import userSelectors from 'features/users/services/selectors';
import { get, set } from 'lodash';
import { useState, useTransition } from 'react';
import { useAppDispatch } from 'store/hooks';
import storage from 'utils/sessionStorage';
import ModalUserForm, { IFormAddStaffData, IFuncSubmit } from '../ModalUserForm';
import { useSearchParams } from 'react-router-dom';
import { DayLocation, WorkingTime } from 'features/locations/services/types/location';
import dayjs from 'dayjs';
import bookingActions from 'features/bookings/services/actions';
import bookingSelectors from 'features/bookings/services/selectors';
export interface IServiceItem {
  id: number;
  name: string;
  sale_price: number;
  service_variants: IServiceVariantItem[];
}

export interface IServiceVariantItem {
  id: number;
  name: string;
  price: string;
}
const UpdateUser = ({ record }: any) => {
  const [, startTransition] = useTransition();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currUser, setCurrUser] = useState<IFormAddStaffData | undefined>();
  const getPayload = usePayload(record);
  const getShowCreateStaffs = userSelectors.getShowCreateStaffs();
  const [errors, setErrors] = useState({});
  const [searchParams] = useSearchParams();  
  const loadingDataConfig = bookingSelectors.quickBooking.getLoadingDataConfigs();

  const dispatch = useAppDispatch();

  const handleCancel = () => {
    setIsModalOpen(false);
    setCurrUser(undefined);
    setErrors({});
  };

  const handleSubmit = async (values: any) => {
    setErrors({});
    
    const payload = getPayload(values);

    try {
      const rest: AxiosResponse<{ message: string }> =
        await userApis.updateStaffs(record.id, payload);
      if (rest.data) {
        message.success(rest.data.message);
        dispatch(userActions.setParams({
          page: Number(searchParams.get('page')),
          per_page: Number(searchParams.get('per_page'))

        }));
        handleCancel();
        return true;
      }
      return false;
    } catch (error: any) {
      const response = get<Record<string, string[]>>(
        error,
        'response.data.errors',
        {}
      );

      const errors = Object.entries(response);
      const errorsObj = {};
      errors.forEach(([key, value]) => {
        set(errorsObj, [key], value?.[0]);
      });
      setErrors(errorsObj);
      
      errors.map(([key, value]) => {
        message.error(get(value, [0], ''));
        if(key === 'phone') {
        }
      });      

      // if(get(errors, [0][0], '') === 'phone') {

      // }
      

      return false;
    }
  };

  const onClickEditUser = () => {

    const workingTime = {};
    record?.employee_shifts?.forEach((item: any) => {
      const time_start = dayjs(item.time_start, 'h:m:s');
      const time_end = dayjs(item.time_end, 'h:m:s');
      const exists = get(workingTime, [item.weekday]);
      if (exists) {
        exists.push({
          weekday: item.weekday,
          time_start: time_start.isValid() ? time_start : null,
          time_end: time_end.isValid() ? time_end : null,
        });
      } else {
        set(workingTime, [item.weekday], [{
          weekday: item.weekday,
          time_start: time_start.isValid() ? time_start : null,
          time_end: time_end.isValid() ? time_end : null,
        }]);
      }
    });
    
    setIsModalOpen(true);
    const services: string[] = [];
    (record?.services as IServiceItem[]).forEach(parent => {
      if (parent.service_variants.length === 0) {
        services.push(parent.id.toString());
      } else {
        parent.service_variants.map(sub => {
          services.push(parent.id + '-' + sub.id);
        });
      }
    });

    setCurrUser({
      name: record?.full_name ?? '',
      phone: record?.phone ?? '',
      email: record?.email ?? '',
      title: record?.title_name ?? '',
      pinNumber: record?.pin_number ?? '',
      location: getShowCreateStaffs?.merchant_locations.find((item?: any) => (item?.value == record?.location_id)),
      accessLevel: getShowCreateStaffs?.access_levels.find((item?: any) => (item?.value == record?.access_level)),
      isReceiveBook: record?.is_receive_book !== 0,
      services,
      workingTime,
      is_manager: record?.is_manager
    });

    dispatch(bookingActions.quickBooking.getDataConfigs.fetch());

  };

  return (
    <>
      <li className='action-edit' onClick={() => startTransition(onClickEditUser)}></li>
      <ModalUpdateUser errors={errors} visible={isModalOpen} handleSubmit={handleSubmit} handleCancel={handleCancel} currUser={currUser} modalLoading={loadingDataConfig}/>
    </>
  );
};

const usePayload = (record: any) => {

  return (data: IFormAddStaffData) => {

    const merchant_id = storage.merchantId.get() ?? '';
    const full_name = data?.name;
    const employee_id = record?.employee_id ?? '';
    const phone = data?.phone ?? '';
    // const email = data?.email ?? '';
    const access_level = Number(data?.accessLevel?.value ?? data?.accessLevel);
    const title_name = data?.title ?? '';
    const merchant_location_id = Number(data?.location?.value ?? data?.location);
    const pin_number = data?.pinNumber;
    const is_receive_book = data?.isReceiveBook;
    
    if(!data.workingTime ) return;

    const weekWorkingTime: any = [];
    Object.values(data.workingTime as WorkingTime).forEach((workTime) => {
      workTime.forEach((element: any) => {
        weekWorkingTime.push(element);
      });
    });
  
    const weekday_working_times = weekWorkingTime.map((item: DayLocation) => ({
      weekday: item?.weekday,
      time_start: item?.time_start?.format('HH:mm:ss'),
      time_end: item?.time_end?.format('HH:mm:ss'),
    }));

    const services: {
      id: number,
      service_variant_ids: number[]
    }[] = [];
    data.services?.forEach(ids => {
      const [parentId, subId] = ids.split('-');
      const parent = services.find(o => o.id === +parentId);
      if (parent) {
        const idx = parent.service_variant_ids.indexOf(+subId);
        if (idx === -1) {
          parent.service_variant_ids.push(+subId);
        }
      } else {
        services.push({
          id: +parentId,
          service_variant_ids: [+subId],
        });
      }
    });
    const payload: any = {
      merchant_id,
      full_name,
      phone,
      // email,
      access_level,
      title_name,
      merchant_location_id,
      services,
      employee_id,
      pin_number,
      is_receive_book,
      weekday_working_times
    };
    return payload;
  };
};

type ModalProps = {
  errors?: any;
  visible?: boolean,
  handleCancel?: () => void;
  handleSubmit: IFuncSubmit;
  currUser?: IFormAddStaffData;
  modalLoading?: boolean
};
const ModalUpdateUser = ({ visible, errors, handleSubmit, currUser, handleCancel = () => undefined, modalLoading }: ModalProps) => {
  if (!visible) return null;

  return (
    <Modal
      maskClosable={false}
      open={visible}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={574}
      footer={null}
      forceRender
      centered

    >
      <h2 className="modal-title">
        <p>Edit User</p>
      </h2>

      <Spin spinning={modalLoading}>
        <Form
          name="basic"
          className="modal-form"
          onFinish={handleSubmit}
          autoComplete="off"
          layout="vertical"
        >
          <ModalUserForm
            isEdit
            defaultData={currUser}
            onCancel={handleCancel}
            errors={errors}
            onSubmit={handleSubmit} />
        </Form>
      </Spin>
    </Modal>
  );
};

export default UpdateUser;