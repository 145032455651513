import { Form, Input, InputNumber } from 'antd';
import invoiceSelectors from 'features/invoices/services/selectors';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useHandleSubmit } from '../hooks/useHandleSubmit';
import { useDispatch } from 'react-redux';
import invoiceActions from 'features/invoices/services/actions';
import bookingActions from 'features/bookings/services/actions';
import { getFormatInputCurrency } from 'utils/unit';

interface ICashPayment {
  onCancel: () => void;
  onBack: () => void;
  total: number;
}

const CashPayment = ({ onCancel, onBack, total }: ICashPayment) => {

  const [form] = Form.useForm();
  const invoiceDetail = invoiceSelectors?.detailInvoice;
  const invoiceNo = invoiceDetail?.getInvoiceNo();
  const setPageLoading = useSetLoadingPage();
  const dispatch = useDispatch();

  const handleSubmit = async (values: any) => {
    setPageLoading(true);
    useHandleSubmit(values, invoiceNo ?? '');
    setPageLoading(false);
    dispatch(invoiceActions.getInvoiceDetail.fetch(invoiceNo?.replace('#', '') ?? ''));
    dispatch(bookingActions.getBookings.fetch({}));
    onCancel();
  };

  return (
    <>
      <h2 className='modal-title'>
        <p>Refund cash payment</p>
      </h2>
      <Form
        form={form}
        name='basic'
        onFinish={handleSubmit}
        autoComplete='off'
        layout='vertical'
      >
        <div className='modal-body'>
          <Form.Item initialValue={total === 0 ? invoiceDetail?.getData()?.total_price : total} name={'amount'} label={'Amount given'} rules={[{ required: true }]}>
            <InputNumber
              precision={getFormatInputCurrency().precision}
              // formatter={(value: any) => {
              //   if (value === undefined) {
              //     return '';
              //   }
              //   return parseFloat(value)?.toFixed(2);
              // }}
              addonBefore={getFormatInputCurrency().addonBefore} min={1} max={invoiceDetail?.getData()?.total_price ?? 2} />
          </Form.Item>
          <Form.Item name={'note'} label={'Reason for refund'}>
            <Input placeholder='e.g why do customers return their purchases' />
          </Form.Item>
        </div>
        <div className='mixmethod-footer'>
          <div
            className='common-btn is-white'
            onClick={onBack}
          >
            Back
          </div>
          <button type='submit' className='common-btn'> Refund</button>
        </div>
      </Form>
    </>
  );
};

export default CashPayment;