import message from 'components/common/Message';
import apisBooking from 'features/bookings/services/apis';
import bookingSelectors from 'features/bookings/services/selectors';
import settingSelectors from 'features/settings/services/selectors';
import { get } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import PrimaryButton from 'components/common/Buttons/PrimaryButton';
import { DividerStyled } from 'features/bookings/components/BookingForm/Confirm/BasicContent';
import Section from 'features/bookings/components/BookingForm/Section';
import ServiceDetails from 'features/bookings/components/BookingForm/Confirm/ServiceDetails';
import Location from 'features/bookings/components/BookingForm/Confirm/Location';
import CustomerInfo from 'features/bookings/components/BookingForm/Confirm/CustomerInfo';
import BookingDetails from 'features/bookings/components/BookingForm/Confirm/BookingDetails';
import { useAppDispatch } from 'store/hooks';
import bookingActions from 'features/bookings/services/actions';
import { IBookingItemResData } from 'features/bookings/services/types/booking';
import ModalCancel, { IModalCancelSubmitFunc } from 'features/bookings/components/BookingCalendar/Table/ModalCancel';
import uiActions from 'services/UI/actions';

const BookingCancelPage = () => {

  const { merchant_code = '', book_id = '' } = useParams();
  const dispatch = useAppDispatch();
  const formValue = bookingSelectors.bookingOnline.getFormValues();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isPinRequired = settingSelectors.getIsRequirePin();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchApi = async () => {
      try {
        const res = await apisBooking.findBookingOnlineById(book_id, merchant_code);
        const data = get(res, 'data.data', '') as IBookingItemResData;
        if (isMounted && data) {
          
          //@ts-ignore
          dispatch(bookingActions.bookingOnlineServiceSelected.set(data.book_assignment_services.map(service => ({
            duration: service?.duration_time,
            id: service?.id,
            price: service?.service_price,
            quantity: service?.quantity,
            regular_price: service?.service_price,
            sale_price: service?.service_price,
            employee_id: service.assigned_employee?.employee.id,
            name: service.service_name,
            merchant_employees: [{
              full_name: service.assigned_employee?.employee.full_name,
              id: service.assigned_employee?.employee.id,
              merchant_location_id: data.merchant_location_id,
              is_receive_book: 1,
              title_name: ''
            }]
          }))));

          dispatch(bookingActions.setBookingOnlineFormValues({
            customerCode: data?.customer?.customer_code,
            book_assignment_code: data?.book_assignment_code,
            bookingTime: data?.time_start,
            name: data?.customer?.name,
            email: data?.customer?.email,
            phoneNumber: data?.customer?.phone_number,
            location: data?.merchant_location_id,
            book_assignment_services: (data?.book_assignment_services ?? []).map(service => ({
              duration_time: service?.duration_time ?? 0,
              employee_id: service?.assigned_employee?.employee?.id ?? '',
              sale_price: service?.service_price ?? 0,
              service_id: service?.service_id ?? '',
              time_start: service?.assigned_employee?.time_start ?? '',
              quantity: service?.quantity ?? '',
              service_variant_id: service?.service_variant_id?.toString() ?? '',
            }))
          }));
          

        } else {
          const msgError = get(res, 'data.error.message');
          if (isMounted && msgError) message.error(msgError);
        }
      } catch (error) {
        message.error('An error occurred. Please try again');
      }
    };

    fetchApi();

    let isMounted = true;
    return () => {
      isMounted = false;
    };
  }, []);

  const handleCancel = () => setIsModalOpen(false);
  const handleOpen = () => setIsModalOpen(true);

  const handleSubmit: IModalCancelSubmitFunc = async (values) => {
    setIsModalOpen(false);

    try {
      dispatch(uiActions.setLoadingPage(true));

      const res = await apisBooking.cancelBookingOnline(String(book_id), { note: values.note, reason_id: values.reason });
      const msg = get(res, 'data.message', '');
      if (msg) {
        message.success(msg);
        navigate('/book-cancel-success', {
          state: {
            bookCode: formValue?.book_assignment_code,
            timeStart: formValue?.bookingTime
          }
        });
      } else {
        const errorMsg = get(res, 'data.error.message');
        if (errorMsg) {
          message.error(errorMsg);
        } else {
          throw 'fail';
        }
      }
    } catch (error) {
      message.error('An error occurred. Please try again');
    } finally {
      dispatch(uiActions.setLoadingPage(false));
    }
  };

  return (
    <>
      <CancelStyled>
      <Section label='Cancel'>
        <Fragment>
          <CustomerInfo />
          <Location isCheckin={true} locationId={formValue?.location?.toString()} />
          <DividerStyled />
          <BookingDetails />
          <DividerStyled />
          <ServiceDetails />
        </Fragment>
      </Section>
      <div className='search-wrapper'>
        <PrimaryButton type='button' label={'Cancel'} className='primary_button' onClick={handleOpen} />
      </div>
      {!!isModalOpen && <ModalCancel visible onCancel={handleCancel} onSubmit={handleSubmit} isPin={isPinRequired} />}
    </CancelStyled>
    </>
  );
};

export default BookingCancelPage;

const CancelStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  width: 100%;
  .primary_button {
    display: flex;
    align-self: center;
  }
  .search-wrapper {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 32px;
    }
    .icon-back {
      width: 64px;
      height: 64px;
      background: #fff;
      border-radius: 6px;
    }
  }
`;