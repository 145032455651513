import { Select } from 'antd';
import bookingActions from 'features/bookings/services/actions';
import apisBooking from 'features/bookings/services/apis';
import bookingSelectors from 'features/bookings/services/selectors';
import { IApiBookingParams } from 'features/bookings/services/types/booking';
import { memo, useEffect, useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';

const LocationPicker = () => {
  const dispatch = useAppDispatch();
  const lstMerchantLocations = bookingSelectors.getLstMerchantLocations();
  const value = (bookingSelectors.getCalendarParamValue('merchant_location_id') ?? 'all') as IApiBookingParams['merchant_location_id'];
  const locationCurrent = bookingSelectors.getCalendarCurrentLocation()?.id;
  const locationParam = bookingSelectors.getCalendarParamValue('merchant_location_id');

  const options = useMemo(() => {
    if (lstMerchantLocations?.length < 1) {
      dispatch(bookingActions.getOptionFilter.fetch());
      dispatch(bookingActions.getParamOptionCalendar.fetch());
    }
    else {
      return lstMerchantLocations.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
    }
  }, [lstMerchantLocations]);

  useEffect(() => {
    if (locationCurrent) {
      onChange(locationCurrent);
    }
  }, [locationParam, options]);

  // if (options.length < 1) return null;

  const onChange = async (val: string | number | undefined) => {    
    dispatch(bookingActions.setCalendarBookingParams({
      merchant_location_id: val,
      employee_id: undefined,
    }));
    await apisBooking.storeParamOptionCalendar({action_behavior: 'merchant_location_id', action_behavior_value: Number(val)});
    await apisBooking.storeParamOptionCalendar({action_behavior: 'employee_filter', action_behavior_value: []});
    // dispatch(bookingActions.getParamOptionCalendar.fetch());

    return;
  };

  return (
    <Select
      value={value}
      options={options}
      onChange={onChange}
      className='is-normal'
    />
  );
};

export default memo(LocationPicker);
