import {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useAppDispatch} from 'store/hooks';
import styled from 'styled-components';
import apisBooking from 'features/bookings/services/apis';
import bookingActions from 'features/bookings/services/actions';
import bookingSelectors from 'features/bookings/services/selectors';
import {IMerchantPublicInfoResData} from 'features/bookings/services/types/bookingOnline';

const PrivacyPolicy: React.FC = () => {
  const {merchant_code = ''} = useParams();
  const dispatch = useAppDispatch();

  const locationsStore = bookingSelectors.bookingOnline.getLocations();
  const [merchantPublicInfo, setMerchantPublicInfo] =
    useState<IMerchantPublicInfoResData>();

  useEffect(() => {
    document.title = 'Privacy Policy';

    (async () => {
      try {
        const res = await apisBooking.getPublicMerchantInfo(merchant_code);
        setMerchantPublicInfo(res.data.data);
      } catch (error) {
        console.log('Error get public merchant info: ', error);
      }
    })();

    dispatch(bookingActions.getBookingOnlineData.fetch(merchant_code));
  }, []);

  return (
    <PrivacyPolicyStyled>
      <h1>HEYA Privacy Policy</h1>
      <h3>HEYA Privacy Policy</h3>
      <div className="param">
        <h4>Introduction</h4>
        <p>
          HeyaPos (&quot;we&quot;, &quot;us&quot;, &quot;our&quot;) is committed
          to protecting the privacy of individuals (&quot;you&quot;,
          &quot;your&quot;) who use our platform to book appointments and make
          payments for services at our merchants&apos; stores. This Privacy
          Policy outlines how both HeyaPos and our merchants collect, use,
          store, and share your personal information. Our platform connects to
          the Tyro Online Payment Portal to process payments securely.
        </p>
      </div>
      <h4>Collection of Information</h4>
      <div className="param">
        <h4>Information We Collect</h4>
        <p>
          When you make a booking or payment via our platform, we collect the
          following information on behalf of the merchant:
        </p>
        <ul>
          <li>
            <strong>Personal Information</strong>: Your name, contact details
            (email address and phone number), and any other information you
            provide during the booking process.
          </li>
          <li>
            <strong>Booking Information</strong>: Details of your appointment,
            including the service booked, date and time, and any special
            requests or notes.
          </li>
          <li>
            <strong>Payment Information</strong>: Payment card details, which
            are securely transmitted to the Tyro Online Payment Portal.
          </li>
        </ul>
      </div>
      <h4>Use of Information</h4>
      <div className="param">
        <h4>HeyaPos Responsibilities</h4>
        <p>HeyaPos uses the collected information to:</p>
        <ul>
          <li>
            Facilitate and confirm your bookings with the chosen merchant.
          </li>
          <li>Securely process payments and send payment confirmations.</li>
          <li>
            Maintain and improve our platform&apos;s functionality and security.
          </li>
          <li>Provide technical support and customer service</li>
        </ul>
      </div>
      <div className="param">
        <h4>Merchant Responsibilities</h4>
        <p>Merchants using our platform are responsible for:</p>
        <ul>
          <li>
            Managing and fulfilling your booking and providing customer service.
          </li>
          <li>
            Communicating with you regarding your appointment and any changes.
          </li>
          <li>
            Ensuring the accuracy and security of the personal information
            collected from you.
          </li>
        </ul>
      </div>
      <div className="param">
        <h4>Security of Payment Information</h4>
        <p>
          We prioritise the security of your payment information. All card
          information is captured on a Hosted Payment Page provided by Tyro and
          stored in a PCI-DSS compliant environment. This ensures that your
          payment details are securely transmitted and protected against
          unauthorised access.
        </p>
      </div>
      <h4>Storage and Security of Personal Information</h4>
      <div className="param">
        <h4>HeyaPos Responsibilities</h4>
        <ul>
          <li>
            <strong>Data Protection</strong>: We store your personal information
            in secure databases protected by industry-standard security
            measures, including encryption and access controls.
          </li>
          <li>
            <strong>Access Control</strong>: Only authorised personnel have
            access to your personal information, and they are required to
            maintain its confidentiality.
          </li>
          <li>
            <strong>Data Breach Response</strong>: We have protocols in place to
            respond to data breaches, including notifying affected parties as
            required by law.
          </li>
        </ul>
      </div>
      <div className="param">
        <h4>Merchant Responsibilities</h4>
        <ul>
          <li>
            <strong>Data Management</strong>: Merchants are responsible for the
            accurate and secure handling of your personal information once it is
            collected through our platform.
          </li>
          <li>
            <strong>Customer Interaction</strong>: Merchants must ensure that
            they comply with applicable data protection laws when interacting
            with your personal data.
          </li>
        </ul>
      </div>
      <div className="param">
        <h4>Sharing of Information</h4>
        <p>
          We do not share your personal information with third parties, except
          in the following circumstances:
        </p>
        <ul>
          <li>
            <strong>With Merchants</strong>: Your booking and contact details
            are shared with the merchant where you have booked a service to
            facilitate your appointment.
          </li>
          <li>
            <strong>With Service Providers</strong>: Who assist us in operating
            our platform and providing our services, and who are contractually
            obligated to keep your information confidential.
          </li>
          <li>
            <strong>Legal Requirements</strong>: When required by law or to
            protect the rights, property, or safety of HeyaPos, our customers,
            or others.
          </li>
        </ul>
      </div>
      <div className="param">
        <h4>Your Rights</h4>
        <p>You have the right to:</p>
        <ul>
          <li>Access and correct your personal information.</li>
          <li>Request the deletion of your personal information.</li>
          <li>
            Withdraw your consent to the processing of your personal
            information.
          </li>
        </ul>
        <p>
          To exercise these rights, please contact us at [insert contact email].
        </p>
      </div>
      <div className="param">
        <h4>Changes to This Privacy Policy</h4>
        <p>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page, and the revised policy will take effect
          immediately upon posting. We encourage you to review this Privacy
          Policy periodically to stay informed about how we are protecting your
          information.
        </p>
      </div>
      <div className="param">
        <h4>Contact Us</h4>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          privacy practices, please contact us at:
        </p>
        <p>
          <strong>Booking Platform Provider (”HeyaPos”)</strong>
        </p>
        <p>HeyaPos Pty Ltd., Trading as “HeyaPos”</p>
        <p>10 Penza Court</p>
        <p>Keilor Downs VIC 3038</p>
        <p>Australia</p>
        <p>Email: admin@heyapos.com</p>
        <p>Phone: 0432 648 531</p>
        <p>
          <strong>Merchant Details</strong>
        </p>
        {locationsStore.length > 0 && <p>{locationsStore[0].address}</p>}
        {merchantPublicInfo && (
          <>
            <p>{merchantPublicInfo.full_name}</p>
            <p>{merchantPublicInfo.email}</p>
            <p>{merchantPublicInfo.phone}</p>
          </>
        )}
      </div>
      <p>
        By using our platform to book appointments and make payments, you
        acknowledge and agree to the shared responsibilities for the protection
        of your information as described in this Privacy Policy.
      </p>
    </PrivacyPolicyStyled>
  );
};

export default PrivacyPolicy;

const PrivacyPolicyStyled = styled.div`
  padding-inline: 20%;
  padding-top: 100px;
  padding-bottom: 30vh;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media only screen and (min-width: 768px) and (max-width: 1024.98px) {
    padding: 40px 58px;
  }

  @media only screen and (max-width: 767.98px) {
    padding: 40px 12px;
  }

  * {
    color: #37352f;
  }

  h1 {
    font-size: 40px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  .param {
    display: flex;
    flex-direction: column;
    gap: 10px;

    ul {
      padding-inline-start: 20px;
      list-style-type: disc;

      li {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 12px;

        &::marker {
          font-size: 20px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;
